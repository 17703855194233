import { FiChevronLeft } from 'react-icons/fi';

const FilterTop = ({ setIsFilterOpen, title }: any) => {
  return (
    <div className=" hidden sm:flex justify-between w-full h-14 items-center px-4 ">
      <span className="opacity-0 text-md font-bold w-full">{title}</span>
      <FiChevronLeft
        className="h-6 w-6 hover:bg-gray-100 active:bg-gray-200 rounded-full cursor-pointer"
        onClick={() => setIsFilterOpen(false)}
      />
    </div>
  );
};

export default FilterTop;
