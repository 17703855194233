import React from 'react';
import { PromotedProfile } from '../../types/types';
import { trackPromotedCardClick } from '../../services/promotedCardService';
interface IPropPromotedCard {
  card: PromotedProfile;
}

const PromotedCard = ({ card }: IPropPromotedCard) => {
  const handleCardClick = async () => {
    // Track the click
    await trackPromotedCardClick(card.id);

    // Open the card link in a new tab
    window.open(card.link, '_blank');
  };

  return (
    <div
      className="market-card bg-white rounded-none flex items-center justify-center"
      key={card.id}
      onClick={handleCardClick}
    >
      <img src={card.image} alt={`Promoted card - ${card.id}`} />
    </div>
  );
};

export default PromotedCard;
