import React, { useState, useEffect, useCallback } from 'react';
import { getPostByIdService } from '../../../services/postService';
import { toggleLikeCommentService, deleteCommentService } from '../../../services/commentService';
import { Post, HeaderUser, PostContainer } from '../../square/Square';

import { useAuth } from './../../../contexts/auth';
import { useGlobal } from './../../../contexts/global';
import { AiFillHeart } from 'react-icons/ai';
import Loader from './../../main/Loader';
import { adjustImageSize } from '../../../utils/adjustImageSize';

type Comment = {
  commentId: number;
  author: any;
  businessId?: number;
  createdAt: string;
  likesPerComment: any;
  content?: string;
  media: any[];
};

const CommentBox = ({
  comment: { commentId, author, content = '', media = [], createdAt, likesPerComment },
  setComments,
}: {
  comment: Comment;
  setComments: React.Dispatch<React.SetStateAction<Comment[]>>;
}) => {
  const { currentUser } = useAuth();
  const [isLiked, setIsLiked] = useState(
    likesPerComment.some((like: any) => like.userId === currentUser?.id)
  );
  const [numberOfLikes, setNumberOfLikes] = useState(likesPerComment.length);

  const toggleLike = async (id: number) => {
    try {
      const res = await toggleLikeCommentService(id);
      setIsLiked(res.data.liked);
      if (res.data.liked) {
        setNumberOfLikes((prev: number) => prev + 1);
      } else {
        setNumberOfLikes((prev: number) => prev - 1);
      }
    } catch (error: any) {
      alert(error.message);
    }
  };

  const deleteComment = async (id: number) => {
    try {
      await deleteCommentService(id);

      setComments((prevComments: Comment[]) =>
        prevComments.filter((comment: Comment) => comment.commentId !== id)
      );
    } catch (error: any) {
      alert(error.message);
    }
  };

  return (
    <div className="w-full border border-transparent border-t-deehiy flex flex-col py-3">
      <HeaderUser user={author} createdAt={createdAt}>
        {author.roles === 'admin' ? (
          <div className="cursor-pointer hover:text-black" onClick={() => deleteComment(commentId)}>
            Delete
          </div>
        ) : (
          <div
            className="cursor-pointer hover:text-black"
            onClick={() => window.open(`/member/${author.id}`, '_blank')}
          >
            Open profile
          </div>
        )}
      </HeaderUser>
      <div className="flex flex-col pl-3 w-full py-6">
        <div dangerouslySetInnerHTML={{ __html: content }}></div>
        <div>
          {media &&
            media.length > 0 &&
            media.map((img: any) => (
              <img
                key={img.url}
                src={adjustImageSize(img.url)}
                className="max-h-52 mx-auto max-w-md"
                alt=""
              />
            ))}
        </div>
      </div>

      <div className="flex gap-x-1 w-36 items-center px-2">
        <AiFillHeart
          onClick={() => {
            toggleLike(commentId);
          }}
          className={`w-4 h-4 sm:h-4 sm:w-4
                ${isLiked ? 'text-deehiy-red' : 'text-gray-300'}
                 duration-200 cursor-pointer`}
        />
        <div>{numberOfLikes}</div>
      </div>
    </div>
  );
};

const PostPage = () => {
  const [postId, setPostId] = useState<string>('');

  const { showModal, setShowModal, setModalType } = useGlobal();

  const [comments, setComments] = useState<Comment[]>([]);
  const [currentPost, setCurrentPost] = useState(null);
  const [editedPost, setEditedPost] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const getPost = useCallback(async (id: number) => {
    try {
      setIsLoading(true);
      const res = await getPostByIdService(id);

      console.log('POST', res.data.post);

      setCurrentPost(res.data.post);
      setComments(res.data.post.comments);
    } catch (err: any) {
      setCurrentPost(null);
      setComments([]);
      setError(err.response.data.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    getPost(Number(postId));
  };

  useEffect(() => {
    if (editedPost) {
      setShowModal(true);
      setModalType(editedPost.type);
    }
  }, [editedPost]);

  useEffect(() => {
    if (!showModal) {
      setEditedPost(null);
    }
  }, [showModal]);

  return (
    <div>
      <div className="width-overlay-layout mx-auto pb-20">
        <div className="z-40 top-16 bg-white w-full mx-5">
          {isLoading && <Loader />}
          <form onSubmit={handleSubmit} className="mb-4">
            {error && (
              <div className="flex flex-col mx-auto p-12 items-center justify-center gap-y-12">
                <span>{error}</span>
              </div>
            )}
            <input
              type="number"
              placeholder="Enter post ID"
              value={postId}
              onChange={(e) => setPostId(e.target.value)}
              className="border border-gray-300 rounded p-2"
            />
            <button type="submit" className="ml-2 p-2 bg-deehiy text-white rounded">
              Get Post
            </button>
          </form>
        </div>
        <div className="mx-5 w-full mt-5">
          {currentPost && (
            <PostContainer>
              <Post
                post={currentPost}
                isPostPage={true}
                nbrOfComments={comments.length}
                setEditedPost={setEditedPost}
              />
              {comments.length > 0 &&
                comments.map((com) => {
                  return <CommentBox key={com.commentId} comment={com} setComments={setComments} />;
                })}
            </PostContainer>
          )}
        </div>
      </div>
    </div>
  );
};

export default PostPage;
