import React, { useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { businessStep1FormSchema } from '../../../lib/yup';
import { ImageUploader } from './ImageUploader';
import Input from '../../form/Input';
import Select from '../../form/Select';
import { AGENCY, CONSULTANCY, DEVELOPER } from '../../../constants/constant';
import { useBusiness } from '../../../contexts/business';
import { ImageProfileUpload } from '../../../utils/uploader';
import { useTranslation } from 'react-i18next';

const Step1 = ({ setIndex, images, setImages }: any) => {
  const methods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(businessStep1FormSchema),
  });
  const { business, setBusinessContext } = useBusiness();
  const { t } = useTranslation();
  const [progress, setProgress] = useState(0);
  const [canClickNext, setCanClickNext] = useState(false);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    console.log({ ...business, [name]: value });
    setBusinessContext({ ...business, [name]: value });
  };

  useEffect(() => {
    if (images[0]) {
      ImageProfileUpload(images[0].file, (prog: number) => {
        setProgress(prog);
      }).then((res) => {
        const { secureUrl } = res;
        setBusinessContext({ ...business, image: secureUrl });
      });
    }
  }, [images]);

  useEffect(() => {
    const { image, businessName } = business;
    if (image === '' || businessName === '') {
      setCanClickNext(false);
    } else {
      setCanClickNext(true);
    }
  }, [business.image, business.businessName]);

  return (
    <div className="h-full">
      <div className="space-y-6 pt-3 pb-3">
        <div className="w-full flex justify-center text-2xl sm:text-3xl md:text-4xl xl:text-5xl font-bold ">
          {t('Start building your Business')}
        </div>
        <div className="w-full flex text-center justify-center text-sm sm:text-md font-bold">
          {t('This will help you get professional exposure')} <br />
          {t('and improve client trust')}
        </div>
      </div>
      <div className="flex h-[62vh] pt-3">
        <div className=" hidden sm:flex w-1/2 justify-end">
          <img alt="add business" className=" mr-12" src="/images/addbusiness1.svg" />
        </div>
        <div className="sm:w-1/2 w-full">
          <div className="flex-col w-full h-full relative sm:w-3/5 mx-2 sm:mx-12 ">
            <FormProvider {...methods}>
              <form className="flex flex-col space-y-6 ">
                {/* business name  */}
                <Input
                  label={t('business name')}
                  type="text"
                  id="businessName"
                  name="businessName"
                  onChange={handleChange}
                  value={business.businessName}
                  placeholder={'business name'}
                />
                {/* business category */}
                <Select
                  id="businessCategory"
                  name="businessCategory"
                  label={t('business category')}
                  placeholder={t('select a category')}
                  onChange={handleChange}
                  value={business.businessCategory}
                >
                  <option value={AGENCY} key={AGENCY}>
                    {t(AGENCY)}
                  </option>
                  <option value={DEVELOPER} key={DEVELOPER}>
                    {t(DEVELOPER)}
                  </option>
                  <option value={CONSULTANCY} key={CONSULTANCY}>
                    {t(CONSULTANCY)}
                  </option>
                </Select>
                {/* upload business profile picture */}
              </form>
            </FormProvider>
            <div className="pt-4">
              <div className="text-sm pb-1">
                {t('upload business profile picture')}
                {progress < 100 && progress > 0 ? (
                  <span className="animate-pulse px-5 text-xs text-gray-700">
                    {t('uploading...')}
                  </span>
                ) : (
                  ''
                )}
              </div>
              <ImageUploader images={images} setImages={setImages} />
            </div>

            <div className="bottom-2 absolute w-full">
              <div className="xl:text-sm text-xs w-full pb-2 ">
                {t('continuing_agreeing')}{' '}
                <a className="text-deehiy" href="">
                  {t('Terms of Service')}
                </a>{' '}
                {t('and')}{' '}
                <a className="text-deehiy" href="">
                  {'Privacy Policy'}
                </a>
                .
              </div>
              <div className="w-full  flex justify-end ">
                <button
                  disabled={!canClickNext}
                  className={`form-next-btn ${!canClickNext && 'opacity-50 cursor-not-allowed'}`}
                  onClick={() => setIndex((prev: number) => prev + 1)}
                >
                  {t('next')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step1;
