import { Action } from '../types/types';
import { SET_CURRENT_USER, RESET_PASSWORD, GET_ERRORS, LOGOUT } from '../actions/actions';

export const userReducer = (state: any, action: Action) => {
  switch (action.type) {
    // context is not updated properly if the state is not changing
    case SET_CURRENT_USER:
      return { ...state, currentUser: action.payload };
    case LOGOUT:
      return { ...state, currentUser: null };
    case RESET_PASSWORD:
      return { ...state, message: action.payload };
    case GET_ERRORS:
      return { ...state, message: action.payload };
    default:
      return state;
  }
};
