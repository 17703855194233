import React from 'react';

// import FullPortalModal from './FullPortalModal';
import GalleryPortal from './GalleryPortal';
import GalleryTemplate from './GalleryTemplate';

// import Slideshow from './Slideshow';
import OpenGalleryBtn from '../form/OpenGalleryBtn';
import { adjustImageSize } from '../../utils/adjustImageSize';

const Gallery = React.memo(({ images }: any) => {
  const MainImage = ({ setShowModal }: any) => {
    return (
      <button
        className="rounded-l-3xl object-cover overflow-hidden  aspect-video relative h-full w-full"
        type="button"
        onClick={() => setShowModal(true)}
      >
        <img
          className="hover:opacity-90 hover:shadow-lg transition duration-300 ease-in-out w-full h-full bg-grey-dark rounded-l-3xl object-cover aspect-video "
          alt=""
          src={images[0]?.url && adjustImageSize(images[0]?.url)}
        />
      </button>
    );
  };

  return (
    <>
      <div className="hidden sm:block mx-auto relative">
        <div className="grid grid-cols-2 gap-1 sm:gap-3">
          <div className="h-auto ">
            <GalleryPortal Btn={MainImage}>
              <GalleryTemplate images={images} defaultIndex={0} />
            </GalleryPortal>
          </div>

          <div className=" grid grid-cols-2 gap-1 sm:gap-3">
            <GalleryPortal
              Btn={({ setShowModal }: any) => (
                <img
                  className="cursor-pointer hover:shadow-lg hover:opacity-90 transition duration-300 ease-in-out w-full object-cover aspect-video sm:aspect-video"
                  alt=""
                  onClick={() => setShowModal(true)}
                  src={images[1]?.url && adjustImageSize(images[1]?.url)}
                />
              )}
            >
              <GalleryTemplate images={images} defaultIndex={1} />
            </GalleryPortal>
            <GalleryPortal
              Btn={({ setShowModal }: any) => (
                <img
                  className="cursor-pointer hover:shadow-lg hover:opacity-90 transition duration-300 ease-in-out w-full  object-cover aspect-video sm:aspect-video rounded-tr-3xl"
                  alt=""
                  onClick={() => setShowModal(true)}
                  src={images[2]?.url && adjustImageSize(images[2]?.url)}
                />
              )}
            >
              <GalleryTemplate images={images} defaultIndex={2} />
            </GalleryPortal>

            <GalleryPortal
              Btn={({ setShowModal }: any) => (
                <img
                  className="cursor-pointer hover:shadow-lg hover:opacity-90 transition duration-300 ease-in-out w-full  object-cover aspect-video sm:aspect-video"
                  alt=""
                  onClick={() => setShowModal(true)}
                  src={images[3]?.url && adjustImageSize(images[3]?.url)}
                />
              )}
            >
              <GalleryTemplate images={images} defaultIndex={3} />
            </GalleryPortal>
            <GalleryPortal
              Btn={({ setShowModal }: any) => (
                <img
                  className="cursor-pointer hover:shadow-lg hover:opacity-90 transition duration-300 ease-in-out w-full  object-cover aspect-video sm:aspect-video rounded-br-3xl"
                  alt=""
                  onClick={() => setShowModal(true)}
                  src={images[4]?.url && adjustImageSize(images[4]?.url)}
                />
              )}
            >
              <GalleryTemplate images={images} defaultIndex={4} />
            </GalleryPortal>
          </div>
        </div>
        <div className="absolute bottom-3 sm:right-3 justify-center sm:justify-end">
          <GalleryPortal Btn={OpenGalleryBtn}>
            <GalleryTemplate images={images} />
          </GalleryPortal>
        </div>
      </div>
    </>
  );
});

export default Gallery;
