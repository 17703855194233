import React, { useState, useEffect } from 'react';
import { Outlet, useParams, useNavigate } from 'react-router-dom';
import { Business } from '../../types/types';
import { useBusiness } from '../../contexts/business';
import { useProperty } from '../../contexts/property';
import { useGlobal } from '../../contexts/global';
import CustomHeader from '../business/CustomHeader';

const CustomLayout = ({ children }: any) => {
  const { businessId, id: propertyId } = useParams();
  const { fetchBusinessByIdContext } = useBusiness();
  const [business, setBusiness] = useState<Business | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { setFilter, setTempFilter, tempFilter, filter } = useProperty();
  const { setIsWL, mainColor, setMainColor } = useGlobal();
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);

    if (businessId) {
      fetchBusinessByIdContext(Number(businessId)).then((data: Business) => {
        if (!data.isWhiteLabel) {
          navigate('/');
        } else {
          setBusiness(data);
          setMainColor(data.color);
          setFilter({ ...filter, businessId: Number(businessId) });
          setTempFilter({ ...tempFilter, businessId: Number(businessId) });
          setIsWL(true);
        }
        setIsLoading(false);
      });
    }
    // Cleanup function
    return () => {
      setFilter({ ...filter, businessId: undefined });
      setTempFilter({ ...tempFilter, businessId: undefined });
      setIsWL(false);
    };
  }, [businessId]);

  if (isLoading) return <>...loading</>;
  return (
    <div
      style={{ '--main-color': mainColor } as React.CSSProperties} // Type assertion
      className="dark:bg-slate-900 flex flex-col min-h-screen h-screen"
    >
      <header>
        {/* <div className="hidden sm:block"> */}
        <div className={`${propertyId && 'hidden sm:flex'}`}>
          {propertyId}
          <CustomHeader business={business} />
        </div>
      </header>
      {/* <main className="mx-auto pt-0 sm:pt-14 flex flex-col flex-grow w-full"> */}
      <main className="mx-auto pt-0 bg-white sm:bg-auth-bg sm:pt-24 flex flex-col flex-grow w-full">
        <Outlet />
        {children}
      </main>
    </div>
  );
};

export default CustomLayout;
