import React, { cloneElement, useEffect } from 'react';
import { AiOutlineClose } from 'react-icons/ai';

import MobileModal from '../main/modal/MobileModal';

const DesktopModal = ({ children, showModal, setShowModal, closeModal }: any) => {
  return (
    <>
      {showModal ? (
        <>
          <div className="fixed inset-0 z-50 overflow-y-auto">
            <div
              className="fixed inset-0 w-full h-full bg-gray-900 opacity-60"
              onClick={() => setShowModal(false)}
            ></div>
            <div className="flex items-center min-h-screen container max-w-[1400px] mx-auto">
              <div className={'relative mx-auto bg-white pt-6 pb-3 rounded-2xl shadow-lg'}>
                <div className="flex justify-start">
                  <button
                    className="text-deehiy duration-200 pl-6 text-xl"
                    onClick={() => setShowModal(false)}
                  >
                    <AiOutlineClose />
                  </button>
                </div>
                {/* {children} */}
                <div className="max-h-[80vh] overflow-auto">
                  {cloneElement(children, { onClose: closeModal })}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

const Modal2 = ({ children, showModal, setShowModal }: any) => {
  const closeModal = () => {
    setShowModal(false);
  };

  const isMobile = window.innerWidth <= 768;

  useEffect(() => {
    const handleEscapeKey = (event: any) => {
      if (event.key === 'Escape') {
        closeModal();
      }
    };

    window.addEventListener('keydown', handleEscapeKey);

    return () => {
      window.removeEventListener('keydown', handleEscapeKey);
      // Set the modal to not shown when the component unmounts
      setShowModal(false);
    };
  }, []);

  if (isMobile)
    return (
      <>
        <MobileModal isModalOpen={showModal} setIsModalOpen={setShowModal} children={children} />
      </>
    );

  return (
    <div>
      <div>
        <DesktopModal
          children={children}
          showModal={showModal}
          setShowModal={setShowModal}
          closeModal={closeModal}
        />
      </div>
    </div>
  );
};

export default Modal2;
