import React, { useState } from 'react';
import { countries } from '../../../data/data';
import { useInternationalization } from '../../../contexts/internationalization';
import { AiFillInfoCircle } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';

const CurrencyDropdown = ({ onClose }: any) => {
  const initialCountries = [...countries];
  const { currency, setCurrency } = useInternationalization();
  const [filter, setFilter] = useState('');
  const [filteredCountries, setFilteredCountries] = useState(initialCountries);
  const { t } = useTranslation();

  const handleFilterChange = (event: any) => {
    const filterValue = event.target.value;
    setFilter(filterValue);

    const filtered = countries.filter(
      (c) =>
        c.currency.toLowerCase().includes(filterValue.toLowerCase()) ||
        c.title.toLowerCase().includes(filterValue.toLowerCase())
    );
    setFilteredCountries(filtered);
  };

  const handleClick = (curr: any) => {
    setCurrency(curr);
    onClose();
  };

  return (
    <div className="flex relative flex-col shadow-2xl w-[400px] sm:w-[800px]">
      <div className="w-full flex items-center border border-transparent  border-b-gray-400 justify-between px-6 py-1">
        <div className=" font-bold">
          <span className=" border-b-2 pb-1 border-[#1d1c4b]">{t('Currency')}</span>
        </div>
        <input
          type="text"
          value={filter}
          onChange={handleFilterChange}
          placeholder={t('Search currency')}
          className="text-sm my-3 px-2 py-2 w-2/3 sm:w-2/5 rounded-xl border focus:shadow-xl duration-500 focus:border-deehiy border-gray-300 focus:outline-none focus:ring-0"
        />
      </div>
      <span className="text-xl px-6 py-4 font-light">{t('Choose a currency')}</span>
      <div className="grid grid-cols-3 auto-rows-max overflow-auto px-6 h-[500px] ">
        {filteredCountries &&
          filteredCountries.map((c: any, i: number) => (
            <button
              key={i}
              onClick={() => handleClick(c.currency)}
              className="flex flex-col justify-center border-2 border-transparent hover:bg-gray-100 py-1 h-14  duration-100"
            >
              <span className={`${currency === c.currency ? 'text-deehiy' : ''} font-bold `}>
                {c.title}
              </span>
              <span className="text-[#a5a5a5] whitespace-nowrap line-clamp-1">{c.currency}</span>
            </button>
          ))}
      </div>
      <div className=" text-deehiy bottom-0 flex border pl-6 border-transparent border-t-gray-400">
        <AiFillInfoCircle className="w-8 h-8 pr-4 pt-2" />
        <span className="text-xs pt-3">{t('text_currency')}</span>
      </div>
    </div>
  );
};

export default CurrencyDropdown;
