import emojiRegex from 'emoji-regex';

export const removeEmojis = (input: string) => {
  const regex = emojiRegex();
  return input.replace(regex, ' ');
};

// keep break line
export const nl2br = (str: string, isXhtml: boolean) => {
  var breakTag = isXhtml || typeof isXhtml === 'undefined' ? '<br ' + '/>' : '<br>';
  return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
};

// replace bold with normal
export const removeBoldText = (input: string) => {
  const regex = /<b>(.*?)<\/b>/gi;
  return input.replace(regex, '$1');
};

export const removeLeadingSpaces = (input: string) => {
  return input.trimStart();
};

export const cleanText = (input: string) => {
  const noEmoji = removeEmojis(input);
  const noBold = removeBoldText(noEmoji);
  const cleanedtext = removeLeadingSpaces(noBold);

  return cleanedtext;
};
