import React, { useCallback, useRef, useState } from 'react';
import { HexColorPicker, HexColorInput } from 'react-colorful';

import useClickOutside from '../../../hooks/useClickOutside';

interface PopoverPickerProps {
  color: string;
  onChange: (color: string) => void;
}

const PopoverPicker: React.FC<PopoverPickerProps> = ({ color, onChange }) => {
  const popover = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const close = useCallback(() => setIsOpen(false), []);
  useClickOutside(popover, close);

  return (
    <div className="picker">
      <div
        className="picker-swatch"
        style={{ backgroundColor: color }}
        onClick={() => setIsOpen(true)}
      />

      {isOpen && (
        <div
          className="picker-popover p-2 flex flex-col w-full items-center justify-center z-30"
          ref={popover}
        >
          <div>
            <HexColorPicker color={color} onChange={onChange} className="w-[600px]" />
          </div>
          <div className="flex gap-x-1 items-center pt-4 justify-between">
            <HexColorInput
              color={color}
              onChange={onChange}
              className="focus:outline-none focus:ring-0 w-32"
            />
            <button
              className="bg-white hover:text-deehiy border rounded-md whitespace-nowrap py-2 px-3 "
              onClick={() => setIsOpen(false)}
            >
              {' '}
              close X
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PopoverPicker;
