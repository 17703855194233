import React from 'react';
import TakeMeBack from '../main/navigation/TakeMeBack';
import { useTranslation } from 'react-i18next';

const HuntCreatedSuccessPage = () => {
  const { t } = useTranslation();

  const handleClick = () => {
    window.open('https://play.google.com/store/apps/details?id=com.deehiy.app', '_blank');
  };

  return (
    <div className="container px-4 sm:px-0 mx-auto flex items-center justify-center w-full flex-col h-[86vh]">
      <div className="w-full mt-3 sm:mt-1">
        <TakeMeBack loc="/users/dashboard" />
      </div>
      <div className="w-full flex flex-col flex-grow justify-start pt-4">
        <img
          src="/images/icons/icon-dashboard-landing/hunting-board.svg"
          className="mx-auto w-36 h-36"
        />
        <div className="flex font-bold mx-auto text-center mt-4 text-2xl sm:text-4xl">
          {t('hunt_on')}
        </div>
        <div className="flex text-center mx-auto text-base mt-3">{t('hunt_submitted')}</div>

        <div className="flex font-bold mx-auto text-center text-deehiy mt-11 text-2xl">
          NEVER MISS A LEAD!
        </div>
        <div className="flex max-w-[600px] mx-auto text-center mt-3 text-base">
          Download the Pulse app for Android to stay updated with leads and to communicate
          effortlessly with our community.
        </div>
        <div className="hidden sm:flex flex-col px-6 text-center mt-6 text-base">
          <div>Scan this QR code with your phone.</div>
          <img src="/images/download-pulse-app.svg" className="mx-auto mt-3 w-32 h-32" />
        </div>
      </div>
      <div className="flex sm:hidden flex-col px-8 text-center text-base">
        <div>Download the Pulse app for Android to stay updated with leads.</div>
        <img
          onClick={handleClick}
          src="/images/store_badge.png"
          alt="google store"
          className="w-32 mx-auto mt-3"
        />{' '}
      </div>
    </div>
  );
};

export default HuntCreatedSuccessPage;
