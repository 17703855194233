import React from 'react';
import { updateLanguage } from '../../services/auth/userService';
// import i18n from '../../i18n';
import { Dropdown } from 'flowbite-react';
import { useAuth } from '../../contexts/auth';
import { useTranslation } from 'react-i18next';
import { useInternationalization } from '../../contexts/internationalization';

const TranslationDropdown = () => {
  const { currentUser } = useAuth();
  const { t } = useTranslation();

  const { locale, setLocale } = useInternationalization();

  const updateUserLanguage = async (language: string) => {
    try {
      if (currentUser) {
        await updateLanguage(currentUser.id, language);
      }
    } catch (error) {
      console.error('Failed to update language:', error);
    }
  };

  function changeLocale(l: string) {
    if (locale !== l) {
      // i18n.changeLanguage(l);
      setLocale(l);
      updateUserLanguage(l); // Update the user language on the server
    }
  }

  return (
    <div>
      <Dropdown
        label={
          <div className="z-40 w-32 text-xs gap-x-2 items-center justify-center border-transparent outline-none focus:outline-none focus:ring-0 ring-0  font-normal flex text-defaultText">
            <img src={`/images/icons/flags/${locale}.svg`} className="w-4 h-4 " />
            {t(locale)}
          </div>
        }
        arrowIcon={false}
        placement="auto"
      >
        <Dropdown.Item onClick={() => changeLocale('th')} className="items-center flex gap-x-2">
          <img src="/images/icons/flags/th.svg" className="w-4 h-4 " />
          {t('Thai')}
        </Dropdown.Item>
        <Dropdown.Item onClick={() => changeLocale('en')} className="items-center flex gap-x-2">
          <img src="/images/icons/flags/en.svg" className="w-4 h-4 " />
          {t('English')}
        </Dropdown.Item>
      </Dropdown>
    </div>
  );
};

export default TranslationDropdown;
