import React, { useState, useEffect } from 'react';
import Loader from '../main/Loader';

import { useProperty } from '../../contexts/property';
import { useNavigate } from 'react-router-dom';

import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';

export default function StepperControl({
  handleClick,
  //handleCreate
  // currentStepIndex,
  // steps,
  step,
  canClickNext,
}: any) {
  const [isSubmitStep, setIsSubmitStep] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { createProperty, currentProperty } = useProperty();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const createNewProperty = async () => {
    setIsLoading(true);
    const id = toast.loading('Please wait...');

    createProperty(currentProperty).then((res) => {
      if (res === 'success') {
        toast.update(id, { render: 'success', type: 'success', isLoading: false });
        if (currentProperty.businessId) {
          navigate('/propertycreated/1'); // pass a param for the redirection in the next page
        } else {
          navigate('/propertycreated');
        }
      } else {
        toast.update(id, { render: 'Something went wrong', type: 'error', isLoading: false });
      }
      setIsLoading(false);
    });
  };

  const handleNextorSubmit = () => {
    if (isSubmitStep) {
      createNewProperty();
    } else {
      handleClick('next');
    }
  };

  useEffect(() => {
    setIsSubmitStep(step.isSubmit);
  }, [step]);

  return (
    <>
      <div className="flex items-center mx-auto justify-between w-full gap-x-4">
        {step.id !== 1 ? (
          <button onClick={() => handleClick()} className="form-back-btn">
            {t('back')}
          </button>
        ) : (
          <div></div>
        )}
        <div className="space-x-8 flex">
          <button
            disabled={canClickNext ? false : true}
            onClick={() => handleClick('next')}
            className={`${!step.canSkip && 'hidden'}
        whitespace-nowrap cursor-pointer font-semibold text-base text-deehiy 2xl:text-xl py-4 px-2 underline`}
          >
            {t('skip')}
          </button>
          <button
            disabled={canClickNext || isLoading ? false : true}
            onClick={handleNextorSubmit}
            className={`${
              canClickNext ? 'opacity-100 cursor-pointer' : 'opacity-50 cursor-not-allowed'
            }
            form-next-btn`}
          >
            {isLoading && <Loader />}
            {step.isSumit ? t('submit') : t('next')}
          </button>
        </div>
      </div>
      <ToastContainer transition={Slide} />
    </>
  );
}
