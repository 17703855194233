import React, { useState, useEffect } from 'react';
import { interestsList } from '../../data/data';
import { PostType } from '../../types/types';
import { useAuth } from '../../contexts/auth';
import { POST_TYPES } from '../../constants/constant';
import { ImagePostUpload } from '../../utils/uploader';
import { createPostService, updatePostService } from '../../services/postService';
import Loader from '../main/Loader';
import { Dropdown } from 'flowbite-react';
// import { DropdownItem } from '../main/navigation/Navigation';
import ItemIcon from '../main/ItemIcon';
import { FiChevronDown } from 'react-icons/fi';
import { AiOutlineGif } from 'react-icons/ai';
import ImageUploading, { ImageListType } from 'react-images-uploading';
import EmojiPicker, { EmojiStyle } from 'emoji-picker-react';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { CiSquarePlus, CiFaceSmile } from 'react-icons/ci';
import { PiImageLight } from 'react-icons/pi';
import EMTextArea from './EMTextArea';
import { useTranslation } from 'react-i18next';

type Interest = {
  id: number;
  SVG: any;
  title: string;
};

type ImageItem = {
  file: File | null;
  dataURL: string;
  isExisting: boolean;
};

interface PostBoxContainerProps {
  setShowModal?: (show: boolean) => void;
  onImageUpload?: any;
  editedPost?: any;
  setEditedPost?: any;
  redirect?: any;
  type: PostType;
}

export const TextAreaButtons = ({ setText, addGif, addImage }: any) => {
  return (
    <div className=" text-deehiy flex gap-x-2 pl-3">
      <PiImageLight onClick={addImage} className="cursor-pointer w-5 h-5" />
      <AiOutlineGif onClick={addGif} className="cursor-pointer w-5 h-5" />
      <Dropdown
        className="z-20 py-4 px-2 shadow-md h-auto"
        arrowIcon={false}
        placement="bottom"
        inline={true}
        label={<CiFaceSmile className="cursor-pointer w-5 h-5" />}
      >
        <EmojiPicker
          previewConfig={{ showPreview: false }}
          height={250}
          emojiStyle={EmojiStyle.NATIVE}
          autoFocusSearch={false}
          lazyLoadEmojis={true}
          searchDisabled={true}
          onEmojiClick={({ emoji }) => {
            setText((prevText: string) => prevText + emoji);
          }}
        />
      </Dropdown>
    </div>
  );
};

export const uploadMedia = async (mediaFiles: any[]) => {
  const mediaArray = mediaFiles.map((media) => {
    return ImagePostUpload(media?.file, (progress: number) => {
      console.log(progress);
    }).then((uploadedUrl) => {
      // media?.file.type
      // adjust type ENUM(IMG, VIDEO, GIF)
      return { url: uploadedUrl.secureUrl, type: 'IMG' };
    });
  });

  const results = await Promise.all(mediaArray);
  return results;
};

export function DndUploader({ maxNumber = 5, images, setImages, children, setText, text }: any) {
  const { t } = useTranslation();

  const onChange = (imageList: ImageListType, addUpdateIndex: number[] | undefined) => {
    // data for submit
    console.log('DND uploader: imageList, update index', imageList, addUpdateIndex);
    setImages(imageList as never[]);
  };

  return (
    <ImageUploading multiple value={images} onChange={onChange} maxNumber={maxNumber}>
      {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => (
        // write your building UI
        <div>
          <div
            className={` ${
              isDragging ? 'border-deehiy bg-opacity-60' : 'bg-opacity-100 border-gray-300'
            } w-full rounded-2xl px-2 border text-sm`}
            // onClick={onImageUpload}
            {...dragProps}
          >
            {/* {children} */}
            {React.cloneElement(children, { onImageUpload })}

            <div className="hidden justify-between w-full">
              <TextAreaButtons setText={setText} addGif={onImageUpload} addImage={onImageUpload} />
              <div className="text-gray-400 text-xs justify-end flex">{text.length}/4000</div>
            </div>
          </div>

          {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
          <div className="grid grid-cols-4 gap-2 mt-4 bg-gray-100 bg-opacity-60">
            {imageList.map((image, index) => (
              <div
                key={image.dataURL}
                className="relative flex items-center justify-center p-1 m-2"
              >
                <img src={image.dataURL} alt="" className="shadow-md max-h-32" />
                <div className="absolute top-1 right-1 flex">
                  <button
                    title={t('delete image')}
                    className="bg-white rounded flex shadow-md text-gray-500 hover:text-deehiy-red p-1 mx-1"
                    onClick={() => onImageRemove(index)}
                  >
                    <RiDeleteBin6Line className="w-5 h-5" />
                  </button>
                </div>
              </div>
            ))}
            {imageList.length < maxNumber && imageList.length > 0 && (
              <div
                title={t('add media')}
                onClick={onImageUpload}
                className="cursor-pointer w-32 flex items-center justify-center"
              >
                <CiSquarePlus className="hover:text-gray-300 text-gray-200 w-28 h-28 duration-300" />
              </div>
            )}
          </div>
        </div>
      )}
    </ImageUploading>
  );
}

const CreatePostModalContent: React.FC<PostBoxContainerProps> = ({
  setShowModal,
  onImageUpload,
  editedPost,
  setEditedPost,
  type,
  redirect,
}) => {
  const { currentUser } = useAuth();
  const [interest, setInterest] = useState<Interest | null>(
    interestsList.find((int) => int.title === editedPost?.interest) || null
  );
  const [caption, setCaption] = useState(editedPost?.content || '');
  const [title, setTitle] = useState(editedPost?.title || '');

  const [images, setImages] = useState<ImageItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const isDisabled = caption.length < 7 && images.length === 0;
  const buttonClasses = `primary-btn ${
    isDisabled ? 'hover:bg-opacity-60 bg-opacity-60 cursor-not-allowed' : ''
  }`;

  const { t } = useTranslation();

  const getFormData = (postType: PostType) => {
    switch (postType) {
      case POST_TYPES.GENERAL:
        return {
          formTitle: t("What's going on?"),
          hasTitleInput: false,
          placeHolder: t('Write a post or drag and drop media here'),
          imgMaxNumber: 8,
          tools: [['image'], ['emoji']],
        };
      case POST_TYPES.ARTICLE:
        return {
          formTitle: t('Write an article'),
          hasTitleInput: true,
          placeHolder: t('Write an article'),
          imgMaxNumber: 1,
          tools: [
            ['image', []],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['bold', 'italic', 'underline'],
            // [{ color: [] }, { background: [] }],
            // [{ script: 'sub' }, { script: 'super' }],
            [{ align: [] }],
            ['link', 'blockquote'],
            ['clean'],
          ],
        };
      case POST_TYPES.DISCUSSION:
        return {
          formTitle: t('Start a discussion'),
          hasTitleInput: false,
          placeHolder: t('Start a discussion'),
          imgMaxNumber: 0,
          tools: [
            ['emoji'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['bold', 'italic', 'underline'],
            // [{ script: 'sub' }, { script: 'super' }],
            ['link', 'blockquote'],
            ['clean'],
          ],
        };
      default:
        throw new Error('Invalid post type');
    }
  };
  const formData = getFormData(type);

  const peopleMention: any = [];
  // [
  //   { value: 'john', display: 'john', link: 'https://#/users/hashtags/1' },
  // ];

  const hash = interestsList.map((_inter) => {
    return {
      id: _inter.id,
      value: _inter.title,
      display: _inter.title,
      link: '/square',
    };
  });

  const handleSubmit = async () => {
    setIsLoading(true);

    const existingImages = images
      .filter((image) => image.isExisting)
      .map((image) => ({ ...image, url: image.dataURL })); // Keep existing images with converted URL

    // If editing, the images may not need to be re-uploaded unless they have changed
    // Filter out existing images and only upload new ones
    const newImagesToUpload = images.filter((image) => !image.isExisting);

    // Upload new images and keep existing ones as they are
    const uploadedImages = newImagesToUpload.length > 0 ? await uploadMedia(newImagesToUpload) : [];
    // Combine existing images with any newly uploaded images
    const combinedImages =
      existingImages.length > 0
        ? [
            ...existingImages, // Keep existing images
            ...uploadedImages, // Add newly uploaded images
          ]
        : uploadedImages;
    try {
      const postPayload = {
        authorId: currentUser?.id,
        interest: interest?.title,
        content: caption,
        media: combinedImages,
        title: title,
        type,
      };

      let response;
      if (editedPost) {
        // If editedPost is provided, update the post
        response = await updatePostService(editedPost.postId, postPayload);
        console.log('post returned', response.data.post);
        setEditedPost(response.data.post);
      } else {
        // If editedPost is not provided, create a new post
        response = await createPostService(postPayload);
      }
      console.log('add/update post response', response.data);

      setIsLoading(false);
      // Optionally, refresh the list of posts or navigate to the updated post
    } catch (error) {
      console.log(error);
    } finally {
      if (setShowModal) {
        setShowModal(false);
      }
      if (redirect) {
        redirect();
      }
    }
  };

  useEffect(() => {
    // if initializing useState with the content, there is an infinite loop in EMTxtArea
    if (editedPost) {
      // setCaption(editedPost?.content);

      if (editedPost?.media) {
        const existingImages = editedPost.media.map((mediaItem: any) => ({
          dataURL: mediaItem.url,
          file: null,
          isExisting: true,
        }));

        setImages(existingImages);
      }

      //add interest
    }
  }, [editedPost, setImages, setCaption]);

  if (isLoading)
    return (
      <div className="flex gap-x-3 h-72 justify-center items-center w-full sm:w-[800px]">
        <span className="text-lg text-deehiy semibold animate-pulse">{t('Posting...')}</span>
        <Loader />
      </div>
    );

  return (
    <div className="shadow-none sm:shadow-xl w-full sm:w-[800px] p-6 rounded-2xl flex flex-col gap-y-6">
      <div className="text-xl">{formData.formTitle}</div>

      <form className="flex flex-col gap-y-4">
        <div className=" w-full sm:w-[500px]">
          <Dropdown
            className="z-20 py-4 mt-[-4px] px-2 shadow-md flex w-72 "
            arrowIcon={false}
            placement="bottom"
            inline={true}
            label={
              <div className="w-72  hover:border-gray-400 flex items-center justify-between active:border-deehiy focus:border-deehiy border-gray-300 rounded-xl py-2 px-2 border text-sm">
                {interest ? (
                  <div className="flex gap-x-3 items-center">
                    <ItemIcon size={6} Icon={interest?.SVG} />
                    <span>{interest?.title}</span>
                  </div>
                ) : (
                  <div className="flex w-full"> {t('want to select a topic?')} </div>
                )}
                <FiChevronDown className="w-5 h-5" />
              </div>
            }
          >
            <div className="h-52 overflow-auto scroller border-t-deeehiy border border-transparent">
              {interestsList.map((int) => (
                <Dropdown.Item
                  className="gap-x-2 py-3 flex text-sm text-defaultText px-3"
                  key={int.id}
                  onClick={() => setInterest(int)}
                >
                  <ItemIcon Icon={int.SVG} size={6} isSelected={false} />
                  <span>{t(int.title)}</span>
                </Dropdown.Item>
              ))}
            </div>
          </Dropdown>
        </div>

        {formData.hasTitleInput && (
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            maxLength={65}
            className="w-full sm:w-72 flex items-center placeholder:text-placeholderText justify-between focus:outline-none focus:border-deehiy focus:ring-deehiy active:border-deehiy border-gray-300 hover:border-gray-400 rounded-xl py-2 px-2 border text-sm"
            placeholder={t('Enter a title...')}
          />
        )}
        <DndUploader
          maxNumber={formData.imgMaxNumber}
          images={images}
          setImages={setImages}
          setText={setCaption}
          text={caption}
        >
          <EMTextArea
            placeHolder={formData.placeHolder}
            name="post"
            value={caption}
            changeHandler={setCaption}
            hash={hash}
            peopleMention={peopleMention}
            onImageUpload={onImageUpload}
            tools={formData.tools}
          />
        </DndUploader>

        <button
          disabled={isDisabled}
          onClick={handleSubmit}
          type="button"
          className={buttonClasses}
        >
          {t('Post')}
        </button>
        <span className="text-xs text-gray-500 mx-auto">{t('posting publicly')}</span>
      </form>
    </div>
  );
};

export default CreatePostModalContent;
