import React from 'react';
import UpdatePassword from '../components/auth/UpdatePassword';

const UpdatePasswordPage = () => {
  return (
    <div>
      <UpdatePassword />
    </div>
  );
};

export default UpdatePasswordPage;
