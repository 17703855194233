import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { initialUser } from '../../initialStates/initialStates';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { registerSchema } from '../../lib/yup';
import Loader from '../main/Loader';
import Box from '../animation/Box';
import { registerInvitedUser } from '../../services/auth/userService';
import Input from '../form/Input';
import PasswordInput from '../form/PasswordInput';
import { useTranslation } from 'react-i18next';

const BusinessUserRegister = ({ email }: { email: string }) => {
  const methods = useForm({
    mode: 'onTouched',
    // mode: 'onChange',
    // mode: 'onBlur',
    // mode: 'all',
    resolver: yupResolver(registerSchema),
  });

  let navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();

  const [error, setError] = useState<string>('');
  const [user, setUser] = useState({ ...initialUser, email });
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [isChecked, setIsChecked] = useState(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    // await methods.trigger();
    try {
      await registerInvitedUser(user);
      setIsLoading(false);
      setMessage('Registration successful!');

      setTimeout(() => {
        navigate('/login', { state: { redirectTo: location } });
      }, 3000);
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = async (e: any) => {
    setError('');
    const value = e.target.value;
    const name = e.target.name;
    setUser({ ...user, [name]: value });

    // await methods.trigger(name);
  };

  useEffect(() => {
    if (
      user.firstName.length < 3 ||
      user.lastName.length < 3 ||
      user.password !== confirmPassword ||
      user.password.length < 1
    ) {
      setIsChecked(false);
    } else {
      setIsChecked(true);
    }
  }, [user.firstName, user.lastName, user.password, confirmPassword]);

  return (
    <Box>
      <div className="auth-form">
        {isLoading && <Loader />}
        <p className="mx-auto font-light text-2xl">{t('Registration')}</p>
        <hr className="border-deehiy" />

        {message && (
          <div className=" flex justify-center text-green-400 text-sm mx-auto">{message}</div>
        )}

        {error && <span className=" flex justify-center text-red-500 mx-auto">{error}</span>}

        <FormProvider {...methods}>
          <form className="flex flex-col space-y-4" onSubmit={(e) => handleSubmit(e)}>
            <Input
              name="email"
              label="email"
              id="email"
              readOnly={true}
              // onChange={handleChange}
              value={email}
            />
            <Input
              name="firstName"
              id="firstName"
              label={t('first name')}
              onChange={handleChange}
              value={user.firstName}
              placeholder={t('first name')}
              helperText="minimum 3 characters"
            />
            <Input
              name="lastName"
              id="lastName"
              label={t('last name')}
              onChange={handleChange}
              value={user.lastName}
              placeholder={t('last name')}
              helperText="minimum 3 characters"
            />

            <PasswordInput
              label={t('password')}
              id="password"
              name="password"
              onChange={handleChange}
              value={user.password}
              placeholder={t('password')}
              helperText={t('password_helper_validation')}
            />
            <PasswordInput
              name="confirmPassword"
              label={t('confirm password')}
              id="confirmPassword"
              onChange={(e: any) => setConfirmPassword(e.target.value)}
              value={confirmPassword}
              placeholder={t('confirm password')}
            />
            <button
              type="submit"
              disabled={!isChecked}
              className={`${isChecked ? 'primary-btn' : 'opacity-0 cursor-not-allowed'}`}
            >
              {t('Submit')}
            </button>
          </form>
        </FormProvider>
      </div>
    </Box>
  );
};
export default BusinessUserRegister;
