import React from 'react';
import ProfileForm from '../components/dashboard/ProfileForm';
import TakeMeBack from '../components/main/navigation/TakeMeBack';

const ProfilePage = () => {
  return (
    <div>
      <div className="px-20 pt-6">
        <TakeMeBack />
      </div>
      <ProfileForm />
    </div>
  );
};

export default ProfilePage;
