import React, { useState } from 'react';

import VerticalTab from '../../dashboard/tabsContents/VerticalTab';
import TopMobileNavigation from '../../main/navigation/TopMobileNavigation';
import { useParams } from 'react-router-dom';
import UsersManagement from './tabs/UsersManagement';
import Notifications from './tabs/Notifications';
import LoginSecurity from './tabs/LoginSecurity';
import TakeMeBack from '../../main/navigation/TakeMeBack';
import { useTranslation } from 'react-i18next';

const Settings = () => {
  const { tab } = useParams();
  const { t } = useTranslation();
  const tabIndex = Number(tab);

  const initialTabs = [
    {
      id: 'tab1',
      title: 'Page roles',
      content: <UsersManagement />,
      bigHeader: 'Manage your business',
      smallHeader: 'Assign roles and permissions to manage your team',
    },
    {
      id: 'tab2',
      title: 'Notifications',
      content: <Notifications />,
      bigHeader: 'Notifications',
      smallHeader: 'coming soon!',
    },
    {
      id: 'tab3',
      title: 'Login & Security',
      content: <LoginSecurity />,
      bigHeader: 'Login & Security',
      smallHeader: 'coming soon!',
    },
  ];
  const [selectedTab, setSelectedTab] = useState(initialTabs[0]);

  return (
    <div className="container">
      <div className="pt-6 sm:px-20">
        <TakeMeBack />
      </div>
      <TopMobileNavigation />
      <div className="pt-12 pb-4 sm:py-6 font-bold px-4 sm:px-20">
        <div className="text-2xl sm:text-5xl font-bold w-full h-16 items-center flex">
          {selectedTab && t(selectedTab.bigHeader)}
        </div>
        <div className="text-sm sm:text-xl pt-2 sm:pt-4">
          {selectedTab && t(selectedTab.smallHeader)}
        </div>
      </div>
      <VerticalTab tabs={initialTabs} selectedTabIndex={tabIndex} setSelectedTab={setSelectedTab} />
    </div>
  );
};

export default Settings;
