import React, { useState, useEffect } from 'react';
import { useProperty } from '../contexts/property';
import { useAuth } from '../contexts/auth';
import PropertiesTable from '../components/dashboard/PropertiesTable';
import { useNavigate } from 'react-router-dom';
import { useInternationalization } from '../contexts/internationalization';
import TopMobileNavigation from '../components/main/navigation/TopMobileNavigation';
import { initialProperty } from '../initialStates/initialStates';
import TakeMeBack from '../components/main/navigation/TakeMeBack';
import { useTranslation } from 'react-i18next';

const ManageListingPage = () => {
  const { fetchPropertiesByUserId, userProperties, setCurrentProperty } = useProperty();
  const { currentUser } = useAuth();
  const { currency } = useInternationalization();
  const [properties, setProperties] = useState(userProperties);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    setCurrentProperty(initialProperty);

    if (currentUser) {
      fetchPropertiesByUserId(currentUser.id, currency);
    }
  }, []);

  useEffect(() => {
    setProperties(userProperties);
  }, [userProperties]);

  return (
    <div>
      <div className="pt-6">
        <TakeMeBack />
      </div>
      <TopMobileNavigation />
      {!!properties.length ? (
        <>
          <div className="flex flex-col justify-between">
            <div className="font-bold text-2xl  sm:text-5xl pt-4 px-4 sm:px-0 sm:pt-6">
              {t('Property listings')}
            </div>

            <div className="justify-end sm:justify-center items-center grid grid-cols-3 py-4 px-4 sm:px-0">
              {!!properties.length && (
                <div className="font-bold text-sm sm:text-xl">
                  {properties.length} {t('listings')}
                </div>
              )}
              <button
                onClick={() => navigate('/addproperty')}
                className="primary-btn whitespace-nowrap "
              >
                {t('create listing')}
              </button>
            </div>
          </div>

          <div className="w-full mt-4 sm:mt-8 pb-20">{<PropertiesTable />}</div>
        </>
      ) : (
        <div className="justify-center h-[500px] items-center flex-col gap-10 flex w-full">
          {t('no_listing_text')}
          <button
            onClick={() => navigate('/addproperty')}
            className="primary-btn whitespace-nowrap"
          >
            + {t('create listing')}
          </button>
        </div>
      )}
    </div>
  );
};

export default ManageListingPage;
