import React, { useEffect, useState } from 'react';
import { useAuth } from '../../contexts/auth';
// import { useNavigate } from 'react-router-dom';
import { initialUser } from '../../initialStates/initialStates';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { registerSchema } from '../../lib/yup';
import RegisterRedirection from './RegisterRedirection';
import Loader from '../main/Loader';
import Box from '../animation/Box';
import SEO from '../SEO/SEO';
import { validateEmail } from '../../utils/fieldValidater';
import Input from '../form/Input';
import PasswordInput from '../form/PasswordInput';
import SuccessBox from './SuccessBox';
// @ts-ignore
import signupImage from '../../assets/images/sign-up-image.webp';
// @ts-ignore
import google from '../../assets/images/google-logo.webp';
// @ts-ignore
import facebook from '../../assets/images/facebook.webp';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { LoginSocialFacebook } from 'reactjs-social-login';
import { useNavigate, useLocation } from 'react-router-dom';
import { RedirectLocationState } from './Login';
import { useInternationalization } from '../../contexts/internationalization';
import { useTranslation } from 'react-i18next';
import { PiEnvelopeSimpleThin } from 'react-icons/pi';

// import ReactDOMServer from 'react-dom/server';
const Register = () => {
  const methods = useForm({
    mode: 'onTouched',
    // mode: 'onChange',
    // mode: 'onBlur',
    // mode: 'all',
    resolver: yupResolver(registerSchema),
  });

  let navigate = useNavigate();
  const { t } = useTranslation();
  const { state: locationState } = useLocation();
  const { locale } = useInternationalization();

  const [error, setError] = useState<string>('');
  const [user, setUser] = useState(initialUser);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isEmailRegistration, setIsEmailRegistration] = useState(false);
  const { loginWithSocialApi, registerUser, currentUser } = useAuth();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    // await methods.trigger();

    const userParams = { ...user, language: locale };

    await registerUser(userParams).then((res: any) => {
      setIsLoading(false);
      if (res === 'success') {
        setMessage('');
        setIsSuccess(true);
        // setTimeout(() => {
        // navigate('/login');
        // }, 4000);
      } else {
        setError(res);
      }
    });
  };

  const handleChange = async (e: any) => {
    setError('');
    const value = e.target.value;
    const name = e.target.name;
    setUser({ ...user, [name]: value });

    // await methods.trigger(name);
  };

  useEffect(() => {
    if (
      user.firstName.length < 3 ||
      user.lastName.length < 3 ||
      !validateEmail(user.email) ||
      user.password !== confirmPassword ||
      user.password.length < 1
    ) {
      setIsChecked(false);
    } else {
      setIsChecked(true);
    }
  }, [user.firstName, user.lastName, user.email, user.password, confirmPassword]);

  // in case we already have a currentUser logged in
  useEffect(() => {
    if (currentUser) {
      navigate('/users/dashboard');
      // navigate(-1);
    }
  }, [currentUser]);

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      // Fetch user info using the access token
      const userInfoResponse = await axios.get('https://www.googleapis.com/oauth2/v1/userinfo', {
        headers: {
          Authorization: `Bearer ${tokenResponse.access_token}`,
        },
      });
      const userInfo = userInfoResponse.data;
      // Log user info
      const { given_name: givenName, family_name: familyName, email, picture } = userInfo;
      const accountType = 'google';
      try {
        const response = await loginWithSocialApi(
          givenName,
          familyName,
          email,
          picture,
          accountType
        );
        if (response === 'success') {
          if (locationState) {
            const { redirectTo } = locationState as RedirectLocationState;
            navigate(`${redirectTo.pathname}${redirectTo.search}`);
          } else navigate('/getting-started');
        } else {
          setError(response);
        }
      } catch (err) {
        // Handle API call errors
        setIsLoading(false);
        setError('An error occurred during login. Please try again.');
      }
    },
    onError: (err) => console.log('Login Failed:', err),
  });

  const handleFacebookLogin = async (response: any) => {
    try {
      const { first_name: firstName, last_name: lastName, email, picture } = response?.data;
      const accountType = 'facebook';
      const loginResponse = await loginWithSocialApi(
        firstName,
        lastName,
        email,
        picture?.data?.url,
        accountType
      );
      if (loginResponse === 'success') {
        if (locationState) {
          const { redirectTo } = locationState as RedirectLocationState;
          navigate(`${redirectTo.pathname}${redirectTo.search}`);
        } else navigate('/getting-started');
      } else {
        setError(loginResponse);
      }
    } catch (err) {
      setIsLoading(false);
      setError('An error occurred during login. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const responseFacebookFailure = (errorFacebook: any) => {
    console.log('Facebook Login Failed:', errorFacebook);
    setIsLoading(false);
    setError('An error occurred during login. Please try again.');
  };

  if (isSuccess) {
    return <SuccessBox user={user} setIsSuccess={setIsSuccess} />;
  }
  return (
    <Box>
      <SEO
        title="deehiy.com"
        name="Deehiy"
        type="register page"
        description="welcome to Deehiy"
        image="/public/images/meta-image.jpg"
      />
      <div className="signup-auth-form flex mx-auto">
        <div className="w-[100%] sm:w-[100%] md:w-[35%] h-full flex flex-col pl-5 pr-8 relative">
          {isLoading && <Loader />}
          <p className="mx-auto text-center font-semibold text-xl md:text-2xl text-deehiy pt-2">
            {t('join_global_community')}
          </p>

          {message && (
            <div className=" flex justify-center text-green-400 text-sm mx-auto">{message}</div>
          )}

          {error && <span className=" flex justify-center text-red-500 mx-auto">{error}</span>}

          <FormProvider {...methods}>
            <form
              className={`${isEmailRegistration ? 'flex' : 'hidden'} flex-col space-y-4 mt-4`}
              onSubmit={(e) => handleSubmit(e)}
            >
              <Input
                name="firstName"
                id="firstName"
                label=""
                onChange={handleChange}
                value={user.firstName}
                placeholder={t('First name')}
              />
              <Input
                name="lastName"
                id="lastName"
                label=""
                onChange={handleChange}
                value={user.lastName}
                placeholder={t('Last name')}
              />
              <Input
                name="email"
                label=""
                id="email"
                onChange={handleChange}
                value={user.email}
                placeholder={t('Email')}
              />
              <PasswordInput
                name="password"
                label=""
                id="passwordId"
                onChange={handleChange}
                value={user.password}
                placeholder={t('Password')}
              />
              <PasswordInput
                name="confirmPassword"
                label=""
                id="confirmPassword"
                onChange={(e: any) => setConfirmPassword(e.target.value)}
                value={confirmPassword}
                placeholder={t('Confirm password')}
              />
              <button
                type="submit"
                disabled={!isChecked}
                className={`${
                  isChecked ? 'signup-btn btn-margin' : 'opacity-5 cursor-not-allowed'
                }`}
              >
                {t('Create account')}
              </button>
              <button
                type="button"
                className="mx-auto flex items-center social-google-login-btn social-border signup-social-btn mt-12"
                onClick={() => setIsEmailRegistration(false)}
              >
                <PiEnvelopeSimpleThin className="w-6 h-6 pr-1" />
                {t('Sign up with Social Login')}
              </button>
            </form>

            {/* <div className="relative signup-line">
              <hr className="separator" />
              <p className="overwrite-seperator signup-overwrite-seperator">{t('or')}</p>
            </div> */}
            <div className={`${isEmailRegistration ? 'hidden' : 'flex'} mt-3 py-14`}>
              <div className="flex w-full flex-col items-center justify-center space-y-9">
                <button
                  className="mx-auto flex items-center social-google-login-btn social-border signup-social-btn"
                  onClick={() => handleGoogleLogin()}
                >
                  <img className="w-7 h-7" src={google} alt="google" />
                  {t('Sign up with Google')}
                </button>
                <LoginSocialFacebook
                  appId={process.env.REACT_APP_FB_APP_ID || ''}
                  onResolve={(response: any) => handleFacebookLogin(response)}
                  onReject={(errorFacebook: any) => responseFacebookFailure(errorFacebook)}
                >
                  <button className="mx-auto flex items-center social-facebook-login-btn social-border">
                    <img className="w-7 h-7" src={facebook} alt="facebook" />
                    {t('Sign up with Facebook')}
                  </button>
                </LoginSocialFacebook>
                <button
                  type="button"
                  className="mx-auto flex items-center social-google-login-btn social-border"
                  onClick={() => setIsEmailRegistration(true)}
                >
                  <PiEnvelopeSimpleThin className="w-7 h-7 pr-1" />
                  {t('Sign up with email')}
                </button>
              </div>
            </div>
          </FormProvider>
          <RegisterRedirection to="login" />
        </div>
        <div className="relative hidden md:flex h-auto w-[65%]">
          <img className="rounded-lg w-full h-full object-cover" src={signupImage} alt="image" />
          <div className="img-content-container">
            <p className="text-[38px] font-semibold">{t('real_estate_hub')}</p>
            <p className="font-semibold text-[18px]">{t('global_community')}</p>
          </div>
        </div>
      </div>
    </Box>
  );
};
export default Register;
