import React, { useState, useEffect } from 'react';
// import OverlaySidebarLayout from '../components/square/OverlaySidebarLayout';
import Loader from '../components/main/Loader';
import { useGlobal } from '../contexts/global';
import { useAuth } from '../contexts/auth';
import AllResults from '../components/allResults/AllResults';
import { getPostsService } from '../services/postService';
import { getAllProperties } from '../services/propertyService';
import { filterAllService } from '../services/communityService';
import { initialFilter } from '../initialStates/initialStates';

import { POST_TYPES } from '../constants/constant';

const AllResultsPage = () => {
  const { keyword } = useGlobal();
  const { currentUser } = useAuth();
  const [postsList, setPostsList] = useState<any[]>([]);
  const [membersList, setMembersList] = useState<any[]>([]);
  const [listingsList, setListingsList] = useState<any[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  // const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [searchParams, setSearchParams] = useState({ keyword: keyword });
  // debounce
  const handleListingSearch = async (currentPage: number) => {
    setIsLoading(true);
    console.log('listing params:', { ...initialFilter, ...searchParams });
    try {
      const res = await getAllProperties({ ...initialFilter, ...searchParams }, currentPage);
      console.log('=>> res.data.properties.rows::', res.data.properties.rows);

      setListingsList(res.data.properties.rows);
    } catch (error) {
      console.error('Error while fetching listings:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePostSearch = async (currentPage: number) => {
    setIsLoading(true);

    try {
      const res = await getPostsService({
        ...searchParams,
        selectedTypes: [POST_TYPES.ARTICLE, POST_TYPES.DISCUSSION, POST_TYPES.GENERAL],
        page: currentPage,
      });

      console.log('=>> res.data.posts::', res.data.posts);

      setPostsList(res.data.posts);
    } catch (error) {
      console.error('Error while fetching posts:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUserSearch = async (currentPage: number) => {
    setIsLoading(true);

    try {
      const res = await filterAllService(searchParams, currentPage, currentUser?.id);
      console.log('=>> res.data.users::', res.data.users);

      setMembersList(res?.data.users);
    } catch (error) {
      console.error('Error while fetching members:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setSearchParams({ ...searchParams, keyword: keyword });
  }, [keyword]);

  useEffect(() => {
    handleListingSearch(1);
    handlePostSearch(1);
    handleUserSearch(1);

    setPostsList([]);
    setMembersList([]);
    setListingsList([]);
  }, [searchParams]);

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <AllResults listings={listingsList} posts={postsList} members={membersList} />
      )}
    </div>
  );
};

export default AllResultsPage;
