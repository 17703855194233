import React from 'react';
import PropertyCard from '../main/PropertyCard';
// import Loader from '../main/Loader';
import { useProperty } from '../../contexts/property';

const ListingRecommendedSide = () => {
  const { properties } = useProperty();

  return (
    <div className="mt-16 space-y-8 flex-col flex-wrap justify-center">
      {properties?.map((property: any) => (
        <PropertyCard key={property.id} property={property} />
      ))}
    </div>
  );
};

export default ListingRecommendedSide;
