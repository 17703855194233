import React, { useState, useEffect, useMemo } from 'react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
  getSortedRowModel,
} from '@tanstack/react-table';
import type { ColumnDef, SortingState } from '@tanstack/react-table';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { CustomDropwdown, DropdownItem } from '../main/navigation/Navigation';
import { DropdownDotsBtn } from '../main/community/Community';
// import { FiEdit } from 'react-icons/fi';
import { BsChevronExpand } from 'react-icons/bs';
import { User } from '../../types/types';
import { deleteBusinessUserService, updateUserRoleService } from '../../services/businessService';
import { useBusiness } from '../../contexts/business';
import { useAuth } from '../../contexts/auth';
import AssignRole from './AssignRole';
import Modal2 from '../listing/Modal2';
import { AccessModal } from './ListingsTable';
import { canAccess } from '../../utils/businessAccess';
import { useTranslation } from 'react-i18next';

const columnHelper = createColumnHelper<User>();
// const headerStyle = 'px-4 w-full flex';

import { ROLES, DELETE_MEMBER, CHANGE_USER_ROLE } from '../../constants/constant';
import ConfirmRemoveUser from './ConfirmRemoveUser';

const EditDropdown = ({
  handleChangeRole,
  handleDelete,
  user,
}: {
  handleChangeRole: any;
  handleDelete: any;
  user: any;
}) => {
  const { currentUser } = useAuth();
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showAccessModal, setShowAccessModal] = useState(false);
  const [text, setText] = useState('');

  const OpenDeleteModal = () => {
    if (!canAccess(currentUser, DELETE_MEMBER)) {
      setText(t('remove_member_access_denied_message'));
      setShowAccessModal(true);
      return;
    }
    setShowConfirmModal(true);
  };

  const handleEdit = () => {
    if (!canAccess(currentUser, CHANGE_USER_ROLE)) {
      setText(t('edit_role_access_denied_message'));
      setShowAccessModal(true);
      return;
    }
    setShowModal(true);
  };

  return (
    <>
      <CustomDropwdown Btn={<DropdownDotsBtn />}>
        <DropdownItem onClick={handleEdit} itemLabel={t('edit')} />

        <DropdownItem onClick={OpenDeleteModal} itemLabel={t('delete')} />
      </CustomDropwdown>
      {showModal && (
        <div className="absolute top-0 left-0 w-full h-full">
          <Modal2 showModal={showModal} setShowModal={setShowModal}>
            <AssignRole
              handleChangeRole={handleChangeRole}
              user={user}
              setShowModal={setShowModal}
            />
          </Modal2>
        </div>
      )}
      {showConfirmModal && (
        <div className="absolute top-0 left-0 w-full h-full">
          <Modal2 showModal={showConfirmModal} setShowModal={setShowConfirmModal}>
            <ConfirmRemoveUser
              handleDelete={handleDelete}
              user={user}
              setShowConfirmModal={setShowConfirmModal}
            />
          </Modal2>
        </div>
      )}
      {showAccessModal && (
        <div className="absolute top-0 left-0 w-full h-full">
          <Modal2 showModal={showAccessModal} setShowModal={setShowAccessModal}>
            <AccessModal text={text} setShowAccessModal={setShowAccessModal} />
          </Modal2>
        </div>
      )}
    </>
  );
};

const UsersTable = () => {
  const { currentBusiness, users, fetchUsersByBusinessIdContext, setUsers } = useBusiness();
  const { currentUser } = useAuth();
  const { t } = useTranslation();
  // const rerender = useReducer(() => ({}), {})[1];
  const [sorting, setSorting] = useState<SortingState>([]);
  // const [users, setUsers] = useState<BusinessUser[]>([]);
  const [data, setData] = useState<User[] | any[]>(() => []);
  const [isLoading, setIsLoading] = useState(false);

  const handleChangeRole = async (userId: number, roleId: number, businessUsers: User[]) => {
    setIsLoading(true);
    updateUserRoleService(userId, roleId)
      .then(() => {
        const updatedUsers = businessUsers.map((user) => {
          if (user.id === userId) {
            return { ...user, roleId };
          }
          return user;
        });

        console.log(updatedUsers);
        setUsers(updatedUsers);
      })
      .catch((error) => {
        console.error('Error changing the role:', error);
        alert(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDelete = async (id: number, businessId: number) => {
    try {
      await deleteBusinessUserService(id, businessId);

      await fetchUsersByBusinessIdContext(businessId);
    } catch (error) {
      console.error('Error deleting the user:', error);
      alert(error);
    }
  };

  const getRoleNameByRoleId = (roleId: number) => {
    const role = ROLES.find((r) => r.roleId === roleId);
    return role ? role.roleName : 'Unknown Role';
  };

  const columns = useMemo<ColumnDef<User, any>[]>(() => {
    const baseColumns = [
      columnHelper.accessor((row) => row, {
        id: 'name',
        cell: (info) => {
          const firstName = info.row.original.firstName ? info.row.original.firstName : '';
          const lastName = info.row.original.lastName ? info.row.original.lastName : '';
          return (
            <div title={`${info.getValue()}`} className="line-clamp-1 px-4">
              {`${firstName} ${lastName}`}
            </div>
          );
        },
        header: () => <span className="pl-4 text-xs sm:text-md">{t('Name')}</span>,
        // footer: (info) => info.column.id,
      }),
      columnHelper.accessor('email', {
        id: 'email',
        header: () => <span className="pl-4 text-xs sm:text-md">{t('Email')}</span>,
        cell: (info) => info.renderValue(),
      }),
      columnHelper.accessor('roleId', {
        id: 'role',
        header: () => <span className="pl-4 text-xs sm:text-md">{t('Role')}</span>,
        cell: (info) => getRoleNameByRoleId(Number(info.renderValue())),
        // footer: (info) => info.column.id,
      }),
      columnHelper.accessor('status', {
        id: 'status',
        header: () => <span className="pl-4 text-xs sm:text-md">{t('Status')}</span>,
        cell: (info) => (
          <div
            className={`${
              info.renderValue() === 'pending' ? 'text-[#ea8e37]' : 'text-deehiy'
            } pl-4`}
          >
            {info.renderValue()}
          </div>
        ),
        // footer: (info) => info.column.id,
      }),
      columnHelper.accessor((row) => row.properties, {
        id: 'listings',
        header: () => <span className="pl-4 text-xs sm:text-md">{t('Listings')}</span>,
        cell: (info) => {
          return (
            <div className="mx-auto font-bold pl-8 sm:pl-8">
              {info.renderValue() && info.renderValue()!.length > 0
                ? info.renderValue()!.length
                : 0}
            </div>
          );
        },

        // footer: (info) => info.column.id,
      }),
      columnHelper.accessor((row) => row, {
        id: 'edit',
        header: '',
        cell: (info) =>
          info.row.original.roleId !== 1 && info.row.original.id !== currentUser?.id ? (
            <EditDropdown
              handleDelete={handleDelete}
              handleChangeRole={handleChangeRole}
              user={{
                firstName: info.row.original.firstName,
                lastName: info.row.original.lastName,
                email: info.row.original.email,
                id: info.row.original.id,
                roleId: info.row.original.roleId,
              }}
            />
          ) : (
            ''
          ),
        enableSorting: false,
      }),
    ];
    const mobileColumns = baseColumns.filter(
      (col: any) =>
        col.id === 'name' || col.id === 'role' || col.id === 'listings' || col.id === 'edit'
    ) as ColumnDef<User, any>[];

    return window.innerWidth <= 768 ? mobileColumns : baseColumns;
  }, []);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });

  useEffect(() => {
    if (currentBusiness?.businessId) {
      fetchUsersByBusinessIdContext(currentBusiness?.businessId);
    }
  }, [currentBusiness?.businessId]);

  useEffect(() => {
    console.log('USERS UPDATED::', users);
    setData(() => [...users]);
  }, [users]);

  return (
    <div>
      <div className="p-0 sm:p-2 pb-20">
        <table className="w-full">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer select-none flex items-center'
                            : 'hidden',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: <BiChevronUp className="w-5 h-5" />,
                          desc: <BiChevronDown className="w-5 h-5" />,
                        }[header.column.getIsSorted() as string] ?? (
                          <BsChevronExpand className="ml-1 w-4 h-4 text-gray-400" />
                        )}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="border border-deehiy  bg-white text-xs rounded-xl ">
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id} className="h-16 border ">
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                ))}
              </tr>
            ))}
          </tbody>
          <tfoot>
            {table.getFooterGroups().map((footerGroup) => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.footer, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </tfoot>
        </table>
        {/* pagination */}
        <div className="h-2" />
        <div className="flex items-center gap-2">
          {/* <button
            className="border rounded p-1"
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            {'<<'}
          </button> */}
          <button
            className=""
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            <img alt="prev" src="/images/icons/previous.svg" className="w-8 h-8" />
          </button>
          <button className="" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
            <img alt="prev" src="/images/icons/next.svg" className="w-8 h-8" />
          </button>
          {/* <button
            className="border rounded p-1"
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            {'>>'}
          </button> */}
          <span className="flex items-center gap-1 text-gray-600">
            <div>Page</div>
            <strong>
              {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
            </strong>
          </span>
          {/* <span className="flex items-center gap-1">
            | Go to page:
            <input
              type="number"
              defaultValue={table.getState().pagination.pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                table.setPageIndex(page);
              }}
              className="border border-gray-400 p-1 rounded w-12 focus:ring-deehiy focus:border-none"
            />
          </span> */}
          <select
            className="border border-deehiy py-1 px-2 text-sm rounded text-center focus:ring-deehiy focus:border-deehiy"
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
          >
            {[5, 10, 20].map((pageSize) => (
              <option key={pageSize} value={pageSize} className="border border-deehiy">
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
        {/* <div>{table.getPrePaginationRowModel().rows.length} Rows</div> */}
        {isLoading && <>...</>}
      </div>
    </div>
  );
};

export default UsersTable;
