import React from 'react';
import TakeMeBack from '../../components/main/navigation/TakeMeBack';

const TeaserPreLaunch = () => {
  return (
    <div className="mx-auto flex flex-col justify-center items-center px-6 sm:px-24 container">
      <div className="pt-6 w-full justify-start">
        <TakeMeBack />
      </div>
      <div className="flex flex-col text-left w-full">
        {/* title */}
        <div className="text-3xl sm:text-5xl font-bold pt-12 sm:pt-8 pb-4 sm:py-6">
          Share, deal, tour, chat, call - all in one!
        </div>
        <div className="text-sm sm:text-xl pb-4 sm:pb-16">
          Moving from app to app for a single task. We say no more!
        </div>
      </div>
      <div className="flex flex-col sm:flex-row">
        <div className="flex flex-col w-wull sm:w-3/5 pr-12">
          <div className="text-xs sm:text-sm py-6 ">
            We are burning the midnight oil to get this amazing new chat app to you, available for
            free in your app store soon. Here are a few things this app can do.
          </div>
          <div className="py-3">
            <div className="text-xs sm:text-sm font-bold text-deehiy">
              Chatting, Video and Voice calls
            </div>
            <div className="text-xs sm:text-sm">
              Instant chat with buyers, sellers and agents directly through the app between phone
              and web or allow clients and partners to tune in for a LIVE TOUR of the property
              directly from your phone.
            </div>
          </div>
          <div className="py-3">
            <div className="text-xs sm:text-sm font-bold text-deehiy">
              Share listings from your phone
            </div>
            <div className="text-xs sm:text-sm">
              Conventiently share your listings with others directly from a chat. It is as easy as
              sharing a picture.
            </div>
          </div>
          <div className="py-3">
            <div className="text-xs sm:text-sm font-bold text-deehiy">
              Arrange meetings in the chat{' '}
            </div>
            <div className="text-xs sm:text-sm">
              Propose property visits or meetings directly through the chat and add them to your
              Deehiy or Google calendar. Never miss an appointmetn again.
            </div>
          </div>
          <div className="py-3 text-xs sm:text-sm font-bold text-deehiy">
            And there is much more, but you have to be patient until release...
          </div>
        </div>
        <div className="flex justify-center w-full sm:w-3/5 p-2 sm:p-6 items-center">
          <img alt="" src={'/images/messenger-teaser.png'} className="w-full" />
        </div>
      </div>
    </div>
  );
};
export default TeaserPreLaunch;
