import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FiChevronLeft } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';

const TakeMeBack = ({ loc }: any) => {
  console.log(loc);
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="text-deehiy text-sm justify-start ">
      <button
        className="flex active:bg-gray-200 group gap-1 duration-500 cursor-pointer items-center"
        onClick={() => navigate(loc ? loc : -1)}
      >
        <FiChevronLeft className="w-9 h-9" />
        <span>{t('take me back')}</span>
      </button>
    </div>
  );
};

export default TakeMeBack;
