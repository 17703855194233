import Compressor from 'compressorjs';

export function compressImage(file: File) {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      quality: 0.9,
      success: (compressedFile) => {
        resolve(compressedFile);
      },
      error: (error) => {
        reject(error);
      },
    });
  });
}
