import React from 'react';
import HuntTable from '../table/HuntTable';
import { useNavigate } from 'react-router-dom';
import { useHunt } from '../../../../contexts/hunt';
import { initialHunt } from '../../../../initialStates/initialStates';
import { useTranslation } from 'react-i18next';
// import { getMatchService } from '../../../../services/huntService';

const PropertyHuntsContent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setCurrentHuntContext } = useHunt();

  const handleClick = () => {
    setCurrentHuntContext(initialHunt); // reset current hunt

    navigate('/users/addhunt');
  };

  // const handleSearchMatch = () => {
  //   getMatchService()
  //     .then((response) => {
  //       console.log(response.data);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };

  return (
    <div>
      <div className="flex justify-end my-4 w-full">
        <div>
          <button onClick={handleClick} className="primary-btn">
            {t('New hunt')}
          </button>
        </div>
        {/* <button
          onClick={handleSearchMatch}
          className="mx-3 px-2 font-bold hover:bg-blue-500 rounded-md font-boldpx-4 py-2 bg-blue-400 text-white shadow-md duration-300 whitespace-nowrap"
        >
          --TESTING-- search hunts
        </button> */}
      </div>
      <HuntTable />
    </div>
  );
};

export default PropertyHuntsContent;
