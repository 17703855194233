import React from 'react';
import VerifyInvitation from '../components/auth/VerifyInvitation';

const VerifyInvitationPage = () => {
  return (
    <div>
      <VerifyInvitation />
    </div>
  );
};

export default VerifyInvitationPage;
