import React, { useState, useEffect, useRef } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { savePopupState, getPopupState } from '../../utils/tutoPopupState';
import {
  useFloating,
  FloatingArrow,
  arrow,
  FloatingPortal,
  offset,
  flip,
  shift,
  autoUpdate,
} from '@floating-ui/react';

const ARROW_WIDTH = 28;
const ARROW_HEIGHT = 14;

const TutoPopup = ({ children, text, title, placement = 'left', storageKey }: any) => {
  const [open, setOpen] = useState(() => getPopupState(storageKey) ?? true);
  const arrowRef = useRef(null);

  const { x, y, strategy, refs, floatingStyles, update, context } = useFloating({
    placement,
    middleware: [
      offset(35),
      flip(),
      shift({ padding: 15 }),
      arrow({
        element: arrowRef,
      }),
    ],
    whileElementsMounted: autoUpdate, // Automatically update position on scroll/resize
  });

  const referenceRef = refs.setReference;
  const floatingRef = refs.setFloating;

  const handleClose = () => {
    setOpen(false);

    savePopupState(storageKey, false); // localStorage
  };

  useEffect(() => {
    if (open) {
      update();
    }
  }, [open, update]);

  return (
    <div>
      {/* Reference element which will trigger the tooltip */}
      <button className="flex items-center" ref={referenceRef} onClick={() => setOpen(false)}>
        {children}
      </button>
      {/* Render the tooltip content only if open */}
      {open && (
        <FloatingPortal>
          <div
            ref={floatingRef}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
              width: 'max-content',
              ...floatingStyles,
            }}
            className="Popover hidden md:flex flex-col relative"
          >
            <div className="font-bold uppercase text-lg pb-1">{title}</div>
            <div className="font-normal text-sm ">{text}</div>

            <FloatingArrow
              ref={arrowRef}
              context={context}
              width={ARROW_WIDTH}
              height={ARROW_HEIGHT}
              style={{
                position: 'absolute',
              }}
              className={`fill-deehiy ${{ ...context.floatingStyles }}`}
            />
            <button
              onClick={handleClose}
              className="absolute top-2 right-2 outline-none ring-0 rounded-full hover:border-white border border-transparent duration-300"
            >
              <AiOutlineClose size={20} />
            </button>
          </div>
        </FloatingPortal>
      )}
    </div>
  );
};

export default TutoPopup;
