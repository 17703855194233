import React from 'react';
import CreatePostModalContent from '../../components/square/CreatePostModalContent';
import { useNavigate, useParams } from 'react-router-dom';
import { PostType } from '../../types/types';
import TakeMeBack from '../../components/main/navigation/TakeMeBack';

const AddPost = () => {
  const navigate = useNavigate();
  const { type } = useParams();

  return (
    <>
      <TakeMeBack />
      <div className=" h-screen w-full flex items-center justify-center">
        <CreatePostModalContent redirect={() => navigate(-1)} type={type as PostType} />
      </div>
    </>
  );
};

export default AddPost;
