import React, { useState } from 'react';

interface ReadMoreProps {
  children: string;
  max: number;
}

const ReadMore = ({ children, max }: ReadMoreProps) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="w-full">
      {isReadMore ? text.slice(0, max) : text}
      {text.length > max && (
        <span onClick={toggleReadMore} className="text-deehiy whitespace-nowrap cursor-pointer">
          {isReadMore ? '...read more' : ' show less'}
        </span>
      )}
    </p>
  );
};

export default ReadMore;
