import React from 'react';
import { Navigation } from '../main/navigation/Navigation';
import MobileNavigation from '../main/navigation/MobileNavigation';
import { Outlet } from 'react-router-dom';
// import BackToTopButton from '../main/BackToTopButton';
import Footer from './Footer';

const BusinessLayout = ({ children }: any) => {
  return (
    <div className=" dark:bg-slate-900 flex flex-col min-h-screen max-h-screen relative">
      <header>
        <div className="hidden sm:block">
          <Navigation label="Business Manager" isBusiness={true} />
        </div>
        <div className="block sm:hidden">
          <MobileNavigation isBusiness={true} />
        </div>
      </header>

      <main className="mx-auto pt-0 sm:pt-16 pb-2 mb-32 h-full w-full px-2 sm:px-24">
        <Outlet />
        {children}
      </main>

      {/* <BackToTopButton /> */}
      <Footer />
    </div>
  );
};

export default BusinessLayout;
