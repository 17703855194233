import React from 'react';
import TakeMeBack from '../main/navigation/TakeMeBack';
import { useTranslation } from 'react-i18next';

const LeadSubmittedSuccessPage = () => {
  const { t } = useTranslation();

  return (
    <div className="container mx-auto flex flex-col justify-center space-y-10 pt-24 items-center">
      <TakeMeBack loc="/users/huntingboard/1" />

      <div className="flex font-bold text-center text-3xl sm:text-5xl">{t('Well done!!')}</div>
      <div className="flex text-center text-md">
        {t('You successfully submitted your listing to the hunter.')}
      </div>
      <div className="flex text-center text-md">
        {t('You will be notified if you are targeted.')}
      </div>
    </div>
  );
};

export default LeadSubmittedSuccessPage;
