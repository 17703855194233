import React from 'react';
import { Navigation } from '../main/navigation/Navigation';
import MobileNavigation from '../main/navigation/MobileNavigation';
import { Outlet } from 'react-router-dom';
// import BackToTopButton from '../main/BackToTopButton';
import Footer from './Footer';

const HelpCenterLayout = ({ children }: any) => {
  return (
    <div className=" dark:bg-slate-900 flex flex-col min-h-screen ">
      <header>
        <div className="hidden sm:block">
          <Navigation label="Help center" />
        </div>
        <div className="block sm:hidden">
          <MobileNavigation />
        </div>
      </header>

      {/* <main className="mx-auto pt-0 sm:pt-16 flex flex-col flex-grow w-full"> */}
      <main className="max-w-[1240px] container mx-auto pt-4 sm:pt-20 h-full pb-12">
        <Outlet />
        {children}
      </main>

      {/* <BackToTopButton /> */}
      <Footer />
    </div>
  );
};

export default HelpCenterLayout;
