import React, { useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { propertyDetailsFormSchema } from '../../../lib/yup';
import { Details, ParamsPerType } from '../../../types/types';

import Input from '../../form/Input';
import Select from '../../form/Select';
import { useTranslation } from 'react-i18next';

import { dateOptions, propertyTypesInitialState } from '../../../data/data';

const PropertyDetails: React.FC<any> = ({ editedProperty, setEditedProperty, setCanClickNext }) => {
  const methods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(propertyDetailsFormSchema),
    // defaultValues: stepOne || {},
  });
  const { t } = useTranslation();

  const [
    { floorLevel, ceilingHeight, parking, builtIn, lastRenovation, furnishingStatus },
    setDetailsParams,
  ] = useState<Details | any>({
    floorLevel: false,
    ceilingHeight: false,
    parking: false,
    builtIn: false,
    lastRenovation: false,
    furnishingStatus: false,
  });

  const handleChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;

    setEditedProperty({ ...editedProperty, [name]: value });
  };

  useEffect(() => {
    console.log(dateOptions);

    setCanClickNext(true);
  }, []);

  useEffect(() => {
    const selectedType = propertyTypesInitialState.find(
      (el: ParamsPerType) => el.propertyType === editedProperty.propertyType
    );
    setDetailsParams(selectedType?.details);
  }, [editedProperty.propertyType]);

  return (
    <div className="step-form-wrapper flex-col w-full">
      <FormProvider {...methods}>
        <form className="flex flex-col space-y-3">
          <div className="flex-col w-7/8 ">
            <p className="font-light text-lg">{t('Share some important details')}</p>
            <p className="font-light hidden sm:flex text-sm">
              {t('If you do not know these details, you can skip it and do this later')}
            </p>
          </div>
          {floorLevel && (
            <div className="flex">
              <div className="w-4/5 sm:w-1/2 flex items-center">{t('floor level')}</div>
              <div className="w-1/5 sm:w-1/2 ">
                <Input
                  label=""
                  type="number"
                  id="floorLevel"
                  name="floorLevel"
                  onChange={handleChange}
                  value={editedProperty.floorLevel}
                  placeholder={'0'}
                />
              </div>
            </div>
          )}
          {ceilingHeight && (
            <div className="flex">
              <div className="w-4/5 sm:w-1/2  flex items-center">
                {t('ceiling height')}&nbsp;<b>{t('in meters')}</b>
              </div>
              <div className="w-1/5 sm:w-1/2 ">
                <Input
                  label=""
                  type="number"
                  id="ceilingHeight"
                  name="ceilingHeight"
                  onChange={handleChange}
                  value={editedProperty.ceilingHeight}
                  placeholder={'0'}
                />
              </div>
            </div>
          )}
          {parking && (
            <div className="flex">
              <div className="w-4/5 sm:w-1/2  flex items-center">
                {t('nr. of private parking lots')}
              </div>
              <div className="w-1/5 sm:w-1/2 ">
                <Input
                  label=""
                  type="number"
                  id="parkingLots"
                  name="parkingLots"
                  onChange={handleChange}
                  value={editedProperty.parkingLots}
                  placeholder={'0'}
                />
              </div>
            </div>
          )}
          {builtIn && (
            <div className="flex">
              <div className="w-3/5 sm:w-1/2  flex items-center">{t('property built in')}</div>
              <div className="w-2/5 sm:w-1/2 ">
                {/* <DatePicker
                label=''
                placeholder='built in'
                id='builtInDate'
                name='builtInDate'
                onSelect={handleChange}
                value={editedProperty.builtInDate}
              /> */}
                <Select
                  id="builtInDate"
                  name="builtInDate"
                  label=""
                  placeholder={t('built in')}
                  onChange={handleChange}
                  value={editedProperty.builtInDate}
                >
                  {dateOptions.map(({ value, text }) => (
                    <option value={value} key={value}>
                      {text}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
          )}
          {lastRenovation && !editedProperty.isOffPlan ? (
            <div className="flex">
              <div className="w-3/5 sm:w-1/2  flex items-center">{t('last renovation in')}</div>
              <div className="w-2/5 sm:w-1/2 ">
                {/* <DatePicker
                label=''
                placeholder='last renovation in'
                id='lastRenovationDate'
                name='lastRenovationDate'
                onSelect={handleChange}
                value={editedProperty.lastRenovationDate}
              /> */}

                <Select
                  id="lastRenovationDate"
                  name="lastRenovationDate"
                  label=""
                  placeholder={t('last renovation in')}
                  onChange={handleChange}
                  value={editedProperty.lastRenovationDate}
                >
                  {dateOptions.map(({ value, text }) => (
                    <option value={value} key={value}>
                      {text}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
          ) : (
            ''
          )}
          {furnishingStatus && (
            <div className="flex">
              <div className="w-3/5 sm:w-1/2  flex">{t('furnishing status')}</div>
              <div className="w-2/5 sm:w-1/2 ">
                <div className="flex pt-2 text-sm gap-x-4 items-center">
                  <input
                    type="radio"
                    className={`focus:outline-none focus:ring-0 h-4 w-4 border-gray-300 ${
                      editedProperty.furnishingStatus === 'fully' ? 'text-deehiy' : 'text-white'
                    }`}
                    name="furnishingStatus"
                    value="fully"
                    onChange={() =>
                      setEditedProperty({ ...editedProperty, furnishingStatus: 'fully' })
                    }
                    checked={editedProperty.furnishingStatus === 'fully'}
                  />
                  <div className="font-normal">{t('fully furnished')}</div>
                </div>

                <div className="flex pt-2 text-sm gap-x-4 items-center">
                  <input
                    type="radio"
                    className={`focus:outline-none focus:ring-0 h-4 w-4 border-gray-300 ${
                      editedProperty.furnishingStatus === 'partially' ? 'text-deehiy' : 'text-white'
                    }`}
                    name="furnishingStatus"
                    value="partially"
                    onChange={() =>
                      setEditedProperty({ ...editedProperty, furnishingStatus: 'partially' })
                    }
                    checked={editedProperty.furnishingStatus === 'partially'}
                  />
                  <div className="font-normal">{t('partially furnished')}</div>
                </div>

                <div className="flex pt-2 text-sm gap-x-4 items-center">
                  <input
                    type="radio"
                    className={`focus:outline-none focus:ring-0 h-4 w-4 border-gray-300 ${
                      editedProperty.furnishingStatus === 'unfurnished'
                        ? 'text-deehiy'
                        : 'text-white'
                    }`}
                    name="furnishingStatus"
                    value="unfurnished"
                    onChange={() =>
                      setEditedProperty({ ...editedProperty, furnishingStatus: 'unfurnished' })
                    }
                    checked={editedProperty.furnishingStatus === 'unfurnished'}
                  />
                  <div className="font-normal">{t('unfurnished')}</div>
                </div>
              </div>
            </div>
          )}
        </form>
      </FormProvider>
    </div>
  );
};

export default PropertyDetails;
