import React from 'react';
import TagManager from 'react-gtm-module';

const GTM_ID = process.env.REACT_APP_GTM_ID;

const GTM = () => {
  React.useEffect(() => {
    const tagManagerArgs = {
      gtmId: GTM_ID,
      dataLayer: {
        userId: '001',
        userProject: 'Climate Mind App',
      },
    };
    // @ts-ignore
    TagManager.initialize(tagManagerArgs);
  }, []);
  return null;
};

export default GTM;
