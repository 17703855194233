import React, { useEffect, useRef } from 'react';
import FullPortalModal from './FullPortalModal';
import Slideshow from './Slideshow';
import { adjustImageSize } from '../../utils/adjustImageSize';

const GalleryTemplate = React.memo(({ images, defaultIndex }: any) => {
  const imageRefs = useRef<(HTMLDivElement | null)[]>([]);

  const scrollToDefaultImage = () => {
    const defaultImageRef = imageRefs.current[defaultIndex];
    if (defaultImageRef) {
      defaultImageRef.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  useEffect(() => {
    scrollToDefaultImage();
  }, []);

  // grid-cols-1 sm:grid-cols-2

  return (
    <div className="grid container width-overlay-layout mx-auto grid-cols-2 gap-2">
      {images.map((image: any, index: number) => (
        <div
          key={index}
          ref={(el) => (imageRefs.current[index] = el)}
          className={`flex justify-center ${
            index % 3 === 0 || images.length - index < 2 ? 'col-span-2' : ''
          }`}
        >
          <FullPortalModal
            Btn={({ setShowModal }: any) => (
              <img
                className="cursor-pointer hover:shadow-lg hover:opacity-90 transition duration-300 ease-in-out w-full object-cover aspect-video sm:aspect-video"
                alt=""
                loading="lazy"
                onClick={() => setShowModal(true)}
                src={images[index]?.url && adjustImageSize(images[index]?.url)}
              />
            )}
          >
            <Slideshow list={images} defaultIndex={index} />
          </FullPortalModal>{' '}
        </div>
      ))}
    </div>
  );
});

export default GalleryTemplate;
