export function adjustImageSize(url: string, size?: number) {
  // to retrieve the desired size from cloud
  if (url?.includes('image/upload/')) {
    // return 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/329623579.jpg?k=10b13586c9d0ee6ac012780c28455200a202a3ccaf30b69c343eabe90a5e32e8&o=&hp=1';
    const updatedURL = size
      ? url.replace('image/upload/', `image/upload/c_scale,w_${size}/`)
      : url.replace('image/upload/', `image/upload/f_auto,q_auto/`);
    return updatedURL;
  } else {
    return url;
  }
}
