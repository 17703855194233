import axios from 'axios';
import { compressImage } from '../utils/compressor';
import { resizeToBlob } from '../utils/resizeToBlob';

const cloudName = process.env.REACT_APP_CLOUDINARY_NAME;
const preset = process.env.REACT_APP_CLOUDINARY_PRESET;
const signedUploadFolder = process.env.REACT_APP_CLOUDINARY_SIGNED_UPLOAD_FOLDER;

// unsigned : not all parameters available (transformations)
export const fileUpload = async (file: any): Promise<string | null> => {
  const cloudinaryUrl = `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`;

  const formData = new FormData();
  formData.append('upload_preset', `${preset}`);
  formData.append('file', file);

  try {
    const res = await fetch(cloudinaryUrl, {
      method: 'POST',
      body: formData,
    });

    if (!res.ok) return null;

    const data = await res.json();
    // return data.secure_url;
    return data;
  } catch (error) {
    return null;
  }
};

export const signedFilesUpload = async (files: any): Promise<any | null> => {
  const signResponse = await fetch('/api/signuploadform');
  const signData = await signResponse.json();
  const url = 'https://api.cloudinary.com/v1_1/' + signData.cloudname + '/auto/upload';
  const arr = [];

  const formData = new FormData();
  // WARNING
  // Append parameters to the form data. The parameters that are signed using
  // the signing function (signuploadform) need to match these in the BACKEND
  for (let i = 0; i < files.length; i++) {
    let file = files[i];
    formData.append('file', file);
    formData.append('api_key', signData.apikey);
    formData.append('timestamp', signData.timestamp);
    formData.append('signature', signData.signature);
    // formData.append("eager", "c_pad,h_300,w_400|c_crop,h_200,w_260/l_sample_watermark")
    // formData.append("eager", "c_fit,l_watermark_bp1jza")
    // formData.append("eager", "l_watermark_sm_unm2wo,x_0,y_0, g_south_west,x_10,y_10, g_center,x_10,y_10, g_north_east,x_10,y_10")
    // formData.append('folder', 'signed_upload_demo_form');
    formData.append('folder', signedUploadFolder!);

    try {
      const res = await fetch(url, {
        method: 'POST',
        body: formData,
      });

      if (!res.ok) return null;
      const data = await res.json();

      arr.push(data);
    } catch (error) {
      return null;
    }
  }

  return arr;
};

// upload streams to cloud
export const uploadOnCloud = async (newFilesStr: string[]) => {
  try {
    // * signed upload *
    const startTime = Date.now();
    const arr = await signedFilesUpload(newFilesStr);
    const endTime = Date.now();
    const processingTime = endTime - startTime;

    console.log(`${processingTime / 1000}s`);
    return arr;
    // * unsigned upload * => no transformation | no watermark
    // const arr = await Promise.all(
    //   newFilesStr.map(async img => {
    //     const cloudinary_img = await fileUpload(img).catch(error => {
    //       console.error(`Error uploading ${img}: ${error}`)
    //     })
    //     return cloudinary_img
    //   })
    // )
    //return arr
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const ImagePropertyUpload = async (
  file: any,
  onProgress?: (progress: number) => void
): Promise<any | null> => {
  const signResponse = await fetch('/api/signuploadform');
  const signData = await signResponse.json();
  const url = 'https://api.cloudinary.com/v1_1/' + signData.cloudname + '/auto/upload';

  const newFile = await resizeToBlob(file, 1280, 720, 1, false);

  const compressedFile = await compressImage(newFile);

  const formData = new FormData();
  formData.append('file', compressedFile as File);
  formData.append('api_key', signData.apikey);
  formData.append('timestamp', signData.timestamp);
  formData.append('signature', signData.signature);
  formData.append('folder', signedUploadFolder!);

  const config = {
    onUploadProgress: (e: any) => {
      const { loaded, total } = e;
      const progress = Math.round((loaded / total) * 100);
      if (onProgress) {
        onProgress(progress);
      }
    },
  };

  try {
    const instance = axios.create();
    delete instance.defaults.headers.common['x-auth-token']; // to remove cors policy
    const { data } = await instance.post(url, formData, config);

    const publicId = data.public_id;
    const secureUrl = data.secure_url;
    return { publicId, secureUrl };
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const ImageProfileUpload = async (
  file: any,
  onProgress?: (progress: number) => void,
  width = 300,
  height = 300
): Promise<any | null> => {
  const signResponse = await fetch('/api/signuploadform');
  const signData = await signResponse.json();
  const url = 'https://api.cloudinary.com/v1_1/' + signData.cloudname + '/auto/upload';

  const newFile = await resizeToBlob(file, width, height, 1, false);

  const compressedFile = await compressImage(newFile);

  const formData = new FormData();
  formData.append('file', compressedFile as File);
  formData.append('api_key', signData.apikey);
  formData.append('timestamp', signData.timestamp);
  formData.append('signature', signData.signature);
  formData.append('folder', signedUploadFolder!);

  const config = {
    onUploadProgress: (e: any) => {
      const { loaded, total } = e;
      const progress = Math.round((loaded / total) * 100);
      if (onProgress) {
        onProgress(progress);
      }
    },
  };

  try {
    const instance = axios.create();
    delete instance.defaults.headers.common['x-auth-token']; // to remove cors policy
    const { data } = await instance.post(url, formData, config);

    const publicId = data.public_id;
    const secureUrl = data.secure_url;
    return { publicId, secureUrl };
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const ImagePostUpload = async (
  file: any,
  onProgress?: (progress: number) => void
): Promise<any | null> => {
  const signResponse = await fetch('/api/signuploadform');
  const signData = await signResponse.json();
  const url = 'https://api.cloudinary.com/v1_1/' + signData.cloudname + '/auto/upload';

  const compressedFile = await compressImage(file);

  const formData = new FormData();
  formData.append('file', compressedFile as File);
  formData.append('api_key', signData.apikey);
  formData.append('timestamp', signData.timestamp);
  formData.append('signature', signData.signature);
  formData.append('folder', signedUploadFolder!);

  const config = {
    onUploadProgress: (e: any) => {
      const { loaded, total } = e;
      const progress = Math.round((loaded / total) * 100);
      if (onProgress) {
        onProgress(progress);
      }
    },
  };

  try {
    const instance = axios.create();
    delete instance.defaults.headers.common['x-auth-token']; // to remove cors policy
    const { data } = await instance.post(url, formData, config);

    const publicId = data.public_id;
    const secureUrl = data.secure_url;
    return { publicId, secureUrl };
  } catch (error) {
    console.error(error);
    return null;
  }
};
