import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropertyCard from '../main/PropertyCard';
import { UserCommunityDetails } from '../main/community/Community';
import { UserTooltip, AvatarNamePost, AvatarImage } from '../square/Square';
import { FiChevronDown } from 'react-icons/fi';
import { adjustImageSize } from '../../utils/adjustImageSize';
import ReadMore from '../listing/ReadMore';
import Tooltip from '../form/Tooltip';
import NoResults from '../main/NoResults';
import { useTranslation } from 'react-i18next';
// import { ReactComponent as MarketSVG } from '../assets/header-icons/market.svg';
// import { ReactComponent as SquareSVG } from '../assets/header-icons/square.svg';
// import { ReactComponent as CommunitySVG } from '../assets/header-icons/community.svg';

export const AvatarNameCommunity = ({ user }: any) => {
  return (
    <div className="flex gap-x-3 items-center">
      <div className="group relative aspect-square">
        <>
          {user?.isSalesRep && (
            <div className="group absolute top-0 left-0">
              <Tooltip
                text="Deehiy Ambassador"
                Component={() => <img className=" w-3 h-3 " src="/images/icons/sales-rep.svg" />}
              />
            </div>
          )}
        </>
        <AvatarImage
          imageUrl={
            user.image ? adjustImageSize(user.image) : process.env.REACT_APP_DEFAULT_USER_URL!
          }
        />
        <div className="group-hover:visible invisible group-hover:w-full w-0 delay-300">
          <UserTooltip user={user} />
        </div>
      </div>
      <UserCommunityDetails member={user} />
    </div>
  );
};

export const PostsFeed = ({ feeds }: any) => {
  return (
    <div className="flex flex-col h-full w-full mx-auto">
      {feeds.slice(0, 4).map((post: any, i: number) => {
        return (
          <div key={i} className="text-xs xl:text-sm h-32 mt-2">
            <div className="flex justify-between">
              <div className=" flex flex-col">
                <AvatarNamePost user={post?.author} isNetwork={false} createdAt={post?.createdAt} />
                {post.title.length > 0 && <div className="font-semibold">{post.title}</div>}
                {post.content.length > 0 ? (
                  <div
                    className="line-clamp-3"
                    dangerouslySetInnerHTML={{ __html: post.content }}
                  ></div>
                ) : (
                  <div className="text-xs">
                    {post.author.firstName} {post.author.lastName} did not write a caption for this
                    post
                  </div>
                )}
              </div>
              {post.media && post.media.length > 0 && (
                <div style={{ minWidth: '128px' }} className="w-32 aspect-square flex justify-end">
                  <img
                    src={adjustImageSize(post.media[0].url)}
                    className="h-full w-full object-cover"
                  />
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const UsersFeed = ({ feeds }: any) => {
  return (
    <div className="w-full flex flex-col text-xs xl:text-sm ">
      {feeds.slice(0, 4).map((member: any, i: number) => {
        return (
          <div key={i} className="flex flex-col min-h-[128px]">
            <AvatarNameCommunity user={member} />
            <div className="w-5/6 text-justify pt-2	">
              {member.description && member.description.length > 0 ? (
                <ReadMore max={500}>{member.description}</ReadMore>
              ) : (
                <>{`Oh! Looks like ${member.firstName} ${member.lastName} has not written a profile description.`}</>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const ListingsFeed = ({ feeds }: any) => {
  return (
    <div className="flex gap-4 justify-start scroller-x py-3">
      {feeds.slice(0, 4).map((listing: any, i: number) => {
        //5
        return (
          <div key={i} className={` ${i === 4 && 'border 2xl:flex hidden'}`}>
            <PropertyCard property={listing} />
          </div>
        );
      })}
    </div>
  );
};

const Container = ({ title, url, children, count }: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="py-3  px-4 sm:pl-20 flex flex-col">
      <div className="font-bold text-lg pb-2">
        {title}
        {/* TODO: update the API call to retrieve the total count */}
        <span className=" hidden font-normal pl-1 text-gray-500 text-sm">({count} results)</span>
      </div>
      {children}
      <div className=" pt-12 border flex w-full justify-center border-transparent border-b-gray-800 relative">
        <button
          className="bg-white w-36 justify-center text-xs xl:text-sm group font-bold items-center px-2 py-1 rounded-full border border-gray-800 sm:px-4 h-10 flex gap-x-2 absolute bottom-[-20px]"
          onClick={() => navigate(url)}
        >
          <span>{t('show more')}</span>
          <FiChevronDown className="group-hover:animate-bounce" />
        </button>
      </div>
    </div>
  );
};

const AllResults = ({ listings, posts, members }: any) => {
  const { t } = useTranslation();

  useEffect(() => {
    console.log('results:', listings, posts, members);
  }, [listings, posts, members]);

  if (listings.length === 0 && posts.length === 0 && members.length === 0) {
    return (
      <div className="pb-12 mt-14 flex justify-center text-center">
        <NoResults />
      </div>
    );
  }

  return (
    <div className=" flex flex-col  w-full sm:w-3/4 pb-12 mt-14 sm:mt-6">
      {listings.length > 0 && (
        <Container title={t('Market')} url="/results/market" count={listings.length}>
          <ListingsFeed feeds={listings} />
        </Container>
      )}
      {posts.length > 0 && (
        <Container title={t('Square')} url="/results/square" count={posts.length}>
          <PostsFeed feeds={posts} />
        </Container>
      )}
      {members.length > 0 && (
        <Container title={t('Community')} url="/results/community" count={members.length}>
          <UsersFeed feeds={members} />
        </Container>
      )}
    </div>
  );
};

export default AllResults;
