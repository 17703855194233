import React from 'react';
import { FaFacebookF, FaLinkedin } from 'react-icons/fa';
import { BiCopy } from 'react-icons/bi';
import { ToastContainer, toast, Slide } from 'react-toastify';

const copyCurrentUrl = () => {
  const currentUrl = window.location.href;
  navigator.clipboard.writeText(currentUrl);
  toast.success('Link copied to clipboard!', {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};

const CopyLinkButton = () => {
  return (
    <div onClick={copyCurrentUrl}>
      <ToastContainer transition={Slide} />
      <BiCopy className="w-5 h-5 text-deehiy" />
    </div>
  );
};

const News = () => {
  // const mediumTitle = 'text-xl sm:text-3xl font-bold pb-4 ';

  return (
    <div className="mx-auto pt-6 sm:pt-12  container px-4 sm:px-20 flex flex-col">
      <div className="w-full sm:w-3/5 mx-auto">
        <div className="text-2xl sm:text-5xl font-bold pb-8">
          New Year, New Face: <br /> Deehiy Launches new <br /> Community Marketplace
        </div>
        <div className=" flex space-x-4 text-md text-gray-600">
          by Deehiy, {new Date().toLocaleString('default', { month: 'long', year: 'numeric' })}
        </div>
        <div className=" flex space-x-6 py-6">
          <a href="https://www.facebook.com/buydeehiy/" target="_blank" className="text-deehiy">
            <FaFacebookF className="w-5 h-5 text-deehiy" />
          </a>
          <a href="https://www.linkedin.com/company/deehiy/mycompany/" target="_blank">
            <FaLinkedin className="w-5 h-5 text-deehiy" />
          </a>
          <CopyLinkButton />
        </div>
        <div className="pt-6 text-xl font-bold sm:text-2xl">
          Get familiar with the money-making features that will boost your performance
        </div>
        <div className="pt-4 text-xs sm:text-sm">
          We are thrilled to announce the launch of the world's first community-based real estate
          marketplace that will disrupt the industry. Learn how you will get the most out of the
          newly launched platform.
        </div>
        <div className="pt-6 text-xs font-bold sm:text-sm">1. It’s all about you</div>
        <div className="pt-3 text-xs sm:text-sm">
          We have designed Deehiy in the most traditional context of a marketplace, where you play
          the most important role. We provide you with everything you need to buy and / or sell, but
          you will ultimately define your own success. The more active and up-to-date your profile
          is, the more leads you will receive.
        </div>
        <div className="pt-6 text-xs font-bold sm:text-sm">2. Powerful Performance</div>
        <div className="pt-3 text-xs sm:text-sm">
          Built on cutting-edge technology, our platform ensures smooth and seamless user
          experiences. Our advanced algorithms connect buyers, sellers and agents to quickly find
          properties they desire, saving time and effort. The responsive design and optimized
          performance guarantee a seamless experience and accessibility across various devices. And
          we are continuously improving to assure even better performance.
        </div>
        <div className="pt-6 text-xs font-bold sm:text-sm">3. Money-Making Features</div>
        <div className="pt-3 text-xs sm:text-sm">
          We’ve had an earful for the last 6 months, listening to every issue buyers, sellers and
          agents have with the current tools at their availability, and we are aiming to solve every
          problem integrated in one single platform. Exiting new features will be released
          continuously over the next 6 months. Get ready to chat, call, tour, appoint, plan, sell,
          buy and network with ease on one single platform.
          <br />
          <br />
          We are thrilled to introduce a multitude of groundbreaking features disrupting
          conventional practices within the real estate industry. The launch of Deehiy's
          community-based real estate marketplace marks a new era where our users have full control
          over their property transactions. We hope you enjoy the new platform as much as we do and
          wish you great success and a lot of fun on Deehiy.
        </div>
        <br />
        <br />
      </div>
      <div className="flex justify-center w-4/5 mx-auto p-2 sm:p-6 items-center">
        <img alt="" src={'/images/launch-original.png'} className="w-full" />
      </div>
      {/* <div className="w-full sm:w-3/5 mx-auto">
        <div className="py-6 text-xs sm:text-sm">
          <div className={`${mediumTitle}`}>New Marketplace</div>
          <div>
            The new marketplace brings you straight to where you want to go without the need to
            navigate anywhere. If you seek to buy a property, you now have quick access buttons to
            search for exactly what you want with a single click. The new filter allows you to
            create an even more accurate search to find your property.
          </div>
        </div>
        <div className="py-6 text-xs sm:text-sm">
          <div className={`${mediumTitle}`}>Your personal exposure</div>
          <div>
            Nothing is more important than how you present yourself. You can now create your
            personal profile and add important information such as the languages you speak, the
            skills you have and if you are an Agent you can dedicate your profile as an Agent and
            other users will be able to appoint you for your services.
          </div>
        </div>
        <div className="py-6 text-xs sm:text-sm">
          <div className={`${mediumTitle}`}>A fresh new look</div>
          <div>yes yes yes and it is so pretty</div> */}
      <br />
      <br />
      <br />
    </div>
  );
};

export default News;
