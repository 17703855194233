import React, { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/auth';
import Loader from '../components/main/Loader';

interface IProps {
  children: ReactNode;
}

const Protected: React.FC<IProps> = ({ children }) => {
  const { currentUser, isLoading } = useAuth();
  const location = useLocation();

  if (isLoading) return <Loader />;

  if (!currentUser) {
    return <Navigate to="/login" replace state={{ redirectTo: location }} />;
  }
  return <>{children}</>;
};
export default Protected;
