import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useBusiness } from '../../../contexts/business';
import { useAuth } from '../../../contexts/auth';
import { useTranslation } from 'react-i18next';

const Step4 = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const { currentBusiness } = useBusiness();
  const { t } = useTranslation();

  useEffect(() => {
    if (!currentUser || !currentBusiness) {
      navigate('/users/dashboard');
    }
  }, []);

  //useEffect(() => {
  //   if (currentBusiness && currentUser?.id === currentBusiness.adminId) {
  //     console.log("2 currentBusiness.businessId:", currentBusiness.businessId)
  //     setCurrentUserAsBusinessAdmin(currentBusiness.adminId);

  //}, [currentBusiness.businessId]);

  return (
    <div>
      <div className="w-full flex justify-center text-2xl sm:text-5xl font-bold pt-12 sm:pt-8 pb-4 sm:py-6">
        {t('Congratulations!')}
      </div>
      <div className="w-full flex text-center justify-center text-xl sm:text-2xl font-bold py-3 sm:py-6">
        {t('You just created your business')}
        <br />
        <br />
      </div>
      <div className="flex pt-6 h-[55vh] sm:h-[52vh]">
        <div className=" hidden sm:flex w-1/2 justify-end">
          <img alt="add business" className=" mr-12" src="/images/addbusiness4.svg" />
        </div>
        <div className="sm:w-1/2 w-full relative">
          <div className="flex-col w-full sm:w-3/5 mx-2 sm:mx-12">
            <div>{t('continue_logging_business')}</div>
            <button onClick={() => navigate('/business/dashboard')} className="primary-btn mt-12">
              {t('Go to your business profile')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step4;
