// import { Post } from '../types/types';
import http from './httpService';

const apiCommentEndpoint = '/api/comments';

export const createCommentService = (comment: any) => {
  console.log(comment);
  return http.post(`${apiCommentEndpoint}/`, { comment });
};

export const toggleLikeCommentService = (commentId: any) => {
  return http.post(`${apiCommentEndpoint}/${commentId}/like`);
};

export const deleteCommentService = (commentId: any) => {
  return http.delete(`${apiCommentEndpoint}/${commentId}`);
};

export const getCommentByIdService = (commentId: any) => {
  return http.get(`${apiCommentEndpoint}/${commentId}`);
};
