import React from 'react';

const AboutUs = () => {
  return (
    <div className="container max-w-[850px] mx-auto space-y-6 py-10 px-4">
      <div className="font-light text-2xl text-center">About us </div>
      <div className="font-light text-sm text-gray-400 text-center">(updated 23 April 2024)</div>
      <div className="text-sm font-extralight">
        Welcome to Deehiy, where innovation meets real estate. I'm Mendes Cavin, Founder & CEO, and
        I'm thrilled to introduce you to our platform.
        <br />
        <br />
        Our journey began in March 2022 when we recognized the stagnant state of the real estate
        market, despite the remarkable advancements seen in other industries. We set out to disrupt
        real estate transactions, starting in Thailand, by providing a simple, commission-free
        platform for agents, sellers, and buyers.
        <br />
        <br />
        Our initial platform launch in June 2022 marked the beginning of our quest to redefine the
        real estate landscape. With listings spanning all 77 provinces of Thailand by the end of
        2022, we quickly gained traction, empowering users to upload and boost listings
        effortlessly.
        <br />
        <br />
        Driven by user feedback and a commitment to enhancing the real estate experience, we
        assembled a dedicated team in January 2023 to develop a new, feature-rich platform focused
        on user-friendly design and global accessibility.
        <br />
        <br />
        At Deehiy, our mission is clear: to simplify real estate transactions worldwide. Our
        platform fosters direct interaction between users, streamlining the process like never
        before. While other platforms operate on traditional business models, Deehiy thrives on
        community-driven innovation, simplicity, and global connectivity.
        <br />
        <br />
        As we propel forward, we aim to be the foremost platform for global real estate. Our goal is
        to create a global hub where users find everything they need to thrive in the real estate
        industry: find customers, expand networks, share expertise and find insights. Derived from
        my native Swiss language, "Deehiy" translates to "home" or "at home." This is what we are
        aiming for with our platform: giving all things real estate related to a home.
        <br />
        <br />
        Join us on our journey to transform real estate. Welcome home to Deehiy!
      </div>
    </div>
  );
};

export default AboutUs;
