import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="container max-w-[850px] mx-auto space-y-6 py-10 px-4">
      <div className="font-light text-2xl text-center">DEEHIY WEBSITE PRIVACY POLICY</div>
      <div className="text-sm font-extralight leading-loose ">
        <div className="font-light text-xl mb-2">1. Introduction</div>
        This policy describes how Deehiy collects, shares, and uses your information when you access
        our services. This policy applies to all of the products and services owned and operated by
        C&C Cavin Co. Ltd., including the content, features, data, and software available on{' '}
        <a href="https://deehiy.com" className="text-deehiy font-bold" target="_blank">
          www.deehiy.com
        </a>
        , our mobile applications, and other websites or applications that we operate (“Services”).{' '}
        <br />
        <br />
        <br />
        <br />
        <div className="font-light text-xl mb-2">2. The Kinds of Information We Collect</div>
        <div className="font-light text-md underline mb-2">a. Information You Provide</div>
        We collect content, images, and other information you provide to us when you access our
        Services, including when you complete webforms on{' '}
        <a href="https://deehiy.com" className="text-deehiy font-bold" target="_blank">
          www.deehiy.com
        </a>{' '}
        or in our mobile application; create an account with us; communicate with us about our
        Services via comments, direct message, e-mail, SMS message, or phone; or respond to one of
        our surveys.
        <br />
        <br />
        Categories of information you may provide to us include:
        <br />
        <br />
        <ul className="text-sm space-y-2 list-disc font-extralight">
          <li>Your name, address, phone number, and e-mail address;</li>
          <li>Your account log-in details;</li>
          <li>Photos and videos of your home (including metadata in the file);</li>

          <li>Circumstances related to your purchase or sale of a home;</li>
          <li>
            Homes you visit or view on our website or mobile application and your opinions about
            those homes;
          </li>
          <li>
            Reviews or comments you leave on our website, mobile application, or on other public
            websites;
          </li>
          <li>Your driver’s license, National Identity Card or Passport information;</li>
          <li>Real estate offer letters;</li>
          <li>Information related to your financial ability to buy a home;</li>
        </ul>
        <br />
        When you request an offer for your home or do a virtual interior assessment with us,{' '}
        <b>
          please do not provide photos or record videos with people or any sensitive information in
          them.{' '}
        </b>
        <br />
        <br />
        <div className="font-normal text-md underline mb-2">
          b. Information We Collect from Your Device(s)
        </div>
        We may collect the following information from the device that you use to access our
        Services:
        <br />
        <br />
        <ul className="text-sm space-y-2 list-disc font-extralight">
          <li>
            Device attributes such as the operating system, hardware and software versions, battery
            level, signal strength, bandwidth, available storage space, browser type, app and file
            names and types, and plugins;
          </li>
          <li>
            Information about operations and behaviors performed on the device, such as mouse
            movements (which can help distinguish humans from bots) or whether a window is in the
            foreground or background;
          </li>
          <li>
            Identifiers unique to you, your device, or your account(s), such as device IDs,
            advertising IDs, and other similar static ID numbers such as those from apps or accounts
            you use;
          </li>
          <li>
            Information you allow us to receive through device settings you turn on, such as access
            to your GPS location;
          </li>
          <li>
            Network and connection information such as the name of your mobile operator or ISP,
            language, time zone, mobile phone number, and IP address; and
          </li>
          <li>
            Data from cookies, tags, pixels, web beacons, and log files stored on your device,
            including cookie IDs, page views, how often you visit our website or mobile application,
            and links you click on.
          </li>
        </ul>
        <br />
        We generally use this information for your convenience and to improve our Services. For
        instance, we may use it to: (i) remember your information so that you do not have to
        re-enter it; (ii) provide you with customized content and information, including
        advertising; (iii) monitor the effectiveness of our Services; (iv) monitor aggregate metrics
        such as total number of visitors, traffic, and usage patterns; and (v) diagnose or fix
        technology problems.
        <br />
        <br />
        <div className="font-normal text-md underline mb-2">
          c. Information We Collect When You Call Us
        </div>
        We may record phone or video calls with you for quality assurance purposes. Our customer
        service team members also enter certain information you provide into our customer service
        databases to ensure continuity and consistency throughout your experience with Deehiy.
        <br />
        <br />
        <div className="font-normal text-md underline mb-2">
          d. Information We Collect from Partners, Service Providers, and Affiliated Businesses
        </div>
        We may collect information about you from our partners, service providers, and affiliated
        businesses in order to provide the Services, along with certain related products and
        services such as home financing, title insurance, escrow services, homebuilder partnership
        programs, real estate agent partnership programs, access to other potential purchasers or
        brokers, or to listing agents who may be able to help with your home sale.
        <br />
        <br />
        Information we gather about you from our partners, service providers, and affiliated
        businesses may include:
        <br />
        <br />
        <ul className="text-sm space-y-2 list-disc font-extralight">
          <li>
            Multiple Listing Service (MLS) information about your home or the home(s) you are
            interested in buying;
          </li>
          <li>Prequalification letters from financial institutions;</li>
          <li>Construction or purchase contracts;</li>
          <li>
            Information related to completion of the construction of your new home (if you are
            working with a homebuilder);
          </li>
          <li>Information about the condition of, and repairs to, your home and property;</li>
          <li>
            Information related to your home purchase, sale, moving dates, and financing in
            connection with helping us facilitate “trade-in” deals;
          </li>
          <li>Information about whether you may need homeowners insurance; and</li>
          <li>
            Information about how you engaged with a Deehiy advertisement or social media post.
          </li>
        </ul>
        <br />
        <br />
        <div className="font-normal text-md underline mb-2">
          e. Information We Collect from Public Sources
        </div>
        Much of the information necessary to help you buy or sell your home is available from public
        government sources. We sometimes collect information from these sources so that you do not
        have to spend time looking for and aggregating it for us. For example, we may collect the
        following information about you and your home from public sources:
        <br />
        <br />
        <ul className="text-sm space-y-2 list-disc font-extralight">
          <li>Tax assessments of your home;</li>
          <li>The school district and public transportation available near your home;</li>
          <li>
            Information contained on the deed to your home, such as name, address, liens,
            encumbrances, and title;
          </li>
          <li>Sale prices of homes similar to your home (called “comparables”);</li>
          <li>The number of bedrooms and bathrooms in your home; </li>
          <li>
            Whether your home has access to features like a beach, boat dock, or parking garage;
          </li>
          <li>
            Whether your home is in an earthquake or flood zone or has any other condition that
            would affect its market value;
          </li>
          <li>Permits issued for remodels and additions; </li>
          <li>The finished and unfinished square footage of your home; and </li>
          <li>The year your home was built.</li>
        </ul>
        <br />
        <br />
        <div className="font-normal text-md underline mb-2">
          f. Information We Collect Through Our Mobile Applications
        </div>
        When you access our Services through our mobile applications, we may receive certain mobile
        device data such as a unique identification number associated with your device, your device
        type and manufacturer, your mobile carrier, subscriber status, payment method, phone number,
        caller ID data, and, depending on your mobile device settings, your location information,
        including GPS coordinates.
        <br />
        <br />
        We generally collect this information to provide and improve your experience with the
        Services and to verify the identity and location of people who access Deehiy homes. This
        helps us keep you and our properties safe.
        <br />
        <br />
        When you first launch our application or when you use it to visit a Deehiy home, you may be
        asked to allow us to access your geographic location. If you initially consent to this and
        change your mind, you can revoke your consent by changing the geolocation preferences in
        your device settings.
        <br />
        <br />
        <br />
        <br />
        <div className="font-light text-xl mb-2">3. How We Use This Information</div>
        <div className="font-light text-md underline mb-2">a. To Provide Our Services</div>
        We use the information that we collect about you to deliver our Services, including to
        personalize features and content to you. We use the information you provide to give you
        options for home buying and selling that may be relevant to your individual circumstances.
        As discussed in more detail below, you have choices about how and whether we process some of
        your information.
        <br />
        <br />
        <div className="font-light text-md underline mb-2">
          b. To Learn About the Home You May Want to Sell or Buy
        </div>
        If you are interested in selling, we use information you provide to us about the home,
        including photos and videos, to help us provide the Services to you and others. If you are
        interested in buying a home, we may consider information you provide us about the home you
        are interested in buying, including property profiles that you have indicated your interest
        in, to try to match you with homes you might want to purchase.
        <br />
        <br />
        <div className="font-light text-md underline mb-2">
          c. To Keep You, Your Information, and Our Properties Safe
        </div>
        Deehiy cares about your safety. We use the monitoring and identity verification technologies
        described above to help keep you safe when you visit a Deehiy home and to prevent property
        damage and fraud.
        <br />
        <br />
        <div className="font-light text-md underline mb-2">d. To Advertise Our Services</div>
        We may use information collected about you to provide you with relevant information about
        additional Services that Deehiy or our affiliated companies offer, such as our trade-in
        program, home financing, and title insurance services. Specifically, we use information
        about how you interact with our website and mobile application, combined with information
        you provide us and information we collect from other sources, to serve you relevant
        advertising.
        <br />
        <br />
        <div className="font-light text-md underline mb-2">e. To Do Market Research</div>
        We are constantly striving to improve our customer experience. We conduct research into all
        aspects of the home buying and selling process to try to identify ways to make it better.
        Sometimes we use partner companies to conduct surveys of willing participants who offer
        suggestions as to how we might improve our Services. We also aggregate and analyze consumer
        information that we collect on our website, mobile applications, and from conversations with
        our customer service representatives to identify trends which inform how we change and grow.
        <br />
        <br />
        <div className="font-light text-md underline mb-2">
          f. To Support Information Security and Debugging
        </div>
        We may use information about you and your use of the Services to detect security incidents,
        protect against malicious, fraudulent, or illegal activity, and improve the security of our
        Services. We may also use this information to debug our Services to identify and repair
        errors that impair existing intended functionality.
        <br />
        <br />
        <div className="font-light text-md underline mb-2">g. In Connection with a Dispute</div>
        We may use personal information to prosecute or defend against claims in court and other
        dispute resolution forums
        <br />
        <br />
        <br />
        <br />
        <div className="font-light text-xl mb-2">4. Why and How We Share Your Information</div>
        <div className="font-light text-md underline mb-2">a. At Your Direction </div>
        We may share your information when you specifically direct us to do so. Examples of these
        situations include:
        <br />
        <br />
        <ul className="text-sm space-y-2 list-disc font-extralight">
          <li>
            Agent partnerships. Deehiy works with both consumers and real estate agents. We may
            share your information with our agent partners in order to pair you with an agent who
            can help you through the home buying or selling process.{' '}
          </li>
          <li>
            Builder partnerships. Deehiy partners with homebuilders to provide buyers who are
            purchasing new homes from these builders with the opportunity to request offers from
            Deehiy for their current homes. When you are working with a homebuilder, we may share
            your information with them.{' '}
          </li>
          <li>
            Financing providers. If and to the extent you authorize us to do so, we may share your
            information with home financing providers.
          </li>
          <li>
            Potential home buyers. We may work with trusted institutional home buyers to try to find
            a purchaser for the home.
          </li>
          <li>
            Escrow, closing attorney, title, homeowners’ insurance. If you need escrow, closing
            attorney, title insurance services, or homeowner’s insurance services, we may share your
            information with escrow, closing attorney, title, and homeowner’s insurance companies.{' '}
          </li>
          <li>
            Renovation companies. If you need renovation services, or to help us evaluate or repair
            homes we may acquire, we may share your information with renovation and construction
            companies.{' '}
          </li>
          <li>Other partners with whom you ask us to share your information.</li>
        </ul>
        <br />
        <br />
        <div className="font-light text-md underline mb-2">b. With Our Affiliated Companies </div>
        <br />
        <br />
        <div className="font-light text-md underline mb-2">c. With Service Providers</div>
        We may share your information with service providers, including payment processors,
        technology and infrastructure providers, digital communications services, commercial data
        and market research analysts, consumer report providers, auditors and accountants, physical
        site security providers, IT security and bug-fix partners, customer service providers, and
        other providers who perform services for and on our behalf for the sole benefit of us and
        our customers.
        <br />
        <br />
        <div className="font-light text-md underline mb-2">
          d. With Law Enforcement, Courts, or Government Agencies
        </div>
        We may share your information as necessary to respond to legal process or to regulatory
        authorities (such as pursuant to a subpoena, warrant, investigative demand from law
        enforcement, regulators, or others; national or international security letters; etc.); to
        investigate, prevent, or take action regarding suspected or actual illegal activities
        (including fraud or stalking) and security or technical issues; to respond to or participate
        in commercial disputes (e.g., lawsuits or investigations); or to protect against fraud,
        claims, or other liability or harm to you, us, or others.
        <br />
        <br />
        <br />
        <br />
        <div className="font-light text-xl mb-2">
          5. When Third Parties Benefit from Deehiy Sharing Your Information with Them
        </div>
        <div className="font-light text-md underline mb-2">a. “Selling” Your Data</div>
        Deehiy is not a data broker. However, in some circumstances we share your information with
        third parties not owned by or co-branded with Deehiy Specifically:
        <br />
        <br />
        <ul className="text-sm space-y-2 list-disc font-extralight">
          <li>
            Social media. If you use our Services, we may provide information about that use to
            social media networks who will use it to better target advertising.{' '}
          </li>
          <li>
            Third-party home buyers. We may share your information with institutional home buyers to
            try to find a purchaser for the home.
          </li>
          <li>
            Home insurance agencies. If you may need homeowners insurance, we may share your
            information with homeowners insurance agencies and those agencies may reach out to offer
            you a quote.{' '}
          </li>
          <li>
            Vendor due diligence. If you are an individual or business that wishes to provide
            services to Deehiy, we may share your information with a due diligence service to
            perform vendor due diligence. The due diligence provider may use this information to
            inform and further refine its risk analysis tools.{' '}
          </li>
        </ul>
        <br />
        <br />
        <br />
        <div className="font-light text-xl mb-2">6. How We Protect Your Information </div>
        <div className="font-light text-md underline mb-2">a. Safeguards</div>
        We use commercially reasonable physical, electronic, administrative, and procedural
        safeguards to preserve the integrity, confidentiality, and security of all personal
        information collected through the Services. However, no security measure, system, or control
        is infallible. Therefore, like all businesses, we cannot guarantee that our Services are
        invulnerable to attack or misuse.
        <br />
        <br />
        <div className="font-light text-md underline mb-2">b. Your Role</div>
        You have a major role in the security and privacy of your own information. For example, you
        should not provide us with personal information that we do not request. Additionally, you
        are responsible for controlling access to any personal computing device through which your
        information may be stored or accessed. You are also responsible for safeguarding any
        passwords or other log-in credentials that may be used to access your information, including
        in the Deehiy environment. We recommend that you log out of any account when you are done
        using it.
        <br />
        <br />
        <div className="font-light text-md underline mb-2">c. Third Parties</div>
        Please remember to review the privacy settings and security policies of third-party sites
        and services that you use, such as social networks. We are not responsible for the privacy
        or security measures of any third party.
        <br />
        <br />
        <div className="font-light text-md underline mb-2">d. Incident Response</div>
        In the event that the security of any personal information under our control is compromised,
        we will take reasonable steps to investigate and mitigate the situation, including, when
        appropriate, notifying those individuals whose information may have been compromised and
        taking other steps in accordance with applicable laws and regulations.
        <br />
        <br />
        <br />
        <br />
        <div className="font-light text-xl mb-2">7. Opting Out of Our Communications</div>
        If you are interested in no longer being contacted by Deehiy, we want to facilitate that.
        You may click on the unsubscribe links in our marketing emails, text STOP in response to
        marketing texts, and request removal from our mailer list.
        <br />
        <br />
        If you prefer to no longer use our app, you can follow the standard process to uninstall it
        from your device.
        <br />
        <br />
        <ul className="text-sm space-y-2 list-disc font-extralight">
          <li>
            The right to request that we disclose what personal information we collect, use, and
            share about you,{' '}
          </li>
        </ul>
        <br />
        <br />
        <br />
        <div className="font-light text-xl mb-2">8. Updates and Questions</div>
        This policy is updated regularly. We will promptly make any changes available at{' '}
        <a href="https://www.deehiy.com/privacy" className="text-deehiy font-bold" target="_blank">
          www.deehiy.com/privacy
        </a>{' '}
        . Changes will be effective as soon as the updated policy is posted unless otherwise stated.
        Questions about this policy can be directed to contact@deehiy.com.
        <br />
        <br />
        This Policy was last modified on February 1, 2022.
      </div>
    </div>
  );
};

export default PrivacyPolicy;
