import React, { useState, useEffect } from 'react';
import UserDropdown from '../main/navigation/UserDropdown';
import { useAuth } from '../../contexts/auth';
import { Navbar, Dropdown } from 'flowbite-react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { HiViewGrid, HiCog, HiLogout } from 'react-icons/hi';

export const Navigation = () => {
  const { logoutUser, currentUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const initialBtnMenuClass = 'block hamburger md:hidden focus:outline-none';
  const initialMenuClass = `absolute flex-col mt-4 py-8 pl-8 space-y-5 
   font-light text-md text-white bg-deehiy w-full shadow-md right-0`;
  const [isToggleOn, setIsToggleOn] = useState(false);
  const [menuBtnClass, setMenuBtnClass] = useState(initialBtnMenuClass);
  const [menuClass, setMenuClass] = useState(initialMenuClass.concat(' ', 'open'));

  const handleToggle = () => {
    setIsToggleOn(!isToggleOn);
    setMenuBtnClass(isToggleOn ? initialBtnMenuClass : initialBtnMenuClass.concat(' ', 'open'));
    setMenuClass(
      isToggleOn ? initialMenuClass.concat(' ', 'hidden') : initialMenuClass.concat(' ', 'flex')
    );
  };

  // TODO: find a fix. menu always open on mobile when visiting a page
  useEffect(() => {
    setMenuClass(initialMenuClass.concat(' ', 'hidden'));
  }, []);

  return (
    <div className="w-full bg-white border-b border-deehiy fixed top-0 z-20">
      <nav className=" bg-white mx-auto py-4 z-20 px-20 w-full">
        <div className="flex items-center text-2xl font-bold justify-between">
          <div className=" ">
            <Navbar.Brand onClick={() => navigate('/')} className="flex">
              <img src="/images/logo.jpg" className="mr-1 h-6 sm:h-9" alt="Logo" />{' '}
              <span className="text-sm pt-2">Learning Center</span>
            </Navbar.Brand>
          </div>
          {/* <div className='text-xl'>Logo</div> */}

          <div className="md:flex hidden">
            <div className="flex space-x-10">
              {currentUser ? (
                <Dropdown
                  arrowIcon={false}
                  inline={true}
                  label={<UserDropdown currentUser={currentUser} />}
                >
                  <Dropdown.Header>
                    <span className="block text-sm font-medium truncate">
                      <Link to="/profile">{currentUser?.firstName}</Link>
                    </span>
                  </Dropdown.Header>
                  <Dropdown.Item icon={HiViewGrid}>
                    {' '}
                    <Link to="/dashboard">Dashboard </Link>
                  </Dropdown.Item>
                  <Dropdown.Item icon={HiCog}>Settings</Dropdown.Item>
                  {/* <Dropdown.Item icon={HiCurrencyDollar}>Earnings</Dropdown.Item> */}
                  <Dropdown.Divider />
                  <Dropdown.Item icon={HiLogout}>
                    <button onClick={logoutUser}>
                      <Link to="/">Logout</Link>
                    </button>
                  </Dropdown.Item>
                </Dropdown>
              ) : (
                <Dropdown
                  className="w-[200px]"
                  placement="bottom"
                  arrowIcon={false}
                  inline={true}
                  label={<UserDropdown currentUser={currentUser} />}
                >
                  <Dropdown.Item>
                    <Link to="/Login" state={{ redirectTo: location }} className="font-bold">
                      Log in
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item className="w-28">
                    <Link to="/register">Sign up</Link>
                  </Dropdown.Item>

                  <Dropdown.Divider />
                  <Dropdown.Item>
                    <Link to="/help">help</Link>
                  </Dropdown.Item>
                </Dropdown>
              )}
            </div>
          </div>

          <button className={menuBtnClass} onClick={handleToggle}>
            <span className="hamburger-top"></span>
            <span className="hamburger-middle"></span>
            <span className="hamburger-bottom"></span>
          </button>
        </div>
        <div className="md:hidden">
          <div className={menuClass}>
            {currentUser ? (
              <>
                <button onClick={handleToggle}>
                  <Link to="/profile">Profile</Link>
                </button>
                <button onClick={logoutUser}>
                  <Link to="/">Logout</Link>
                </button>
              </>
            ) : (
              <>
                <button onClick={handleToggle}>
                  <Link to="/login" state={{ redirectTo: location }}>
                    Login
                  </Link>
                </button>

                <button onClick={handleToggle}>
                  <Link to="/register">Sign Up</Link>
                </button>
              </>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
};
