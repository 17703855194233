import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ForgotPasswordLink = () => {
  const { t } = useTranslation();

  return (
    <div className="flex justify-end">
      <Link
        className="text-gray-400 hover:text-gray-500 duration-200 text-[8px] sm:text-sm"
        to="/reset-password"
      >
        {t('Forgot password?')}
      </Link>
    </div>
  );
};

export default ForgotPasswordLink;
