// utils/localStorageUtils.js

/**
 * Save the popup state to local storage.
 * @param {string} key - The key to identify the popup.
 * @param {boolean} state - The state of the popup (open or closed).
 */
export const savePopupState = (key: string, state: boolean) => {
  localStorage.setItem(key, JSON.stringify(state));
};

/**
 * Get the popup state from local storage.
 * @param {string} key - The key to identify the popup.
 * @returns {boolean} - The state of the popup (open or closed).
 */
export const getPopupState = (key: string) => {
  const state = localStorage.getItem(key);
  return state ? JSON.parse(state) : null;
};
