import React from 'react';
import { COMMUNITY_CATEGORIES } from '../../../constants/constant';
import { useAuth } from '../../../contexts/auth';
import { useTranslation } from 'react-i18next';

const FilterRadio = ({ communityCategory, setCommunityCategory }: any) => {
  const { currentUser } = useAuth();
  const { t } = useTranslation();

  return (
    <div>
      {Object.entries(COMMUNITY_CATEGORIES).map(([key, category], index) => (
        <div className="flex w-full justify-between mb-2 px-4" key={key}>
          {!currentUser && category !== COMMUNITY_CATEGORIES.ALL ? (
            <>
              <label className="opacity-40 italic" title="not logged" htmlFor={`option-${index}`}>
                {t(category.label)}
              </label>
              <input
                type="radio"
                disabled={true}
                className="opacity-60 cursor-not-allowed h-4 w-4 border-gray-300"
              />
            </>
          ) : (
            <>
              <label htmlFor={`option-${index}`}> {t(category.label)}</label>
              <input
                type="radio"
                id={`option-${index}`}
                name="filterOption"
                value={category.value}
                checked={communityCategory.value === category.value}
                onChange={() => setCommunityCategory(category)}
                className={`focus:outline-none focus:ring-0 h-4 w-4 border-gray-300 ${
                  communityCategory.value === category.value ? 'text-deehiy' : 'text-white'
                }`}
              />
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default FilterRadio;
