import React from 'react';
import { useNavigate } from 'react-router-dom';

const VerifyProfile = () => {
  const navigate = useNavigate();

  return (
    <div className="flex justify-center items-center h-[80vh] px-6 sm:px-44">
      <div className="container flex flex-col mx-auto justify-center items-center">
        {/* title */}
        <div className="text-5xl font-bold mx-auto py-6">Welcome Real Estate Rockstar!</div>
        <div className="text-xl mx-auto pb-16">
          We’re finalizing this feature and let you know once its done.
        </div>
        <div className="text-xl mx-auto font-bold py-6">
          Verify your profile and improve your exposure rate significantly.
        </div>
        <div className="text-sm mx-auto justify-center flex text-center max-w-[900px]">
          You are not required to confirm your identity to use our platform. However, at Deehiy, we
          want to build a community of trust. By providing your identification info, you help build
          that trust. That’s why, when you confirm your identity, you may also enjoy a better
          experience on our platform.
        </div>
        <button
          onClick={() => navigate('/users/profile')}
          className="bg-deehiy shadow-md hover:opacity-90 rounded-xl my-16 py-3 px-5 font-bold text-white "
        >
          back
        </button>
      </div>
    </div>
  );
};

export default VerifyProfile;
