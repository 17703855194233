import React from 'react';
// import DropzoneUploader from "../components/Dropzone/DropzoneUploader"

import Steps from '../components/steps/Steps';

// import SelectCountry from '../components/steps/propertySteps/SelectCountry';
// import SelectListingCountry from '../components/steps/propertySteps/SelectListingCountry';
import SelectPropertyType from '../components/steps/propertySteps/SelectPropertyType';
import LocationProperty from '../components/steps/propertySteps/LocationProperty';
import SelectSpecifics from '../components/steps/propertySteps/SelectSpecifics';
import PropertyDescription from '../components/steps/propertySteps/PropertyDescription';
import PropertyDetails from '../components/steps/propertySteps/PropertyDetails';
import UploadPropertyImages from '../components/steps/propertySteps/UploadPropertyImages';
import Features from '../components/steps/propertySteps/Features';
// import UploadFloorplans from '../components/steps/propertySteps/UploadFloorplans';
import ManualLocation from '../components/steps/propertySteps/Location/ManualLocation';
import SelectSellRentType from '../components/steps/propertySteps/SelectSellRentType';
import Availability from '../components/steps/propertySteps/Availability';

const propertySteps = [
  {
    id: 1,
    title: "let's get you started!",
    description: 'what do you wanna do?',
    category: 'Country',
    completed: false,
    highlighted: true,
    selected: true,
    Component: SelectSellRentType,
    isSubmit: false,
    canSkip: false,
  },
  {
    id: 2,
    title: 'property type',
    description: 'tell us what type of property you would like to list',
    category: 'property type',
    completed: false,
    highlighted: false,
    selected: false,
    Component: SelectPropertyType,
    isSubmit: false,
    canSkip: false,
  },
  {
    id: 3,
    title: 'location',
    description: 'find your property and drop the pin correctly',
    category: 'location',
    completed: false,
    highlighted: false,
    selected: false,
    Component: LocationProperty,
    isSubmit: false,
    canSkip: false,
  },
  {
    id: 4,
    title: 'address',
    description: 'type up the address for better reference',
    category: 'location',
    completed: false,
    highlighted: false,
    selected: false,
    Component: ManualLocation,
    isSubmit: false,
    canSkip: false,
  },
  {
    id: 5,
    title: 'Tell us about features',
    description: 'buyers love using quick access buttons - don’t skip!',
    category: 'features',
    completed: false,
    highlighted: false,
    selected: false,
    Component: Features,
    isSubmit: false,
    canSkip: true,
  },
  {
    id: 6,
    title: 'availability',
    description: 'Make hay while the sun shines',
    category: 'availability',
    completed: false,
    highlighted: false,
    selected: false,
    Component: Availability,
    isSubmit: false,
    canSkip: false,
  },
  {
    id: 7,
    title: 'give us the specifics',
    description: 'these are the major search criteria buyers look for',
    category: 'specifics',
    completed: false,
    highlighted: false,
    selected: false,
    Component: SelectSpecifics,
    isSubmit: false,
    canSkip: false,
  },
  {
    id: 8,
    title: 'property description',
    description:
      'we have taken the liberty and wrote a property description for you. You can edit it or write your own',
    category: 'title and description',
    completed: false,
    highlighted: false,
    selected: false,
    Component: PropertyDescription,
    isSubmit: false,
    canSkip: false,
  },
  {
    id: 9,
    title: "let's look into the details together",
    description: "- There is no Magic in Magic. It's all in the details. - Walt Disney",
    category: 'details',
    completed: false,
    highlighted: false,
    selected: false,
    Component: PropertyDetails,
    isSubmit: false,
    canSkip: true,
  },
  {
    id: 10,
    title: "show us what you've got",
    description:
      'one image is worth a thousand words. carefully choose the best quality images for a quick and smooth sale.',
    category: 'images upload',
    completed: false,
    highlighted: false,
    selected: false,
    Component: UploadPropertyImages,
    isSubmit: true,
    canSkip: false,
  },
  // {
  //   id: 11,
  //   title: "show us what you've got",
  //   description:
  //     'one image is worth a thousand words. carefully choose the best quality images for a quick and smooth sale.',
  //   category: 'images upload',
  //   completed: false,
  //   highlighted: false,
  //   selected: false,
  //   Component: UploadFloorplans,
  // },
];

const AddProperty = () => {
  return (
    <div>
      {/* <DropzoneUploader />
      <div className='p-8 border-2 rounder border-gray-300'>
    </div> */}
      <Steps steps={propertySteps} />

      {/* <StepsLayout title={propertySteps[6].title} description={propertySteps[6].description} /> */}
    </div>
  );
};

export default AddProperty;
