import React, { useState } from 'react';
import Box from '../animation/Box';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { forgotPasswordSchema } from '../../lib/yup';
import { useAuth } from '../../contexts/auth';
import Input from '../form/Input';
import Loader from '../main/Loader';
import { useTranslation } from 'react-i18next';

type Props = {};

const ForgotPassword: React.FC<Props> = () => {
  const { resetPasswordUser } = useAuth();
  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const methods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(forgotPasswordSchema),
    // defaultValues: stepOne || {},
  });

  const handleSubmit = async () => {
    setIsLoading(true);

    await resetPasswordUser(email).then((res: any) => {
      setIsLoading(false);
      if (res === 'success') {
        setMessage(t('email_reset_password_sent'));
      } else {
        setError(res);
      }
    });
  };

  const handleChange = (e: any) => {
    setError('');
    setEmail(e.target.value);
  };

  return (
    <Box>
      <div className="auth-form">
        {isLoading && <Loader />}

        <p className="mx-auto font-light text-gray-500 text-2xl">{t('Reset password')}</p>
        <hr className="border-deehiy" />
        {message && (
          <div className=" flex justify-center text-green-400 text-xl mx-auto">{message}</div>
        )}

        {error && <span className=" flex justify-center text-red-500 mx-auto">{error}</span>}
        <FormProvider {...methods}>
          <form className="flex flex-col space-y-4">
            {!message && (
              <>
                <Input
                  name="email"
                  id="email"
                  label="email"
                  onChange={handleChange}
                  value={email}
                  placeholder="email"
                />
                <button type="button" onClick={handleSubmit} className="primary-btn ">
                  {' '}
                  {t('Submit')}
                </button>
              </>
            )}
          </form>
        </FormProvider>
      </div>
    </Box>
  );
};

export default ForgotPassword;
