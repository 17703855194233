import React from 'react';

const NoResults = () => {
  return (
    <div className="text-center pt-12">
      <p>We didn't find any results</p>
      Make sure that everything is spelt correctly or try different keywords.
    </div>
  );
};

export default NoResults;
