import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';

const Unpublished = () => {
  const backgroundStyle = {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  return (
    <div className=" h-screen bg-unpublished" style={backgroundStyle}>
      <div className="flex items-baseline px-6 sm:px-24 py-6 space-x-3">
        <div className="font-title text-2xl sm:text-4xl text-white">deehiy</div>
        <div className="font-semibold text-sm sm:text-md text-gray-200">Maiday Maiday</div>
      </div>
      <div className="flex flex-col items-center place-content-center mt-[-50px] h-full ">
        <div className="  flex flex-col  items-center">
          {/* text-transparent is not working with text-shadow */}
          <div className="font-bold text-3xl sm:text-[90px] font-outline-2 text-[#0f0f0f] ">
            Oh! It's gone.
          </div>
          <div className="font-bold text-2xl sm:text-5xl text-white mt-12 text-center px-8">
            This listing has been sent into outer space
          </div>
          {/* <div className="font-bold text-base sm:text-xl pt-6 text-white">
            This happens when listing owners delete a listing
          </div> */}

          <Link
            to="/"
            className="mt-28 font-bold flex bg-black bg-opacity-40 hover:bg-opacity-50 gap-2 items-center justify-center border border-gray-200 rounded-2xl text-white py-3 px-5"
          >
            <ChevronLeftIcon className="w-6 h-6 animate-pulse" />
            Back to Earth
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Unpublished;
