import { motion, AnimatePresence } from 'framer-motion';
import { Outlet } from 'react-router-dom';

const SlideTransition = ({ children, direction = 'rtl' }: any) => {
  const isMobile = window.innerWidth <= 768;

  return (
    <AnimatePresence exitBeforeEnter>
      {isMobile ? (
        <motion.div
          key={window.location.pathname}
          initial={{ x: direction === 'rtl' ? '100vw' : '-100vw' }}
          animate={{ x: 0 }}
          // exit={{ x: 0 }}
          exit={{ x: direction === 'rtl' ? '100vw' : '-100vw' }}
          transition={{ duration: 0.3 }}
        >
          <Outlet />
          {children}
        </motion.div>
      ) : (
        <div>
          <Outlet />
          {children}
        </div>
      )}
    </AnimatePresence>
  );
};

export default SlideTransition;
