import React from 'react';
import ListingsTable from './ListingsTable';
import TopMobileNavigation from '../main/navigation/TopMobileNavigation';
import TakeMeBack from '../main/navigation/TakeMeBack';

const ListingsManagementPage = () => {
  return (
    <div className="pb-20">
      <div className="pt-6">
        <TakeMeBack />
      </div>
      <TopMobileNavigation />
      <ListingsTable />
    </div>
  );
};

export default ListingsManagementPage;
