import React, { useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { propertyDescriptionFormSchema } from '../../../lib/yup';
import { cleanText } from '../../../utils/textValidater';
// import { IoSettingsOutline } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import { generateDescription } from '../../../services/propertyService';
import Textarea from '../../form/Textarea';

const PropertyDescription: React.FC<any> = ({
  editedProperty,
  setEditedProperty,
  setCanClickNext,
}) => {
  const methods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(propertyDescriptionFormSchema),
    // defaultValues: stepOne || {},
  });

  const { t } = useTranslation();
  const [titleLength, setTitleLength] = useState(0);
  const [descriptionLength, setDescriptionLength] = useState(0);
  const [isGenerating, setIsGenerating] = useState(false);

  const handleChange = (e: any) => {
    const value = cleanText(e.target.value);

    const name = e.target.name;
    // const value = e.target.value;
    // if (name === 'propertyTitle') {
    //   setTitleLength(value.length);
    // } else {
    //   setDescriptionLength(value.length);
    // }

    setEditedProperty({ ...editedProperty, [name]: value });
  };

  // function typeWriter(description: string, callbackAfterTyping: (text: string) => void) {
  //   let displayedText = '';
  //   let index = 0;
  //   const intervalId = setInterval(() => {
  //     if (index < description.length) {
  //       // Determine the end index for this chunk (limited to 10 characters)
  //       const endIndex = Math.min(index + 10, description.length);
  //       // Extract the chunk of text
  //       const chunk = description.slice(index, endIndex);
  //       // Update displayed text
  //       displayedText += chunk;
  //       // Update the callback with the entire displayed text
  //       callbackAfterTyping(displayedText);
  //       // Update the index to the end of the chunk
  //       index = endIndex;
  //     } else {
  //       clearInterval(intervalId);
  //     }
  //   }, 200);
  // }

  useEffect(() => {
    setCanClickNext(
      !editedProperty.propertyDescription || !editedProperty.propertyTitle ? false : true
    );
  }, [editedProperty.propertyDescription, editedProperty.propertyTitle]);

  useEffect(() => {
    // let fullDescription = editedProperty.propertyDescription;
    // let fullTitle = editedProperty.propertyTitle;
    let intervalId: any; // Define intervalId outside typeWriter function

    function typeWriter(description: string, callbackAfterTyping: (text: string) => void) {
      let displayedText = '';
      let index = 0;
      intervalId = setInterval(() => {
        if (index < description.length) {
          // Determine the end index for this chunk (limited to 10 characters)
          const endIndex = Math.min(index + 10, description.length);
          // Extract the chunk of text
          const chunk = description.slice(index, endIndex);
          // Update displayed text
          displayedText += chunk;
          // Update the callback with the entire displayed text
          callbackAfterTyping(displayedText);
          // Update the index to the end of the chunk
          index = endIndex;
        } else {
          clearInterval(intervalId);
        }
      }, 50);
    }

    const fetchText = async () => {
      try {
        if (editedProperty.propertyDescription.length > 0) return;

        setIsGenerating(true);
        const res = await generateDescription(editedProperty);

        const { description, title } = res.data;

        // fullDescription = description;
        // fullTitle = title;

        // Usage in your component
        typeWriter(description, (str: any) => {
          setEditedProperty({
            ...editedProperty,
            propertyDescription: str,
            propertyTitle: title,
          });
        });

        setDescriptionLength(description.length);
        setTitleLength(title.length);
      } catch (error) {
        // Handle the error
      } finally {
        setIsGenerating(false);
      }
    };

    fetchText();

    return () => {
      // if (fullDescription.length > editedProperty.propertyDescription.length) {
      //   setEditedProperty({
      //     ...editedProperty,
      //     propertyTitle: fullTitle,
      //     propertyDescription: fullDescription,
      //   });
      // }
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    setTitleLength(editedProperty.propertyTitle.length);
    console.log(editedProperty.propertyTitle);
  }, [editedProperty.propertyTitle]);

  useEffect(() => {
    setDescriptionLength(editedProperty.propertyDescription.length);
  }, [editedProperty.propertyDescription]);

  return (
    <div className="step-form-wrapper flex-col w-full relative">
      <FormProvider {...methods}>
        <form className="flex flex-col flex-grow">
          <Textarea
            label={t('Listing title')}
            id="propertyTitle"
            name="propertyTitle"
            // placeholder={`${
            //   isGenerating
            //     ? 'Magic in the making...'
            //     : 'short titles work best - use few words to stand out'
            // }`}
            placeholder={`short titles work best - use few words to stand out`}
            rows={2}
            cols={9}
            maxLength={50}
            textLength={titleLength}
            onChange={handleChange}
            value={editedProperty.propertyTitle}
          />
          <Textarea
            label={t('Listing description')}
            id="propertyDescription"
            name="propertyDescription"
            // placeholder={`${
            //   isGenerating
            //     ? 'Magic in the making...'
            //     : 'The more details and accuracy, the more likely you will get people excited'
            // }`}
            placeholder={t('listing_description_placeholder')}
            rows={10}
            cols={13}
            maxLength={2000}
            textLength={descriptionLength}
            onChange={handleChange}
            value={editedProperty.propertyDescription}
          />
        </form>
      </FormProvider>

      {isGenerating && (
        // <div className="absolute h-screen flex justify-center items-center w-full text-deehiy gap-x-2 bg-white z-20 bg-opacity-70">

        <div className=" flex-col fixed right-0 left-0 bottom-0 top-[-12] h-screen flex justify-center items-center w-full text-deehiy gap-x-2 bg-white z-20 bg-opacity-90">
          <img alt="magic" src="/images/magic.svg" className="w-64 h-64" />
          <span className="text-deehiy  text-3xl sm:text-5xl">{t('Abracadabra')}</span>{' '}
          <span className="text-sm text-defaultText sm:text-3xl pt-2 text-center">
            {t('Magically creating your title and description...')}
          </span>{' '}
        </div>
      )}
    </div>
  );
};

export default PropertyDescription;
