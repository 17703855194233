import React, { useState, useEffect } from 'react';
import { PiArrowClockwiseThin } from 'react-icons/pi';
import Box from '../animation/Box';
import Loader from '../main/Loader';
import { sendVerificationEmail } from '../../services/auth/userService';
import { FiChevronLeft } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';

const SuccessBox = ({ user, setIsSuccess }: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const { t } = useTranslation();

  const handleSendEmail = () => {
    setIsLoading(true);
    sendVerificationEmail(user)
      .then(() => {
        setMessage('Email sent');
      })
      .catch(() => {
        setMessage('The email could not be sent');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setMessage('');
  }, []);

  return (
    <Box>
      <div className="auth-form">
        <p className="mx-auto font-light text-2xl">{t('email_verification')}</p>
        <hr className="border-deehiy" />
        <button
          className="text-sm text-deehiy flex justify-start cursor-pointer"
          onClick={() => setIsSuccess(false)}
          type="button"
        >
          <FiChevronLeft className="w-5 h-5 group-hover:animate-ping" />
          <span>{t('change email')}</span>
        </button>
        <div className="flex justify-center pt-3">{t('An email was sent to')}:</div>
        <div className="flex justify-center text-deehiy font-bold">{user.email}</div>
        <div className="fle text-center">{t('check_email')}</div>
        {isLoading ? (
          <div className="mx-auto w-12">
            <Loader />
          </div>
        ) : (
          <button
            type="button"
            onClick={handleSendEmail}
            className="text-deehiy group mx-auto font-bold flex space-x-2 p-3"
          >
            <PiArrowClockwiseThin className="w-6 h-6 rotate-0 group-hover:rotate-180 duration-500 " />
            <span>{t('resend email')}</span>
          </button>
        )}

        <span className="mx-auto text-xs flex text-center">{message}</span>
      </div>
    </Box>
  );
};

export default SuccessBox;
