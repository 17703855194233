import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useProperty } from '../../contexts/property';
import { initialProperty } from '../../initialStates/initialStates';
import { useTranslation } from 'react-i18next';

const CreatedSuccessfully = () => {
  const { setCurrentProperty } = useProperty();
  const { t } = useTranslation();
  const { redirectToBusiness } = useParams();

  useEffect(() => {
    setCurrentProperty(initialProperty);
  }, []);

  return (
    <div className="container mx-auto flex flex-col justify-center space-y-10 pt-24 items-center">
      <div className="flex font-bold text-center text-2xl sm:text-5xl">{t('Congratulations!')}</div>
      <div className="flex text-center text-md">{t('listing_submitted')}</div>
      <div className="flex text-center text-md">{t('listing_submitted_text')}</div>

      <Link
        to={redirectToBusiness ? '/business/managelistings' : '/users/managelistings'}
        className="primary-btn mt-24"
      >
        {t('see my listings')}
      </Link>
    </div>
  );
};

export default CreatedSuccessfully;
