import React, { useState } from 'react';

import VerticalTab from '../../dashboard/tabsContents/VerticalTab';
import TopMobileNavigation from '../../main/navigation/TopMobileNavigation';
import PropertyHuntsContent from './tabs/PropertyHuntsContent';
import ListingLeadsContent from './tabs/ListingLeadsContent';
// import Appointments from './tabs/AppointmentsContent';
import { useParams } from 'react-router-dom';
import TakeMeBack from '../../main/navigation/TakeMeBack';
import { useTranslation } from 'react-i18next';

const HuntingDashboard = () => {
  const { tab } = useParams();
  const tabIndex = Number(tab);
  const { t } = useTranslation();

  const initialTabs = [
    {
      id: 'tab1',
      title: 'Property hunts',
      content: <PropertyHuntsContent />,
      bigHeader: "It's hunting season",
      smallHeader: 'Here is everything you have been after.',
    },
    {
      id: 'tab2',
      title: 'Listing leads',
      content: <ListingLeadsContent />,
      bigHeader: 'Time to make some money',
      smallHeader: "Show them what you've got",
    },
    // {
    //   id: 'tab3',
    //   title: 'Appointments',
    //   content: <Appointments />,
    //   bigHeader: 'Appointment',
    //   smallHeader: 'coming soon!',
    // },
  ];
  const [selectedTab, setSelectedTab] = useState(initialTabs[0]);

  return (
    <div className="container">
      <div className="pt-6 w-full justify-start sm:px-20">
        <TakeMeBack loc="/users/dashboard" />
      </div>
      <TopMobileNavigation />
      <div className="pt-12 pb-4 sm:py-6 font-bold px-4 sm:px-20 ">
        <div className="text-2xl sm:text-5xl font-bold w-full h-16 items-center flex">
          {selectedTab && t(selectedTab.bigHeader)}
        </div>
        <div className="text-sm sm:text-xl pt-2 sm:pt-4">
          {selectedTab && t(selectedTab.smallHeader)}
        </div>
      </div>
      <VerticalTab tabs={initialTabs} selectedTabIndex={tabIndex} setSelectedTab={setSelectedTab} />
    </div>
  );
};

export default HuntingDashboard;
