import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ForgotPasswordLink from './ForgotPasswordLink';
import Box from '../animation/Box';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { loginSchema } from '../../lib/yup';
import PasswordInput from '../form/PasswordInput';
import Input from '../form/Input';
import RegisterRedirection from './RegisterRedirection';
import Loader from '../main/Loader';
import { sendVerificationEmail } from '../../services/auth/userService';
import { PiArrowClockwiseThin } from 'react-icons/pi';
// @ts-ignore
import google from '../../assets/images/google-logo.webp';
// @ts-ignore
import facebook from '../../assets/images/facebook.webp';
import SEO from '../SEO/SEO';
import { useAuth } from '../../contexts/auth';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
// @ts-ignore
import { LoginSocialFacebook } from 'reactjs-social-login';
import { useTranslation } from 'react-i18next';

export type RedirectLocationState = {
  redirectTo: Location;
};

type Props = {};

const Login: React.FC<Props> = () => {
  const initialUser: {
    email: string;
    password: string;
  } = {
    email: '',
    password: '',
  };
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [user, setUser] = useState(initialUser);
  const [showSendverificationButton, setShowSendverificationButton] = useState(false);
  const [message, setMessage] = useState('');
  const { loginUser, currentUser, loginWithSocialApi } = useAuth();
  const { t } = useTranslation();

  let navigate = useNavigate();
  const { state: locationState } = useLocation();

  const methods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(loginSchema),
  });
  const resendVerification = (verifyingUser: any) => {
    setIsLoading(true);
    sendVerificationEmail(verifyingUser)
      .then((res) => {
        console.log(res);
        setMessage('A verification email has been sent to your inbox');
      })
      .catch(() => {
        setMessage('something went wrong');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    setIsLoading(false);
    setShowSendverificationButton(false);
    setMessage('');

    try {
      const response = await loginUser(user.email, user.password);

      if (response === 'success') {
        if (locationState) {
          const { redirectTo } = locationState as RedirectLocationState;
          navigate(`${redirectTo.pathname}${redirectTo.search}`);
        } else navigate('/');
      } else if (response.toLowerCase() === 'email not verified') {
        setShowSendverificationButton(true);
      } else {
        setError(response);
      }
    } catch (err) {
      // Handle API call errors
      setIsLoading(false);
      setError('An error occurred during login. Please try again.');
    }
  };

  const handleChange = (e: any) => {
    setError('');
    const value = e.target.value;
    const name = e.target.name;
    setUser({ ...user, [name]: value });
  };

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      // Fetch user info using the access token
      const userInfoResponse = await axios.get('https://www.googleapis.com/oauth2/v1/userinfo', {
        headers: {
          Authorization: `Bearer ${tokenResponse.access_token}`,
        },
      });
      const userInfo = userInfoResponse.data;
      // Log user info
      const { given_name: givenName, family_name: familyName, email, picture } = userInfo;
      const accountType = 'google';
      try {
        const response = await loginWithSocialApi(
          givenName,
          familyName,
          email,
          picture,
          accountType
        );
        if (response === 'success') {
          if (locationState) {
            const { redirectTo } = locationState as RedirectLocationState;
            navigate(`${redirectTo.pathname}${redirectTo.search}`);
          } else navigate('/');
        } else {
          setError(response);
        }
      } catch (err) {
        // Handle API call errors
        setIsLoading(false);
        setError('An error occurred during login. Please try again.');
      }
    },
    onError: (err) => console.log('Login Failed:', err),
  });

  const handleFacebookLogin = async (response: any) => {
    try {
      const { first_name: firstName, last_name: lastName, email, picture } = response?.data;
      const accountType = 'facebook';
      const loginResponse = await loginWithSocialApi(
        firstName,
        lastName,
        email,
        picture?.data?.url,
        accountType
      );
      if (loginResponse === 'success') {
        if (locationState) {
          const { redirectTo } = locationState as RedirectLocationState;
          navigate(`${redirectTo.pathname}${redirectTo.search}`);
        } else navigate('/');
      } else {
        setError(loginResponse);
      }
    } catch (err) {
      setIsLoading(false);
      setError('An error occurred during login. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const responseFacebookFailure = (errorFacebook: any) => {
    console.log('Facebook Login Failed:', errorFacebook);
    setIsLoading(false);
    setError('An error occurred during login. Please try again.');
  };

  // in case we already have a currentUser logged in
  useEffect(() => {
    if (currentUser) {
      navigate('/users/dashboard');
      // navigate(-1);
    }
  }, [currentUser]);

  return (
    <Box>
      <SEO
        title="deehiy.com"
        name="Deehiy"
        type="login page"
        description="welcome to Deehiy"
        image="/public/images/meta-image.jpg"
      />
      <div className="auth-form">
        {isLoading && <Loader />}
        <p className="mx-auto text-deehiy text-[18px] md:text-2xl font-semibold">{t('Log in')}</p>
        {/* {message && (
          <div className=" flex justify-center text-green-400 text-xl mx-auto">{message}</div>
        )} */}

        {error && <span className=" flex justify-center text-red-500 mx-auto">{error}</span>}
        <FormProvider {...methods}>
          <form className="flex flex-col space-y-4" onSubmit={(e) => handleSubmit(e)}>
            <Input
              name="email"
              id="email"
              label=""
              value={user.email}
              placeholder={t('Email')}
              onChange={handleChange}
              className="text-[12px] md:text-[16px]"
            />
            <PasswordInput
              id="passwordId"
              label=""
              name="password"
              value={user.password}
              placeholder={t('Password')}
              onChange={handleChange}
              // isEyeIconVisible={false}
            />
            <ForgotPasswordLink />
            <button type="submit" className="primary-btn">
              {' '}
              {t('Log in')}
            </button>
          </form>

          {showSendverificationButton && (
            <div className="border border-deehiy px-3 bg-[#f7f7f7] shadow-md text-xs py-4 text-center rounded-2xl">
              {t('not_verified')}
              <br /> {t('click_to_verify')}
              <button
                type="button"
                onClick={() => resendVerification(user)}
                className="text-deehiy items-center group mx-auto font-bold flex space-x-2 p-3"
              >
                <PiArrowClockwiseThin className="w-6 h-6 rotate-0 group-hover:rotate-180 duration-500 " />
                <span>{t('send verification email')}</span>
              </button>
              {message.length > 0 && (
                <span className="mx-auto text-xs text-defaultText font-bold bg-white p-2 justify-center flex text-center">
                  {message}
                </span>
              )}
            </div>
          )}

          <div className="relative">
            <hr className="separator" />
            <p className="overwrite-seperator">{t('or')}</p>
          </div>

          <button
            className="flex items-center social-google-login-btn social-border"
            onClick={() => handleGoogleLogin()}
          >
            <img className="w-5 h-5 sm:w-7 sm:h-7" src={google} alt="google" />
            {t('Sign in with Google')}
          </button>
          <LoginSocialFacebook
            appId={process.env.REACT_APP_FB_APP_ID || ''}
            onResolve={(response: any) => handleFacebookLogin(response)}
            onReject={(errorFacebook: any) => responseFacebookFailure(errorFacebook)}
          >
            <button className="flex items-center social-facebook-login-btn social-border login-facebook-btn loginLibBtn">
              <img className="w-5 h-5 sm:w-7 sm:h-7" src={facebook} alt="google" />
              {t('Sign in with Facebook')}
            </button>
          </LoginSocialFacebook>
        </FormProvider>

        <RegisterRedirection to="register" />
      </div>
    </Box>
  );
};

export default Login;
