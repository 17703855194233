import React from 'react';

const Toggle = ({ setIsToggled, isToggled }: any) => {
  return (
    <div className="flex ">
      <label className="inline-flex relative items-center ml-2 cursor-pointer">
        <input type="checkbox" className="sr-only peer" checked={isToggled} readOnly />
        <div
          onClick={() => {
            setIsToggled(!isToggled);
          }}
          className="w-11 h-6 bg-gray-300 rounded-full peer  peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-deehiy"
        ></div>
      </label>
    </div>
  );
};

export default Toggle;
