import React, { useState, useEffect, useMemo } from 'react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  // sortingFns,
} from '@tanstack/react-table';
import {
  rankItem,
  // RankingInfo,
  // compareItems,
} from '@tanstack/match-sorter-utils';

import type {
  ColumnDef,
  SortingState,
  FilterFn,
  // SortingFn,
} from '@tanstack/react-table';

import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { RxCrossCircled } from 'react-icons/rx';
import { BsChevronExpand } from 'react-icons/bs';
import { adminGetAllUsers } from '../../../services/adminService';
import { adjustImageSize } from '../../../utils/adjustImageSize';
import AdminLabel from './AdminLabel';
import { User } from '../../../types/types';
import { formatDate } from '../../../utils/dateFormatter';
import DebouncedInput from './DebouncedInput';

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

const columnHelper = createColumnHelper<User>();

const AdminManageUsers = () => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [data, setData] = useState<User[]>(() => []);
  const [globalFilter, setGlobalFilter] = useState('');

  const getIcon = (bool: boolean) => {
    return bool ? (
      <AiOutlineCheckCircle className="text-deehiy w-4 h-4" />
    ) : (
      <RxCrossCircled className="text-deehiy-red w-4 h-4" />
    );
  };

  const columns = useMemo<ColumnDef<User, any>[]>(
    () => [
      columnHelper.accessor('image', {
        header: '',
        cell: (info) => (
          <img
            src={adjustImageSize(info.renderValue(), 30)}
            alt=""
            className="my-3 pr-0 max-w-[30px] aspect-square rounded-full"
          />
        ),
      }),
      columnHelper.accessor('firstName', {
        id: 'firstName',
        cell: (info) => (
          <div
            title="open profile page"
            className="font-bold text-left cursor-pointer hover:underline"
            onClick={() => window.open(`/member/${info.row.original.id}`, '_blank')}
          >
            {info.getValue()}
          </div>
        ),
        // filterFn: 'fuzzy',
        header: () => <span className="pl-4">First name</span>,
      }),
      columnHelper.accessor('lastName', {
        id: 'lastName',
        cell: (info) => (
          <div title={`${info.getValue()}`} className="line-clamp-1">
            {info.getValue()}
          </div>
        ),
        header: () => <span className="pl-4">Last name</span>,
      }),

      columnHelper.accessor('email', {
        header: () => <span className="pl-4">email</span>,
        cell: (info) => <span>{info.getValue()}</span>,
      }),
      columnHelper.accessor('isDedicatedProfile', {
        header: () => <span className="pl-4">Agent</span>,
        cell: (info) => <span className="flex justify-center">{getIcon(info.renderValue())}</span>,
      }),
      columnHelper.accessor('isLandlord', {
        header: () => <span className="pl-4">Landlord</span>,
        cell: (info) => <span className="flex justify-center">{getIcon(info.renderValue())}</span>,
      }),
      columnHelper.accessor('isInvestor', {
        header: () => <span className="pl-4">Buyer</span>,
        cell: (info) => <span className="flex justify-center">{getIcon(info.renderValue())}</span>,
      }),
      columnHelper.accessor('isSeller', {
        header: () => <span className="pl-4">Seller</span>,
        cell: (info) => <span className="flex justify-center">{getIcon(info.renderValue())}</span>,
      }),
      columnHelper.accessor('isRenter', {
        header: () => <span className="pl-4">Renter</span>,
        cell: (info) => <span className="flex justify-center">{getIcon(info.renderValue())}</span>,
      }),
      columnHelper.accessor('preferredAreas', {
        header: () => <span className="pl-4">Areas</span>,
        cell: (info) => {
          const areas = info.renderValue();
          const areaString = areas ? JSON.parse(areas).join(', ') : '';
          return (
            <span title={areaString} className="text-xs line-clamp-1">
              {areaString}
            </span>
          );
        },
      }),
      columnHelper.accessor('language', {
        header: 'language',
        cell: (info) => <span>{info.renderValue()}</span>,
      }),
      // columnHelper.accessor('isVerified', {
      //   header: 'Verified',
      //   cell: (info) => <span>{getIcon(info.renderValue())}</span>,
      // }),
      columnHelper.accessor('createdAt', {
        id: 'createdAt',
        header: 'Registration date',
        cell: (info) => <span>{formatDate(info.renderValue())}</span>,
      }),
      columnHelper.accessor('updatedAt', {
        header: 'Last edit',
        cell: (info) => <span>{formatDate(info.renderValue())}</span>,
      }),
    ],
    []
  );

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      sorting,
      globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });

  useEffect(() => {
    adminGetAllUsers()
      .then((res) => {
        console.log(res.data);
        setData(() => [...res.data]);
      })
      .catch((error: any) => {
        console.error('Error fetching the users:', error);
        alert('An error occurred while fetching the users');
      });
  }, []);

  return (
    <div>
      <AdminLabel />
      <div className="py-8 font-bold">
        Number of users:<span className="text-deehiy px-1">{data.length}</span>
      </div>
      <div>
        <DebouncedInput
          debounce={300}
          value={globalFilter ?? ''}
          onChange={(value) => setGlobalFilter(String(value))}
          className="p-2 font-lg rounded-md focus:rounded-lg shadow-sm focus:shadow-lg duration-500 border outline-none focus:border-deehiy"
          placeholder="Search all columns..."
        />
      </div>
      <div className="h-2" />
      <div className="p-2">
        <table className="w-full ">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer select-none flex items-center text-xs'
                            : 'hidden',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: <BiChevronUp className="w-5 h-5" />,
                          desc: <BiChevronDown className="w-5 h-5" />,
                        }[header.column.getIsSorted() as string] ?? (
                          <BsChevronExpand className="ml-1 w-4 h-4 text-gray-300" />
                        )}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td
                    className=" bg-white text-gray-700 text-xs border-b border-gray-300"
                    key={cell.id}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
          <tfoot>
            {table.getFooterGroups().map((footerGroup) => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.footer, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </tfoot>
        </table>
        {/* pagination */}
        <div className="h-2" />
        <div className="flex items-center gap-2">
          <button
            className="border rounded p-1"
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            {'<<'}
          </button>
          <button
            className="border rounded p-1"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {'<'}
          </button>
          <button
            className="border rounded p-1"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            {'>'}
          </button>
          <button
            className="border rounded p-1"
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            {'>>'}
          </button>
          <span className="flex items-center gap-1">
            <div>Page</div>
            <strong>
              {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
            </strong>
          </span>
          <span className="flex items-center gap-1">
            | Go to page:
            <input
              type="number"
              defaultValue={table.getState().pagination.pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                table.setPageIndex(page);
              }}
              className="border border-gray-400 p-1 rounded w-12 focus:ring-deehiy focus:border-none"
            />
          </span>
          <select
            className="border border-gray-400 p-1 rounded text-center focus:ring-deehiy focus:border-none"
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
        <div>{table.getPrePaginationRowModel().rows.length} Rows</div>
      </div>
    </div>
  );
};

export default AdminManageUsers;
