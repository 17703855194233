import { useTranslation } from 'react-i18next';

const InputLoc = ({ loc, onChange }: any) => {
  const { t } = useTranslation();

  return (
    <div className="h-12 bg-white ml-2 mr-4 pl-2 flex items-center border border-gray-300 focus:border-deehiy rounded-xl ">
      <img alt="pin" src="/images/icons/pin-card.svg" className="w-5 h-5 " />

      <input
        className="focus:ring-0 w-full group placeholder-placeholderText placeholder:text-sm border-none outline-none text-sm"
        value={loc}
        autoFocus={false}
        onChange={onChange}
        placeholder={t('Search location')}
      />
    </div>
  );
};

export default InputLoc;
