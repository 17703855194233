import React from 'react';
import { Link } from 'react-router-dom';

const LandingPage = () => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 w-full container px-4 sm:px-20 mx-auto items-center justify-center h-[80vh]">
      <div className="flex flex-col flex-grow space-y-4">
        <div className="text-md text-gray-400">
          {new Date().toLocaleString('default', { month: 'long', year: 'numeric' })}
        </div>
        <div className="text-2xl sm:text-5xl font-bold pb-8">
          New Year, New Face: <br /> Deehiy Launches new <br /> Community Marketplace
        </div>
        <Link to="/launch">
          <div className="bg-deehiy w-[140px] font-sm font-bold px-3 py-3 text-white hover:opacity-80 rounded-xl hover:cursor-pointer flex justify-center">
            read more
          </div>
        </Link>
      </div>
      <div className="flex justify-center w-full p-2 sm:p-6 items-center">
        <img alt="" src={'/images/news-original.png'} className="w-full" />
      </div>
    </div>
  );
};

export default LandingPage;
