import React, { useState, useEffect } from 'react';
import { propertyTypesInitialState } from '../../../data/data';
import { useTranslation } from 'react-i18next';

interface PropertyTypeBoxProp {
  propertyType: IPropertyType;
  updateSelectedPropertyType: (id: number) => void;
}

interface IPropertyType {
  id: number;
  // Icon: any
  image: any;
  propertyType: string;
  description: string;
  isSelected: boolean;
}

const PropertyTypeBox = (props: PropertyTypeBoxProp) => {
  const { t } = useTranslation();

  const {
    propertyType: { id, image, propertyType, description, isSelected },
    updateSelectedPropertyType,
  } = props;
  return (
    <div onClick={() => updateSelectedPropertyType(id)} className="group flex mt-6 ">
      <div className="w-1/5">
        <div
          className={`active:scale-90 place-content-center flex-wrap justify-center center-items  h-14 w-14 sm:h-16 sm:w-16 md:h-16 
        md:w-16 lg:w-20 lg:h-20 2xl:w-24 2xl:h-24 duration-300 text-deehiy flex rounded-2xl sm:rounded-3xl border-2 border-deehiy  ${
          isSelected ? 'shadow-md  border-deehiy-dark bg-deehiy-veryLight scale-110  ' : ''
        }`}
        >
          {/* {Icon} */}
          {/* <Icon className="h-20 w-20 sm:h-20 sm:w-20 md:h-20 md:w-20 lg:w-24 lg:h-24 2xl:w-28 2xl:h-28" /> */}
          <img alt="" src={image} className=" w-20" />
        </div>
      </div>
      <div className=" w-4/5 flex flex-col text-sm sm:text-md md:text-md text-gray-600 my-auto ml-6">
        <p className="font-bold">{t(propertyType)}</p>
        <p className="font-normal">{t(description)}</p>
      </div>
    </div>
  );
};

const SelectPropertyType: React.FC<any> = ({
  editedProperty,
  setEditedProperty,
  setCanClickNext,
}) => {
  //remove for off-plan and rent(only)
  const filteredPropertyTypesInitialState =
    editedProperty.isForSale && !editedProperty.isForRent
      ? propertyTypesInitialState
      : propertyTypesInitialState.filter((type) => type.id !== 3);

  const [propertyTypes, setPropertyTypes] = useState<IPropertyType[]>(
    filteredPropertyTypesInitialState
  );

  const updateSelectedPropertyType = (id?: number) => {
    const updatedPropertyTypes = propertyTypes.map((c) => {
      if (c.id === id) {
        c.isSelected = true;
        setEditedProperty({ ...editedProperty, propertyType: c.propertyType });
      } else {
        c.isSelected = false;
      }
      return c;
    });

    setCanClickNext(true);
    setPropertyTypes(updatedPropertyTypes);
  };

  useEffect(() => {
    propertyTypes.map((type) => {
      if (editedProperty.propertyType === type.propertyType) {
        updateSelectedPropertyType(type.id);
      }
      if (!editedProperty.propertyType) updateSelectedPropertyType(); // reset
    });

    setCanClickNext(editedProperty.propertyType ? true : false);
  }, []);

  return (
    <div className="step-form-wrapper w-full">
      <div className=" flex flex-col w-full">
        {propertyTypes.map((propertyType) => (
          <PropertyTypeBox
            key={propertyType.id}
            propertyType={propertyType}
            updateSelectedPropertyType={updateSelectedPropertyType}
          />
        ))}
      </div>
    </div>
  );
};

export default SelectPropertyType;
