import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import StepperControl from './StepperControl';
import { Step, Property } from '../../types/types';
import { IDirection } from './Steps';
import { useTranslation } from 'react-i18next';

// import FormTopBtn from './FormTopBtn';

interface StepsLayoutProp {
  step: Step;
  handleClick: (direction: IDirection) => void;
  steps: Step[];
  currentStepIndex: number;
  editedProperty: Property;
  setEditedProperty: React.Dispatch<React.SetStateAction<Property>>;
}

const StepsLayout = ({
  step: { title, description, Component },
  step,
  handleClick,
  steps,
  currentStepIndex,
  editedProperty,
  setEditedProperty,
}: StepsLayoutProp) => {
  const { t } = useTranslation();
  const [canClickNext, setCanClickNext] = useState(false);

  return (
    <div className="flex w-full h-screen ">
      <div className="relative pl-[80px] hidden font-light md:text-3xl lg:text-3xl 2xl:text-5xl lg:flex w-1/2 antialiased bg-gradient-to-b from-deehiy via-[#1e7a7a]  to-[#1d1c4b]  text-white  justify-center items-center">
        <Link to="/">
          <span className="hidden lg:block font-title absolute md:top-8 lg:top-16  md:text-4xl lg:text-5xl xl:text-6xl text-white">
            deehiy
          </span>
        </Link>
        <span className=" flex-col space-y-6 w-full">
          <p>{t(title)}</p>
          <p className="sm:text-sm md:text-xl xl:text-2xl opacity-90 pr-12">{t(description)}</p>
        </span>
      </div>

      <div className="w-full lg:w-1/2 max-w-[780px] mx-auto pt-4 sm:pt-10 px-4 sm:px-8">
        {/* 20vh */}

        <div className="h-5/6 flex  mx-auto ">
          {Component && (
            <Component
              editedProperty={editedProperty}
              setEditedProperty={setEditedProperty}
              handleClick={handleClick}
              setCanClickNext={setCanClickNext}
            />
          )}
        </div>
        {/* <StepsleftLayer /> */}
        <div className=" flex pt-1">
          <StepperControl
            handleClick={handleClick}
            currentStepIndex={currentStepIndex}
            steps={steps}
            step={step}
            canClickNext={canClickNext}
          />
        </div>
      </div>
    </div>
  );
};

export default StepsLayout;
