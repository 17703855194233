import React from 'react';

const CustomURLPolicy = () => {
  return (
    <div className="container max-w-[850px] mx-auto space-y-6 py-10 px-4">
      <div className="font-light text-2xl text-center">
        How can I have a custom link for my profile?
      </div>
      <div className="text-sm font-extralight leading-loose ">
        Users can create a personalized web address to direct people to their profile with a custom
        link.
        <br />
        <b>Note: </b>This feature is only available to Premium Plan subscribers.
        <br />
        <br />
        If you have subscribed to a premium plan you may create a custom link:
        <br />
        <br />
        1) <b>Login</b> to your Profile <br />
        2) Go to your <b>Dashboard</b> <br />
        3) Go to your <b>Profile Information</b> <br />
        4) Find <b>Personalized URL</b> in the list and <b>edit</b>
        <br />
        <br />
        Each profile can only have one link and each link can exist only once. If a link of your
        choosing has been taken, you will need to change your personalized URL to an available
        option.
        <br />
        <br />
        <br />
        <div className="font-light text-xl mb-2">Custom Link Policy </div>
        Each address is valid only while the account is active, and may be forfeited if the account
        is deactivated or removed from Deehiy for any reason.
        <br />
        <br />
        You don’t own your custom link, and upon any violation of this policy or Deehiy terms,
        Deehiy may end your use of it.
        <br />
        <br />
        <b>Custom links can't include:</b>
        <ul className="text-sm space-y-2 list-disc font-extralight">
          <li>
            Any variation of the word "deehiy" or any other content that violates our brand
            guidelines
          </li>
          <li>The word "verified"</li>
          <li>The word "official"</li>
          <li>Symbols or punctuation, except for hyphens</li>
          <li>Fewer than three characters</li>
          <li>
            Anything that violates{' '}
            <a href="https://deehiy/contentpolicy">Deehiy's Content Policy</a>
          </li>
        </ul>
        <br />
        <b>Custom links can't consist only of:</b>
        <ul className="text-sm space-y-2 list-disc font-extralight">
          <li>Generic property types (for example, Townhouse, Condo, Home)</li>
          <li>Generic geographic locations (for example, Zurich)</li>
          <li>Numbers (all URLs must contain at least one letter)</li>
        </ul>
        <br />
        <b>Some examples of acceptable URLs:</b>
        <ul className="text-sm space-y-2 list-disc font-extralight">
          <li>/prestigious-properties-zurich</li>
          <li>/luxury-living</li>
          <li>/johndoe</li>
        </ul>
      </div>
    </div>
  );
};

export default CustomURLPolicy;
