import React, { useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import { FaMapMarkerAlt } from 'react-icons/fa';
//import Json Data
import { useTranslation } from 'react-i18next';

const GoogleMaps = ({ editedProperty, setEditedProperty, setCanClickNext }: any) => {
  const { t } = useTranslation();

  const [address, setAddress] = useState('');
  const [formattedAddress, setFormattedAddress] = useState('');

  const [coordinates, setCoordinates] = useState({
    lat: editedProperty.latitude,
    lng: editedProperty.longitude,
  });
  const [marker, setMarker] = useState<any>();

  const [map, setMap] = useState<any>(null);
  const [maps, setMaps] = useState<any>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  // const [message, setMessage] = useState('');

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${process.env.REACT_APP_GOOGLEMAP_WEBSERVICE_API_KEY}`
    );
    const data = await response.json();

    if (!data.results.length) {
      // setMessage('no result found');
    }

    const location = data.results[0].geometry.location;

    setFormattedAddress(data.results[0].formatted_address);

    setCoordinates({ lat: location.lat, lng: location.lng });
    if (maps && map) {
      marker.setPosition({ lat: location.lat, lng: location.lng });
    }
  };

  const setAddressFromLatlng = async ({ lat, lng }: any) => {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLEMAP_WEBSERVICE_API_KEY}`
    );
    const data = await response.json();
    // setFormattedAddress(data.results[0].formatted_address)
    setFormattedAddress('');
    setAddress(data.results[0].formatted_address);

    // if (!data.results.length) {
    //   setMessage("no result found")
    // }
  };

  const handleChange = (e: any) => {
    // setMessage('');
    setFormattedAddress('');
    setAddress(e.target.value);
  };

  // place cursor on current location
  const initPosition = () => {
    if (editedProperty.latitude !== 0) {
      setCoordinates({
        lat: editedProperty.latitude,
        lng: editedProperty.longitude,
      });
    } else if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        setCoordinates({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });

        if (maps && map) {
          marker.setPosition({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        }
      });
    }
  };

  // set Current Location Coordinates
  const setCurrentLocation = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        setCoordinates({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });

        if (maps && map) {
          marker.setPosition({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        }
        setAddressFromLatlng({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    }
  };

  // eslint-disable-next-line
  const onGoogleApiLoaded = (map: any, maps: any) => {
    setMap(map);
    setMaps(maps);
    setIsLoaded(true);
  };

  const getMarkerIcon = (showExactLocation: boolean) => {
    if (showExactLocation) {
      return {
        url: '/images/icons/map-pin.svg', // url
        scaledSize: new google.maps.Size(72, 72), // scaled size
        origin: new google.maps.Point(0, 0), // origin
        anchor: new google.maps.Point(36, 72), // anchor
      };
      // marker.setMap(map)
    } else {
      return {
        url: '/images/icons/map-circle.svg', // url
        scaledSize: new google.maps.Size(120, 120), // scaled size
        size: new google.maps.Size(120, 120),
        origin: new google.maps.Point(0, 0), // origin
        anchor: new google.maps.Point(60, 60), // anchor
      };
      // map.setZoom(13)
    }
  };

  // place map on the location of the user
  useEffect(() => {
    setCanClickNext(false);
    initPosition();
  }, []);

  useEffect(() => {
    // if user does not allow geoloc, he has to set a lat/lng to be allowed to click on Next button
    if (editedProperty.latitude !== 0) {
      setCanClickNext(true);
    }
  }, [editedProperty.latitude]);

  // init marker
  useEffect(() => {
    if (isLoaded && !marker) {
      let icon = getMarkerIcon(editedProperty.showExactLocation);

      setMarker(
        new maps.Marker({
          icon,
          map,
          position: { lat: coordinates.lat, lng: coordinates.lng },
          // center: { lat: coordinates.lat, lng: coordinates.lng },
          title: t('drag and drop me'),
          draggable: true,
        })
      );
      map.setCenter({ lat: coordinates.lat, lng: coordinates.lng });
    }
  }, [map, maps, isLoaded]);

  useEffect(() => {
    if (marker) {
      maps.event.addListener(marker, 'dragend', function () {
        let coord = marker.getPosition();
        // let coord = marker.getCenter()
        if (coord) {
          setCoordinates({ lat: coord.lat(), lng: coord.lng() });
        }
      });

      map?.addListener('zoom_changed', function () {
        if (!editedProperty.showExactLocation) {
          if (map.zoom === 20) {
            marker.icon.scaledSize = new google.maps.Size(600, 600); // scaled size
            marker.icon.size = new google.maps.Size(600, 600);
            marker.origin = new google.maps.Point(0, 0); // origin
            marker.anchor = new google.maps.Point(300, 300); // anchor
          }
          if (map.zoom === 18) {
            marker.icon.scaledSize = new google.maps.Size(460, 460); // scaled size
            marker.icon.size = new google.maps.Size(460, 460);
            marker.origin = new google.maps.Point(0, 0); // origin
            marker.anchor = new google.maps.Point(230, 230); // anchor
          }
          if (map.zoom === 16) {
            marker.icon.scaledSize = new google.maps.Size(280, 280); // scaled size
            marker.icon.size = new google.maps.Size(280, 280);
            marker.origin = new google.maps.Point(0, 0); // origin
            marker.anchor = new google.maps.Point(140, 140); // anchor
          }
          if (map.zoom === 13) {
            marker.icon.scaledSize = new google.maps.Size(120, 120); // scaled size
            marker.icon.size = new google.maps.Size(120, 120);
            marker.origin = new google.maps.Point(0, 0); // origin
            marker.anchor = new google.maps.Point(60, 60); // anchor
          }
          if (map.zoom === 8) {
            marker.icon.scaledSize = new google.maps.Size(40, 40); // scaled size
            marker.icon.size = new google.maps.Size(40, 40);
            marker.origin = new google.maps.Point(0, 0); // origin
            marker.anchor = new google.maps.Point(20, 20); // anchor
          }
        }
        marker.setMap(map);
      });
    }
  }, [marker]);

  useEffect(() => {
    if (marker && maps) {
      marker.setIcon(getMarkerIcon(editedProperty.showExactLocation));
    }
  }, [editedProperty.showExactLocation]);

  useEffect(() => {
    const updatedPosition = { ...editedProperty };
    updatedPosition.latitude = coordinates.lat;
    updatedPosition.longitude = coordinates.lng;
    setEditedProperty(updatedPosition);

    // the map is first rendered with the marker at the wrong coordinates, so we need to set the need position
    if (maps && map) {
      marker.setPosition({
        lat: coordinates.lat,
        lng: coordinates.lng,
      });
    }
  }, [coordinates]);

  return (
    <div className="h-[25vh] sm:h-[36vh] w-full">
      <form onSubmit={handleSubmit} className="mb-3">
        <div className="rounded-full place-content-center">
          <div className="flex border-2 border-deehiy rounded-full">
            <div className=" py-3 pl-3  pr-2 text-center rounded-l-full bg-white">
              <FaMapMarkerAlt className="w-6 h-6 text-gray-400" />
            </div>
            <input
              className={'outline-0 ring-0 border-none focus:ring-0 py-3 px-0 w-full text-sm'}
              type="text"
              value={address}
              placeholder={t('find your location...')}
              onChange={handleChange}
            />
            <button
              className={
                'bg-deehiy text-white font-bold  duration-300 hover:shadow-lg  py-3 rounded-r-full px-4 '
              }
              type="submit"
            >
              {t('find')}
            </button>
          </div>
        </div>
        <div className=" flex justify-center w-full">
          <button
            type="button"
            onClick={setCurrentLocation}
            className="text-sm underline text-deehiy hover:cursor-pointer"
          >
            {t('or use your current location')}
          </button>
        </div>

        {formattedAddress && <span className="text-sm text-gray-600"> {formattedAddress}</span>}
        {/* {message && <span> {message}</span>} */}

        <div className="flex flex-col text-sm font-light pt-1">
          <span className="font-light text-lg">{t('Pin not correct?')}</span>
          <span className="text-sm">
            {t('Drag to adjust pin manually to the correct location.')}
          </span>
        </div>

        {/* <div className='bg-white mt-10 p-1 items-center rounded-full flex justify-evenly w-full text-xs'>
          <span>latitude : {coordinates.lat}</span>
          <span>longitude : {coordinates.lng}</span>
          <button
          type="button"
            className='bg-gray-100 rounded-full p-1 px-3 text-black hover:shadow-md duration-300 hover:bg-gray-50  '
            onClick={() => setAddressFromLatlng(coordinates)}>
            find the address of this position
          </button>
        </div> */}
      </form>
      <div
        className="
      xl:h-[40vh]
       lg:h-[34vh]
       md:h-[32vh]
        sm:h-[34vh]  
        h-[34vh]"
      >
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLEMAP_API_KEY }}
          defaultCenter={coordinates}
          center={coordinates}
          defaultZoom={12}
          yesIWantToUseGoogleMapApiInternals
          // eslint-disable-next-line
          onGoogleApiLoaded={({ map, maps }: any) => onGoogleApiLoaded(map, maps)}
        />
      </div>
    </div>
  );
};

export default GoogleMaps;
