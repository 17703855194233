import React, { useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';

export default function ListingMap({ isToggled, center }: any) {
  const [map, setMap] = useState<any>(null);
  const [maps, setMaps] = useState<any>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [marker, setMarker] = useState<any>();

  const defaultProps = {
    center: {
      lat: 13.7563,
      lng: 100.5018,
    },
    zoom: 15,
  };

  // eslint-disable-next-line
  const handleApiLoaded = (map: any, maps: any) => {
    // use map and maps objects
    setMap(map);
    setMaps(maps);
    setIsLoaded(true);
  };

  useEffect(() => {
    if (isLoaded && !marker) {
      var icon = isToggled
        ? {
            // url: '/images/icons/map-pin.svg', // url
            url: '/images/icons/pin-card.svg', // url
            scaledSize: new google.maps.Size(72, 72), // scaled size
            origin: new google.maps.Point(0, 0), // origin
            anchor: new google.maps.Point(36, 72), // anchor
          }
        : {
            url: '/images/icons/map-circle.svg', // url
            scaledSize: new google.maps.Size(120, 120), // scaled size
            size: new google.maps.Size(120, 120),
            origin: new google.maps.Point(0, 0), // origin
            anchor: new google.maps.Point(60, 60), // anchor
          };
      // map.setZoom(13)

      setMarker(
        new maps.Marker({
          icon: icon,
          map,
          position: { lat: center.lat, lng: center.lng },
          draggable: false,
        })
      );
      map.setCenter({ lat: center.lat, lng: center.lng });
    }
  }, [isToggled, map, maps, isLoaded]);

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: '50vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLEMAP_API_KEY }}
        defaultCenter={center}
        defaultZoom={defaultProps.zoom}
        yesIWantToUseGoogleMapApiInternals
        // eslint-disable-next-line
        onGoogleApiLoaded={({ map, maps }: any) => handleApiLoaded(map, maps)}
      ></GoogleMapReact>
    </div>
  );
}
