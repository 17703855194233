import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Box from '../animation/Box';
import Loader from '../main/Loader';
import { verifyInvitation } from '../../services/auth/userService';
import BusinessUserRegister from './BusinessUserRegister';

const VerifyInvitation = () => {
  const { userId, token } = useParams();

  let navigate = useNavigate();

  const [message, setMessage] = useState('We are verifying your account...');
  const [isLoading, setIsLoading] = useState(false);
  const [showRegistration, setShowRegistration] = useState(false);
  const [email, setEmail] = useState('');

  useEffect(() => {
    setIsLoading(true);
    if (userId && token) {
      verifyInvitation(Number(userId), token)
        .then((res: any) => {
          if (!res.data.isUserRegistered) {
            setEmail(res.data.user.email);
            setMessage('Please, register your details');
            setShowRegistration(true);
          } else {
            setMessage('Invitation verified! you are all set to start collaborating!');
            setTimeout(() => {
              navigate('/login');
            }, 3000);
          }
        })
        .catch((err: any) => {
          //   setMessage(res);
          console.log(err.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      navigate('/');
    }
  }, []);

  return (
    <Box>
      {/* <div className="auth-form"> */}
      {isLoading && <Loader />}
      <div className="hidden pt-2  text-center text-xs">{message}</div>
      {/* </div>  */}
      {showRegistration && <BusinessUserRegister email={email} />}
    </Box>
  );
};
export default VerifyInvitation;
