import React, { useState, useEffect } from 'react';
import { initialProperty } from '../../initialStates/initialStates';
import StepsLayout from './StepLayout';
// import StepsLayout from "./StepsLayout"
import { Property, Step } from '../../types/types';
import { useProperty } from '../../contexts/property';
import { useParams } from 'react-router-dom';
import { LAND } from '../../constants/constant';
import { useBusiness } from '../../contexts/business';

interface IStepProp {
  steps: Step[];
}

export type IDirection = 'next' | 'back';

function Steps({ steps }: IStepProp) {
  const [currentStepIndex, setCurrentStepIndex] = useState(1);
  const { setCurrentProperty } = useProperty();
  const { currentBusiness } = useBusiness();
  const { isBusiness } = useParams();
  const [editedProperty, setEditedProperty] = useState<Property>(initialProperty);

  const [updatedSteps, setUpdatedSteps] = useState(steps);

  const handleClick = (direction: IDirection) => {
    let newStepIndex = currentStepIndex;

    if (direction === 'next') {
      newStepIndex++;
    } else {
      newStepIndex--;
    }

    // check if steps are within bounds
    if (newStepIndex > 0 && newStepIndex <= steps.length) {
      setCurrentStepIndex(newStepIndex);
    }
  };

  const resetEditProperty = () => {
    // Reset logic here
    setEditedProperty(initialProperty);
  };

  useEffect(() => {
    // This function will be called when the component is unmounted
    return () => {
      resetEditProperty();
    };
  }, []);

  useEffect(() => {
    setCurrentProperty(editedProperty);
  }, [editedProperty]);

  // determine if the listing is created within the business
  useEffect(() => {
    if (isBusiness && currentBusiness.businessId) {
      setEditedProperty({ ...editedProperty, businessId: currentBusiness.businessId });
    }
  }, [isBusiness, currentBusiness]);

  useEffect(() => {
    let newSteps = [...steps];

    if (editedProperty.propertyType === LAND) {
      // Remove steps with ids 5 and 9 for LAND property types
      newSteps = newSteps.filter((step) => step.id !== 5 && step.id !== 9);
    }

    if ((editedProperty.isForSale && !editedProperty.isForRent) || editedProperty.isOffPlan) {
      // Remove step with id 6 for properties for sale but not for rent
      newSteps = newSteps.filter((step) => step.id !== 6);
    }

    // Additional logic for removing step with id 7 for properties that are not off-plan
    if (editedProperty.isOffPlan) {
      newSteps = newSteps.filter((step) => step.id !== 9);
    }
    setUpdatedSteps(newSteps);
  }, [
    editedProperty.propertyType,
    editedProperty.isForSale,
    editedProperty.isForRent,
    editedProperty.isOffPlan,
  ]);

  return (
    <>
      {/* Stepper */}
      {/* <div className='horizontal container mt-5 '> */}
      <div className=" ">
        {/* <Stepper steps={steps} currentStepIndex={currentStepIndex} /> */}
        {/* <div className='my-10 p-10 '> */}
        {/* {displayStep(currentStepIndex)} */}
        <StepsLayout
          step={updatedSteps[currentStepIndex - 1]}
          handleClick={handleClick}
          steps={updatedSteps}
          currentStepIndex={currentStepIndex}
          editedProperty={editedProperty}
          setEditedProperty={setEditedProperty}
        />
        {/* </div> */}
      </div>
    </>
  );
}

export default Steps;
