import React, { useState, useEffect } from 'react';
import { useHunt } from '../../../contexts/hunt';
import { useAuth } from '../../../contexts/auth';
import { useParams, useNavigate } from 'react-router-dom';
import MatchTable from './table/MatchTable';
import Loader from '../../main/Loader';
import TakeMeBack from '../../main/navigation/TakeMeBack';
import { useTranslation } from 'react-i18next';
import { BiChevronRight } from 'react-icons/bi';
import { initialHunt } from '../../../initialStates/initialStates';

const MatchesPerHunt = () => {
  const { id } = useParams();
  const { fetchHuntByIdContext } = useHunt();
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [hunt, setHunt] = useState(initialHunt);

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      // authentication check
      fetchHuntByIdContext(Number(id))
        .then((fetchedHunt: any) => {
          if (fetchedHunt.userId !== currentUser?.id) {
            alert('you are not allowed to edit this hunt');
            navigate('/');
          } else {
            console.log(fetchedHunt.id);
            setHunt(fetchedHunt);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false));
    }
  }, [id]);

  if (isLoading) return <Loader />;

  if (!hunt?.matches)
    return (
      <div className="container items-center justify-center mx-auto h-screen">
        <div className="py-4 sm:py-12 space-y-6 sm:space-y-12 ">
          <div className="text-sm font-bold sm:text-lg pt-2 sm:pt-4 text-center">
            No Match Yet — But We're On It
          </div>
          <div className="max-w-[600px] mx-auto space-y-6">
            <p>
              Our community of agents has been notified about your hunt and is actively searching
              for properties that fit your criteria. As soon as a match is found, you’ll be the
              first to know.
            </p>
            <p>
              Want to boost your chances? Create a new hunt with different criteria and open more
              doors!
            </p>
            <p>Stay tuned!</p>
          </div>
          <button
            className="primary-btn flex items-center justify-center"
            onClick={() => navigate('/users/dashboard')}
          >
            <span>Go to dashboard</span>
            <BiChevronRight className="w-5 h-5" />
          </button>
        </div>
      </div>
    );
  return (
    <div className="container mx-auto h-screen">
      <div className="py-4 sm:py-12 space-y-6 sm:space-y-12 ">
        <TakeMeBack loc={'/users/huntingboard'} />
        <div className="text-xl sm:text-2xl font-bold w-full ">{hunt.location}</div>

        <div className="text-sm font-bold sm:text-md pt-2 sm:pt-4">
          {t('listings_received', { count: hunt?.matches?.length || 0 })}
          {/* {currentHunt?.matches?.length && currentHunt?.matches?.length > 1 && 's'} */}
          <div className="text-sm font-normal pt-2 sm:pt-4">
            {t('Review these listings and reach out to the listing owner')}
          </div>
        </div>
      </div>
      <MatchTable hunt={hunt} />
      {/* matches={currentHunt.matches} */}
    </div>
  );
};

export default MatchesPerHunt;
