import React from 'react';
import { Navigation } from '../main/navigation/Navigation';
import MobileNavigation from '../main/navigation/MobileNavigation';
import { Outlet } from 'react-router-dom';
// import BackToTopButton from '../main/BackToTopButton';
import Footer from './Footer';

const SquareLayout = ({ children }: any) => {
  return (
    <div className="dark:bg-slate-900 flex flex-col min-h-screen max-h-screen relative">
      <header>
        <div className="hidden sm:block">
          <Navigation label="" isBusiness={false} />
        </div>
        <div className="block sm:hidden">
          <MobileNavigation isBusiness={false} />
        </div>
      </header>

      <main className="mx-auto pt-0 sm:pt-16 pb-2 mb-32 h-full w-full max-h-screen">
        <Outlet />
        {children}
      </main>

      <Footer />
    </div>
  );
};

export default SquareLayout;
