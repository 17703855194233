import React, { useState } from 'react';
import { Link, useNavigate, useLocation, NavLink } from 'react-router-dom';
import { useAuth } from '../../../contexts/auth';
import { useScroll } from '../../../contexts/scroll';
import MobileModal from '../modal/MobileModal';
import { useBusiness } from '../../../contexts/business';
import { MainIconMenu } from './Navigation';
import { useTranslation } from 'react-i18next';

const MenuItem = ({ itemLabel, url, imageUrl }: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleNavigation = () => {
    if (itemLabel === 'Messenger') {
      window.open(url, '_blank');
    } else {
      navigate(url);
    }
  };

  return (
    <div className="flex gap-x-3 text-sm mt-5 items-center" onClick={handleNavigation}>
      {imageUrl && (
        <img src={imageUrl} alt="ico" className="w-10 h-10 sm:w-12 sm:h-12 rounded-full" />
      )}
      {t(itemLabel)}
    </div>
  );
};

const CommonItems = () => {
  return (
    <>
      <MenuItem
        itemLabel="Help center"
        url="/help"
        imageUrl="/images/icons/icon-dashboard-landing/help.svg"
      />
    </>
  );
};

const DashboardItems = () => {
  return (
    <>
      <MenuItem
        itemLabel="Property listings"
        url="/users/managelistings"
        imageUrl="/images/icons/icon-dashboard-landing/listings.svg"
      />
      <MenuItem
        itemLabel="Hunting board"
        url="/users/huntingboard"
        imageUrl="/images/icons/icon-dashboard-landing/hunting-board.svg"
      />
    </>
  );
};

const BusinessItems = () => {
  // const appToken = localStorage.getItem('@App:token');
  return (
    <>
      {/* <DropdownItem
        itemLabel="Business info"
        onClick={() => navigate('/business/profile')}
        imageUrl="/images/icons/icon-dashboard-landing/personal-info.svg"
      /> */}
      <MenuItem
        itemLabel="Property listings"
        url="/business/managelistings"
        imageUrl="/images/icons/icon-dashboard-landing/listings.svg"
      />
      {/* <MenuItem
        itemLabel="Messenger"
        url={`${process.env.REACT_APP_MESSENGER_URL}?token=${appToken}`}
        imageUrl="/images/icons/icon-dashboard-landing/messaging.svg"
      /> */}
      {/* <MenuItem
        itemLabel="Hunting board"
        url="/huntingboard"
        imageUrl="/images/icons/icon-dashboard-landing/hunting-board.svg"
      /> */}
      <MenuItem
        itemLabel="Account settings"
        url="/business/settings"
        imageUrl="/images/icons/icon-dashboard-landing/settings.svg"
      />
      {/* <MoreUserTools /> */}
    </>
  );
};

// const SwitchItem = ({ url, imageUrl, SwitchItemLabel }: any) => {
//   return (
//     <Link to={url} className="items-center gap-x-3 flex cursor-pointer ">
//       <img className="w-8 h-8 rounded-full" src={imageUrl} />
//       <span className="line-clamp-1">{SwitchItemLabel}</span>
//     </Link>
//   );
// };

const MobileMenu = ({ isBusiness }: any) => {
  const { currentUser } = useAuth();
  const { currentBusiness } = useBusiness();

  return (
    <div className="py-4 px-10">
      <div className="border border-transparent border-b-deehiy pb-5">
        {currentBusiness.businessId ? (
          <>
            {isBusiness ? (
              <>
                <MenuItem
                  url={'/business/dashboard'}
                  imageUrl={currentBusiness.image}
                  itemLabel={currentBusiness.businessName}
                />

                <MenuItem
                  url={'/users/dashboard'}
                  imageUrl={'/images/icons/business/profile-green.svg'}
                  itemLabel="Switch to Personal"
                />
              </>
            ) : (
              <>
                <MenuItem
                  url={'/users/dashboard'}
                  imageUrl={currentUser?.image}
                  itemLabel={`${
                    currentUser?.firstName ? currentUser?.firstName : 'Personal Profile'
                  } `}
                />
                <MenuItem
                  url={'/business/dashboard'}
                  imageUrl={'/images/icons/icon-dashboard-landing/business-profile-green.svg'}
                  itemLabel="Switch to business"
                />
              </>
            )}
          </>
        ) : (
          <>
            <MenuItem
              url={'/users/dashboard'}
              imageUrl={currentUser?.image}
              itemLabel={`${currentUser?.firstName ? currentUser?.firstName : 'Personal Profile'} `}
            />
            <MenuItem
              url={'/users/addbusiness'}
              imageUrl={'/images/icons/icon-dashboard-landing/business-profile-green.svg'}
              itemLabel="Create your business"
            />
          </>
        )}
      </div>
      {isBusiness ? <BusinessItems /> : <DashboardItems />}
      <CommonItems />
      {/* ADMIN */}
      {currentUser?.roles === 'admin' && (
        <MenuItem itemLabel=" ⚠️⚠️⚠️ - Admin dashboard" url={'/admin/dashboard'} />
      )}
    </div>
  );
};

const MobileNavigation = ({ isBusiness }: any) => {
  const { currentUser } = useAuth();
  const location = useLocation();
  const { visible } = useScroll();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const appToken = localStorage.getItem('@App:token');

  const navStyle = `fixed bottom-0 left-0 right-0 bg-white z-40 transition-all duration-300 ${
    visible ? 'translate-y-0' : 'translate-y-[56px]'
  }`;

  const MainIconMobileMenu = () => {
    const { t } = useTranslation();

    return (
      <>
        {MainIconMenu.map(({ url, Icon, title }, i) =>
          url ? (
            <NavLink
              className={({ isActive }) => {
                return ` ${
                  isActive
                    ? ' border-transparent h-full border-t-2 border-deehiy text-deehiy'
                    : ' text-defaultText'
                } flex items-center justify-center`;
              }}
              to={url}
              key={i}
            >
              <div title={t(title)} className="w-7 h-7 items-center flex">
                <Icon />
              </div>
            </NavLink>
          ) : null
        )}
      </>
    );
  };

  return (
    <>
      <MobileModal
        children={<MobileMenu isBusiness={isBusiness} />}
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
      />
      <nav className={navStyle}>
        {currentUser ? (
          <div className="text-[11px] bg-white grid grid-cols-5 h-[56px] border border-transparent border-t-gray-100">
            <MainIconMobileMenu />

            <Link
              to=""
              onClick={() =>
                window.open(`${process.env.REACT_APP_MESSENGER_URL}?token=${appToken}`, '_blank')
              }
              className="flex items-center justify-center "
            >
              <img src="/images/icons/deehiy-messenger-logo-headerbar.svg" className="w-7 h-7" />
            </Link>
            {/* <Link to="/users/dashboard" className="flex gap-0 flex-col items-center justify-center">
            <img src="/images/icons/icon-mobile/profile.svg" className="w-6 h-6" />
            Profile
          </Link> */}
            <button
              onClick={() => setIsModalOpen((p) => !p)}
              className="flex items-center justify-center rounded-full"
            >
              <img
                src={currentUser?.image}
                className="w-8 h-8 rounded-full  border border-gray-200"
              />
            </button>
          </div>
        ) : (
          <div className="text-[11px] bg-white grid grid-cols-5 h-[56px] items-center border border-transparent border-t-gray-100">
            <MainIconMobileMenu />
            <Link
              to="/login"
              state={{ redirectTo: location }}
              className="flex items-center justify-center "
            >
              <img src="/images/icons/deehiy-messenger-logo-headerbar.svg" className="w-7 h-7" />
            </Link>
            <Link
              to="/login"
              state={{ redirectTo: location }}
              className="flex gap-0 flex-col items-center justify-center text-defaultText"
            >
              <img src="/images/icons/icon-mobile/profile_mobile.svg" className="w-7 h-7" />
            </Link>
          </div>
        )}
      </nav>
    </>
  );
};

export default MobileNavigation;
