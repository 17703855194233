import React from 'react';
import TakeMeBack from '../../components/main/navigation/TakeMeBack';

const FormsPreLaunch = () => {
  return (
    <div className="mx-auto flex flex-col  justify-center items-center px-6 sm:px-24 container">
      <div className="pt-6 w-full justify-start">
        <TakeMeBack />
      </div>
      <div className="flex flex-col text-left w-full">
        {/* title */}
        <div className="text-3xl sm:text-5xl font-bold pt-12 sm:pt-8 pb-4 sm:py-6">
          Create solid contracts with a few clicks
        </div>
        <div className="text-sm sm:text-xl pb-4 sm:pb-16">Contracting has never been easier..</div>
      </div>
      <div className="flex flex-col sm:flex-row">
        <div className="flex flex-col w-wull sm:w-3/5 pr-12">
          <div className="text-xs sm:text-sm py-6 ">
            Available on desktop, Deehiy Forms will simplify the creation of agreements and allow
            everyone to safeguard their interest without expensive lawyers involved.
          </div>
          <div className="py-3">
            <div className="text-xs sm:text-sm font-bold text-deehiy">
              What you will be able to do
            </div>
            <div className="text-xs sm:text-sm">
              Choose from a variety of available templates for specific countries, select a
              preferred language and input the information. Et Voilà! Within a few short minutes you
              have created a professional looking, typo-free, legally accurate real estate
              agreement.
            </div>
          </div>
        </div>
        <div className="flex justify-center w-full sm:w-2/5 p-2 sm:p-6 items-center">
          <img alt="" src={'/images/deehiy-Forms-Teaser-Image.png'} className="w-3/5" />
        </div>
      </div>
    </div>
  );
};
export default FormsPreLaunch;
