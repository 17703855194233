import React, { useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Loader from '../../../main/Loader';
import { sendInvitationEmail } from '../../../../services/auth/userService';
import { useBusiness } from '../../../../contexts/business';
import { useAuth } from '../../../../contexts/auth';
import { INVITE_MEMBER, ROLES } from '../../../../constants/constant';
import UsersTable from '../../UsersTable';
import Select from '../../../form/Select';
import Tooltip from '../../../form/Tooltip';
import { canAccess } from '../../../../utils/businessAccess';

import { validateEmail } from '../../../../utils/fieldValidater';
import { useTranslation } from 'react-i18next';

const UsersManagement = () => {
  const methods = useForm({
    mode: 'onTouched',
  });
  const { currentBusiness, users, fetchUsersByBusinessIdContext } = useBusiness();
  const { currentUser } = useAuth();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [roleId, setRoleId] = useState(4);
  const [canClickInvite, setCanClickInvite] = useState(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    sendInvitationEmail(email, currentBusiness, roleId)
      .then(() => {
        setEmail('');
        fetchUsersByBusinessIdContext(currentBusiness.businessId!);
      })
      .catch((err) => {
        alert(err.response.data.error);
      });
    setIsLoading(false);
  };

  useEffect(() => {
    if (currentBusiness.businessId) {
      fetchUsersByBusinessIdContext(currentBusiness.businessId);
    }
  }, [currentBusiness.businessId]);

  useEffect(() => {
    if (validateEmail(email)) {
      const isExistingUser = users.some((u) => u.email === email);
      if (isExistingUser) alert(t('this person has already been invited'));

      setCanClickInvite(!isExistingUser);
    } else {
      setCanClickInvite(false);
    }
  }, [email]);

  return (
    <div className="pb-20">
      {canAccess(currentUser, INVITE_MEMBER) && (
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit}>
            <div className="my-auto flex flex-col sm:flex-row space-y-4 py-4 sm:pt-0 pb-6 gap-x-4 justify-between">
              <div className=" items-center flex flex-grow">
                <input
                  id="email"
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  type="email"
                  placeholder={t('Add email address')}
                  className="border-deehiy w-full max-w-lg placeholder:px-3 placeholder:font-bold rounded-full placeholder:text-deehiy"
                />
              </div>
              <div>
                <Select
                  label=""
                  id="role"
                  name="role"
                  placeholder={t('select a role')}
                  onChange={(e) => setRoleId(Number(e.target.value))}
                  value={roleId}
                >
                  {/* we filter out admin role */}
                  {ROLES.filter((r) => r.roleId !== 1).map((r) => (
                    <option value={r.roleId} key={r.roleId}>
                      {t(r.roleName)}
                    </option>
                  ))}
                </Select>
              </div>
              <div className=" flex justify-center items-center">
                <button
                  className={` ${
                    !canClickInvite && 'opacity-60 cursor-not-allowed'
                  } primary-btn rounded whitespace-nowrap flex px-2 gap-x-1 min-w-36 py-2`}
                  disabled={!canClickInvite || isLoading ? true : false}
                >
                  <img
                    alt="add-member"
                    src="/images/icons/business/add_member_white.svg"
                    className="w-5 h-5"
                  />
                  {t('Invite member')}
                </button>
                <div className="pl-1 hidden md:flex">
                  <Tooltip
                    text={t('Invited recipients will receive an email to accept your invitation.')}
                    width={300}
                  />
                </div>
                {isLoading && <Loader />}
              </div>
            </div>
          </form>
        </FormProvider>
      )}
      <UsersTable />
    </div>
  );
};

export default UsersManagement;
