import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Box from '../animation/Box';
import Loader from '../main/Loader';
import { verifyAccount } from '../../services/auth/userService';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../contexts/auth';

const VerifyAccountPage = () => {
  const { userId, token } = useParams();
  const { LoginWithToken } = useAuth();

  let navigate = useNavigate();
  const { t } = useTranslation();

  const [message, setMessage] = useState(t('verifying_account'));
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Define the async function inside useEffect
    const verifyAndLogin = async () => {
      if (!userId || !token) {
        navigate('/');
        return;
      }

      setIsLoading(true);

      try {
        const response = await verifyAccount(Number(userId), token);
        console.log(response.data);

        // Proceed with login if account verification is successful
        const loginResult = await LoginWithToken(response.data.authToken);

        if (loginResult === 'success') {
          setMessage(t('account_verified'));

          setTimeout(() => {
            navigate('/getting-started');
          }, 3000);
        } else {
          setMessage(t('login_failed'));
        }
      } catch (error: any) {
        console.error('Verification or login failed:', error);
        setMessage(error.response?.data || t('verification_failed'));
      } finally {
        setIsLoading(false);
      }
    };

    verifyAndLogin();
  }, [userId, token]);

  return (
    <Box>
      <div className="auth-form">
        {isLoading && <Loader />}
        <p className="mx-auto font-light text-2xl">{t('email_verification')}</p>
        <hr className="border-deehiy" />
        <div className="p-4 my-2 text-center text-sm bg-white">{message}</div>
      </div>
    </Box>
  );
};
export default VerifyAccountPage;
