import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/auth';
import { useProperty } from '../contexts/property';
import { useInternationalization } from '../contexts/internationalization';
import Loader from '../components/main/Loader';
import { User, Property } from '../types/types';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import PropertyCard from '../components/main/PropertyCard';
import TopMobileNavigation from '../components/main/navigation/TopMobileNavigation';
// import AppointAgentBtn from '../components/listing/AppointAgentBtn';
// import { sendAppointAgentToAdmin } from '../services/auth/userService';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReadMore from '../components/listing/ReadMore';
import { FiChevronDown } from 'react-icons/fi';
import { Dropdown } from 'flowbite-react';
import { BsFillShareFill } from 'react-icons/bs';
import Share from '../components/main/Share';
import Tooltip from '../components/form/Tooltip';
import { useChat } from '../contexts/chat';
import { adjustImageSize } from '../utils/adjustImageSize';
import { getPostByUserIdService } from '../services/postService';
import { Feed } from '../components/square/Square';
import { useTranslation } from 'react-i18next';

const AgentPage = () => {
  const [user, setUser] = useState<null | User>(null);
  const [properties, setProperties] = useState<any>(null);
  const [visibleProperties, setVisibleProperties] = useState([]);
  const [filteredProperties, setFilteredProperties] = useState([]);
  const { fetchUserById, currentUser } = useAuth();
  const { fetchPropertiesByUserId } = useProperty();
  const { currency } = useInternationalization();
  const [isLoading, setIsLoading] = useState(false);
  // const [isDisabled, setIsDisabled] = useState(false);
  // const [isSendingRequest, setIsSendingRequest] = useState(false);
  const [showCount, setShowCount] = useState(3);
  const [posts, setPosts] = useState([]);
  const { addToContact, chatHandler } = useChat();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const handleShowMore = () => {
    setShowCount((prevCount) => prevCount + 6);
  };

  // const handleAppointAgent = async () => {
  //   if (!currentUser?.id) {
  //     toast.info('Please, log in to save this property');
  //     return;
  //   }
  //   setIsSendingRequest(true);
  //   const userId = currentUser?.id;
  //   const agentId = user?.id;
  //   try {
  //     if (userId && agentId) await sendAppointAgentToAdmin(userId, agentId);
  //     setIsDisabled(true);
  //     toast.success('Appoint agent request sent!');
  //   } catch (err: any) {
  //     toast.error('oops, something went wrong');
  //     throw new Error(err);
  //   } finally {
  //     setIsSendingRequest(false);
  //   }
  // };

  const chatButtonHandler = async () => {
    if (currentUser?.id) {
      await chatHandler(user?.id, user?.firstName || '');
    } else {
      navigate('/login', { state: { redirectTo: location } });
    }
  };

  const addContactHandler = async () => {
    if (currentUser?.id) {
      await addToContact(user?.id);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);

      try {
        const userData = await fetchUserById(Number(id));
        setUser(userData);

        const postData = await getPostByUserIdService(Number(id));

        setPosts(postData.data.posts);
      } catch (error) {
        // Handle any errors that occur during the fetch operations
        console.error('An error occurred while fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, [id]);

  useEffect(() => {
    fetchPropertiesByUserId(user?.id, currency).then((data: any) => {
      setProperties(data);
    });
  }, [user, currency]);

  useEffect(() => {
    if (properties?.length > 0) {
      const filtered = properties?.filter((property: Property) => property.published);
      setFilteredProperties(filtered);
      const updatedProperties = filteredProperties?.slice(0, showCount);
      setVisibleProperties(updatedProperties);
    }
  }, [properties]);

  useEffect(() => {
    if (filteredProperties?.length > 0) {
      const updatedProperties = filteredProperties?.slice(0, showCount);
      setVisibleProperties(updatedProperties);
    }
  }, [filteredProperties, showCount]);

  if (isLoading) return <Loader />;

  return (
    <>
      <TopMobileNavigation />
      <div className="xl:max-w-[1430px] lg:max-w-[1240px] container mx-auto  py-2 sm:py-0 px-0 sm:px-0">
        <div className="flex flex-col sm:flex-row text-sm sm:text-base">
          <div className="flex-col w-full sm:w-1/3 px-4 sm:px-10 pt-12 sm:pt-12 ">
            <div className=" sticky top-20 flex mx-auto flex-col space-y-2 border  border-transparent sm:border-deehiy rounded-2xl shadow-none sm:shadow-xl p-0 sm:p-8">
              <div className="relative w-32 flex mx-auto">
                <>
                  {user?.isSalesRep && (
                    <div className="group absolute top-0 left-0">
                      <Tooltip
                        text="Deehiy Ambassador"
                        Component={() => (
                          <img className=" w-10 h-10 " src="/images/icons/sales-rep.svg" />
                        )}
                      />
                    </div>
                  )}
                </>
                <img
                  src={
                    user?.image
                      ? adjustImageSize(user.image)
                      : '/images/icons/icon-mobile/profile.svg'
                  }
                  alt=""
                  className="w-28 h-28 rounded-full mx-auto"
                />
              </div>
              <div className="flex text-sm space-x-6 mx-auto pt-6 font-semibold items-baseline gap-2">
                {user?.firstName} {user?.lastName}{' '}
                {user?.isVerified && (
                  <Tooltip
                    text="this user has provided proof of identity"
                    Component={() => (
                      <img
                        src="/images/icons/verified_profile.svg"
                        className="text-deehiy w-5 h-5"
                      />
                    )}
                  />
                )}
              </div>
              <div className="flex-col text-sm space-y-4">
                <div className="flex flex-col">
                  <span>{t('active in')}</span>
                  {/* <span className="font-bold">
                    {user?.city && user?.country && <span>{`${user.city}, ${user.country}`}</span>}
                    {user?.city && !user?.country && <span>{user.city}</span>}
                    {!user?.city && user?.country && <span>{user.country}</span>}
                  </span> */}
                  <span className="font-bold">
                    {user?.preferredAreas && JSON.parse(user.preferredAreas).join(',')}
                  </span>
                </div>
                <div className="flex flex-col">
                  <span>{t('joined')}</span>
                  <span className="font-bold">
                    {user?.createdAt &&
                      new Date(user?.createdAt).toLocaleString('en-US', {
                        month: 'long',
                        year: 'numeric',
                      })}
                  </span>
                </div>
                <div className="flex flex-col">
                  <span>{t('languages')}</span>
                  <div className="font-bold">
                    {user?.languages?.length
                      ? JSON.parse(user?.languages).map((l: string) => `${l}, `)
                      : '-'}
                  </div>
                </div>
                <div className="flex flex-col">
                  <span>{t('strengths')}</span>
                  <div className="flex, font-bold">
                    {user?.skills?.length
                      ? JSON.parse(user?.skills).map((s: string) => `${s}, `)
                      : '-'}
                  </div>
                </div>
              </div>
              {currentUser?.id !== user?.id && user?.isBlocked === false && (
                <div className="flex justify-between items-center py-4 pt-6 ">
                  {!user?.isNetwork && (
                    <button className="primary-btn flex gap-x-2" onClick={addContactHandler}>
                      <img src="/images/icons/business/add_member_white.svg" className="h-5 w-5" />{' '}
                      {t('Add')}
                    </button>
                  )}

                  <button
                    className="primary-btn flex gap-x-2 justify-center items-center"
                    onClick={chatButtonHandler}
                  >
                    <img src="/images/icons/chat.svg" className="h-5 w-5" /> {t('Chat')}
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="flex-col w-full sm:w-2/3 px-4 pt-4 sm:pt-12 space-y-8">
            <div className="hidden sm:flex text-2xl sm:text-5xl font-bold">
              {' '}
              {t('Thanks_passing_by')}
            </div>
            <div className="text-xl font-bold flex">
              {' '}
              {t('About')} {''}
              {user?.firstName}
              <div className="flex my-auto">
                <Dropdown
                  arrowIcon={false}
                  inline={true}
                  placement="bottom-end"
                  label={
                    <div className=" hover:text-deehiy flex ml-2 text-gray-400 text-sm sm:text-lg gap-3 justify-center items-center">
                      <BsFillShareFill className="my-auto w-4 h-4 sm:w-4 sm:h-4" />
                      <span className="hidden sm:flex">{t('share')}</span>{' '}
                    </div>
                  }
                >
                  <Dropdown.Header>
                    <Share type="member" id={user?.id} />
                  </Dropdown.Header>
                </Dropdown>
              </div>
            </div>
            <div className="text-sm whitespace-pre-wrap text-justify">
              <ReadMore max={800}>{user?.description ? user?.description : ''}</ReadMore>
            </div>

            {filteredProperties?.length > 0 && (
              <div className="pb-6">
                <div className="text-xl font-bold pb-4">
                  {t('user_listings', { name: user?.firstName })}
                </div>

                <div className="flex flex-wrap gap-3">
                  {visibleProperties.map((property: Property, index: number) => (
                    <PropertyCard property={property} key={index} />
                  ))}
                </div>

                {showCount < filteredProperties.length && filteredProperties.length > 3 && (
                  <div className="flex justify-end font-bold text-deehiy text-xs pb-20 sm:pb-8 sm:my-4">
                    <button
                      onClick={handleShowMore}
                      className=" justify-center items-center flex space-x-2"
                    >
                      <div className=" hover:animate-bounce text-deehiy rounded-full border border-deehiy p-1">
                        <FiChevronDown className="w-5 h-5" />
                      </div>
                      <span>{t('show more')}</span>
                    </button>
                  </div>
                )}
              </div>
            )}

            <hr className="hidden sm:flex border border-deehiy" />
            <div className="text-xl font-bold">{t('user_posts', { name: user?.firstName })}</div>
            {posts && posts.length > 0 ? (
              <div className="width-overlay-layout">
                <Feed posts={posts} />
              </div>
            ) : (
              <>{t('no_posts_yet', { name: user?.firstName })}</>
            )}
          </div>
        </div>
      </div>
      <ToastContainer transition={Slide} />
    </>
  );
};

export default AgentPage;
