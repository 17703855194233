import React, { createContext, useContext, useReducer } from 'react';
import { Hunt, HuntContextType } from '../types/types';
import { SET_HUNTS, SET_CURRENT_HUNT } from '../actions/actions';
import { huntReducer } from '../reducers/huntReducer';
import {
  createHuntService,
  getHuntsByUserIdService,
  updateHuntService,
  getHuntByIdService,
  deleteHuntService,
} from '../services/huntService';
import { initialHunt } from '../initialStates/initialStates';
import { useAuth } from './auth';
// import { useInternationalization } from './internationalization';

const initialHuntContext = {
  hunts: [],
  currentHunt: initialHunt,
  setCurrentHuntContext: () => {},
  setHuntsContext: () => {},
  fetchHuntByIdContext: async () => {},
  fetchHuntsByUserIdContext: async () => {},
  updateHuntContext: async () => {},
  createHuntContext: async () => '',
  deleteHuntContext: async () => '',
};

export const HuntContext = createContext<HuntContextType>(initialHuntContext);

export const HuntProvider = ({ children }: any) => {
  const [{ hunts, currentHunt }, dispatch] = useReducer(huntReducer, initialHuntContext);
  // const { currency } = useInternationalization();

  const { currentUser } = useAuth();
  // set hunts
  const setHuntsContext = (updatedHunts: Hunt[]) => {
    dispatch({ type: SET_HUNTS, payload: updatedHunts });
  };

  // set current hunt
  const setCurrentHuntContext = (updatedHunt: Hunt) => {
    dispatch({ type: SET_CURRENT_HUNT, payload: updatedHunt });
  };

  //getHuntById
  const fetchHuntByIdContext = async (id: number) => {
    try {
      const fetchedHunt = await getHuntByIdService(id);
      console.log(fetchedHunt.data);

      return fetchedHunt.data;
    } catch (err: any) {
      console.log(err);
      throw new Error(err.response.data);
    }
  };

  // create hunt
  const createHuntContext = async (editedHunt: Hunt) => {
    try {
      const res = await createHuntService(editedHunt);

      return res.data;
    } catch (err: any) {
      console.log(err);
      throw new Error(err.response.data);
    }
  };

  // delete hunt
  const deleteHuntContext = async (id: number) => {
    try {
      await deleteHuntService(id);

      return 'success';
    } catch (err: any) {
      console.log(err);
      throw new Error(err.response.data);
    }
  };

  // update hunt
  const updateHuntContext = async (editedParams: any) => {
    try {
      const updatedHunt = await updateHuntService(editedParams);
      // setCurrentHunt(updateHunt);
      return updatedHunt;
    } catch (err: any) {
      console.log(err);
      throw new Error(err.response.data);
    }
  };

  const fetchHuntsByUserIdContext = async () => {
    try {
      const fetchedHunts = await getHuntsByUserIdService(currentUser?.id);

      console.log(fetchedHunts);

      dispatch({ type: SET_HUNTS, payload: fetchedHunts.data });
    } catch (err: any) {
      console.log(err);
      throw new Error(err.response.data);
    }
  };

  const value = {
    hunts,
    currentHunt,
    setCurrentHuntContext,
    setHuntsContext,
    fetchHuntsByUserIdContext,
    createHuntContext,
    deleteHuntContext,
    updateHuntContext,
    fetchHuntByIdContext,
  };

  return <HuntContext.Provider value={value}>{children}</HuntContext.Provider>;
};

export function useHunt() {
  const context = useContext(HuntContext);

  return context;
}
