import React from 'react';
import { Navigation } from '../main/navigation/Navigation';
import { Outlet } from 'react-router-dom';
import BackToTopButton from '../main/navigation/BackToTopButton';
// import Footer from './Footer';
import MobileNavigation from '../main/navigation/MobileNavigation';

// const links = [
//   { to: '/users/managelistings', label: 'My Listings' },
//   { to: '/users/profile', label: 'Profile' },
// ];

const DashboardLayout = ({ children }: any) => {
  return (
    <div className="dark:bg-slate-900 flex flex-col min-h-screen  ">
      <header>
        <div className="hidden sm:block">
          <Navigation />
        </div>
        <div className="block sm:hidden">
          <MobileNavigation />
        </div>
      </header>

      <main className="mx-auto pt-0 sm:pt-16 pb-2 h-full w-full px-2 sm:px-24">
        <Outlet />
        {children}
      </main>
      <BackToTopButton />
      {/* <Footer /> */}
    </div>
  );
};

export default DashboardLayout;
