import React, { useState } from 'react';
import PropertyCard from '../main/PropertyCard';
import Loader from '../main/Loader';
import { initialProperty } from '../../initialStates/initialStates';
import { useProperty } from '../../contexts/property';
import { useInternationalization } from '../../contexts/internationalization';

const CircularMarker = ({ id, lat, lng, setCenter }: any) => {
  const { fetchPublicPropertyById } = useProperty();
  const { currency } = useInternationalization();

  const [isOpen, setIsOpen] = useState(false);
  const [place, setPlace] = useState(initialProperty);
  const [isLoading, setIsLoading] = useState(false);

  const isMobile = window.innerWidth <= 768;

  const handleFetchProperty = () => {
    setIsLoading(true);
    fetchPublicPropertyById(id, currency)
      .then((data) => {
        setPlace(data.property);
      })
      .catch((error) => {
        console.error('Failed to fetch property data:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleMarkerClick = () => {
    if (isMobile) {
      setIsOpen(!isOpen);
      setCenter({ lat, lng });
      if (!isOpen) {
        handleFetchProperty();
      }
    }
  };

  const handleMarkerHover = () => {
    if (!isMobile && !isOpen) {
      setIsOpen(true);
      handleFetchProperty();
    }
  };

  const handleClose = () => setIsOpen(false);

  return (
    <div
      onClick={handleMarkerClick}
      onMouseEnter={handleMarkerHover}
      onMouseLeave={handleClose}
      // className="group flex w-8 h-8 rounded-full items-center-justify-center"
      className="group  flex items-center justify-center h-5"

      // className="  group  flex items-center absolute transform -translate-x-1/2 -translate-y-1/2 justify-center w-6 h-6 border border-red-600"
    >
      {/* Display the pin header */}
      {/* <img src="/images/icons/pin-card.svg" alt="Marker Pin" /> */}
      <div className="absolute w-5 h-5 group-hover:hidden flex rounded-full bg-deehiy z-10"></div>
      {/* Desktop info window */}
      <div className={`hidden mx-auto group-hover:block w-[250px] z-50 absolute  `}>
        {isLoading ? (
          <div className="items-center justify-center flex">
            <Loader />
          </div>
        ) : (
          <PropertyCard property={place} />
        )}
      </div>

      {/* Mobile info window */}
      <div
        className={`${
          isOpen ? 'relative' : 'hidden'
        } sm:hidden flex flex-col w-[300px] ml-[-150px] mt-12 z-20`}
      >
        <div className="absolute z-30 top-0 flex w-full p-2 justify-end">
          <button className="px-2 rounded py-1 bg-white absolute" onClick={handleClose}>
            Close X
          </button>
        </div>
        {isLoading ? <Loader /> : <PropertyCard property={place} />}
      </div>
    </div>
  );
};

export default CircularMarker;
