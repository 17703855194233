import React, { useState, useEffect } from 'react';
import { User } from '../../types/types';
import { adjustImageSize } from '../../utils/adjustImageSize';
import { Avatar } from '../../pages/ListingPage';

const UserSuggestionInput = ({ userList, setSelectedUser }: any) => {
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState<any>([]);

  const handleInputChange = (event: any) => {
    const value = event.target.value;
    setInputValue(value);

    // Filter user list based on input value
    const filteredSuggestions = userList.filter((user: any) => {
      const lowerCaseInput = value.toLowerCase();

      // Check if user.firstName, user.lastName, and user.email are not null or undefined
      const firstNameMatch =
        user.firstName && user.firstName.toLowerCase().indexOf(lowerCaseInput) !== -1;

      const lastNameMatch =
        user.lastName && user.lastName.toLowerCase().indexOf(lowerCaseInput) !== -1;

      const emailMatch = user.email && user.email.toLowerCase().indexOf(lowerCaseInput) !== -1;

      // Return true if any of the properties match the input (and are not null)
      return firstNameMatch || lastNameMatch || emailMatch;
    });

    // Display up to 5 suggestions
    setSuggestions(filteredSuggestions.slice(0, 5));
  };

  const handleSuggestionClick = (suggestion: any) => {
    setInputValue(`${suggestion.firstName} ${suggestion.lastName}`); // Set first name as input value
    setSelectedUser(suggestion);
    setSuggestions([]); // Clear suggestions after selection
  };

  return (
    <div className="py-4">
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        placeholder="Assign listing to a team member"
        className="relative placeholder:text-xs w-full flex p-3 border rounded border-deehiy focus:ring-deehiy outline-0 outline-none focus:outline-0"
      />
      <ul className="absolute z-20 bg-white shadow-md border border-gray-200 rounded-b-xl ">
        {suggestions.map((suggestion: User, index: number) => (
          <li
            className=" py-3 px-2 text-gray-500 hover:text-gray-700 font-bold cursor-pointer hover:bg-deehiy-veryLight"
            key={index}
            onClick={() => handleSuggestionClick(suggestion)}
          >
            {suggestion.firstName} {suggestion.lastName} ({suggestion.email})
          </li>
        ))}
      </ul>
    </div>
  );
};

const AssignListing = ({ handleAssignment, property, users, setShowModal }: any) => {
  const [userList, setUserList] = useState(users);
  const [selectedUser, setSelectedUser] = useState(property.assignment || property.user);

  const handleDone = () => {
    if (selectedUser.id) {
      handleAssignment(property.propertyId, selectedUser.id);
    }

    setShowModal(false);
  };

  useEffect(() => {
    setUserList(users);
  }, [users]);

  return (
    <div className="w-full sm:w-[550px] flex flex-col">
      <div className="flex w-full justify-center border-transparent text-base text-center font-bold border pb-4 border-b-deehiy">
        Assign listing
      </div>
      <div className="flex-col space-y-3 px-12 py-6">
        <div className="flex">
          <img
            alt="listing"
            src={
              property.images && property.images?.length > 0
                ? adjustImageSize(property.images[0].url, 250)
                : process.env.REACT_APP_DEFAULT_LISTING_URL
            }
            className="w-24 sm:w-48"
          />
          <div className="flex items-center pl-8 justify-center text-sm font-bold">
            {property.propertyTitle}
          </div>
        </div>

        <UserSuggestionInput userList={userList} setSelectedUser={setSelectedUser} />

        <div className="flex w-full text-sm text-gray-700 pt-2">Currently assigned to:</div>
        <div className="flex justify-between">
          <div className="flex items-center justify-center space-x-4">
            <Avatar isSales={selectedUser.isSalesRep} image={selectedUser.image} />
            <div className="flex flex-col">
              <span className="font-bold">
                {selectedUser.firstName + ' ' + selectedUser.lastName}
              </span>
              <span className="">
                {selectedUser?.city && selectedUser?.country && (
                  <span>{`${selectedUser.city}, ${selectedUser.country}`}</span>
                )}
                {selectedUser?.city && !selectedUser?.country && <span>{selectedUser.city}</span>}
                {!selectedUser?.city && selectedUser?.country && (
                  <span>{selectedUser.country}</span>
                )}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-center">
            <button onClick={handleDone} className="primary-btn">
              Done
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignListing;
