import React from 'react';
import { Link } from 'react-router-dom';
import { BsChevronUp, BsChevronDown, BsDot } from 'react-icons/bs';
import { useInternationalization } from '../../contexts/internationalization';
import CurrencyDropdown from '../main/navigation/CurrencyDropdown';
import Modal from '../listing/Modal';
import { useTranslation } from 'react-i18next';
import TranslationDropdown from '../main/TranslationDropdown';

const SmallFooter = ({ setFooterIsOpen, isFooterOpen }: any) => {
  const { t } = useTranslation();

  const Btn = ({ setShowModal }: any) => {
    const { currency } = useInternationalization();

    const handleClick = async () => {
      setShowModal(true);

      setFooterIsOpen(false);
    };

    return (
      <button onClick={handleClick} className="hidden sm:flex cursor-pointer px-2">
        {currency}
      </button>
    );
  };

  return (
    <footer className="hidden sm:flex w-full text-xs px-2 sm:px-16 items-center bg-white border border-t-deehiy h-10 justify-between">
      <div className="grid grid-flow-col auto-cols-max gap-4 sm:gap-6 ">
        <span className="mx-auto ">&copy; 2024 Deehiy LLC</span>
        <BsDot className="h-4 mx-auto" />
        <Link className="mx-auto" to="/privacypolicy">
          {t('Privacy')}
        </Link>
        <BsDot className="h-4 mx-auto" />
        <Link className="mx-auto" to="/termsofservice">
          {t('Terms')}
        </Link>
      </div>

      <div className="grid grid-flow-col auto-cols-max items-center gap-2 sm:gap-6">
        <div className="hidden sm:flex">
          <Modal Btn={Btn}>
            <CurrencyDropdown />
          </Modal>
        </div>
        <TranslationDropdown />
        <button
          className="flex hover:text-black gap-2 "
          onClick={() => setFooterIsOpen(!isFooterOpen)}
        >
          <div className="hidden sm:block">{t('Support & Resources')}</div>
          {isFooterOpen ? (
            <BsChevronDown className="w-4 h-4 text-gray-500" />
          ) : (
            <BsChevronUp className="w-4 h-4 text-gray-500" />
          )}
        </button>
      </div>
    </footer>
  );
};

const BigFooter = () => {
  const { t } = useTranslation();

  const appToken = localStorage.getItem('@App:token');
  return (
    <div className=" flex w-full text-xs justify-center bg-white">
      <div className="container px-4 sm:px-20 py-6">
        <div className="grid grid-cols-3">
          <div className="mb-6">
            <h5 className="uppercase font-bold mb-2.5">{t('Company')}</h5>

            <ul className="list-none mb-0 space-y-2">
              <li>
                <Link to="/aboutus">{t('About us')}</Link>{' '}
              </li>
              <li>
                <Link to="/news">{t('News')}</Link>{' '}
              </li>
              <li>
                <Link to="/listing">{t('Listing')}</Link>{' '}
              </li>
            </ul>
          </div>
          <div className="mb-6">
            <h5 className="uppercase font-bold mb-2.5">{t('Features')}</h5>

            <ul className="list-none mb-0 space-y-2">
              <li>
                <Link to="/forms">{t('Forms')}</Link>{' '}
              </li>
              <li>
                <Link to="/users/huntingboard">{t('Hunting board')}</Link>{' '}
              </li>
              <li>
                <Link to="/network">{t('Network')}</Link>{' '}
              </li>
              <li>
                <Link
                  to=""
                  onClick={() =>
                    window.open(
                      `${process.env.REACT_APP_MESSENGER_URL}?token=${appToken}`,
                      '_blank'
                    )
                  }
                >
                  {t('Messenger')}
                </Link>{' '}
              </li>
              <li>
                <Link to="/help">{t('Help Center')}</Link>{' '}
              </li>
            </ul>
          </div>
          <div className="mb-6">
            <h5 className="uppercase font-bold mb-2.5">{t('Policies & Guidelines')}</h5>

            <ul className="list-none mb-0 space-y-2">
              <li>
                <Link to="/contentpolicy">{t('Content Policy')}</Link>{' '}
              </li>
              <li>
                <Link to="/customurlpolicy">{t('Custom URL policy')}</Link>{' '}
              </li>
              <li>
                <Link to="/listingguidelines">{t('Listing Guidelines')}</Link>{' '}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

const Footer = () => {
  const [isFooterOpen, setFooterIsOpen] = React.useState(false);

  return (
    <div className="mt-auto w-full bottom-0 fixed z-20">
      <SmallFooter setFooterIsOpen={setFooterIsOpen} isFooterOpen={isFooterOpen} />

      <div
        className={
          'fixed overflow-hidden z-50 bg-gray-900 bg-opacity-20 inset-0 transform ease-in-out ' +
          (isFooterOpen
            ? ' transition-opacity opacity-100 duration-200 translate-y-0  '
            : ' transition-all delay-200 opacity-0 translate-y-full  ')
        }
      >
        <div
          className={
            ' rounded-t-2xl mr-20  max-h-lg absolute bottom-0  w-full shadow-xl delay-400 duration-500 ease-in-out transition-all transform  ' +
            (isFooterOpen ? ' h-[210px]' : '  h-0 ')
          }
        >
          {/* <SmallFooter setFooterIsOpen={setFooterIsOpen} isFooterOpen={isFooterOpen} /> */}
          {isFooterOpen && <BigFooter />}
        </div>
        <div
          className=" w-screen h-full cursor-pointer "
          onClick={() => {
            setFooterIsOpen(false);
          }}
        ></div>
      </div>
    </div>
  );
};

export default Footer;
