import { ArticleContent } from '../types/types';

export const articles: ArticleContent[] = [
  {
    id: 1010,
    title: 'Getting started on Deehiy',
    category: 'help',
    preview:
      'https://res.cloudinary.com/dpdnpljjb/image/upload/c_scale,w_700/v1686044570/help-center/getting_started_preview.jpg',
    image:
      'https://res.cloudinary.com/dpdnpljjb/image/upload/c_scale,q_100,w_1200/v1686028841/help-center/getting-started.png',
    text: `
        <div>
        <p>Whether you want to buy or sell a property anywhere, here’s how Deehiy works for you - as a buyer, seller or agent. Let’s start with the basics and go from there. Welcome to the community!</p>
        <br />
        <b>The Deehiy Community</b>
        <p>Deehiy is the world’s first community based real estate platform to buy and sell property with ease, anywhere. We take the quality of our community very seriously and strive towards offering only available and up-to-date listings on our platform. As the community is the core of Deehiy, it means our users navigate through Deehiy with respect, honesty and integrity.</p>
        <br />
    
        <b>Getting set up</b>
        <p>Creating an account is free and takes less than 30 seconds! We’ll need to know only the very basic details and then you’ll be able to update any additional information you wish through your dashboard. But remember, we are a community based platform and the more transparent you are, the more likely are others to interact with you. 
        </p>
        <br />
    
        <b>Using Deehiy as a Buyer</b>
        <p>On our platform you can browse a world filled with up-to-date and available properties and connect directly with other users to start a conversation through our very own app available for iOs and Android. You are not required to verify your identity to use our platform, but we recommend completing your profile to instill trust for other users you may interact with.
        </p>
        <br />
    
        <b>Using Deehiy as a Seller</b>
        <p>Using Deehiy is free and comes with no hidden surprises. Create an account within seconds and start listing your property within a few minutes. Once your property is submitted, it is pending approval from our Hot Desk prior to being published. You will be notified once it’s published and can start boosting it to increase your exposure. As a seller you have the opportunity to generate direct leads and sell your property without an agent at ease. If your property matches any active hunts, you will be notified and can get started on closing the deal without any agents involved, - saving the big bucks for yourself.</p>
        <br />
    
        <b>Using Deehiy as an Agent</b>
        <p>If you are using Deehiy as an agent you should dedicate your profile as an agent in your profile settings. Simply go to your dashboard, edit your profile and enable the toggle on the bottom. You will then be visible on Deehiy as an agent and can be appointed for your services. You will benefit even more if you become a <a style="color:blue" href="https://deehiy.com">Verified Sales Representative</a> of Deehiy. Check it out now!
        </p>
        <br />
        </div>`,
    keywords: '',
    date: 'May 2023',
  },
  {
    id: 1011,
    title: 'Benefits of sharing your Deehiy profile',
    category: 'help',
    preview:
      'https://res.cloudinary.com/dpdnpljjb/image/upload/v1686029220/help-center/sharing-profile.png',
    image:
      'https://res.cloudinary.com/dpdnpljjb/image/upload/v1686029220/help-center/sharing-profile.png',
    text: `
    <div>
    <p>Share your profile with potential clients and partners and allow them to learn about you and discover all your listings. You will increase your chances to be appointed as an Agent. Here is an example of how you can post on your social media platforms.</p>
    <p>“If you are looking for property to buy I am here to help. Check out my personal profile on Deehiy and learn about my experience and discover my beautiful property listings.”</p>
    <p>Developing features include being reviewed by others. Your reviews will define the power of your profile. The more power you have, the higher your listings will be ranked on Deehiy. Try to get on the top to get the most leads. Start now by <a style="color:blue" href="https://deehiy.com/users/profile">completing your profile</a>.</p>
    </div>`,
    keywords: '',
    date: 'May 2023',
  },
  {
    id: 1012,
    title: 'Benefits of sharing your Deehiy listing',
    category: 'help',
    preview:
      'https://res.cloudinary.com/dpdnpljjb/image/upload/c_scale,w_700/v1686028841/help-center/getting-started.png',
    image:
      'https://res.cloudinary.com/dpdnpljjb/image/upload/v1686028639/help-center/sharing-your-listing.png',
    text: `
    <div>
    <p>Share your listing on your social media and invite other users to explore more about you and your portfolio of listings by that increase your chances for more leads. Here an example of how you can create a post:</p>
    <i>“Check out this stunning property for sale in Pattaya and many more of my listings on my Deehiy profile”</i>
    </div>`,
    keywords: '',
    date: 'June 2023',
  },
  {
    id: 1013,
    title: 'Accessing your account',
    category: 'help',
    preview:
      'https://res.cloudinary.com/dpdnpljjb/image/upload/c_scale,w_700/v1686045062/help-center/accessing_your_account-min_zx8hlp.jpg',
    image:
      'https://res.cloudinary.com/dpdnpljjb/image/upload/c_scale,w_700/v1686045062/help-center/accessing_your_account-min_zx8hlp.jpg',
    text: `
    <div>
    <p>Do you need help getting into your account? Want to update your profile or edit your account? No problem! Here you’ll find info on how to reset your password, upload a new profile pic, or change your email address.</p>
    <br />
    <b>Managing your personal info</b>
    <p>Once you’re logged in, go to your <b>Personal info</b> to edit information like your email address or phone number. Note that some information will be public (like your picture and name), but we won’t share information like your government ID, phone number, or email address.</p>
    <p>If you are an agent, you will find it useful to <b>dedicate</b> your <b>profile as an agent</b> by activating the toggle on the bottom of your Personal info. Your profile will then appear on a world map of agents available and you are able to be appointed for your services.</p>
    </div>`,
    keywords: '',
    date: 'June 2023',
  },
];
