// import { GiHamburgerMenu } from 'react-icons/gi';
import { User } from '../../../types/types';

interface UserDropdownProps {
  currentUser: User | null;
}

// const UserDropdown = ({ currentUser }: UserDropdownProps) => {
//   return (
//     <div className="w-20 py-2 px-2 space-x-2 flex justify-between rounded-full border border-deehiy">
//       <GiHamburgerMenu className="w-7 h-7 text-deehiy pl-1" />

//       {currentUser?.image ? (
//         <img src={currentUser.image} alt="prof" className="w-7 h-7 rounded-full" />
//       ) : (
//         <HiUser className="w-7 h-7 bg-deehiy text-white rounded-full p-1 border-deehiy border hover:animate-pulse" />
//       )}
//     </div>
//   );
// };

const UserDropdown = ({ currentUser }: UserDropdownProps) => {
  return (
    <div className="block w-9 h-9 rounded-full">
      {currentUser?.image ? (
        <img src={currentUser.image} alt="prof" className="w-9 h-9 rounded-full" />
      ) : (
        <img
          src="/images/icons/not-registered.svg"
          className="w-9 h9  bg-deehiy text-white rounded-full border-deehiy "
        />
      )}
    </div>
  );
};

export default UserDropdown;
