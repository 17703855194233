import React from 'react';

const Loader = () => {
  return (
    <div className="flex items-center justify-center">
      <div className="flex items-center justify-center ">
        <div className="w-8 h-8 border-4 border-gray-200 shadow-deehiy border-b-deehiy rounded-full animate-spin"></div>
      </div>
    </div>
  );
};

export default Loader;
