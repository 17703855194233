import { CONDO, HOUSE, LAND, TOWNHOUSE } from '../constants/constant';
import { ParamsPerType } from '../types/types';

// sort by SVG
import { ReactComponent as TrendingSVG } from '../components/assets/trending.svg';
import { ReactComponent as JustListedSVG } from '../components/assets/just-listed.svg';

export const TrendAndJustListedList = [
  {
    category: 'trending',
    title: 'Trending',
    selected: false,
    SVG: TrendingSVG,
  },
  {
    category: 'justListed',
    title: 'Just listed',
    // iconPath: '/images/icons/just-listed.svg',
    selected: false,
    SVG: JustListedSVG,
    // Icon: MdOutlineWavingHand,
  },
];

export const sortList = [
  { category: 'lowestPrice', title: 'lowest price', selected: false },
  {
    category: 'highestPrice',
    title: 'highest price',
    selected: false,
  },
  {
    category: 'mostPopular',
    title: 'most popular',
    selected: false,
  },
  {
    category: 'newest',
    title: 'newest',
    selected: false,
  },
];
// features SVG
import { ReactComponent as SwimmingPoolSVG } from '../components/assets/swimming-pool.svg';
import { ReactComponent as SharedPoolSVG } from '../components/assets/shared-pool.svg';
import { ReactComponent as BalconySVG } from '../components/assets/balcony.svg';
import { ReactComponent as WaterfrontSVG } from '../components/assets/waterfront.svg';
import { ReactComponent as PetFriendlySVG } from '../components/assets/pet-friendly.svg';
import { ReactComponent as SeaViewSVG } from '../components/assets/sea-view.svg';
import { ReactComponent as ParkingGarageSVG } from '../components/assets/parking-garage.svg';
import { ReactComponent as TerraceSVG } from '../components/assets/terrace.svg';
import { ReactComponent as GardenSVG } from '../components/assets/garden.svg';
import { ReactComponent as GymSVG } from '../components/assets/gym.svg';
import { ReactComponent as FireplaceSVG } from '../components/assets/fireplace.svg';
import { ReactComponent as SaunaSVG } from '../components/assets/sauna.svg';
import { ReactComponent as HomeTheaterSVG } from '../components/assets/home-theater.svg';
import { ReactComponent as TennisSVG } from '../components/assets/tennis.svg';
import { ReactComponent as PlaygroundSVG } from '../components/assets/playground.svg';
import { ReactComponent as Security24hSVG } from '../components/assets/24h-security.svg';
import { ReactComponent as AirConditioningSVG } from '../components/assets/air-conditioning.svg';
import { ReactComponent as AlarmSystemSVG } from '../components/assets/alarm-system.svg';
import { ReactComponent as BathtubSVG } from '../components/assets/bathtub.svg';
import { ReactComponent as BidetSVG } from '../components/assets/bidet.svg';
import { ReactComponent as COAlarmSVG } from '../components/assets/carbon-monoxide-alarm.svg';
import { ReactComponent as CeilingFanSVG } from '../components/assets/ceiling-fan.svg';
import { ReactComponent as CityViewSVG } from '../components/assets/city-view.svg';
import { ReactComponent as DryerSVG } from '../components/assets/dryer.svg';
import { ReactComponent as FireAlarmSVG } from '../components/assets/fire-alarm.svg';
import { ReactComponent as FireDoorSVG } from '../components/assets/fire-safety-door.svg';
import { ReactComponent as FloorHeatingSVG } from '../components/assets/floor-heating.svg';
import { ReactComponent as GatedCommunitySVG } from '../components/assets/gated-community.svg';
import { ReactComponent as HeaterSVG } from '../components/assets/heater.svg';
import { ReactComponent as HomeAutomationSVG } from '../components/assets/home-automation.svg';
import { ReactComponent as LakeViewSVG } from '../components/assets/lake-view.svg';
import { ReactComponent as MountainViewSVG } from '../components/assets/mountain-view.svg';
import { ReactComponent as PianoSVG } from '../components/assets/piano.svg';
import { ReactComponent as PingPongSVG } from '../components/assets/ping-pong.svg';
import { ReactComponent as PoolTableSVG } from '../components/assets/pool-table.svg';
import { ReactComponent as RainShowerSVG } from '../components/assets/rain-shower.svg';
import { ReactComponent as Reception24_7SVG } from '../components/assets/reception-24-7.svg';
import { ReactComponent as SolidEntranceDoorSVG } from '../components/assets/solid-entrance-door.svg';
import { ReactComponent as SoundSystemSVG } from '../components/assets/sound-system.svg';
import { ReactComponent as SprinklerSystemSVG } from '../components/assets/sprinkler-system.svg';
import { ReactComponent as TVSVG } from '../components/assets/TV.svg';
import { ReactComponent as WashingMachineSVG } from '../components/assets/washing-machine.svg';
import { ReactComponent as WaterBoilerSVG } from '../components/assets/water-boiler.svg';
import { ReactComponent as SolarSystemSVG } from '../components/assets/solar-system.svg';
import { ReactComponent as StorageSVG } from '../components/assets/storage.svg';
import { ReactComponent as KidsPoolSVG } from '../components/assets/kids-pool.svg';
import { ReactComponent as HammamSVG } from '../components/assets/hammam.svg';
import { ReactComponent as WineCellarSVG } from '../components/assets/wine-cellar.svg';
import { ReactComponent as LibrarySVG } from '../components/assets/library.svg';
import { ReactComponent as BBQSVG } from '../components/assets/BBQ.svg';
import { ReactComponent as JacuzziSVG } from '../components/assets/jacuzzi.svg';

// interests
import { ReactComponent as PropertyInvestmentSVG } from '../components/assets/interests-icons/property-investment.svg';
import { ReactComponent as MarketTrendsSVG } from '../components/assets/interests-icons/market-trends.svg';
import { ReactComponent as PropertyDevelopmentSVG } from '../components/assets/interests-icons/property-development.svg';
import { ReactComponent as InteriorDesignSVG } from '../components/assets/interests-icons/interior-design.svg';
import { ReactComponent as ArchitectureSVG } from '../components/assets/interests-icons/architecture.svg';
import { ReactComponent as NetworkingSVG } from '../components/assets/interests-icons/networking.svg';
import { ReactComponent as FinancialLiteracySVG } from '../components/assets/interests-icons/financial-literacy.svg';
import { ReactComponent as LegalKnowledgeSVG } from '../components/assets/interests-icons/legal-knowledge.svg';
import { ReactComponent as PropertyTechnologySVG } from '../components/assets/interests-icons/property-technology.svg';
import { ReactComponent as NegotiationSkillsSVG } from '../components/assets/interests-icons/negotiation.svg';
import { ReactComponent as ClientServiceSVG } from '../components/assets/interests-icons/client-service.svg';
import { ReactComponent as PropertyManagementSVG } from '../components/assets/interests-icons/property-management.svg';
import { ReactComponent as CommunityInvolvementSVG } from '../components/assets/interests-icons/community-involvement.svg';
import { ReactComponent as EnvironmentalAwarenessSVG } from '../components/assets/interests-icons/environmental-awareness.svg';
import { ReactComponent as MarketingAdvertisingSVG } from '../components/assets/interests-icons/marketing-advertising.svg';
import { ReactComponent as ResearchSkillsSVG } from '../components/assets/interests-icons/research-skills.svg';
import { ReactComponent as CreativeProblemSolvingSVG } from '../components/assets/interests-icons/creative-problem-solving.svg';
import { ReactComponent as DueDiligenceSVG } from '../components/assets/interests-icons/due-diligence.svg';
import { ReactComponent as FinancialPlanningSVG } from '../components/assets/interests-icons/financial-planning.svg';
import { ReactComponent as EntrepreneurshipSVG } from '../components/assets/interests-icons/entrepreneurship.svg';

// skills
import { ReactComponent as ContractLawSVG } from '../components/assets/skills-icons/contracting-law.svg';
import { ReactComponent as DroneSVG } from '../components/assets/skills-icons/drone.svg';
import { ReactComponent as DueDiligenceSkillSVG } from '../components/assets/skills-icons/due-diligence-2.svg';
import { ReactComponent as MarketInsightSVG } from '../components/assets/skills-icons/market-insight.svg';
import { ReactComponent as NegotiationSVG } from '../components/assets/skills-icons/negotiation-2.svg';
import { ReactComponent as ScoutingSVG } from '../components/assets/skills-icons/scouting.svg';
import { ReactComponent as PhotographySVG } from '../components/assets/skills-icons/photography.svg';
import { ReactComponent as VideographySVG } from '../components/assets/skills-icons/videography.svg';
import { ReactComponent as StagingSVG } from '../components/assets/skills-icons/staging.svg';

export const skillsList = [
  {
    id: 1,
    category: 'all',
    title: 'Contract law',
    selected: false,
    SVG: ContractLawSVG,
  },
  {
    id: 2,
    category: 'all',
    title: 'Drone',
    selected: false,
    SVG: DroneSVG,
  },
  {
    id: 3,
    category: 'all',
    title: 'Due Diligence',
    selected: false,
    SVG: DueDiligenceSkillSVG,
  },
  {
    id: 4,
    category: 'all',
    title: 'Market Insights',
    selected: false,
    SVG: MarketInsightSVG,
  },
  {
    id: 5,
    category: 'all',
    title: 'Negotiation',
    selected: false,
    SVG: NegotiationSVG,
  },
  {
    id: 6,
    category: 'all',
    title: 'Photography',
    selected: false,
    SVG: PhotographySVG,
  },
  {
    id: 7,
    category: 'all',
    title: 'Scouting',
    selected: false,
    SVG: ScoutingSVG,
  },
  {
    id: 8,
    category: 'all',
    title: 'Staging',
    selected: false,
    SVG: StagingSVG,
  },
  {
    id: 9,
    category: 'all',
    title: 'Videography',
    selected: false,
    SVG: VideographySVG,
  },
];

export const interestsList = [
  {
    id: 1,
    category: 'all',
    title: 'Property investment',
    selected: false,
    SVG: PropertyInvestmentSVG,
  },
  { id: 2, category: 'all', title: 'Market trends', selected: false, SVG: MarketTrendsSVG },
  {
    id: 3,
    category: 'all',
    title: 'Property development',
    selected: false,
    SVG: PropertyDevelopmentSVG,
  },
  { id: 4, category: 'all', title: 'Interior design', selected: false, SVG: InteriorDesignSVG },
  { id: 5, category: 'all', title: 'Architecture', selected: false, SVG: ArchitectureSVG },
  { id: 6, category: 'all', title: 'Networking', selected: false, SVG: NetworkingSVG },
  {
    id: 7,
    category: 'all',
    title: 'Financial literacy',
    selected: false,
    SVG: FinancialLiteracySVG,
  },
  { id: 8, category: 'all', title: 'Legal knowledge', selected: false, SVG: LegalKnowledgeSVG },
  {
    id: 9,
    category: 'all',
    title: 'Property technology',
    selected: false,
    SVG: PropertyTechnologySVG,
  },
  {
    id: 10,
    category: 'all',
    title: 'Negotiation skills',
    selected: false,
    SVG: NegotiationSkillsSVG,
  },
  { id: 11, category: 'all', title: 'Client service', selected: false, SVG: ClientServiceSVG },
  {
    id: 12,
    category: 'all',
    title: 'Property management',
    selected: false,
    SVG: PropertyManagementSVG,
  },
  {
    id: 13,
    category: 'all',
    title: 'Community involvement',
    selected: false,
    SVG: CommunityInvolvementSVG,
  },
  {
    id: 14,
    category: 'all',
    title: 'Environmental awareness',
    selected: false,
    SVG: EnvironmentalAwarenessSVG,
  },
  {
    id: 15,
    category: 'all',
    title: 'Marketing & advertising',
    selected: false,
    SVG: MarketingAdvertisingSVG,
  },
  { id: 16, category: 'all', title: 'Research skills', selected: false, SVG: ResearchSkillsSVG },
  {
    id: 17,
    category: 'all',
    title: 'Creative problem solving',
    selected: false,
    SVG: CreativeProblemSolvingSVG,
  },
  { id: 18, category: 'all', title: 'Due diligence', selected: false, SVG: DueDiligenceSVG },
  {
    id: 19,
    category: 'all',
    title: 'Financial planning',
    selected: false,
    SVG: FinancialPlanningSVG,
  },
  { id: 20, category: 'all', title: 'Entrepreneurship', selected: false, SVG: EntrepreneurshipSVG },
];

export const featuresList = [
  {
    category: 'basic',
    title: 'Air conditioning',
    // iconPath: '/images/icons/air-conditioning.svg',
    selected: false,
    SVG: AirConditioningSVG,
  },
  {
    category: 'security',
    title: 'Alarm system',
    // iconPath: '/images/icons/alarm-system.svg',
    selected: false,
    SVG: AlarmSystemSVG,
  },
  {
    category: 'standoutAmenities',
    title: 'Bathtub',
    // iconPath: '/images/icons/bathtub.svg',
    selected: false,
    SVG: BathtubSVG,
  },
  {
    category: 'standoutAmenities',
    title: 'Bidet',
    // iconPath: '/images/icons/bidet.svg',
    selected: false,
    SVG: BidetSVG,
  },
  {
    category: 'standoutAmenities',
    title: 'Ceiling fan',
    // iconPath: '/images/icons/ceiling-fan.svg',
    selected: false,
    SVG: CeilingFanSVG,
  },
  {
    category: 'views',
    title: 'City view',
    // iconPath: '/images/icons/city-view.svg',
    selected: false,
    SVG: CityViewSVG,
  },
  {
    category: 'security',
    title: 'CO alarm',
    // iconPath: '/images/icons/carbon-monoxide-alarm.svg',
    selected: false,
    SVG: COAlarmSVG,
  },
  {
    category: 'basic',
    title: 'Balcony',
    // iconPath: '/images/icons/balcony.svg',
    selected: false,
    SVG: BalconySVG,
    // Icon: MdOutlinePool,
  },
  {
    category: 'standoutAmenities',
    title: 'Fireplace',
    // iconPath: '/images/icons/fireplace.svg',
    selected: false,
    SVG: FireplaceSVG,
    // Icon: BsTree,
  },
  {
    category: 'basic',
    title: 'Garage',
    // iconPath: '/images/icons/parking-garage.svg',
    selected: false,
    SVG: ParkingGarageSVG,
    // Icon: GiAtSea,
  },

  {
    category: 'basic',
    title: 'Garden',
    // iconPath: '/images/icons/garden.svg',
    selected: false,
    SVG: GardenSVG,
    // Icon: BsTree,
  },
  {
    category: 'standoutAmenities',
    title: 'Gym',
    // iconPath: '/images/icons/gym.svg',
    selected: false,
    SVG: GymSVG,
    // Icon: IoIosFitness,
  },
  {
    category: 'standoutAmenities',
    title: 'Home Theatre',
    // iconPath: '/images/icons/home-theater.svg',
    selected: false,
    SVG: HomeTheaterSVG,
    // Icon: GiTheaterCurtains,
  },
  {
    category: 'basic',
    title: 'Pet Friendly',
    // iconPath: '/images/icons/pet-friendly.svg',
    selected: false,
    SVG: PetFriendlySVG,
    // Icon: FaDog,
  },
  {
    category: 'basic',
    title: 'Private pool',
    // iconPath: '/images/icons/swimming-pool.svg',
    selected: false,
    SVG: SwimmingPoolSVG,
    // Icon: MdOutlinePool,
  },
  {
    category: 'standoutAmenities',
    title: 'Sauna',
    // iconPath: '/images/icons/sauna.svg',
    selected: false,
    SVG: SaunaSVG,
    // Icon: FaHotTub,
  },
  {
    category: 'views',
    title: 'Sea view',
    // iconPath: '/images/icons/sea-view.svg',
    selected: false,
    SVG: SeaViewSVG,
    // Icon: GiAtSea,
  },
  {
    category: 'basic',
    title: 'Shared pool',
    // iconPath: '/images/icons/shared-pool.svg',
    selected: false,
    SVG: SharedPoolSVG,
    // Icon: MdOutlinePool,
  },
  {
    category: 'standoutAmenities',
    title: 'Terrace',
    // iconPath: '/images/icons/terrace.svg',
    selected: false,
    SVG: TerraceSVG,
    // Icon: MdOutlineDeck,
  },
  {
    category: 'basic',
    title: 'TV',
    // iconPath: '/images/icons/TV.svg',
    selected: false,
    SVG: TVSVG,
  },
  {
    category: 'basic',
    title: 'Washing-machine',
    // iconPath: '/images/icons/washing-machine.svg',
    selected: false,
    SVG: WashingMachineSVG,
  },
  {
    category: 'standoutAmenities',
    title: 'Tennis',
    // iconPath: '/images/icons/tennis.svg',
    selected: false,
    SVG: TennisSVG,
    // Icon: MdSportsTennis,
  },
  {
    category: 'standoutAmenities',
    title: 'Playground',
    // iconPath: '/images/icons/playground.svg',
    selected: false,
    SVG: PlaygroundSVG,
    // Icon: TbMoodKid,
  },
  {
    category: 'views',
    title: 'Waterfront',
    // iconPath: '/images/icons/waterfront.svg',
    selected: false,
    SVG: WaterfrontSVG,
    // Icon: MdOutlineHouseboat,
  },

  {
    category: 'security',
    title: '24h security',
    // iconPath: '/images/icons/24h-security.svg',
    selected: false,
    SVG: Security24hSVG,
  },
  {
    category: 'security',
    title: 'Fire alarm',
    // iconPath: '/images/icons/fire-alarm.svg',
    selected: false,
    SVG: FireAlarmSVG,
  },
  {
    category: 'security',
    title: 'Fire door',
    // iconPath: '/images/icons/fire-safety-door.svg',
    selected: false,
    SVG: FireDoorSVG,
  },
  {
    category: 'standoutAmenities',
    title: 'Floor heating',
    // iconPath: '/images/icons/floor-heating.svg',
    selected: false,
    SVG: FloorHeatingSVG,
  },
  {
    category: 'standoutAmenities',
    title: 'Dryer',
    // iconPath: '/images/icons/dryer.svg',
    selected: false,
    SVG: DryerSVG,
  },
  {
    category: 'basic',
    title: 'Gated community',
    // iconPath: '/images/icons/gated-community.svg',
    selected: false,
    SVG: GatedCommunitySVG,
  },
  {
    category: 'standoutAmenities',
    title: 'Heater',
    // iconPath: '/images/icons/heater.svg',
    selected: false,
    SVG: HeaterSVG,
  },
  {
    category: 'standoutAmenities',
    title: 'Home automation',
    // iconPath: '/images/icons/home-automation.svg',
    selected: false,
    SVG: HomeAutomationSVG,
  },
  {
    category: 'views',
    title: 'Lake view',
    // iconPath: '/images/icons/lake-view.svg',
    selected: false,
    SVG: LakeViewSVG,
  },
  {
    category: 'views',
    title: 'Mountain view',
    // iconPath: '/images/icons/mountain-view.svg',
    selected: false,
    SVG: MountainViewSVG,
  },
  {
    category: 'standoutAmenities',
    title: 'Piano',
    // iconPath: '/images/icons/piano.svg',
    selected: false,
    SVG: PianoSVG,
  },
  {
    category: 'standoutAmenities',
    title: 'Ping pong',
    // iconPath: '/images/icons/ping-pong.svg',
    selected: false,
    SVG: PingPongSVG,
  },
  {
    category: 'standoutAmenities',
    title: 'Pool table',
    // iconPath: '/images/icons/pool-table.svg',
    selected: false,
    SVG: PoolTableSVG,
  },
  {
    category: 'standoutAmenities',
    title: 'Rain shower',
    // iconPath: '/images/icons/rain-shower.svg',
    selected: false,
    SVG: RainShowerSVG,
  },
  {
    category: 'standoutAmenities',
    title: 'Sound system',
    // iconPath: '/images/icons/sound-system.svg',
    selected: false,
    SVG: SoundSystemSVG,
  },
  {
    category: 'standoutAmenities',
    title: 'Storage',
    // iconPath: '/images/icons/storage.svg',
    selected: false,
    SVG: StorageSVG,
  },
  {
    category: 'standoutAmenities',
    title: 'Sprinkler system',
    // iconPath: '/images/icons/sprinkler-system.svg',
    selected: false,
    SVG: SprinklerSystemSVG,
  },
  {
    category: 'standoutAmenities',
    title: 'Water boiler',
    // iconPath: '/images/icons/water-boiler.svg',
    selected: false,
    SVG: WaterBoilerSVG,
  },
  {
    category: 'security',
    title: 'Reception 24-7',
    // iconPath: '/images/icons/reception-24-7.svg',
    selected: false,
    SVG: Reception24_7SVG,
  },
  {
    category: 'security',
    title: 'Solid door',
    // iconPath: '/images/icons/solid-entrance-door.svg',
    selected: false,
    SVG: SolidEntranceDoorSVG,
  },
  {
    category: 'basic',
    title: 'Solar system',
    // iconPath: '/images/icons/solar-system.svg',
    selected: false,
    SVG: SolarSystemSVG,
  },
  {
    category: 'standoutAmenities',
    title: 'Kids pool',
    // iconPath: '/images/icons/kids-pool.svg',
    selected: false,
    SVG: KidsPoolSVG,
  },
  {
    category: 'basic',
    title: 'Hammam',
    // iconPath: '/images/icons/hammam.svg',
    selected: false,
    SVG: HammamSVG,
  },
  {
    category: 'basic',
    title: 'Wine cellar',
    // iconPath: '/images/icons/wine-cellar.svg',
    selected: false,
    SVG: WineCellarSVG,
  },
  {
    category: 'standoutAmenities',
    title: 'Library',
    // iconPath: '/images/icons/library.svg',
    selected: false,
    SVG: LibrarySVG,
  },
  {
    category: 'standoutAmenities',
    title: 'BBQ',
    // iconPath: '/images/icons/BBQ.svg',
    selected: false,
    SVG: BBQSVG,
  },
  {
    category: 'standoutAmenities',
    title: 'Jacuzzi',
    // iconPath: '/images/icons/jacuzzi.svg',
    selected: false,
    SVG: JacuzziSVG,
  },
];

// trending
// justListed
// swimmingPool
// waterfront
// petFriendly
// seaView
// parkingGarage
// terrace
// garden
// gym
// fireplace
// sauna
// homeTheater
// tennis
// playground
// 24hSecurity
// airConditioning
// alarmSystem
// bathtub
// bidet
// carbonMonoxideAlarm
// ceilingFan
// cityView
// dryer
// fireAlarm
// fireSafetyDoor
// floorHeating
// gatedCommunity
// heater
// homeAutomation
// lakeView
// mountainView
// piano
// pingPong
// poolTable
// rainShower
// reception247
// solidEntranceDoor
// soundSystem
// sprinklerSystem
// TV
// washingMachine
// waterBoiler

export const provincesData = [
  {
    province: 'Krung Thep Mahanakhon (Bangkok) ',
    districts: [
      'Bang Bon',
      'Bang Kapi',
      'Bang Khae',
      'Bang Khen',
      'Bang Kho Laem',
      'Bang Khun Thian',
      'Bang Na',
      'Bang Phlat',
      'Bang Rak',
      'Bang Sue',
      'Bangkok Noi',
      'Bangkok Yai',
      'Bueng Kum',
      'Chatuchak',
      'Chom Thong',
      'Din Daeng',
      'Don Mueang',
      'Dusit',
      'Huai Khwang',
      'Khan Na Yao',
      'Khlong Sam Wa',
      'Khlong San',
      'Khlong Toei',
      'Lak Si',
      'Lat Krabang',
      'Lat Phrao',
      'Min Buri',
      'Nong Chok',
      'Nong Khaem',
      'Pathum Wan',
      'Phasi Charoen',
      'Phaya Thai',
      'Phra Khanong',
      'Phra Nakhon',
      'Pom Prap Sattru Phai',
      'Prawet',
      'Rat Burana',
      'Ratchathewi',
      'Sai Mai',
      'Samphanthawong',
      'Saphan Sung',
      'Sathon',
      'Suan Luang',
      'Taling Chan',
      'Thawi Watthana',
      'Thon Buri',
      'Thung Khru',
      'Wang Thonglang',
      'Watthana',
      'Yan Nawa',
    ],
  },
  {
    province: 'Amnat Charoen',
    districts: [
      'Chanuman',
      'Hua Taphan',
      'Lue Amnat',
      'Mueang Amnat Charoen',
      'Pathum Ratchawongsa',
      'Phana',
      'Senangkhanikhom',
    ],
  },
  {
    province: 'Ang Thong',
    districts: [
      'Chaiyo',
      'Mueang Ang Thong',
      'Pa Mok',
      'Pho Thong',
      'Samko',
      'Sawaeng Ha',
      'Wiset Chai Chan',
    ],
  },
  {
    province: 'Bueng Kan',
    districts: [
      'Bueng Khong Long',
      'Bung Khla',
      'Mueang Bueng Kan',
      'Pak Khat',
      'Phon Charoen',
      'Seka',
      'Si Wilai',
      'So Phisai',
    ],
  },
  {
    province: 'Buriram',
    districts: [
      'Ban Dan',
      'Ban Kruat',
      'Ban Mai Chaiyaphot',
      'Chaloem Phra Kiat',
      'Chamni',
      'Huai Rat',
      'Khaen Dong',
      'Khu Mueang',
      'Krasang',
      'Lahan Sai',
      'Lam Plai Mat',
      'Mueang Buriram',
      'Na Pho',
      'Nang Rong',
      'Non Din Daeng',
      'Non Suwan',
      'Nong Hong',
      'Nong Ki',
      'Pakham',
      'Phlapphla Chai',
      'Phutthaisong',
      'Prakhon Chai',
      'Satuek',
    ],
  },
  {
    province: 'Chachoengsao',
    districts: [
      'Ban Pho',
      'Bang Khla',
      'Bang Nam Priao',
      'Bang Pakong',
      'Khlong Khuean',
      'Mueang Chachoengsao',
      'Phanom Sarakham',
      'Plaeng Yao',
      'Ratchasan',
      'Sanam Chai Khet',
      'Tha Takiap',
    ],
  },
  {
    province: 'Chai Nat',
    districts: [
      'Hankha',
      'Manorom',
      'Mueang Chai Nat',
      'Noen Kham',
      'Nong Mamong',
      'Sankhaburi',
      'Sapphaya',
      'Wat Sing',
    ],
  },
  {
    province: 'Chaiyaphum',
    districts: [
      'Bamnet Narong',
      'Ban Khwao',
      'Ban Thaen',
      'Chatturat',
      'Kaeng Khro',
      'Kaset Sombun',
      'Khon San',
      'Khon Sawan',
      'Mueang Chaiyaphum',
      'Noen Sa-nga',
      'Nong Bua Daeng',
      'Nong Bua Rawe',
      'Phakdi Chumphon',
      'Phu Khiao',
      'Sap Yai',
      'Thep Sathit',
    ],
  },
  {
    province: 'Chanthaburi',
    districts: [
      'Kaeng Hang Maeo',
      'Khao Khitchakut',
      'Khlung',
      'Laem Sing',
      'Makham',
      'Mueang Chanthaburi',
      'Na Yai Am',
      'Pong Nam Ron',
      'Soi Dao',
      'Tha Mai',
    ],
  },
  {
    province: 'Chiang Mai',
    districts: [
      'Chai Prakan',
      'Chiang Dao',
      'Chom Thong',
      'Doi Lo',
      'Doi Saket',
      'Doi Tao',
      'Fang',
      'Galyani Vadhana',
      'Hang Dong',
      'Hot',
      'Mae Ai',
      'Mae Chaem',
      'Mae On',
      'Mae Rim',
      'Mae Taeng',
      'Mae Wang',
      'Mueang Chiang Mai',
      'Omkoi',
      'Phrao',
      'Samoeng',
      'San Kamphaeng',
      'San Pa Tong',
      'San Sai',
      'Saraphi',
      'Wiang Haeng',
    ],
  },
  {
    province: 'Chiang Rai',
    districts: [
      'Chiang Khong',
      'Chiang Saen',
      'Doi Luang',
      'Khun Tan',
      'Mae Chan',
      'Mae Fa Luang',
      'Mae Lao',
      'Mae Sai',
      'Mae Suai',
      'Mueang Chiang Rai',
      'Pa Daet',
      'Phan',
      'Phaya Mengrai',
      'Thoeng',
      'Wiang Chai',
      'Wiang Chiang Rung',
      'Wiang Kaen',
      'Wiang Pa Pao',
    ],
  },
  {
    province: 'Chonburi',
    districts: [
      'Ban Bueng',
      'Bang Lamung',
      'Bo Thong',
      'Ko Chan',
      'Ko Sichang',
      'Mueang Chonburi',
      'Nong Yai',
      'Phan Thong',
      'Phanat Nikhom',
      'Sattahip',
      'Si Racha',
    ],
  },
  {
    province: 'Chumphon',
    districts: [
      'Lamae',
      'Lang Suan',
      'Mueang Chumphon',
      'Pathio',
      'Phato',
      'Sawi',
      'Tha Sae',
      'Thung Tako',
    ],
  },
  {
    province: 'Kalasin',
    districts: [
      'Don Chan',
      'Huai Mek',
      'Huai Phueng',
      'Kamalasai',
      'Kham Muang',
      'Khao Wong',
      'Khong Chai',
      'Kuchinarai',
      'Mueang Kalasin',
      'Na Khu',
      'Na Mon',
      'Nong Kung Si',
      'Rong Kham',
      'Sahatsakhan',
      'Sam Chai',
      'Somdet',
      'Tha Khantho',
      'Yang Talat',
    ],
  },
  {
    province: 'Kamphaeng Phet',
    districts: [
      'Bueng Samakkhi',
      'Khanu Woralaksaburi',
      'Khlong Khlung',
      'Khlong Lan',
      'Kosamphi Nakhon',
      'Lan Krabue',
      'Mueang Kamphaeng Phet',
      'Pang Sila Thong',
      'Phran Kratai',
      'Sai Ngam',
      'Sai Thong Watthana',
    ],
  },
  {
    province: 'Kanchanaburi',
    districts: [
      'Bo Phloi',
      'Dan Makham Tia',
      'Huai Krachao',
      'Lao Khwan',
      'Mueang Kanchanaburi',
      'Nong Prue',
      'Phanom Thuan',
      'Sai Yok',
      'Sangkhla Buri',
      'Si Sawat',
      'Tha Maka',
      'Tha Muang',
      'Thong Pha Phum',
    ],
  },
  {
    province: 'Khon Kaen',
    districts: [
      'Ban Fang',
      'Ban Haet',
      'Ban Phai',
      'Chonnabot',
      'Chum Phae',
      'Khao Suan Kwang',
      'Khok Pho Chai',
      'Kranuan',
      'Mancha Khiri',
      'Mueang Khon Kaen',
      'Nam Phong',
      'Non Sila',
      'Nong Na Kham',
      'Nong Ruea',
      'Nong Song Hong',
      'Phon',
      'Phra Yuen',
      'Phu Pha Man',
      'Phu Wiang',
      'Pueai Noi',
      'Sam Sung',
      'Si Chomphu',
      'Ubolratana',
      'Waeng Noi',
      'Waeng Yai',
      'Wiang Kao',
    ],
  },
  {
    province: 'Krabi',
    districts: [
      'Ao Luek',
      'Khao Phanom',
      'Khlong Thom',
      'Ko Lanta',
      'Lam Thap',
      'Mueang Krabi',
      'Nuea Khlong',
      'Plai Phraya',
    ],
  },
  {
    province: 'Lampang',
    districts: [
      'Chae Hom',
      'Hang Chat',
      'Ko Kha',
      'Mae Mo',
      'Mae Phrik',
      'Mae Tha, Lampang',
      'Mueang Lampang',
      'Mueang Pan',
      'Ngao',
      'Soem Ngam',
      'Sop Prap',
      'Thoen',
      'Wang Nuea',
    ],
  },
  {
    province: 'Lamphun',
    districts: [
      'Ban Hong',
      'Ban Thi',
      'Li',
      'Mae Tha, Lamphun',
      'Mueang Lamphun',
      'Pa Sang',
      'Thung Hua Chang',
      'Wiang Nong Long',
    ],
  },
  {
    province: 'Loei',
    districts: [
      'Chiang Khan',
      'Dan Sai',
      'Erawan',
      'Mueang Loei',
      'Na Duang',
      'Na Haeo',
      'Nong Hin',
      'Pak Chom',
      'Pha Khao',
      'Phu Kradueng',
      'Phu Luang',
      'Phu Ruea',
      'Tha Li',
      'Wang Saphung',
    ],
  },
  {
    province: 'Lopburi',
    districts: [
      'Ban Mi',
      'Chai Badan',
      'Khok Charoen',
      'Khok Samrong',
      'Lam Sonthi',
      'Mueang Lopburi',
      'Nong Muang',
      'Phatthana Nikhom',
      'Sa Bot',
      'Tha Luang',
      'Tha Wung',
    ],
  },
  {
    province: 'Mae Hong Son',
    districts: [
      'Khun Yuam',
      'Mae La Noi',
      'Mae Sariang',
      'Mueang Mae Hong Son',
      'Pai',
      'Pang Mapha',
      'Sop Moei',
    ],
  },
  {
    province: 'Maha Sarakham',
    districts: [
      'Borabue',
      'Chiang Yuen',
      'Chuen Chom',
      'Kae Dam',
      'Kantharawichai',
      'Kosum Phisai',
      'Kut Rang',
      'Mueang Maha Sarakham',
      'Na Chueak',
      'Na Dun',
      'Phayakkhaphum Phisai',
      'Wapi Pathum',
      'Yang Sisurat',
    ],
  },
  {
    province: 'Mukdahan',
    districts: [
      'Don Tan',
      'Dong Luang',
      'Khamcha-i',
      'Mueang Mukdahan',
      'Nikhom Kham Soi',
      'Nong Sung',
      'Wan Yai',
    ],
  },
  {
    province: 'Nakhon Nayok',
    districts: ['Ban Na', 'Mueang Nakhon Nayok', 'Ongkharak', 'Pak Phli'],
  },
  {
    province: 'Nakhon Pathom',
    districts: [
      'Bang Len',
      'Don Tum',
      'Kamphaeng Saen',
      'Mueang Nakhon Pathom',
      'Nakhon Chai Si',
      'Phutthamonthon',
      'Sam Phran',
    ],
  },
  {
    province: 'Nakhon Phanom',
    districts: [
      'Ban Phaeng',
      'Mueang Nakhon Phanom',
      'Na Kae',
      'Na Thom',
      'Na Wa',
      'Phon Sawan',
      'Pla Pak',
      'Renu Nakhon',
      'Si Songkhram',
      'Tha Uthen',
      'That Phanom',
      'Wang Yang',
    ],
  },
  {
    province: 'Nakhon Ratchasima',
    districts: [
      'Ban Lueam',
      'Bua Lai',
      'Bua Yai',
      'Chaloem Phra Kiat',
      'Chakkarat',
      'Chok Chai',
      'Chum Phuang',
      'Dan Khun Thot',
      'Huai Thalaeng',
      'Kaeng Sanam Nang',
      'Kham Sakaesaeng',
      'Kham Thale So',
      'Khon Buri',
      'Khong',
      'Lam Thamenchai',
      'Mueang Nakhon Ratchasima',
      'Mueang Yang',
      'Non Daeng',
      'Non Sung',
      'Non Thai',
      'Nong Bun Mak',
      'Pak Chong',
      'Pak Thong Chai',
      'Phimai',
      'Phra Thong Kham',
      'Prathai',
      'Sida',
      'Sikhio',
      'Soeng Sang',
      'Sung Noen',
      'Thepharak',
      'Wang Nam Khiao',
    ],
  },
  {
    province: 'Nakhon Sawan',
    districts: [
      'Banphot Phisai',
      'Chum Saeng',
      'Chum Ta Bong',
      'Kao Liao',
      'Krok Phra',
      'Lat Yao',
      'Mae Poen',
      'Mae Wong',
      'Mueang Nakhon Sawan',
      'Nong Bua',
      'Phaisali',
      'Phayuha Khiri',
      'Tak Fa',
      'Takhli',
      'Tha Tako',
    ],
  },
  {
    province: 'Nakhon Si Thammarat',
    districts: [
      'Bang Khan',
      'Cha-uat',
      'Chaloem Phra Kiat',
      'Chang Klang',
      'Chawang',
      'Chian Yai',
      'Chulabhorn',
      'Hua Sai',
      'Khanom',
      'Lan Saka',
      'Mueang Nakhon Si Thammarat',
      'Na Bon',
      'Nopphitam',
      'Pak Phanang',
      'Phipun',
      'Phra Phrom',
      'Phrom Khiri',
      'Ron Phibun',
      'Sichon',
      'Tha Sala',
      'Tham Phannara',
      'Thung Song',
      'Thung Yai',
    ],
  },
  {
    province: 'Nan',
    districts: [
      'Ban Luang',
      'Bo Kluea',
      'Chaloem Phra Kiat',
      'Chiang Klang',
      'Mae Charim',
      'Mueang Nan',
      'Na Muen',
      'Na Noi',
      'Phu Phiang',
      'Pua',
      'Santi Suk',
      'Song Khwae',
      'Tha Wang Pha',
      'Thung Chang',
      'Wiang Sa',
    ],
  },
  {
    province: 'Narathiwat',
    districts: [
      'Bacho',
      'Chanae',
      'Cho-airong',
      'Mueang Narathiwat',
      'Ra-ngae',
      'Rueso',
      'Si Sakhon',
      'Su-ngai Kolok',
      'Su-ngai Padi',
      'Sukhirin',
      'Tak Bai',
      'Waeng',
      'Yi-ngo',
    ],
  },
  {
    province: 'Nong Bua Lamphu',
    districts: [
      'Mueang Nongbua Lamphu',
      'Na Klang',
      'Na Wang',
      'Non Sang',
      'Si Bun Rueang',
      'Suwannakhuha',
    ],
  },
  {
    province: 'Nong Khai',
    districts: [
      'Fao Rai',
      'Mueang Nong Khai',
      'Pho Tak',
      'Phon Phisai',
      'Rattanawapi',
      'Sakhrai',
      'Sangkhom',
      'Si Chiang Mai',
      'Tha Bo',
    ],
  },
  {
    province: 'Nonthaburi',
    districts: [
      'Bang Bua Thong',
      'Bang Kruai',
      'Bang Yai',
      'Mueang Nonthaburi',
      'Pak Kret',
      'Sai Noi',
    ],
  },
  {
    province: 'Pathum Thani',
    districts: [
      'Khlong Luang',
      'Lam Luk Ka',
      'Lat Lum Kaeo',
      'Mueang Pathum Thani',
      'Nong Suea',
      'Sam Khok',
      'Thanyaburi',
    ],
  },
  {
    province: 'Pattani',
    districts: [
      'Kapho',
      'Khok Pho',
      'Mae Lan',
      'Mai Kaen',
      'Mayo',
      'Mueang Pattani',
      'Nong Chik',
      'Panare',
      'Sai Buri',
      'Thung Yang Daeng',
      'Yarang',
      'Yaring',
    ],
  },
  {
    province: 'Phang Nga',
    districts: [
      'Kapong',
      'Khura Buri',
      'Ko Yao',
      'Mueang Phang Nga',
      'Takua Pa',
      'Takua Thung',
      'Thai Mueang',
      'Thap Put',
    ],
  },
  {
    province: 'Phatthalung',
    districts: [
      'Bang Kaeo',
      'Khao Chaison',
      'Khuan Khanun',
      'Kong Ra',
      'Mueang Phatthalung',
      'Pa Bon',
      'Pa Phayom',
      'Pak Phayun',
      'Si Banphot',
      'Srinagarindra',
      'Tamot',
    ],
  },
  {
    province: 'Phayao',
    districts: [
      'Chiang Kham',
      'Chiang Muan',
      'Chun',
      'Dok Khamtai',
      'Mae Chai',
      'Mueang Phayao',
      'Phu Kamyao',
      'Phu Sang',
      'Pong',
    ],
  },
  {
    province: 'Phetchabun',
    districts: [
      'Bueng Sam Phan',
      'Chon Daen',
      'Khao Kho',
      'Lom Kao',
      'Lom Sak',
      'Mueang Phetchabun',
      'Nam Nao',
      'Nong Phai',
      'Si Thep',
      'Wang Pong',
      'Wichian Buri',
    ],
  },
  {
    province: 'Phetchaburi',
    districts: [
      'Ban Laem',
      'Ban Lat',
      'Cha-am',
      'Kaeng Krachan',
      'Khao Yoi',
      'Mueang Phetchaburi',
      'Nong Ya Plong',
      'Tha Yang',
    ],
  },
  {
    province: 'Phichit',
    districts: [
      'Bang Mun Nak',
      'Bueng Na Rang',
      'Dong Charoen',
      'Mueang Phichit',
      'Pho Prathap Chang',
      'Pho Thale',
      'Sak Lek',
      'Sam Ngam',
      'Taphan Hin',
      'Thap Khlo',
      'Wachirabarami',
      'Wang Sai Phun',
    ],
  },
  {
    province: 'Phitsanulok',
    districts: [
      'Bang Krathum',
      'Bang Rakam',
      'Chat Trakan',
      'Mueang Phitsanulok',
      'Nakhon Thai',
      'Noen Maprang',
      'Phrom Phiram',
      'Wang Thong',
      'Wat Bot',
    ],
  },
  {
    province: 'Phra Nakhon Si Ayutthaya',
    districts: [
      'Ban Phraek',
      'Bang Ban',
      'Bang Pa-in',
      'Bang Pahan',
      'Bang Sai 1404',
      'Bang Sai 1413',
      'Lat Bua Luang',
      'Maha Rat',
      'Nakhon Luang',
      'Phachi',
      'Phak Hai',
      'Phra Nakhon Si Ayutthaya',
      'Sena',
      'Tha Ruea',
      'Uthai',
      'Wang Noi',
    ],
  },
  {
    province: 'Phrae',
    districts: [
      'Den Chai',
      'Long',
      'Mueang Phrae',
      'Nong Muang Khai',
      'Rong Kwang',
      'Song',
      'Sung Men',
      'Wang Chin',
    ],
  },
  { province: 'Phuket', districts: ['Kathu', 'Mueang Phuket', 'Thalang'] },
  {
    province: 'Prachinburi',
    districts: [
      'Ban Sang',
      'Kabin Buri',
      'Mueang Prachinburi',
      'Na Di',
      'Prachantakham',
      'Si Maha Phot',
      'Si Mahosot',
    ],
  },
  {
    province: 'Prachuap Khiri Khan',
    districts: [
      'Bang Saphan',
      'Bang Saphan Noi',
      'Hua Hin',
      'Kui Buri',
      'Mueang Prachuap Khiri Khan',
      'Pran Buri',
      'Sam Roi Yot',
      'Thap Sakae',
    ],
  },
  {
    province: 'Ranong',
    districts: ['Kapoe', 'Kra Buri', 'La-un', 'Mueang Ranong', 'Suk Samran'],
  },
  {
    province: 'Ratchaburi',
    districts: [
      'Ban Kha',
      'Ban Pong',
      'Bang Phae',
      'Chom Bueng',
      'Damnoen Saduak',
      'Mueang Ratchaburi',
      'Pak Tho',
      'Photharam',
      'Suan Phueng',
      'Wat Phleng',
    ],
  },
  {
    province: 'Rayong',
    districts: [
      'Ban Chang',
      'Ban Khai',
      'Khao Chamao',
      'Klaeng',
      'Mueang Rayong',
      'Nikhom Phatthana',
      'Pluak Daeng',
      'Wang Chan',
    ],
  },
  {
    province: 'Roi Et',
    districts: [
      'At Samat',
      'Changhan',
      'Chaturaphak Phiman',
      'Chiang Khwan',
      'Kaset Wisai',
      'Moei Wadi',
      'Mueang Roi Et',
      'Mueang Suang',
      'Nong Hi',
      'Nong Phok',
      'Pathum Rat',
      'Phanom Phrai',
      'Pho Chai',
      'Phon Sai',
      'Phon Thong',
      'Selaphum',
      'Si Somdet',
      'Suwannaphum',
      'Thawat Buri',
      'Thung Khao Luang',
    ],
  },
  {
    province: 'Sa Kaeo',
    districts: [
      'Aranyaprathet',
      'Khao Chakan',
      'Khlong Hat',
      'Khok Sung',
      'Mueang Sa Kaeo',
      'Ta Phraya',
      'Wang Nam Yen',
      'Wang Sombun',
      'Watthana Nakhon',
    ],
  },
  {
    province: 'Sakon Nakhon',
    districts: [
      'Akat Amnuai',
      'Ban Muang',
      'Charoen Sin',
      'Kham Ta Kla',
      'Khok Si Suphan',
      'Kusuman',
      'Kut Bak',
      'Mueang Sakon Nakhon',
      'Nikhom Nam Un',
      'Phang Khon',
      'Phanna Nikhom',
      'Phon Na Kaeo',
      'Phu Phan',
      'Sawang Daen Din',
      'Song Dao',
      'Tao Ngoi',
      'Wanon Niwat',
      'Waritchaphum',
    ],
  },
  {
    province: 'Samut Prakan',
    districts: [
      'Bang Bo',
      'Bang Phli',
      'Bang Sao Thong',
      'Mueang Samut Prakan',
      'Phra Pradaeng',
      'Phra Samut Chedi',
    ],
  },
  {
    province: 'Samut Sakhon',
    districts: ['Ban Phaeo', 'Krathum Baen', 'Mueang Samut Sakhon'],
  },
  {
    province: 'Samut Songkhram',
    districts: ['Amphawa', 'Bang Khonthi', 'Mueang Samut Songkhram'],
  },
  {
    province: 'Saraburi',
    districts: [
      'Ban Mo',
      'Chaloem Phra Kiat',
      'Don Phut',
      'Kaeng Khoi',
      'Muak Lek',
      'Mueang Saraburi',
      'Nong Don',
      'Nong Khae',
      'Nong Saeng',
      'Phra Phutthabat',
      'Sao Hai',
      'Wang Muang',
      'Wihan Daeng',
    ],
  },
  {
    province: 'Satun',
    districts: [
      'Khuan Don',
      'Khuan Kalong',
      'La-ngu',
      'Manang',
      'Mueang Satun',
      'Tha Phae',
      'Thung Wa',
    ],
  },
  {
    province: 'Sing Buri',
    districts: [
      'Bang Rachan',
      'In Buri',
      'Khai Bang Rachan',
      'Mueang Sing Buri',
      'Phrom Buri',
      'Tha Chang',
    ],
  },
  {
    province: 'Sisaket',
    districts: [
      'Benchalak',
      'Bueng Bun',
      'Huai Thap Than',
      'Kantharalak',
      'Kanthararom',
      'Khukhan',
      'Khun Han',
      'Mueang Chan',
      'Mueang Sisaket',
      'Nam Kliang',
      'Non Khun',
      'Phayu',
      'Pho Si Suwan',
      'Phrai Bueng',
      'Phu Sing',
      'Prang Ku',
      'Rasi Salai',
      'Si Rattana',
      'Sila Lat',
      'Uthumphon Phisai',
      'Wang Hin',
      'Yang Chum Noi',
    ],
  },
  {
    province: 'Songkhla',
    districts: [
      'Bang Klam',
      'Chana',
      'Hat Yai',
      'Khlong Hoi Khong',
      'Khuan Niang',
      'Krasae Sin',
      'Mueang Songkhla',
      'Na Mom',
      'Na Thawi',
      'Ranot',
      'Rattaphum',
      'Saba Yoi',
      'Sadao',
      'Sathing Phra',
      'Singhanakhon',
      'Thepha',
    ],
  },
  {
    province: 'Sukhothai',
    districts: [
      'Ban Dan Lan Hoi',
      'Khiri Mat',
      'Kong Krailat',
      'Mueang Sukhothai',
      'Sawankhalok',
      'Si Nakhon',
      'Si Samrong',
      'Si Satchanalai',
      'Thung Saliam',
    ],
  },
  {
    province: 'Suphan Buri',
    districts: [
      'Bang Pla Ma',
      'Dan Chang',
      'Doem Bang Nang Buat',
      'Don Chedi',
      'Mueang Suphanburi',
      'Nong Ya Sai',
      'Sam Chuk',
      'Si Prachan',
      'Song Phi Nong',
      'U Thong',
    ],
  },
  {
    province: 'Surat Thani',
    districts: [
      'Ban Na Doem',
      'Ban Na San',
      'Ban Ta Khun',
      'Chai Buri',
      'Chaiya',
      'Don Sak',
      'Kanchanadit',
      'Khian Sa',
      'Khiri Rat Nikhom',
      'Ko Pha-ngan',
      'Ko Samui',
      'Mueang Surat Thani',
      'Phanom',
      'Phrasaeng',
      'Phunphin',
      'Tha Chana',
      'Tha Chang',
      'Vibhavadi',
      'Wiang Sa',
    ],
  },
  {
    province: 'Surin',
    districts: [
      'Buachet',
      'Chom Phra',
      'Chumphon Buri',
      'Kap Choeng',
      'Khwao Sinarin',
      'Lamduan',
      'Mueang Surin',
      'Non Narai',
      'Phanom Dong Rak',
      'Prasat',
      'Rattanaburi',
      'Samrong Thap',
      'Sangkha',
      'Sanom',
      'Si Narong',
      'Sikhoraphum',
      'Tha Tum',
    ],
  },
  {
    province: 'Tak',
    districts: [
      'Ban Tak',
      'Mae Ramat',
      'Mae Sot',
      'Mueang Tak',
      'Phop Phra',
      'Sam Ngao',
      'Tha Song Yang',
      'Umphang',
      'Wang Chao',
    ],
  },
  {
    province: 'Trang',
    districts: [
      'Hat Samran',
      'Huai Yot',
      'Kantang',
      'Mueang Trang',
      'Na Yong',
      'Palian',
      'Ratsada',
      'Sikao',
      'Wang Wiset',
      'Yan Ta Khao',
    ],
  },
  {
    province: 'Trat',
    districts: [
      'Bo Rai',
      'Khao Saming',
      'Khlong Yai',
      'Ko Chang',
      'Ko Kut',
      'Laem Ngop',
      'Mueang Trat',
    ],
  },
  {
    province: 'Ubon Ratchathani',
    districts: [
      'Buntharik',
      'Det Udom',
      'Don Mot Daeng',
      'Khemarat',
      'Khong Chiam',
      'Khueang Nai',
      'Kut Khaopun',
      'Lao Suea Kok',
      'Muang Sam Sip',
      'Mueang Ubon Ratchathani',
      'Na Chaluai',
      'Na Tan',
      'Na Yia',
      'Nam Khun',
      'Nam Yuen',
      'Phibun Mangsahan',
      'Pho Sai',
      'Samrong',
      'Sawang Wirawong',
      'Si Mueang Mai',
      'Sirindhorn',
      'Tan Sum',
      'Thung Si Udom',
      'Trakan Phuet Phon',
      'Warin Chamrap',
    ],
  },
  {
    province: 'Udon Thani',
    districts: [
      'Ban Dung',
      'Ban Phue',
      'Chai Wan',
      'Ku Kaeo',
      'Kumphawapi',
      'Kut Chap',
      'Mueang Udon Thani',
      'Na Yung',
      'Nam Som',
      'Non Sa-at',
      'Nong Han',
      'Nong Saeng',
      'Nong Wua So',
      'Phen',
      'Phibun Rak',
      'Prachaksinlapakhom',
      'Sang Khom',
      'Si That',
      'Thung Fon',
      'Wang Sam Mo',
    ],
  },
  {
    province: 'Uthai Thani',
    districts: [
      'Ban Rai',
      'Huai Khot',
      'Lan Sak',
      'Mueang Uthai Thani',
      'Nong Chang',
      'Nong Khayang',
      'Sawang Arom',
      'Thap Than',
    ],
  },
  {
    province: 'Uttaradit',
    districts: [
      'Ban Khok',
      'Fak Tha',
      'Laplae',
      'Mueang Uttaradit',
      'Nam Pat',
      'Phichai',
      'Tha Pla',
      'Thong Saen Khan',
      'Tron',
    ],
  },
  {
    province: 'Yala',
    districts: [
      'Bannang Sata',
      'Betong',
      'Kabang',
      'Krong Pinang',
      'Mueang Yala',
      'Raman',
      'Than To',
      'Yaha',
    ],
  },
  {
    province: 'Yasothon',
    districts: [
      'Kham Khuean Kaeo',
      'Kho Wang',
      'Kut Chum',
      'Loeng Nok Tha',
      'Maha Chana Chai',
      'Mueang Yasothon',
      'Pa Tio',
      'Sai Mun',
      'Thai Charoen',
    ],
  },
];

export const properties = [
  {
    id: 0,
    title: '4 Bedrooms house',
    image:
      'https://cf.bstatic.com/xdata/images/hotel/max1024x768/307292076.jpg?k=341e0397780017b10a8ddf817e2ce21d8d12e6d3db26c41c1c896a5bc755b69e&o=&hp=1',
    latitude: 13.7563,
    longitude: 100.5018,
  },

  {
    id: 1,
    title: 'Beach view House',
    image: 'https://www.horizonhomes-samui.com/wp-content/uploads/2021/04/1-2.jpg',
    latitude: 13.763,
    longitude: 100.018,
  },
  {
    id: 2,
    title: 'Spacious apartment in the center',
    image:
      'https://thairesidential.com/wp-content/uploads/2019/04/sunset_plaza_condo_karon_dining2.jpg',
    latitude: 13.75632,
    longitude: 100.50118,
  },
  {
    id: 3,
    title: 'Nice House with view on the beach',
    image:
      'https://cf.bstatic.com/xdata/images/hotel/max1024x768/307292076.jpg?k=341e0397780017b10a8ddf817e2ce21d8d12e6d3db26c41c1c896a5bc755b69e&o=&hp=1',
    latitude: 12.9236,
    longitude: 100.8825,
  },
  {
    id: 4,
    title: 'Beach view House',
    image: 'https://www.horizonhomes-samui.com/wp-content/uploads/2021/04/1-2.jpg',
    latitude: 12.923,
    longitude: 100.8826,
  },
  {
    id: 5,
    title: 'Spacious apartment in the center',
    image:
      'https://thairesidential.com/wp-content/uploads/2019/04/sunset_plaza_condo_karon_dining2.jpg',
    latitude: 12.9238,
    longitude: 100.8822,
  },
  {
    id: 6,
    title: 'Nice House with view on the beach',
    image:
      'https://cf.bstatic.com/xdata/images/hotel/max1024x768/307292076.jpg?k=341e0397780017b10a8ddf817e2ce21d8d12e6d3db26c41c1c896a5bc755b69e&o=&hp=1',
    latitude: 12.9213,
    longitude: 100.8833,
  },
  {
    id: 7,
    title: 'Beach view House',
    image: 'https://www.horizonhomes-samui.com/wp-content/uploads/2021/04/1-2.jpg',
    latitude: 12.9291,
    longitude: 100.8819,
  },
  {
    id: 8,
    title: 'Spacious apartment in the center',
    image:
      'https://thairesidential.com/wp-content/uploads/2019/04/sunset_plaza_condo_karon_dining2.jpg',
    latitude: 12.9222,
    longitude: 100.8621,
  },
];

export const propertyTypesInitialState: ParamsPerType[] = [
  {
    id: 0,
    // Icon: GiHouse,
    image: '/images/icons/types/house.svg',
    propertyType: HOUSE,
    description: 'house_type_description',
    isSelected: false,
    features: true,
    specifics: {
      bedrooms: true,
      bathrooms: true,
      homeSize: true,
      lotSize: true,
      // listingPrice: false,
      // monthlyRentPrice: false,
    },
    details: {
      floorLevel: false,
      ceilingHeight: true,
      parking: true,
      builtIn: true,
      lastRenovation: true,
      furnishingStatus: true,
    },
  },
  {
    id: 1,
    // Icon: GiFamilyHouse,
    image: '/images/icons/types/townhouse.svg',
    propertyType: TOWNHOUSE,
    description: 'townhouse_type_description',
    isSelected: false,
    features: true,
    specifics: {
      bedrooms: true,
      bathrooms: true,
      homeSize: true,
      lotSize: true,
      // listingPrice: false,
      // monthlyRentPrice: false,
    },
    details: {
      floorLevel: false,
      ceilingHeight: true,
      parking: true,
      builtIn: true,
      lastRenovation: true,
      furnishingStatus: true,
    },
  },
  {
    id: 2,
    // Icon: FaBuilding,
    image: '/images/icons/types/apartment.svg',
    propertyType: CONDO,
    description: 'condo_type_description',
    isSelected: false,
    features: true,
    specifics: {
      bedrooms: true,
      bathrooms: true,
      homeSize: true,
      lotSize: false,
      // listingPrice: false,
      // monthlyRentPrice: false,
    },
    details: {
      floorLevel: true,
      ceilingHeight: true,
      parking: true,
      builtIn: true,
      lastRenovation: true,
      furnishingStatus: true,
    },
  },
  {
    id: 3,
    // Icon: BsTreeFill,
    image: '/images/icons/types/land.svg',
    propertyType: LAND,
    description: 'land_type_description',
    isSelected: false,
    features: false,
    specifics: {
      bedrooms: false,
      bathrooms: false,
      homeSize: false,
      lotSize: true,
      // listingPrice: true,
      // monthlyRentPrice: false,
    },
    details: {
      floorLevel: false,
      ceilingHeight: false,
      parking: false,
      builtIn: false,
      lastRenovation: false,
      furnishingStatus: false,
    },
  },
];

export const loremipsum = `
In the middle of a sumptuous private palm grove comprising more than a hundred trees, spacious Provencal-style property of around 520 m2.

Located in an idyllic and green setting the property consists of large reception areas, and of 7 bedrooms spread between the main villa (approx. 335 m2) and the independent guest house (approx. 185 m2). Closed garage, possibility of making a second one. Pleasant pool area with a panorama over the surrounding hills.

The house is in a very good condition, but requires the decor bring up to date. It truly remains the ideal place to meet up with family and friends, nestled just 20 minutes from Nice airport.

sollicitudin tempor id eu. Aliquet eget sit amet tellus cras
adipiscing enim. Eu volutpat odio facilisis mauris sit amet massa.
Tortor id aliquet lectus proin nibh nisl condimentum
Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
eiusmod tempor incididunt ut labore et dolore magna aliqua. Netus et

malesuada fames ac turpis egestas. Ipsum faucibus vitae aliquet nec
ullamcorper sit. Fames ac turpis egestas maecenas. Purus non enim
praesent elementum. Bibendum at varius vel pharetra. Eros in cursus
turpis massa tincidunt. A condimentum vitae sapien pellentesque
habitant morbi tristique senectus et. Sodales neque sodales ut etiam
sit amet nisl. Enim ut tellus elementum sagittis vitae et leo duis.
Volutpat diam ut venenatis tellus. Aliquam ut porttitor leo a diam
sollicitudin tempor id eu. Aliquet eget sit amet tellus cras
adipiscing enim. Eu volutpat odio facilisis mauris sit amet massa.
Tortor id aliquet lectus proin nibh nisl condimentum`;

export const dateOptions = [
  { value: 'unknown', text: 'Unknown' },
  // { value: 'under development', text: 'Under development' },
];

const currentYear = new Date().getFullYear();
for (let year = currentYear; year >= 1924; year--) {
  dateOptions.push({ value: year.toString(), text: year.toString() });
}

export const countries = [
  { title: 'Afghanistan', value: 'AF', currency: 'AFN' },
  { title: 'Albania', value: 'AL', currency: 'ALL' },
  { title: 'Algeria', value: 'DZ', currency: 'DZD' },
  { title: 'American Samoa', value: 'AS', currency: 'USD' },
  { title: 'Andorra', value: 'AD', currency: 'EUR' },
  { title: 'Angola', value: 'AO', currency: 'AOA' },
  { title: 'Anguilla', value: 'AI', currency: 'XCD' },
  { title: 'Argentina', value: 'AR', currency: 'ARS' },
  { title: 'Armenia', value: 'AM', currency: 'AMD' },
  { title: 'Aruba', value: 'AW', currency: 'AWG' },
  { title: 'Australia', value: 'AU', currency: 'AUD' },
  { title: 'Azerbaijan', value: 'AZ', currency: 'AZN' },
  { title: 'Bahamas', value: 'BS', currency: 'BSD' },
  { title: 'Bahrain', value: 'BH', currency: 'BHD' },
  { title: 'Bangladesh', value: 'BD', currency: 'BDT' },
  { title: 'Barbados', value: 'BB', currency: 'BBD' },
  { title: 'Belarus', value: 'BY', currency: 'BYN' },
  { title: 'Belgium', value: 'BE', currency: 'EUR' },
  { title: 'Belize', value: 'BZ', currency: 'BZD' },
  { title: 'Benin', value: 'BJ', currency: 'XOF' },
  { title: 'Bermuda', value: 'BM', currency: 'BMD' },
  { title: 'Bhutan', value: 'BT', currency: 'BTN' },
  { title: 'Bolivia', value: 'BO', currency: 'BOB' },
  { title: 'Bosnia and Herzegovina', value: 'BA', currency: 'BAM' },
  { title: 'Botswana', value: 'BW', currency: 'BWP' },
  { title: 'Brazil', value: 'BR', currency: 'BRL' },
  { title: 'British Virgin Islands', value: 'VG', currency: 'USD' },
  { title: 'Brunei', value: 'BN', currency: 'BND' },
  { title: 'Bulgaria', value: 'BG', currency: 'BGN' },
  { title: 'Burkina Faso', value: 'BF', currency: 'XOF' },
  { title: 'Burundi', value: 'BI', currency: 'BIF' },
  { title: 'Cambodia', value: 'KH', currency: 'USD' },
  { title: 'Cameroon', value: 'CM', currency: 'XAF' },
  { title: 'Canada', value: 'CA', currency: 'CAD' },
  { title: 'Cape Verde', value: 'CV', currency: 'CVE' },
  { title: 'Cayman Islands', value: 'KY', currency: 'KYD' },
  { title: 'Central African Republic', value: 'CF', currency: 'XAF' },
  { title: 'Chad', value: 'TD', currency: 'XAF' },
  { title: 'Chile', value: 'CL', currency: 'CLP' },
  { title: 'China', value: 'CN', currency: 'CNY' },
  { title: 'Columbia', value: 'CO', currency: 'COP' },
  { title: 'Comoros', value: 'KM', currency: 'KMF' },
  { title: 'Cook Islands', value: 'CK', currency: 'NZD' },
  { title: 'Costa Rica', value: 'CR', currency: 'CRC' },
  { title: 'Croatia', value: 'HR', currency: 'HRK' },
  { title: 'Cuba', value: 'CU', currency: 'CUP' },
  { title: 'Curacao', value: 'CW', currency: 'ANG' },
  { title: 'Cyprus', value: 'CY', currency: 'EUR' },
  { title: 'Czech Republic', value: 'CZ', currency: 'CZK' },
  { title: 'Democratic Republic of the Congo', value: 'CD', currency: 'CDF' },
  { title: 'Denmark', value: 'DK', currency: 'DKK' },
  { title: 'Djibouti', value: 'DJ', currency: 'DJF' },
  { title: 'Dominica', value: 'DM', currency: 'XCD' },
  { title: 'Dominican Republic', value: 'DO', currency: 'DOP' },
  { title: 'East Timor', value: 'TL', currency: 'USD' },
  { title: 'Ecuador', value: 'EC', currency: 'USD' },
  { title: 'Egypt', value: 'EG', currency: 'EGP' },
  { title: 'El Salvador', value: 'SV', currency: 'USD' },
  { title: 'Eritrea', value: 'ER', currency: 'ERN' },
  { title: 'Estonia', value: 'EE', currency: 'EUR' },
  { title: 'Faroe Islands', value: 'FO', currency: 'DKK' },
  { title: 'Fiji', value: 'FJ', currency: 'FJD' },
  { title: 'Finland', value: 'FI', currency: 'EUR' },
  { title: 'France', value: 'FR', currency: 'EUR' },
  { title: 'French Polynesia', value: 'PF', currency: 'XPF' },
  { title: 'Gabon', value: 'GA', currency: 'XAF' },
  { title: 'Gambia', value: 'GM', currency: 'GMD' },
  { title: 'Georgia', value: 'GE', currency: 'GEL' },
  { title: 'Germany', value: 'DE', currency: 'EUR' },
  { title: 'Ghana', value: 'GH', currency: 'GHS' },
  { title: 'Greece', value: 'GR', currency: 'EUR' },
  { title: 'Greenland', value: 'GL', currency: 'DKK' },
  { title: 'Grenada', value: 'GD', currency: 'XCD' },
  { title: 'Guam', value: 'GU', currency: 'USD' },
  { title: 'Guatemala', value: 'GT', currency: 'GTQ' },
  { title: 'Guernsey', value: 'GG', currency: 'GBP' },
  { title: 'Guinea', value: 'GN', currency: 'GNF' },
  { title: 'Guinea-Bissau', value: 'GW', currency: 'XOF' },
  { title: 'Guyana', value: 'GY', currency: 'GYD' },
  { title: 'Haiti', value: 'HT', currency: 'HTG' },
  { title: 'Honduras', value: 'HN', currency: 'HNL' },
  { title: 'Hong Kong', value: 'HK', currency: 'HKD' },
  { title: 'Hungary', value: 'HU', currency: 'HUF' },
  { title: 'Iceland', value: 'IS', currency: 'ISK' },
  { title: 'India', value: 'IN', currency: 'INR' },
  { title: 'Indonesia', value: 'ID', currency: 'IDR' },
  { title: 'Iran', value: 'IR', currency: 'IRR' },
  { title: 'Iraq', value: 'IQ', currency: 'IQD' },
  { title: 'Ireland', value: 'IE', currency: 'EUR' },
  { title: 'Isle of Man', value: 'IM', currency: 'GBP' },
  { title: 'Israel', value: 'IL', currency: 'ILS' },
  { title: 'Italy', value: 'IT', currency: 'EUR' },
  { title: 'Ivory Coast', value: 'CI', currency: 'XOF' },
  { title: 'Jamaica', value: 'JM', currency: 'JMD' },
  { title: 'Japan', value: 'JP', currency: 'JPY' },
  { title: 'Jersey', value: 'JE', currency: 'GBP' },
  { title: 'Jordan', value: 'JO', currency: 'JOD' },
  { title: 'Kazakhstan', value: 'KZ', currency: 'KZT' },
  { title: 'Kenya', value: 'KE', currency: 'KES' },
  { title: 'Kiribati', value: 'KI', currency: 'AUD' },
  { title: 'Kosovo', value: 'XK', currency: 'EUR' },
  { title: 'Kuwait', value: 'KW', currency: 'KWD' },
  { title: 'Kyrgyzstan', value: 'KG', currency: 'KGS' },
  { title: 'Laos', value: 'LA', currency: 'LAK' },
  { title: 'Latvia', value: 'LV', currency: 'EUR' },
  { title: 'Lebanon', value: 'LB', currency: 'LBP' },
  { title: 'Lesotho', value: 'LS', currency: 'LSL' },
  { title: 'Liberia', value: 'LR', currency: 'LRD' },
  { title: 'Libya', value: 'LY', currency: 'LYD' },
  { title: 'Liechtenstein', value: 'LI', currency: 'CHF' },
  { title: 'Lithuania', value: 'LT', currency: 'EUR' },
  { title: 'Luxembourg', value: 'LU', currency: 'EUR' },
  { title: 'Macau', value: 'MO', currency: 'MOP' },
  { title: 'Macedonia', value: 'MK', currency: 'MKD' },
  { title: 'Madagascar', value: 'MG', currency: 'MGA' },
  { title: 'Malawi', value: 'MW', currency: 'MWK' },
  { title: 'Malaysia', value: 'MY', currency: 'MYR' },
  { title: 'Maldives', value: 'MV', currency: 'MVR' },
  { title: 'Mali', value: 'ML', currency: 'XOF' },
  { title: 'Malta', value: 'MT', currency: 'EUR' },
  { title: 'Marshall Islands', value: 'MH', currency: 'USD' },
  { title: 'Mauritania', value: 'MR', currency: 'MRU' },
  { title: 'Mauritius', value: 'MU', currency: 'MUR' },
  { title: 'Mayotte', value: 'YT', currency: 'EUR' },
  { title: 'Mexico', value: 'MX', currency: 'MXN' },
  { title: 'Micronesia', value: 'FM', currency: 'USD' },
  { title: 'Moldova', value: 'MD', currency: 'MDL' },
  { title: 'Monaco', value: 'MC', currency: 'EUR' },
  { title: 'Mongolia', value: 'MN', currency: 'MNT' },
  { title: 'Montenegro', value: 'ME', currency: 'EUR' },
  { title: 'Morocco', value: 'MA', currency: 'MAD' },
  { title: 'Mozambique', value: 'MZ', currency: 'MZN' },
  { title: 'Myanmar', value: 'MM', currency: 'MMK' },
  { title: 'Namibia', value: 'NA', currency: 'NAD' },
  { title: 'Nepal', value: 'NP', currency: 'NPR' },
  { title: 'Netherlands', value: 'NL', currency: 'EUR' },
  { title: 'Netherlands Antilles', value: 'AN', currency: 'ANG' },
  { title: 'New Caledonia', value: 'NC', currency: 'XPF' },
  { title: 'New Zealand', value: 'NZ', currency: 'NZD' },
  { title: 'Nicaragua', value: 'NI', currency: 'NIO' },
  { title: 'Niger', value: 'NE', currency: 'XOF' },
  { title: 'Nigeria', value: 'NG', currency: 'NGN' },
  { title: 'North Korea', value: 'KP', currency: 'KPW' },
  { title: 'Northern Mariana Islands', value: 'MP', currency: 'USD' },
  { title: 'Norway', value: 'NO', currency: 'NOK' },
  { title: 'Oman', value: 'OM', currency: 'OMR' },
  { title: 'Pakistan', value: 'PK', currency: 'PKR' },
  { title: 'Palestine', value: 'PS', currency: 'ILS' },
  { title: 'Panama', value: 'PA', currency: 'PAB' },
  { title: 'Papua New Guinea', value: 'PG', currency: 'PGK' },
  { title: 'Paraguay', value: 'PY', currency: 'PYG' },
  { title: 'Peru', value: 'PE', currency: 'PEN' },
  { title: 'Philippines', value: 'PH', currency: 'PHP' },
  { title: 'Poland', value: 'PL', currency: 'PLN' },
  { title: 'Portugal', value: 'PT', currency: 'EUR' },
  { title: 'Puerto Rico', value: 'PR', currency: 'USD' },
  { title: 'Qatar', value: 'QA', currency: 'QAR' },
  { title: 'Republic of the Congo', value: 'CG', currency: 'XAF' },
  { title: 'Reunion', value: 'RE', currency: 'EUR' },
  { title: 'Romania', value: 'RO', currency: 'RON' },
  { title: 'Russia', value: 'RU', currency: 'RUB' },
  { title: 'Rwanda', value: 'RW', currency: 'RWF' },
  { title: 'Saint Kitts and Nevis', value: 'KN', currency: 'XCD' },
  { title: 'Saint Lucia', value: 'LC', currency: 'XCD' },
  { title: 'Saint Martin', value: 'MF', currency: 'EUR' },
  { title: 'Saint Pierre and Miquelon', value: 'PM', currency: 'EUR' },
  { title: 'Saint Vincent and the Grenadines', value: 'VC', currency: 'XCD' },
  { title: 'Samoa', value: 'WS', currency: 'WST' },
  { title: 'San Marino', value: 'SM', currency: 'EUR' },
  { title: 'Sao Tome and Principe', value: 'ST', currency: 'STN' },
  { title: 'Saudi Arabia', value: 'SA', currency: 'SAR' },
  { title: 'Senegal', value: 'SN', currency: 'XOF' },
  { title: 'Serbia', value: 'RS', currency: 'RSD' },
  { title: 'Seychelles', value: 'SC', currency: 'SCR' },
  { title: 'Sierra Leone', value: 'SL', currency: 'SLL' },
  { title: 'Singapore', value: 'SG', currency: 'SGD' },
  { title: 'Sint Maarten', value: 'SX', currency: 'ANG' },
  { title: 'Slovakia', value: 'SK', currency: 'EUR' },
  { title: 'Slovenia', value: 'SI', currency: 'EUR' },
  { title: 'Solomon Islands', value: 'SB', currency: 'SBD' },
  { title: 'Somalia', value: 'SO', currency: 'SOS' },
  { title: 'South Africa', value: 'ZA', currency: 'ZAR' },
  { title: 'South Korea', value: 'KR', currency: 'KRW' },
  { title: 'South Sudan', value: 'SS', currency: 'SSP' },
  { title: 'Spain', value: 'ES', currency: 'EUR' },
  { title: 'Sri Lanka', value: 'LK', currency: 'LKR' },
  { title: 'Sudan', value: 'SD', currency: 'SDG' },
  { title: 'Suriname', value: 'SR', currency: 'SRD' },
  { title: 'Swaziland', value: 'SZ', currency: 'SZL' },
  { title: 'Sweden', value: 'SE', currency: 'SEK' },
  { title: 'Switzerland', value: 'CH', currency: 'CHF' },
  { title: 'Syria', value: 'SY', currency: 'SYP' },
  { title: 'Taiwan', value: 'TW', currency: 'TWD' },
  { title: 'Tajikistan', value: 'TJ', currency: 'TJS' },
  { title: 'Tanzania', value: 'TZ', currency: 'TZS' },
  { title: 'Thailand', value: 'TH', currency: 'THB' },
  { title: 'Togo', value: 'TG', currency: 'XOF' },
  { title: 'Tonga', value: 'TO', currency: 'TOP' },
  { title: 'Trinidad and Tobago', value: 'TT', currency: 'TTD' },
  { title: 'Tunisia', value: 'TN', currency: 'TND' },
  { title: 'Turkey', value: 'TR', currency: 'TRY' },
  { title: 'Turkmenistan', value: 'TM', currency: 'TMT' },
  { title: 'Turks and Caicos Islands', value: 'TC', currency: 'USD' },
  { title: 'Tuvalu', value: 'TV', currency: 'AUD' },
  { title: 'U.S. Virgin Islands', value: 'VI', currency: 'USD' },
  { title: 'Uganda', value: 'UG', currency: 'UGX' },
  { title: 'Ukraine', value: 'UA', currency: 'UAH' },
  { title: 'United Arab Emirates', value: 'AE', currency: 'AED' },
  { title: 'United Kingdom', value: 'GB', currency: 'GBP' },
  { title: 'United States', value: 'US', currency: 'USD' },
  { title: 'Uruguay', value: 'UY', currency: 'UYU' },
  { title: 'Uzbekistan', value: 'UZ', currency: 'UZS' },
  { title: 'Vanuatu', value: 'VU', currency: 'VUV' },
  { title: 'Venezuela', value: 'VE', currency: 'VES' },
  { title: 'Vietnam', value: 'VN', currency: 'VND' },
  { title: 'Western Sahara', value: 'EH', currency: 'MAD' },
  { title: 'Yemen', value: 'YE', currency: 'YER' },
  { title: 'Zambia', value: 'ZM', currency: 'ZMW' },
  { title: 'Zimbabwe', value: 'ZW', currency: 'ZWL' },
] as const;
