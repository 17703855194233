import React from 'react';
import { BiSolidInfoCircle } from 'react-icons/bi';

interface TooltipProps {
  text: string;
  Component?: React.ComponentType;
  width?: number;
}

const Tooltip = ({ text, Component, width = 250 }: TooltipProps) => {
  return (
    <div className="group relative">
      {Component ? <Component /> : <BiSolidInfoCircle className="text-deehiy w-5 h-5" />}
      <div
        // className={`absolute text-justify w-[${width}px] shadow-xl z-10 p-4 hidden text-xs font-light group-hover:block bg-white`}
        className={`absolute left-1/2 -translate-x-1/2 w-[${width}px] max-w-[calc(100vw-20px)] shadow-xl z-10 p-4 hidden text-xs font-light group-hover:block bg-white`}
      >
        {text}
      </div>
    </div>
  );
};

export default Tooltip;
