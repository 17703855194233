import { Business } from '../../types/types';
import { useNavigate } from 'react-router-dom';

const CustomHeader = ({ business }: { business: Business | null }) => {
  const navigate = useNavigate();

  if (!business || !business.isWhiteLabel) return <>please try again ...</>;

  return (
    <nav className="  w-full px-6 sm:pl-20 sm:pr-10 bg-white shadow-md fixed top-0 z-20 h-12 sm:h-24 flex items-center justify-between mb-2">
      <div
        className="flex items-center cursor-pointer"
        onClick={() => navigate(`/business/${business.businessId}/market`)}
      >
        <div>
          {business && <img src={business?.image} alt="Business Logo" className="h-10 sm:h-20" />}
        </div>
      </div>
      <div className="flex h-full items-center sm:items-end sm:pb-2">
        <span className="text-xs">powered by</span>
        <img
          src="/images/logo.jpg"
          className="cursor-pointer mr-1 h-[18px] w-[60px] "
          alt="Logo"
          style={{ width: '60px', height: '18px' }}
          onClick={() => {
            navigate('/');
            window.location.reload(); // Force the page to reload
          }}
        />
      </div>
    </nav>
  );
};

export default CustomHeader;
