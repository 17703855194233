import React, { useEffect } from 'react';
import { BsFillPersonFill } from 'react-icons/bs';

interface PopUpProps {
  onClose: () => void;
  popUpData: any;
}

const PopUp: React.FC<PopUpProps> = ({ onClose, popUpData }) => {
  const { groupId } = popUpData;
  const appToken = localStorage.getItem('@App:token');
  const handleContainerClick = () => {
    window.open(
      `${process.env.REACT_APP_MESSENGER_URL}?token=${appToken}&groupId=${groupId}`,
      '_blank'
    );
  };
  const handleCrossButtonClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onClose();
  };

  useEffect(() => {
    const playAudio = () => {
      const audioElement = document.getElementById('audio') as HTMLAudioElement;
      audioElement.muted = false;
      audioElement?.play();
    };
    playAudio();
  });

  const RenderMessages = ({ message }: any) => {
    const messageList = message.split('\n');
    return messageList.map((msg: string) =>
      msg.length > 15 ? <p>{`${msg.substring(0, 15)}...`}</p> : <p>{msg}</p>
    );
  };

  const getNotificationMessage = (type: string): string => {
    switch (type) {
      case 'album':
        return 'album shared';
      case 'contact':
        return popUpData?.contactName;
      case 'updated_note':
      case 'note':
        return popUpData.note.length > 15
          ? `${popUpData.note.substring(0, 15)}...`
          : `${popUpData.note}`;
      case 'forward':
        return getNotificationMessage(popUpData?.messageSubType);
      default:
        return popUpData.extension
          ? popUpData.fileName + '.' + popUpData.extension
          : popUpData.fileName;
    }
  };

  return (
    <div
      onClick={handleContainerClick}
      className="fixed notification-size bottom-9 right-5 popup flex bg-gray-200 border border-gray-200 p-4 pl-2 shadow-md z-50"
    >
      <audio
        src="/deehiy_noti_sanct.mp3"
        id="audio"
        controls
        muted
        style={{ display: 'none' }}
      ></audio>
      <div className="flex-grow-0 max-w-screen-xl full-width full-height gap-y-8">
        <div className="flex items-center mb-4 justify-between">
          <div className=" items-start flex justify-between">
            <img className="h-5 w-5 rounded-full flex-shrink-0" src="/favicon.ico" alt="user" />
            <span>Deehiy Messenger</span>
          </div>
          <div>
            <button
              onClick={handleCrossButtonClick}
              className="text-deehiy-dark hover:text-deehiy-light focus:outline-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="flex full-width full-height gap-x-4 overflow-y-auto">
          <img
            src={popUpData.profilePic ? popUpData.profilePic : './../images/profile.png'}
            alt="sender"
            className=" resizeable-img object-contain w-1/5"
          />
          <div className="full-width full-height">
            <div className="font-semibold">
              {' '}
              <p>
                {popUpData.name.length > 10
                  ? `${popUpData.name.substring(0, 10)}...`
                  : `${popUpData.name}`}
              </p>
            </div>
            <p>
              {(popUpData?.messageType === 'contact' ||
                popUpData?.messageSubType === 'contact') && (
                <BsFillPersonFill style={{ height: '20px' }} />
              )}{' '}
              {['text', 'listing', 'reply'].includes(popUpData?.messageType) ? (
                <RenderMessages
                  message={
                    popUpData?.lastMessage === 'IniatedChatFirstTIme@#'
                      ? ''
                      : popUpData?.lastMessage
                  }
                />
              ) : (
                getNotificationMessage(popUpData?.messageType)
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopUp;
