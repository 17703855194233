import {
  APPROVED,
  DELETED,
  FULLY_FURNISHED,
  INPROGRESS,
  PARTIALLY_FURNISHED,
  PENDING,
  REJECTED,
  UNFURNISHED,
} from '../constants/constant';
// import { useTranslation } from 'react-i18next';

const classStatus =
  'w-20 mx-auto font-bold text-center whitespace-nowrap rounded text-white py-1 text-xs shadow-md';
// style is overwritting the theme in index js

export const adaptStatus = (status: string) => {
  // For translation: see comment in PropertiesTable
  // const { t } = useTranslation();

  switch (status) {
    case INPROGRESS:
      return (
        <div className={classStatus + ' ' + 'bg-yellow-200 shadow-yellow-200'}>
          {/* {t('in progress')} */}
          in progress
        </div>
      );
    case PENDING:
      return (
        <div className={classStatus + ' ' + 'bg-[#ea8e37] shadow-orange-200'}>
          {/* {t('pending')} */}
          pending
        </div>
      );
    case APPROVED:
      return (
        <div className={classStatus + ' ' + 'bg-green-400  shadow-green-200'}>
          {/* {t('approved')} */}
          approved
        </div>
      );
    case DELETED:
      return (
        <div className={classStatus + ' ' + 'bg-black'}>
          {/* {t('deleted')} */}
          deleted
        </div>
      );
    case REJECTED:
      return (
        <div className={classStatus + ' ' + 'bg-deehiy-red  shadow-red-200'}>
          {/* {t('rejected')} */}
          rejected
        </div>
      );
    default:
      return <>unknown</>;
  }
};

export const getFurnishingStatusLabel = (status: string) => {
  // const { t } = useTranslation();

  switch (status) {
    case FULLY_FURNISHED:
      return 'fully furnished';
    case PARTIALLY_FURNISHED:
      return 'Partially furnished';
    case UNFURNISHED:
      return 'Unfurnished';
    default:
      return '';
  }
};
