import React, { useState } from 'react';

interface Step {
  target: string;
  content: string;
  icon: string;
  title: string;
}

interface MobileGuideTourProps {
  joyrideSteps: {
    run: boolean;
    steps: Step[];
    showProgress: boolean;
    continuous: boolean;
    showSkipButton: boolean;
    spotlightPadding: number;
  };
}

const MobileGuideTour = ({ joyrideSteps }: MobileGuideTourProps) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [hasCompletedTour, setHasCompletedTour] = useState(
    localStorage.getItem('hasCompletedTour') === 'true'
  );

  const handleNext = () => {
    if (currentStep < joyrideSteps.steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleSkip = () => {
    // setCurrentStep(joyrideSteps.steps.length - 1);
    localStorage.setItem('hasCompletedTour', 'true');
    setHasCompletedTour(true);
  };

  const handleClose = () => {
    // setCurrentStep(0); // Reset to the first step or close entirely
    localStorage.setItem('hasCompletedTour', 'true');
    setHasCompletedTour(true);
  };

  const currentContent = joyrideSteps.steps[currentStep]?.content;
  const title = joyrideSteps.steps[currentStep]?.title;
  const icon = joyrideSteps.steps[currentStep]?.icon;

  return (
    <>
      {!hasCompletedTour ? (
        <div className="fixed inset-0 bg-deehiy flex flex-col justify-center items-center z-50">
          <div className="bg-deehiy p-6 rounded-lg max-w-md mx-auto text-center w-full">
            <img src={icon} className="mx-auto w-72 h-72 pb-12" />
            <div className="">
              {joyrideSteps.run}
              <div className="mb-4 text-xl font-bold text-white">{title}</div>
              <div className="mb-4 text-lg text-white">{currentContent}</div>
            </div>

            <div className="flex justify-between space-x-4 pt-12">
              {joyrideSteps.showSkipButton && currentStep < joyrideSteps.steps.length - 1 && (
                <button className="text-sm text-white hover:underline" onClick={handleSkip}>
                  Skip
                </button>
              )}

              {currentStep < joyrideSteps.steps.length - 1 ? (
                <button
                  className="text-sm bg-white text-deehiy py-2 px-4 rounded"
                  onClick={handleNext}
                >
                  Next
                  {joyrideSteps.showProgress && (
                    <>{`${currentStep + 1} / ${joyrideSteps.steps.length}`}</>
                  )}
                </button>
              ) : (
                <button
                  className="text-sm bg-white text-deehiy py-2 px-4 rounded absolute right-6"
                  onClick={handleClose}
                >
                  Finish
                </button>
              )}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default MobileGuideTour;
