import { t } from 'i18next';

export function timeAgo(timestamp: string): string {
  const date = new Date(timestamp);
  const difference = Date.now() - date.getTime();
  const msPerMinute = 1000 * 60;
  const msPerHour = msPerMinute * 60;
  const msPerDay = 1000 * 60 * 60 * 24;
  const msPerMonth = msPerDay * 30.44; // average number of days per month
  const msPerYear = msPerDay * 365.25; // average number of days per year

  const minutesAgo = Math.floor(difference / msPerMinute);
  const hoursAgo = Math.floor(difference / msPerHour);
  const daysAgo = Math.floor(difference / msPerDay);
  const monthsAgo = Math.floor(difference / msPerMonth);
  const yearsAgo = Math.floor(difference / msPerYear);

  if (yearsAgo >= 1) {
    return t('{{count}} year(s) ago', { count: yearsAgo });
  } else if (monthsAgo >= 1) {
    return t('{{count}} month(s) ago', { count: monthsAgo });
  } else if (daysAgo >= 1) {
    return t('{{count}} day(s) ago', { count: daysAgo });
  } else if (hoursAgo >= 1) {
    return t('{{count}} hour(s) ago', { count: hoursAgo });
  } else if (minutesAgo >= 1) {
    return t('{{count}} minute(s) ago', { count: minutesAgo });
  } else {
    return t('now');
  }
}

export function formatDate(dateString: string) {
  const date = new Date(dateString);
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  if (date.toDateString() === today.toDateString()) {
    return t('Today');
  } else if (date.toDateString() === yesterday.toDateString()) {
    return t('Yesterday');
  } else {
    const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'long', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  }
}
