// src/components/NotificationBanner.tsx

import React, { useEffect } from 'react';

const NotificationBanner = ({ isBannerVisible, setIsBannerVisible }: any) => {
  useEffect(() => {
    // Check if the banner has been dismissed in this session
    const isBannerDismissed = sessionStorage.getItem('bannerDismissed');
    if (!isBannerDismissed) {
      setIsBannerVisible(true);
    }
  }, []);

  const handleClose = () => {
    setIsBannerVisible(false);
    sessionStorage.setItem('bannerDismissed', 'true');
  };

  const handleClick = () => {
    setIsBannerVisible(false);
    sessionStorage.setItem('bannerDismissed', 'true');

    window.open('https://play.google.com/store/apps/details?id=com.deehiy.app', '_blank');
  };

  if (!isBannerVisible) return null;

  return (
    <div className="bg-deehiy text-white h-12 px-2 py-2 items-center flex relative">
      <button onClick={handleClose} className="text-white w-10" aria-label="Close Notification">
        &#10005;
      </button>
      <img src="/images/pulse-app-logo.svg" alt="logo-app" className="w-10 h-full" />
      <div className="flex flex-col pl-1 pr-4">
        <span className="font-bold text-sm">Get the app</span>
        <span className="text-xs">Never miss an opportunity</span>
      </div>
      <button
        className="border border-white text-sm rounded-full px-2 py-1 font-semibold"
        onClick={handleClick}
      >
        Use app
      </button>
    </div>
  );
};

export default NotificationBanner;
