import React, { useMemo, useRef, useEffect } from 'react';
import { Grid, _ } from 'gridjs-react';

import 'gridjs/dist/theme/mermaid.css';
import { AiFillEyeInvisible } from 'react-icons/ai';
import { BsTrash } from 'react-icons/bs';
import { BiEdit } from 'react-icons/bi';
import { NumericFormat } from 'react-number-format';

import { useNavigate } from 'react-router-dom';
import { useProperty } from '../../../contexts/property';
import { useInternationalization } from '../../../contexts/internationalization';
import {
  adminTogglePropertyPublishStatus,
  adminDeleteProperty,
} from '../../../services/adminService';
import AdminLabel from './AdminLabel';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { APPROVED, DELETED, INPROGRESS, PENDING, REJECTED } from '../../../constants/constant';
import { initialProperty } from '../../../initialStates/initialStates';

const DefaultImgUrl = '/images/housedefault.png';
const classStatus =
  'w-20 mx-auto font-bold text-center whitespace-nowrap rounded text-white py-1 text-xs shadow-md';
// style is overwritting the theme in index js

const AdminManageListings = () => {
  const token = localStorage.getItem('@App:token');
  const { setCurrentProperty } = useProperty();
  const { currency } = useInternationalization();

  // const search = useState('pattaya');

  const navigate = useNavigate();
  const gridRef = useRef<any>(null);

  const adaptStatus = (status: string) => {
    switch (status) {
      case INPROGRESS:
        return (
          <div className={classStatus + ' ' + 'bg-yellow-200 shadow-yellow-200'}>in progress</div>
        );
      case PENDING:
        return <div className={classStatus + ' ' + 'bg-[#ea8e37] shadow-orange-200'}>pending</div>;
      case APPROVED:
        return <div className={classStatus + ' ' + 'bg-green-400  shadow-green-200'}>approved</div>;
      case DELETED:
        return <div className={classStatus + ' ' + 'bg-black'}>deleted</div>;
      case REJECTED:
        return <div className={classStatus + ' ' + 'bg-deehiy-red  shadow-red-200'}>rejected</div>;
      default:
        return <>unknown</>;
    }
  };

  const handlePublication = async (propertyId: number, rowId: string) => {
    console.log(rowId);
    try {
      const publish = await adminTogglePropertyPublishStatus(propertyId);

      if (gridRef.current) {
        gridRef.current.forceUpdate();
      }

      if (publish.data === 'not approved') {
        toast.error('the listing has not been approved yet.');
        return;
      }
    } catch (err: any) {
      console.log('error when toggle publih status', err);
      throw new Error(err);
    }

    // gridRef.current.forceRender(rowId);
    // alert(`change publication of property: ${propertyId} with rowID:${rowId}`);
  };

  const handleDelete = async (propertyId: number) => {
    if (confirm(`Are you sure you want to delete property: ${propertyId}?`) == true) {
      try {
        await adminDeleteProperty(propertyId);

        if (gridRef.current) {
          gridRef.current.forceUpdate();
        }
      } catch (err: any) {
        console.log('error when delete property', err);
        throw new Error(err);
      }
    } else {
      return;
    }
  };

  const columns = useMemo(() => {
    return [
      {
        id: '0',
        name: _(<div className="flex justify-evenly"></div>),
        width: '5%',
        formatter: (cell: any) =>
          _(
            <div className=" relative aspect-video object-contain px-1 hover:pl-3">
              <img className="w-full duration-700" alt="img_property" src={cell} />
            </div>
          ),
      },
      {
        id: '1',
        name: 'Listings',
        sort: 'true',
        align: 'left',
        width: '10%',
        formatter: (cell: any, row: any) =>
          _(
            <div
              title="Edit property"
              className="px-6 hover:underline hover:cursor-pointer"
              onClick={() => navigate(`/admin/editlisting/${row.cells[11].data}`)}
            >
              {cell ? cell : '-'}
            </div>
          ),
      },
      {
        id: '2',
        name: 'Created at',
        width: '8%',
        sort: true,
        formatter: (cell: any) =>
          _(<div className="px-3">{new Date(cell).toLocaleDateString()}</div>),
      },
      {
        id: '3',
        name: 'Updated at',
        width: '8%',
        sort: true,
        formatter: (cell: any) =>
          _(<div className="px-3">{new Date(cell).toLocaleDateString()}</div>),
      },
      {
        id: '4',
        name: 'Price',
        width: '8%',
        sort: true,
        formatter: (cell: any) =>
          _(
            <div className="text-center">
              {cell ? (
                <NumericFormat
                  value={cell}
                  thousandSeparator=" "
                  displayType={'text'}
                  prefix={currency + ' '}
                />
              ) : (
                '-'
              )}
            </div>
          ),
      },
      {
        id: '5',
        name: ' monthly Price',
        width: '8%',
        sort: true,
        formatter: (cell: any) =>
          _(
            <div className="text-center">
              {cell ? (
                <NumericFormat
                  value={cell}
                  thousandSeparator=" "
                  displayType={'text'}
                  prefix={' '}
                />
              ) : (
                '-'
              )}
            </div>
          ),
      },
      {
        id: '6',
        name: 'Status',
        width: '6%',
        sort: false,
        formatter: (cell: any) =>
          _(<div className="mx-auto">{cell ? adaptStatus(cell) : 'unknown'}</div>),
      },
      {
        id: '7',
        name: 'Visibility',
        width: '7%',
        sort: true,
        formatter: (cell: any) =>
          _(
            <div className="mx-auto">
              <img
                alt=""
                className="w-6 h-6 mx-auto"
                src={`/images/icons/icon-dashboard-landing/${
                  cell ? 'published.svg' : 'unpublished.svg'
                }`}
              />
            </div>
          ),
      },
      {
        id: '8',
        name: 'Views',
        width: '4%',
        sort: true,
        formatter: (cell: any) => _(<div className="text-center">{cell}</div>),
      },
      {
        id: '9',
        name: 'Name',
        width: '6%',
        sort: true,
        formatter: (cell: any, row: any) =>
          _(
            <div
              title="open agent page"
              className=" px-6 hover:underline hover:cursor-pointer"
              onClick={() => window.open(`/member/${row.cells[12].data}`, '_blank')}
            >
              {cell ? cell : '-'}
            </div>
          ),
      },
      {
        id: '10',
        name: 'Email',
        width: '8%',
        sort: true,
        formatter: (cell: any) => _(<div className=" text-xs text-center">{cell}</div>),
      },
      {
        id: '11',
        name: 'Action',
        width: '7%',
        sort: false,

        formatter: (cell: any, row: any) =>
          _(
            <div className="flex justify-evenly">
              <BsTrash
                size={20}
                onClick={() => handleDelete(cell)}
                className="text-gray-600 hover:text-deehiy hover:scale-110 duration-300"
              />
              <AiFillEyeInvisible
                size={20}
                onClick={() => handlePublication(cell, row.id)}
                className="text-gray-600 hover:text-deehiy hover:scale-110 duration-300"
              />
              <BiEdit
                size={20}
                onClick={() => navigate(`/admin/editlisting/${cell}`)}
                className="text-gray-600 hover:text-deehiy hover:scale-110 duration-300"
              />
            </div>
          ),
      },
      {
        id: '12',
        name: 'user id',
        hidden: true,
        width: '0%',
      },
    ];
  }, []);

  useEffect(() => {
    setCurrentProperty(initialProperty);
  }, []);

  return (
    <>
      <AdminLabel />
      <Grid
        ref={gridRef}
        className={{
          table: 'text-xs w-full bg-gray-300',
          search: 'border-deehiy border rounded-md ',
        }}
        server={{
          url: '/api/admin/properties',
          headers: { 'x-auth-token': `${token}` },
          then: (data: any) => {
            console.log(data);
            return data.data.map((property: any) => [
              property.images && property.images.length ? property.images[0].url : DefaultImgUrl,
              property.propertyTitle,
              property.createdAt,
              property.updatedAt,
              property.listingPrice,
              property.monthlyRentPrice,
              property.status,
              property.published,
              property.viewCount,
              `${property.user?.firstName ? property.user.firstName : ''} ${
                property.user?.lastName ? property.user.lastName : ''
              }`,
              property.user.email,
              property.propertyId,
              property.user.id,
            ]);
          },
          handle: (res: any) => {
            console.log(res);
            // no matching records found
            if (res.status === 500) {
              const data = res.json();
              alert(data.message);
              return { data: [] };
            }

            if (res.status === 404) return { data: [] };
            if (res.ok) return res.json();

            throw Error('oh no :(');
          },
          total: (data: any) => data.total,
        }}
        columns={columns}
        sort={{
          multiColumn: false,
          server: {
            url: (prev, columnsArray) => {
              if (!columnsArray.length) return prev;

              const col = columnsArray[0];
              const dir = col.direction === 1 ? 'asc' : 'desc';
              let colName = [
                'propertyId',
                'propertyTitle',
                'createdAt',
                'updatedAt',
                'listingPrice',
                'monthlyRentPrice',
                'status',
                'published',
                'viewCount',
                'userId',
                'userId',
              ][col.index];

              return `${prev}?order=${colName}&dir=${dir}`;
            },
          },
        }}
        pagination={{
          server: {
            url: (prev: string, page: number) =>
              `${prev}${prev.includes('?') ? '&' : '?'}page=${page + 1}&currency=${currency}`,
          },
        }}
        search={{
          server: {
            url: (prev: string, search: string) => `${prev}?search=${search}`,
          },
        }}
        language={{
          search: {
            placeholder: 'Search listings...',
          },
          // pagination: {
          //   previous: " < Previous",
          //   next: "Next >",
          //   showing: "Showing",
          //   results: () => "properties",
          // },
        }}
      />
      <ToastContainer transition={Slide} />
    </>
  );
};

export default AdminManageListings;
