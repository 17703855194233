import React from 'react';

const ListingGuidelines = () => {
  return (
    <div className="container max-w-[850px] mx-auto space-y-6 py-10 px-4">
      <div className="font-light text-2xl text-center">LISTING GUIDELINES</div>
      <div className="text-sm">
        To assure your listing is approved swiftly post submission, please follow the below
        guidelines.
      </div>

      <div>
        <div className="font-light text-xl mb-2">Listing Description</div>
        <ul className="text-sm space-y-2 list-disc font-extralight">
          <li>
            We strongly recommend users to publish lengthy descriptions with all the keywords you
            think appropriate.
          </li>
          <li>
            Users like reading well structured content and tend to stay longer if the content is
            interesting and insightful.
          </li>
          <li>The use of third party links to other websites is not allowed.</li>
          <li>The use of phone numbers and other contact details is not allowed.</li>
          <li>The reference to a third party company is not allowed.</li>
        </ul>
      </div>

      <div>
        <div className="font-light text-xl mb-2">Photo Guidelines</div>
        <ul className="text-sm space-y-2 list-disc font-extralight">
          <li>
            Please use high quality photos. For more information on how deehiy.com illustrates
            photos, please read these photo tips
          </li>
          <li>
            Our system automatically crops images to improve user experience. Please read the tips
            here how we crop to assure your listing will portrait stunning photos.
          </li>
          <li>Company watermarks are not allowed and such listings will be rejected.</li>
          <li>Marketing content on images is not allowed.</li>
          <li>Faces of humans must be blurred to comply with our Privacy Policy</li>
          <li>
            Plate numbers of cars or house numbers must be blurred to comply with our Privacy Policy
          </li>
          <li>Photos illustrating violence, pornographic content or suffering are not allowed.</li>
        </ul>
      </div>
    </div>
  );
};

export default ListingGuidelines;
