import React from 'react';
import FilterSideButton from '../main/filter/FilterSideButton';
import Modal2 from '../listing/Modal2';
import TutoPopup from '../main/TutoPopup';
import { useTranslation } from 'react-i18next';

const OverlaySidebarLayout = ({
  Filter,
  children,
  isFilterOpen,
  setIsFilterOpen,
  isFullWidth,
  isMarket,
  ...Props
}: any) => {
  const isMobile = window.innerWidth <= 768;
  const { t } = useTranslation();

  return (
    <div>
      {isMarket ? (
        <div className="absolute bottom-[63vh] left-10 z-10">
          <TutoPopup
            storageKey="tuto-filter"
            title={t('popup-tuto-title-2')}
            text={t('popup-tuto-text-2')}
            placement="left"
          >
            <FilterSideButton setIsFilterOpen={setIsFilterOpen} isFilterOpen={isFilterOpen} />
          </TutoPopup>
        </div>
      ) : (
        <FilterSideButton setIsFilterOpen={setIsFilterOpen} isFilterOpen={isFilterOpen} />
      )}

      {isMobile ? (
        <Modal2 showModal={isFilterOpen} setShowModal={setIsFilterOpen}>
          <Filter setIsFilterOpen={setIsFilterOpen} {...Props} />
        </Modal2>
      ) : (
        <div
          className={`w-full z-10  fixed top-14 xl:w-[400px] lg:w-[370px] md:w-[250px] shadow-xl h-screen bg-white transition-transform duration-1000 ease-in-out ${
            isFilterOpen ? 'translate-x-0' : '-translate-x-full'
          }`}
        >
          <Filter setIsFilterOpen={setIsFilterOpen} {...Props} />
        </div>
      )}
      <div className={`${!isFullWidth && 'width-overlay-layout'} mx-auto`}>{children}</div>
      {isFilterOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-80 z-11"
          onClick={() => setIsFilterOpen(false)}
        ></div>
      )}
    </div>
  );
};

export default OverlaySidebarLayout;
