import React, { useState, useEffect, useCallback } from 'react';
import { User, PostType } from '../../types/types';
import { getPostByIdService } from '../../services/postService';
import {
  createCommentService,
  toggleLikeCommentService,
  deleteCommentService,
} from '../../services/commentService';
import { Post, HeaderUser, PostContainer, AvatarImage } from './Square';

import CreatePostModalContent, { DndUploader, uploadMedia } from './CreatePostModalContent';
import { useAuth } from '../../contexts/auth';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useGlobal } from '../../contexts/global';
import { AiFillHeart } from 'react-icons/ai';
import Loader from '../main/Loader';
import EMTextArea from './EMTextArea';
import { adjustImageSize } from '../../utils/adjustImageSize';
import Modal2 from '../listing/Modal2';
import Slideshow from '../listing/Slideshow';
import FullPortalModal from '../listing/FullPortalModal';
import { useTranslation } from 'react-i18next';

type NewComment = {
  author: User;
  text?: string;
  images?: string[];
  gifs?: string[];
};

type Comment = {
  commentId: number;
  author: any;
  businessId?: number;
  createdAt: string;
  likesPerComment: any;
  content?: string;
  media: any[];
};

const AddCommentBox = ({ postComment, onImageUpload, comments }: any) => {
  const { currentUser } = useAuth();
  const [text, setText] = useState('');
  const [media, setMedia] = useState<any[]>([]);
  const [canPost, setCanPost] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [reset, setReset] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  // const peopleMention = [
  //   { value: 'pierre', display: 'pierre' },
  //   { value: 'mendes', display: 'Mendes' },
  // ];

  const peopleMention = comments.reduce((acc: any[], comment: any) => {
    const fullName = comment.author.firstName + ' ' + comment.author.lastName;

    const alreadyExists = acc.some((item) => item.value === fullName);

    if (!alreadyExists) {
      acc.push({
        value: fullName,
        display: fullName,
      });
    }

    return acc;
  }, []);

  const hash = [{ value: 'Deehiy', display: 'Deehiy' }];

  const init = () => {
    setReset(true);
    setText('');
    setMedia([]);
    setCanPost(false);
  };

  const postValidatedComment = async () => {
    if (!currentUser) {
      navigate('/login', { state: { redirectTo: location } });
      return;
    }

    if (!canPost) {
      alert(t('Cannot post an empty comment'));
      return;
    }
    try {
      setIsLoading(true);

      const uploadedMedia = await uploadMedia(media);

      await postComment({
        authorId: currentUser.id,
        content: text.trim(),
        media: uploadedMedia,
      });

      init();
    } catch (error) {
      alert(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (text.trim().length === 0 && media.length === 0) {
      setCanPost(false);
    } else {
      setCanPost(true);
    }
  }, [text, media]);

  useEffect(() => {
    if (reset) {
      setReset(false);
    }
  }, [reset]);

  return (
    <div className="w-full border border-transparent border-t-deehiy flex py-2 relative">
      {isLoading && (
        <div className=" absolute top-0 z-10 left-0 bg-white bg-opacity-100 w-full h-full flex p-12 justify-center items-center">
          <Loader />
        </div>
      )}

      <div className="w-16 pl-1">
        <AvatarImage imageUrl={currentUser?.image!} />
      </div>

      <div className="flex flex-col px-2 w-full">
        <DndUploader
          maxNumber={1}
          images={media}
          setImages={setMedia}
          setText={setText}
          text={text}
        >
          <EMTextArea
            placeHolder={t('Post your comment')}
            name="my-comment"
            value={text}
            changeHandler={setText}
            hash={hash}
            peopleMention={peopleMention}
            onImageUpload={onImageUpload}
            reset={reset}
          />
        </DndUploader>
        <div className="flex w-full justify-end">
          <div className="gap-x-2 flex">
            <button
              onClick={init}
              className={`text-sm cursor-pointer border-none outline-none focus:outline-none active:text-gray-600 hover:text-gray-500 text-gray-400 mr-0 py-1 px-2 `}
            >
              {t('Cancel')}
            </button>
            <button
              onClick={postValidatedComment}
              disabled={!canPost}
              className={`${
                canPost ? 'cursor-pointer opacity-100' : 'opacity-60 cursor-not-allowed'
              } primary-btn mr-0 py-1 px-2 text-xs`}
            >
              {t('Comment')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const CommentBox = ({
  comment: { commentId, author, content = '', media = [], createdAt, likesPerComment },
  setComments,
}: {
  comment: Comment;
  setComments: React.Dispatch<React.SetStateAction<Comment[]>>;
}) => {
  const { currentUser } = useAuth();
  const { t } = useTranslation();
  const [isLiked, setIsLiked] = useState(
    likesPerComment.some((like: any) => like.userId === currentUser?.id)
  );
  const [numberOfLikes, setNumberOfLikes] = useState(likesPerComment.length);

  const toggleLike = async (id: number) => {
    try {
      const res = await toggleLikeCommentService(id);
      setIsLiked(res.data.liked);
      if (res.data.liked) {
        setNumberOfLikes((prev: number) => prev + 1);
      } else {
        setNumberOfLikes((prev: number) => prev - 1);
      }
    } catch (error: any) {
      alert(error.message);
    }
  };

  const deleteComment = async (id: number) => {
    try {
      await deleteCommentService(id);

      setComments((prevComments: Comment[]) =>
        prevComments.filter((comment: Comment) => comment.commentId !== id)
      );
    } catch (error: any) {
      alert(error.message);
    }
  };

  return (
    <div className="w-full border border-transparent border-t-deehiy flex flex-col py-3">
      <HeaderUser user={author} createdAt={createdAt}>
        {author.id === currentUser?.id && (
          <div className="cursor-pointer hover:text-black" onClick={() => deleteComment(commentId)}>
            {t('Delete')}
          </div>
        )}
      </HeaderUser>
      <div className="flex flex-col pl-3 w-full py-6">
        <div dangerouslySetInnerHTML={{ __html: content }}></div>
        <div>
          {media &&
            media.length > 0 &&
            media.map((img: any) => (
              <FullPortalModal
                Btn={({ setShowModal }: any) => (
                  <div key={img.url} className="h-52 mx-auto hover:cursor-pointer w-52">
                    <img
                      src={adjustImageSize(img.url)}
                      alt="media1"
                      className="w-full h-full object-cover"
                      onClick={() => setShowModal(true)}
                    />
                  </div>
                )}
              >
                <Slideshow list={media} defaultIndex={0} />
              </FullPortalModal>
            ))}
        </div>
      </div>

      <div className="flex gap-x-1 w-36 items-center px-2">
        <AiFillHeart
          onClick={() => {
            toggleLike(commentId);
          }}
          className={`w-4 h-4 sm:h-4 sm:w-4
                ${isLiked ? 'text-deehiy-red' : 'text-gray-300'}
                 duration-200 cursor-pointer`}
        />
        <div>{numberOfLikes}</div>
      </div>
    </div>
  );
};

const PostPage = () => {
  const { postId } = useParams();

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { modalType, showModal, setShowModal, setModalType } = useGlobal();

  const [comments, setComments] = useState<Comment[]>([]);
  const [currentPost, setCurrentPost] = useState(null);
  const [editedPost, setEditedPost] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const postComment = async (newComment: NewComment) => {
    try {
      const res = await createCommentService({ ...newComment, postId });
      const addedComment = res.data.comment;

      setComments([addedComment, ...comments]);
    } catch (err) {
      alert(err);
    }
  };

  const getPost = useCallback(async (id: number) => {
    try {
      setIsLoading(true);
      const res = await getPostByIdService(id);

      console.log('POST', res.data.post);

      setCurrentPost(res.data.post);
      setComments(res.data.post.comments);
    } catch (err: any) {
      console.log(err);
      setError(err.response.data.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getPost(Number(postId));
  }, [getPost, postId]);

  useEffect(() => {
    if (editedPost) {
      setShowModal(true);
      setModalType(editedPost.type);
    }
  }, [editedPost]);

  useEffect(() => {
    if (!showModal) {
      setEditedPost(null);
    }
  }, [showModal]);

  if (isLoading) return <Loader />;
  if (error)
    return (
      <div className="flex flex-col mx-auto p-12 items-center justify-center gap-y-12">
        <span>{error}</span>
        <div
          className="cursor-pointer font-bold hover:underline text-deehiy"
          onClick={() => navigate('/square')}
        >
          {t('go to Square')}
        </div>
      </div>
    );

  return (
    <div>
      <div className="width-overlay-layout mx-auto pb-20">
        <div className="z-40 top-16 bg-white w-full mx-0 sm:mx-5">
          <div className="w-full shadow-md py-4 gap-x-4 font-bold text-sm items-center px-8">
            {t('Post a comment')}
          </div>
        </div>
        <div className="mx-0 sm:mx-5 w-full mt-3">
          {currentPost && (
            <PostContainer>
              <Post
                post={currentPost}
                isPostPage={true}
                nbrOfComments={comments.length}
                setEditedPost={setEditedPost}
              />
              <AddCommentBox postComment={postComment} comments={comments} />
              {comments.length > 0 &&
                comments.map((com) => {
                  return <CommentBox key={com.commentId} comment={com} setComments={setComments} />;
                })}
            </PostContainer>
          )}
        </div>
      </div>
      {showModal && (
        <Modal2 showModal={showModal} setShowModal={setShowModal}>
          <CreatePostModalContent
            type={modalType as PostType}
            setShowModal={setShowModal}
            editedPost={currentPost}
            setEditedPost={setCurrentPost}
          />
        </Modal2>
      )}
    </div>
  );
};

export default PostPage;
