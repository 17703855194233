import React, { useState, useEffect } from 'react';
import NavSearch from '../listingMap/NavSearch';
import { useNavigate } from 'react-router-dom';
import { useGlobal } from '../../contexts/global';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';

interface RecentSearchesProps {
  recentSearches: string[];
  clearRecentSearches: () => void;
  onRecentSearchClick: (search: string) => void;
}

const RecentSearches: React.FC<RecentSearchesProps> = ({
  recentSearches,
  clearRecentSearches,
  onRecentSearchClick,
}) => {
  return (
    <div className="pt-12">
      <div className="flex items-center justify-between w-full">
        <span className="font-bold">Recent:</span>
        <button
          className={`${
            recentSearches && recentSearches.length > 0 ? 'opacity-100' : 'opacity-40'
          } px-3 bg-white py-2 rounded-xl shadow-sm`}
          onClick={clearRecentSearches}
        >
          clear
        </button>
      </div>
      <ul>
        {recentSearches.map((search, index) => (
          <li className="py-3" key={index} onClick={() => onRecentSearchClick(search)}>
            {search}
          </li>
        ))}
      </ul>
    </div>
  );
};

const MobileSearch = () => {
  const navigate = useNavigate();
  const { keyword } = useGlobal();
  //   const { setCoordinates } = useProperty();
  //   const [locationObject, setLocationObject] = useState(initialLocation);
  const [search, setSearch] = useState('');
  const [recentSearches, setRecentSearches] = useState<string[]>([]);
  const [message, setMessage] = useState('');

  const clearRecentSearches = () => {
    // Clear recent searches and sessionStorage
    setRecentSearches([]);
    sessionStorage.removeItem('recentSearches');
  };

  const handleRecentSearchClick = (loc: string) => {
    setSearch(loc);
  };

  // Load recent searches from sessionStorage when the component mounts
  useEffect(() => {
    const savedSearches = sessionStorage.getItem('recentSearches');
    if (savedSearches) {
      setRecentSearches(JSON.parse(savedSearches));
    }
  }, []);

  useEffect(() => {
    setSearch(keyword);
  }, [keyword]);

  useEffect(() => {
    // setCoordinates({ lat: locationObject.latitude, lng: locationObject.longitude });
    if (search.trim().length > 0 && !recentSearches.includes(search)) {
      const updatedSearches = [...recentSearches, search];
      setRecentSearches(updatedSearches);
      sessionStorage.setItem('recentSearches', JSON.stringify(updatedSearches));
      setMessage('');
    }
  }, [search]);

  return (
    <div className="p-4">
      <div className="flex items-center ">
        <ChevronLeftIcon className="text-deehiy font-bold h-7 w-7" onClick={() => navigate(-1)} />
        <NavSearch search={search} setSearch={setSearch} setMessage={setMessage} />
      </div>
      <span className="text-gray-500">{message}</span>
      <RecentSearches
        onRecentSearchClick={handleRecentSearchClick}
        recentSearches={recentSearches}
        clearRecentSearches={clearRecentSearches}
      />
    </div>
  );
};

export default MobileSearch;
