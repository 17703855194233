import React from 'react';

export const SmartText = ({ text, length = 300 }: any) => {
  const [showLess, setShowLess] = React.useState(true);

  if (text.length < length) {
    return <div dangerouslySetInnerHTML={{ __html: text }}></div>;
  }

  return (
    <div>
      <p
        dangerouslySetInnerHTML={{
          __html: showLess ? `${text.slice(0, length)}...` : text,
        }}
      ></p>
      <p
        className="hover:opacity-100 opacity-80 text-deehiy cursor-pointer text-sm"
        onClick={() => setShowLess(!showLess)}
      >
        &nbsp;view {showLess ? 'more' : 'less'}
      </p>
    </div>
  );
};
