import React, { useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { businessStep2FormSchema } from '../../../lib/yup';
import LocationSearch from '../../steps/propertySteps/Location/LocationSearch';
import { RxCrossCircled } from 'react-icons/rx';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { useBusiness } from '../../../contexts/business';
import Input from '../../form/Input';
import { useTranslation } from 'react-i18next';

const Step2 = ({ setIndex }: any) => {
  const methods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(businessStep2FormSchema),
  });

  const initialLocation = {
    city: '',
    country: '',
    latitude: 0,
    longitude: 0,
    postalCode: '',
  };

  const { business, setBusinessContext } = useBusiness();
  const { t } = useTranslation();
  const [canClickNext, setCanClickNext] = useState(false);

  const [locationObject, setLocationObject] = useState(initialLocation);
  const [locationString, setLocationString] = useState('');

  const handleChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;

    setBusinessContext({ ...business, [name]: value });
  };

  useEffect(() => {
    setLocationObject(initialLocation);
  }, [locationString]);

  useEffect(() => {
    const { city, postalCode, country, latitude, longitude } = locationObject;

    setBusinessContext({
      ...business,
      city,
      postalCode,
      latitude,
      longitude,
      isoCodeCountry: country,
      location: locationString,
    });

    if (
      city === '' ||
      // postalCode === '' ||
      country === '' ||
      locationString === '' ||
      business.streetNameHouseNumber === ''
    ) {
      setCanClickNext(false);
    } else {
      setCanClickNext(true);
    }
  }, [locationString, location, business.streetNameHouseNumber]);

  return (
    <div className="h-full">
      <div className="space-y-6 pt-3 pb-3">
        <div className="w-full flex justify-center text-2xl sm:text-3xl md:text-4xl xl:text-5xl font-bold ">
          {t('What’s your address')}
        </div>
        <div className="w-full flex text-center justify-center text-base sm:text-md font-bold">
          {t('address_to_verify_business')}
          <br />
          <br />
        </div>
      </div>
      <div className="flex h-[62vh] pt-3">
        <div className=" hidden sm:flex w-1/2 justify-end">
          <img alt="add business" className=" mr-12" src="/images/addbusiness2.svg" />
        </div>
        <div className="sm:w-1/2 w-full">
          <div className="flex-col w-full h-full relative sm:w-3/5 mx-2 sm:mx-12 ">
            <FormProvider {...methods}>
              <form className="flex flex-col space-y-12">
                <div>
                  <LocationSearch
                    locationString={locationString}
                    setLocationString={setLocationString}
                    setLocationObject={setLocationObject}
                    label="location"
                  />
                  <div
                    className={`flex flex-col duration-700 text-sm pl-2 py-1 bg-gray-100 ${
                      locationObject.country ? 'h-18' : ' hidden h-0'
                    }`}
                  >
                    <span className="grid grid-cols-2">
                      {t('country')}:
                      {locationObject.country ? (
                        <AiOutlineCheckCircle className="text-deehiy w-5 h-5" />
                      ) : (
                        <RxCrossCircled className="text-deehiy-red animate-pulse w-5 h-5" />
                      )}
                    </span>
                    <span className="grid grid-cols-2">
                      {t('city')}:
                      {locationObject.city ? (
                        <AiOutlineCheckCircle className="text-deehiy w-5 h-5" />
                      ) : (
                        <RxCrossCircled className="text-deehiy-red animate-pulse w-5 h-5" />
                      )}
                    </span>
                    {/* <span className="grid grid-cols-2">
                      {t('postal code')}:
                      {locationObject.postalCode ? (
                        <AiOutlineCheckCircle className="text-deehiy w-5 h-5" />
                      ) : (
                        <RxCrossCircled className="text-deehiy-red animate-pulse w-5 h-5" />
                      )}
                    </span> */}
                  </div>
                </div>
                {/* street name  */}
                <Input
                  label={t('street name, house number')}
                  type="text"
                  id="streetNameHouseNumber"
                  name="streetNameHouseNumber"
                  onChange={handleChange}
                  value={business.streetNameHouseNumber}
                  placeholder={t('street name, house number')}
                />
              </form>
            </FormProvider>
            <div className="w-full flex justify-between absolute bottom-2">
              <button
                className="form-back-btn"
                onClick={() => setIndex((prev: number) => prev - 1)}
              >
                {t('back')}
              </button>
              <button
                disabled={!canClickNext}
                className={`form-next-btn ${!canClickNext && 'opacity-50 cursor-not-allowed'}`}
                onClick={() => setIndex((prev: number) => prev + 1)}
              >
                {t('next')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step2;
