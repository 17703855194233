import { accessControlConfig, EDIT_LISTING } from '../constants/constant';
import { Property } from '../types/types';

export const isUserAssignedToProperty = (
  userId: number,
  property: Property,
  accessRule?: number
) => {
  // if user is ADMIN or MANAGER
  if (accessRule && (accessRule === 1 || accessRule === 2)) {
    return true;
  }
  // if the property is assigned to someone, we use this user ID, otherwise we user the assignee ID
  const assignId = property.assignment ? property.assignment.assignedTo.id : property.userId;

  return userId === assignId;
};

export const canAccess = (user: any, action: string, property?: Property) => {
  const roleAccess: any = accessControlConfig[action as keyof typeof accessControlConfig];
  if (roleAccess) {
    const accessRule = roleAccess[user.roleId];

    if (action === EDIT_LISTING && accessRule === 4) {
      // For 'EDIT_LISTING' action and 'agent' role, check user assignment
      if (property) {
        return isUserAssignedToProperty(user.id, property, accessRule);
      }
      alert(
        'Sorry, you are not allowed to edit this listing, please contact your admin or manager.'
      );
    } else {
      return accessRule;
    }
  }
  return true;
};
