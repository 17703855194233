import React, { useState, useEffect, useRef } from 'react';
import { useProperty } from '../../../contexts/property';
import { Owner, OwnerTitle } from '../../../types/types';
import Input from '../../form/Input';
import Textarea from '../../form/Textarea';
import Select from '../../form/Select';
import { FormProvider, useForm } from 'react-hook-form';
import { saveOwner, fetchOwner } from '../../../services/propertyService';
import Loader from '../../main/Loader';
import { useTranslation } from 'react-i18next';

const OwnerContent = () => {
  const methods = useForm({
    mode: 'onTouched',
    // resolver: yupResolver(UserFormSchema),
  });
  const { currentProperty } = useProperty();
  const { t } = useTranslation();

  const [owner, setOwner] = useState<Owner>({ title: 'Mrs.', email: '', phone: '' });
  const [isEditingOwner, setIsEditingOwner] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({ phone: '', email: '' });

  const firstUpdate = useRef(true);

  const handleKeyDown = (event: any) => {
    if (event.keyCode == 13 && !event.shiftKey) {
      setIsEditingOwner(false);
    }
  };

  const validatePhoneNumber = (phone: any) => {
    const phoneNumberRegex = /^[0-9()+]+$/;
    if (phoneNumberRegex.test(phone) || phone === '' || phone === undefined) return true;

    return false;
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailRegex.test(email) || email === '' || email === undefined) return true;

    return false;
  };

  const checkInput = (updatedOwner: Owner) => {
    if (!validatePhoneNumber(updatedOwner.phone)) {
      setError({ ...error, phone: 'Please write a correct phone number' });
      return false;
    } else {
      setError({ ...error, phone: '' });
    }

    if (!validateEmail(updatedOwner.email!)) {
      setError({ ...error, email: 'Please write a correct email address' });
      return false;
    } else {
      setError({ ...error, email: '' });
    }

    return true;
  };

  const HandleEmailChange = (updatedEmail: string) => {
    if (!validateEmail(updatedEmail) && updatedEmail.length > 5) {
      setError({ ...error, email: 'Please write a correct email address' });
    } else {
      setError({ ...error, email: '' });
    }
    setOwner({ ...owner, email: updatedEmail });
  };

  const HandlePhoneChange = (updatedPhone: any) => {
    if (!validatePhoneNumber(updatedPhone) && updatedPhone !== '') {
      setError({ ...error, phone: 'Please enter a valid phone number' });
    } else {
      setError({ ...error, phone: '' });
    }
    setOwner({ ...owner, phone: updatedPhone });
  };

  const handleOwnerEditClick = () => {
    if (!checkInput(owner)) {
      setIsEditingOwner(true);
    } else {
      setIsEditingOwner((p) => !p);
    }
  };

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    if (!isEditingOwner && currentProperty.propertyId) {
      saveOwner(currentProperty.propertyId, owner).then((data) => {
        console.log(data);
      });
    }
  }, [currentProperty, isEditingOwner]);

  useEffect(() => {
    if (currentProperty.propertyId) {
      setIsLoading(true);

      fetchOwner(currentProperty.propertyId).then((fetchedOwner) => {
        setOwner(fetchedOwner.data);
        setIsLoading(false);
      });
    }
  }, []);

  if (isLoading) return <Loader />;

  return (
    <div className="w-full md:w-full">
      <div className="space-x-4">
        <FormProvider {...methods}>
          <form onKeyDown={handleKeyDown}>
            <div className="w-full md:w-full">
              <div className="pt-2 pb-4">
                <div className="flex justify-between w-full pb-2">
                  <span className="font-light">{t('Owner details')}</span>
                  <button
                    type="button"
                    className="text-deehiy font-bold"
                    onClick={handleOwnerEditClick}
                    onKeyDown={handleKeyDown}
                  >
                    {!isEditingOwner ? t('Edit') : t('Save')}
                  </button>
                </div>
                <div className="flex w-full space-x-4 items-center">
                  <span className="whitespace-nowrap font-light py-3 w-[110px]">{t('Title')}:</span>
                  <div className="max-w-[150px]">
                    {!isEditingOwner ? (
                      owner?.title
                    ) : (
                      <Select
                        id="title"
                        name="title"
                        label=""
                        placeholder="title"
                        value={owner?.title as OwnerTitle}
                        onChange={(e) => {
                          setOwner({
                            ...owner,
                            title: e.target.value as OwnerTitle,
                          });
                        }}
                      >
                        <option value={'Mr.'} key={'Mr.'}>
                          Mr.
                        </option>
                        <option value={'Mrs.'} key={'Mrs.'}>
                          Mrs.
                        </option>
                        <option value={'Miss'} key={'Miss'}>
                          Miss
                        </option>
                        <option value={'Ms.'} key={'Ms.'}>
                          Ms.
                        </option>
                        <option value={'Mx.'} key={'Mx.'}>
                          Mx.
                        </option>
                        <option value={'Ind.'} key={'Ind.'}>
                          Ind.
                        </option>
                      </Select>
                    )}
                  </div>
                </div>
                <div className="flex w-full space-x-4 items-center">
                  <span className="whitespace-nowrap font-light py-3 w-[110px]">
                    {t('First name')}:
                  </span>
                  <div>
                    {!isEditingOwner ? (
                      <div className="">{owner?.firstName}</div>
                    ) : (
                      <Input
                        label=""
                        name="firstName"
                        id="firstName"
                        type="text"
                        maxLength={50}
                        value={owner?.firstName}
                        onChange={(e: any) => setOwner({ ...owner, firstName: e.target.value })}
                      />
                    )}
                  </div>
                </div>
                <div className="flex w-full space-x-4 items-center">
                  <span className="whitespace-nowrap font-light py-3 w-[110px]">
                    {t('Last name')}:
                  </span>
                  <div>
                    {!isEditingOwner ? (
                      <div className="">{owner?.lastName}</div>
                    ) : (
                      <Input
                        label=""
                        name="lastName"
                        id="lastName"
                        type="text"
                        maxLength={50}
                        value={owner?.lastName}
                        onChange={(e: any) => setOwner({ ...owner, lastName: e.target.value })}
                      />
                    )}
                  </div>
                </div>
                <div className="flex w-full space-x-4 items-center">
                  <span className="whitespace-nowrap font-light py-3 w-[110px]">{t('Email')}:</span>
                  <div>
                    {!isEditingOwner ? (
                      <div className="">{owner?.email}</div>
                    ) : (
                      <>
                        <Input
                          label=""
                          name="email"
                          id="email"
                          type="email"
                          maxLength={150}
                          value={owner?.email}
                          onChange={(e: any) => HandleEmailChange(e.target.value)}
                        />
                        <span className="text-red-500 text-xs">{error.email}</span>
                      </>
                    )}
                  </div>
                </div>
                <div className="flex w-full space-x-4 items-center">
                  <span className="whitespace-nowrap font-light py-3 w-[110px]">{t('Phone')}:</span>
                  <div>
                    {!isEditingOwner ? (
                      <div className="">{owner?.phone}</div>
                    ) : (
                      <>
                        <Input
                          label=""
                          name="phone"
                          id="phone"
                          type="tel"
                          pattern="[0-9]*"
                          title="Please enter a valid phone number (10 digits)"
                          maxLength={150}
                          value={owner?.phone}
                          onChange={(e: any) => HandlePhoneChange(e.target.value)}
                        />
                        <span className="text-red-500 text-xs">{error.phone}</span>
                      </>
                    )}
                  </div>
                </div>
                <div className="flex w-full space-x-4">
                  <span className="whitespace-nowrap font-light py-3 w-[110px]">{t('Note')}:</span>
                  <div className="flex flex-row w-full pl-3">
                    {!isEditingOwner ? (
                      <div className="pt-3">{owner?.note}</div>
                    ) : (
                      <Textarea
                        cols={5}
                        maxLength={1000}
                        textLength={owner?.note ? owner?.note.length : 0}
                        rows={4}
                        placeholder=""
                        label=""
                        id="note"
                        // type='text'
                        value={owner?.note}
                        onChange={(e: any) => setOwner({ ...owner, note: e.target.value })}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default OwnerContent;
