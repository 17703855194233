import React, { useState, useEffect, useRef } from 'react';
import { NumericFormat } from 'react-number-format';
import { useProperty } from '../../../contexts/property';
import { SellerFinanceCheckBox } from '../../steps/propertySteps/SelectSpecifics';
import Toggle2 from '../../form/Toggle2';
import { useTranslation } from 'react-i18next';

const PriceContent = ({ modifyProperty }: any) => {
  const { currentProperty, setCurrentProperty } = useProperty();
  const { t } = useTranslation();

  const [isEditingListingPrice, setIsEditingListingPrice] = useState(false);

  const firstUpdate = useRef(true);

  const handleListingPriceEditClick = () => {
    setIsEditingListingPrice((p) => !p);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      setIsEditingListingPrice(false);
    }
  };

  const handlePriceChange = (e: any) => {
    let value = e.target.value; // string
    const name = e.target.name;
    const formatted = Number(value.replace(/ /g, ''));
    setCurrentProperty({ ...currentProperty, [name]: formatted });
  };

  // useEffect(() => {
  //   if (!isEditingListingPrice && price !== currentProperty.listingPrice) {
  //     let formattedPrice = 0;
  //     // price is saved as a number but converted in string in NumericFormat component
  //     if (typeof price === 'string') {
  //       formattedPrice = Number(price.replace(/ /g, ''));
  //     } else if (price === null) {
  //       formattedPrice = 0;
  //     } else {
  //       formattedPrice = price;
  //     }
  //     setCurrentProperty({ ...currentProperty, listingPrice: formattedPrice });
  //   }
  // }, [price, isEditingListingPrice]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    modifyProperty(currentProperty);
  }, [
    currentProperty.listingPrice,
    currentProperty.monthlyRentPrice,
    currentProperty.dailyRentPrice,
    currentProperty.hasSellerFinance,
    currentProperty.isPOR,
  ]);

  return (
    <div>
      <div className="w-full md:w-full">
        <div className="pt-2 pb-4 flex justify-between items-start">
          <div className="flex flex-col gap-y-6">
            {currentProperty.isForSale || currentProperty.isOffPlan ? (
              <div className="flex flex-col">
                <div className="">
                  <span className="w-40 flex ">{t('Sales price')}:</span>

                  {!isEditingListingPrice ? (
                    <NumericFormat
                      value={currentProperty.listingPrice}
                      thousandSeparator=" "
                      displayType={'text'}
                      prefix={currentProperty.isoCodeCurrency + ' '}
                    />
                  ) : (
                    <NumericFormat
                      id="listingPrice"
                      className=" focus:ring-0 rounded border-gray-300 focus:border-gray-300"
                      name="listingPrice"
                      onChange={handlePriceChange}
                      value={currentProperty.listingPrice}
                      thousandSeparator=" "
                      placeholder="0"
                      onKeyDown={handleKeyDown}
                    />
                  )}
                </div>
                <div className=" gap-x-2 flex py-3 items-center">
                  <span>{t('Hide price')}</span>
                  <Toggle2
                    setIsToggled={() =>
                      setCurrentProperty({ ...currentProperty, isPOR: !currentProperty.isPOR })
                    }
                    isToggled={currentProperty.isPOR}
                  />
                </div>

                <SellerFinanceCheckBox
                  value={currentProperty.hasSellerFinance}
                  handleChange={(e: any) => {
                    setCurrentProperty({
                      ...currentProperty,
                      hasSellerFinance: e.target.checked,
                    });
                  }}
                />
              </div>
            ) : (
              ''
            )}
            {currentProperty.isForRent && (
              <div className="flex flex-col">
                <span className="w-40 flex ">{t('Rental price / month')}:</span>

                {!isEditingListingPrice ? (
                  <NumericFormat
                    value={Number(currentProperty.monthlyRentPrice)}
                    thousandSeparator=" "
                    displayType={'text'}
                    prefix={currentProperty.isoCodeCurrency + ' '}
                  />
                ) : (
                  <NumericFormat
                    id="monthlyRentPrice"
                    className=" focus:ring-0 rounded border-gray-300 focus:border-gray-300"
                    name="monthlyRentPrice"
                    onChange={handlePriceChange}
                    value={Number(currentProperty.monthlyRentPrice)}
                    thousandSeparator=" "
                    placeholder="0"
                    onKeyDown={handleKeyDown}
                  />
                )}
                <div
                  className={` ${currentProperty.frequency === 'yearly' ? 'hidden' : 'flex'} pt-2`}
                >
                  <input
                    className="w-5 h-5 text-deehiy cursor-pointer bg-white outline-none  focus:outline-transparent focus:ring-transparent border-gray-300 rounded"
                    type="checkbox"
                    name="daily"
                    checked={currentProperty.hasDailyRental}
                    onChange={(e) =>
                      setCurrentProperty({ ...currentProperty, hasDailyRental: e.target.checked })
                    }
                  />
                  <div className=" text-justify w-full flex flex-wrap gap-x-4 font-normal">
                    <span className="whitespace-nowrap">
                      &nbsp;&nbsp;{t('Also available for daily rental')}
                    </span>{' '}
                  </div>
                </div>
              </div>
            )}
            {currentProperty.hasDailyRental && (
              <div className="">
                <span className="w-40 flex ">{t('Rental price / day')}:</span>

                {!isEditingListingPrice ? (
                  <NumericFormat
                    value={Number(currentProperty.dailyRentPrice)}
                    thousandSeparator=" "
                    displayType={'text'}
                    prefix={currentProperty.isoCodeCurrency + ' '}
                  />
                ) : (
                  <NumericFormat
                    id="dailyRentPrice"
                    className=" focus:ring-0 rounded border-gray-300 focus:border-gray-300"
                    name="dailyRentPrice"
                    onChange={handlePriceChange}
                    value={Number(currentProperty.dailyRentPrice)}
                    thousandSeparator=" "
                    placeholder="0"
                    onKeyDown={handleKeyDown}
                  />
                )}
              </div>
            )}
          </div>

          <button
            type="button"
            className="text-deehiy font-bold"
            onClick={handleListingPriceEditClick}
          >
            {!isEditingListingPrice ? t('Edit') : t('Save')}
          </button>
        </div>

        <div className="w-full sm:w-2/3 text-sm p-6 border border-deehiy rounded-2xl mt-5">
          {t('change_price_text')}
        </div>
      </div>
    </div>
  );
};

export default PriceContent;
