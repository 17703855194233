import http from './httpService';
const apiArticleEndpoint = '/api/articles';

// article: Retrieve all articles
export const getAllArticles = () => {
  return http.get(`${apiArticleEndpoint}`);
};

// retrieve article by Id
export const getArticleById = (articleId: number) => {
  return http.get(`${apiArticleEndpoint}/${articleId}`);
};
