import {
  HOUSE,
  CONDO,
  TOWNHOUSE,
  LAND,
  AGENCY,
  MONTHLY_CONTRACT,
  YEARLY_CONTRACT,
  PENDING,
} from '../constants/constant';
import {
  UserContext,
  PropertyContext,
  Property,
  User,
  Hunt,
  Business,
  MarketFilter,
} from '../types/types';

// const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const storedCurrency = sessionStorage.getItem('currency');

export const initialUser: User = {
  id: null,
  username: '',
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  roles: '',
  image: process.env.REACT_APP_DEFAULT_USER_URL,
  phone: '',
};

export const initialBusinessUser: User = {
  ...initialUser,
  roleId: 4,
  status: 'pending',
};

export const initialContext: UserContext = {
  currentUser: null,
  message: '',
  isLoading: true,
  loginWithSocialApi: async () => '',
  loginUser: async () => '',
  LoginWithToken: async () => '',
  registerUser: async () => '',
  updateUser: async () => '',
  logoutUser: () => [],
  resetPasswordUser: async () => '',
  resetUpdatePasswordUser: async () => '',
  fetchUserById: async () => initialUser,
  setCurrentUserAsBusinessAdmin: () => '',
};

export const initialProperty: Property = {
  // Basic info
  userId: null,
  isForSale: false,
  isForRent: false,
  isOffPlan: false,
  // propertyId: null,
  propertyTitle: '',
  propertyType: '',
  propertyDescription: '',

  // availibilty
  availability: null,
  //furnishing status
  furnishingStatus: 'fully',

  // Date and Time
  builtInDate: '',
  lastRenovationDate: '',

  // Property details
  parkingLots: null,
  ceilingHeight: null,
  floorLevel: null,
  features: [],

  // Images and Files
  images: [],
  cloudinaryImages: [],
  cloudinaryFps: [],
  imagesTempFiles: [],
  floorplansTempFiles: [],

  // Address
  province: '',
  district: '',
  postalCode: null,
  streetNameHouseNumber: '',
  address2: '',
  city: '',
  country: '',

  // Property details
  listingPrice: null,
  bedrooms: 0,
  bathrooms: 0,
  homeSize: null,
  lotSize: null,
  priceRent: 0,
  priceSell: 0,

  // Location
  latitude: 0,
  longitude: 0,

  // Other details
  published: false,
  showExactLocation: true,
  status: PENDING,

  //Price on request (POR)
  isPOR: false,
};

export const initialBusinessProperty: Property = {
  ...initialProperty,
  assignment: initialUser,
};

export const initialLatLong = { lat: 0, lng: 0 };

export const initialFilter: MarketFilter = {
  keyword: '',
  // Basic filters
  propertyTypes: [CONDO, HOUSE, TOWNHOUSE, LAND],
  // listingPrice: [0, 999999999],
  listingPrice: ['', ''],
  country: '',
  city: '',
  hasSellerFinance: false,

  // Advanced filters
  bedrooms: 0,
  bathrooms: 0,
  features: [],
  // Sorting
  order: { column: 'createdAt', direction: 'DESC' },

  // currency
  currency: storedCurrency || 'THB',

  // type
  isForSale: true,
  isForRent: false,
  isOffPlan: false,

  // frequency:
  frequency: [MONTHLY_CONTRACT, YEARLY_CONTRACT],

  hasDailyRental: false,

  // radius
  radius: '',
  centerLat: 0,
  centerLon: 0,
};

export const initialPropContext: PropertyContext = {
  // state variables
  userProperties: [],
  properties: [],
  property: initialProperty,
  currentProperty: initialProperty,
  filter: initialFilter,
  coordinates: initialLatLong,
  maxListingPrice: 0,
  pageIndex: 1,
  count: 0,
  hasMore: true,
  tempFilter: initialFilter,
  showMap: false,

  // state setters
  setCurrentProperty: () => [],
  initCurrentProperty: () => [],
  setFilter: () => [],
  setCoordinates: () => [],
  setPageIndex: () => [],
  setProperties: () => [],
  setHasMore: () => {},
  setTempFilter: () => {},
  setShowMap: () => {},

  // async functions
  fetchAllProperties: async () => [],
  fetchAllPropertiesForMap: async () => [],
  fetchPropertyById: async () => {},
  fetchCount: async () => {},
  fetchPublicPropertyById: async () => {},
  fetchPropertiesByUserId: () => new Promise(() => []),
  fetchMaxListingPrice: async () => {},
  createProperty: async () => '',
  modifyProperty: async () => '',
  adminModifyProperty: async () => '',
  saveProperty: async () => '',
  debouncedFetchProperties: () => new Promise(() => []),
  initCountryFromIP: async () => {},
};

export const initialHunt: Hunt = {
  userId: 0,
  isoCodeCountry: '',
  city: '',
  postalCode: '',
  areaRadius: null,
  propertyType: null,
  homeSize: null,
  lotSize: null,
  bedrooms: null,
  latitude: 0,
  longitude: 0,
  price: 0,
  location: '',
  status: 'active',
  features: [],
  isoCodeCurrency: '',
  isForSale: true,
  isForRent: false,
};

export const initialBusiness: Business = {
  adminId: 0,
  isoCodeCountry: '',
  city: '',
  location: '',
  streetNameHouseNumber: '',
  postalCode: '',
  latitude: 0,
  longitude: 0,
  image: '',
  businessName: '',
  businessCategory: AGENCY,
  status: 'active',
  phone: '',
  email: '',
  websiteUrl: '',
  description: '',
  isDedicatedProfile: true,
  haveWebsite: true,
  color: '#bbb',
  isWhiteLabel: true,
};
