import React, { useState } from 'react';
import { FaArrowCircleUp } from 'react-icons/fa';

const BackToTopButton = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
      /* you can also use 'auto' behaviour
           in place of 'smooth' */
    });
  };

  window.addEventListener('scroll', toggleVisible);

  return (
    <div className="relative w-full flex justify-end h-0">
      <button>
        <FaArrowCircleUp
          onClick={scrollToTop}
          style={{ display: visible ? 'inline' : 'none' }}
          className="absolute right-1 sm:right-6 bottom-1 w-8 sm:w-12 h-8 sm:h-12 m-4 text-deehiy opacity-30 hover:opacity-100 duration-300 hover:animate-bounce"
        />
      </button>
    </div>
  );
};

export default BackToTopButton;
