type CallbackFunction = (...args: any[]) => void;

export function throttle(cb: CallbackFunction, delay = 250): CallbackFunction {
  let shouldWait = false;

  return (...args: any[]) => {
    if (shouldWait) return;

    cb(...args);
    shouldWait = true;
    setTimeout(() => {
      shouldWait = false;
    }, delay);
  };
}

// export function debounce(cb: CallbackFunction, delay = 250) {
//   let timeout: NodeJS.Timeout;

//   return (...args: any[]) => {
//     clearTimeout(timeout);
//     timeout = setTimeout(() => {
//       cb(...args);
//     }, delay);
//   };
// }
// for async
export function debounce<T extends (...args: any[]) => Promise<any>>(cb: T, delay = 250) {
  let timeout: ReturnType<typeof setTimeout>;

  return (...args: any[]): Promise<any> => {
    clearTimeout(timeout);
    return new Promise((resolve, reject) => {
      timeout = setTimeout(() => {
        console.log('Debounced function is about to execute.');

        cb(...args)
          .then(resolve)
          .catch(reject);
      }, delay);
    });
  };
}
