import moment from 'moment-timezone';
export function getCountryByTimeZone(userTimeZone: string) {
  // Get all time zones from moment-timezone
  const allTimeZones = moment.tz.names();

  console.log(`Total number of time zones: ${allTimeZones.length}`);
  console.log(`time zones: ${allTimeZones}`);
  // Filter time zones that start with the user's time zone (assuming location within the zone)
  const filteredTimeZones = allTimeZones.filter((zone) => zone.startsWith(userTimeZone));

  // If a filtered time zone is found, extract the country code
  if (filteredTimeZones.length > 0) {
    const firstTimeZone = filteredTimeZones[0]; // Assuming first match is relevant
    const countryCode = firstTimeZone.split('/')[0]; // Assuming country code is the first part
    console.log(countryCode);
    // Use Intl.DisplayNames to get the full country name
    // const countryName = new Intl.DisplayNames(['en'], { type: 'region' }).of(countryCode);
    // return countryName;
    return 'test';
  }

  // Return the original time zone if no matching country is found
  return userTimeZone;
}

export async function getUserLocalisationByIP() {
  const apiKey = process.env.REACT_APP_EXTREME_IP_LOOKUP;

  try {
    const res = await fetch(`https://extreme-ip-lookup.com/json/?key=${apiKey}`);
    const response = await res.json();
    console.log('-> XTRM API', response);

    return response;
    // Handle the response here
  } catch (error) {
    console.error('Error fetching the user location:', error);
    return '';
  }
}
