import React from 'react';

const SalesPreLaunch = () => {
  return (
    <div className="mx-auto flex flex-col h-full sm:h-[70vh]  justify-center items-center px-4 sm:px-24 container">
      <div className="flex flex-col text-left w-full">
        {/* title */}
        <div className="text-2xl sm:text-5xl font-bold pt-12 sm:py-6">
          Welcome Real Estate Rockstars!
        </div>
        <div className="text-xl pb-4 sm:pb-16">
          We’re launching this part soon. Rest assured you will be notified.
        </div>
      </div>
      <div className="flex flex-col sm:flex-row">
        <div className="flex flex-col w-full sm:w-3/5 pr-2 sm:pr-12">
          <div className="text-sm py-6 ">
            We are finalizing last details and launch the community map very soon. Here is a small
            teaser.
          </div>
          <div className="py-3">
            <div className="text-sm font-bold text-deehiy">Benefits for property seekers</div>
            <div className="text-sm ">
              For the first time ever can property seekers now appoint any agent from around the
              world to handle specific tasks. It has never been easier or more convenient before.
            </div>
          </div>
          <div className="py-3">
            <div className="text-sm font-bold text-deehiy">Benefits for property agents </div>
            <div className="text-sm ">
              The world is yours! By dedicating your profile as an agent you will be listed as an
              agent on the global community map and can accept leads from potential clients from
              anywhere in the world.{' '}
            </div>
          </div>
        </div>
        <div className="flex justify-center w-full sm:w-2/5 p-6 items-center">
          <img alt="" src={'/images/prelaunch-agent.svg'} className="w-full" />
        </div>
      </div>
    </div>
  );
};

export default SalesPreLaunch;
