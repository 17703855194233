import React, { MouseEvent, ReactElement } from 'react';
import { HiX, HiOutlinePaperClip, HiCheck } from 'react-icons/hi';
// import { FileWithPreview } from '../../types/types';
import { FileStateObject } from './DropzoneInput';

type FilePreviewProps = {
  // file: FileWithPreview;
  file: FileStateObject;
  ref?: any;
  position?: any;
  type: string;
  deleteFile: any;
  readOnly: boolean;
};

export const FilePreview = React.forwardRef(
  ({ ...props }: FilePreviewProps, ref: any): ReactElement => {
    const { file, deleteFile, readOnly, position, type } = props;

    // const [isOpen, setIsOpen] = useState(false)

    const handleDelete = (e: MouseEvent) => {
      e.stopPropagation();
      deleteFile?.(e, file);
    };

    const imagesType = ['image/png', 'image/jpg', 'image/jpeg'];

    return imagesType.includes(file.file.type) ? (
      <div
        ref={ref}
        {...props}
        className={
          ' relative flex my-2 aspect-video overflow-hidden object-contain  group  text-xs shadow-md hover:shadow-lg rounded'
        }
        key={file.id}
      >
        {position === 0 && type !== 'floorplans' ? (
          <div className="relative h-0 w-0 top-1 left-1">
            <div className="flex  justify-center px-1 py-2 center-items rounded-xl gap-x-2 bg-white w-24  border border-deehiy text-deehiy">
              <span>cover photo</span>
            </div>
          </div>
        ) : (
          ''
        )}
        <img src={file.preview} alt="prev" className="object-cover w-full aspect-video" />
        <div className=" flex items-center flex-shrink-0 ml-4 ">
          {!readOnly && (
            <button
              title="remove this file"
              type="button"
              onClick={handleDelete}
              className=" absolute top-1 right-0 hover:bg-gray-300 bg-white  text-xl text-red-700 rounded focus:ring focus:ring-red-500 focus:outline-none hover:text-red-700"
            >
              <HiX />
            </button>
          )}
        </div>

        {!file.uploaded && (
          <div
            className={`absolute center ${
              file.progress < 100 ? 'cursor-progress inset-0' : ' inset-6'
            }  flex items-center justify-center`}
          >
            <div className="relative w-12 h-12">
              <div className="absolute inset-0">
                <div className="w-full h-full rounded-full bg-white opacity-50 border-gray-300 border-4" />
                <div
                  className="absolute top-0 left-0 w-full h-full rounded-full"
                  style={{
                    background: `conic-gradient(#1BB095 ${
                      (file.progress / 100) * 360
                    }deg, transparent 0 ${(file.progress / 100) * 360}deg)`,
                  }}
                />
              </div>
              <span className="absolute text-lg inset-0 flex items-center justify-center text-white font-semibold">
                {file.progress < 100 ? `${file.progress}%` : <HiCheck className="w-8 h-8" />}
              </span>
            </div>
          </div>
        )}
      </div>
    ) : (
      <li
        {...props}
        ref={ref}
        key={file.id}
        className="flex items-center justify-between py-3 pl-3 pr-4 text-sm"
      >
        <div className="flex items-center flex-1 w-0">
          <HiOutlinePaperClip className="flex-shrink-0 w-5 h-5 text-gray-400" aria-hidden="true" />
          <span className="flex-1 w-0 ml-2 truncate">{file.file.name}</span>
        </div>
        <div className="flex items-center flex-shrink-0 ml-4 space-x-2">
          {!readOnly && (
            <button
              className="text-orange-500 rounded cursor-pointer hover:text-red-700 focus:ring focus:ring-red-500 focus:outline-none"
              type="button"
              onClick={(e) => deleteFile?.(e, file)}
            >
              <HiX size={24} />
            </button>
          )}
        </div>
      </li>
    );
  }
);
