import { FilePreview } from '../FilePreview';
import { Droppable, Draggable } from '@hello-pangea/dnd';

const PreviewList = ({ files, deleteFile, readOnly, type }: any) => {
  return (
    <Droppable droppableId="filesPreview" direction="vertical">
      {(droppableProvider) => (
        <div
          ref={droppableProvider.innerRef}
          {...droppableProvider.droppableProps}
          className={
            ' mx-auto pt-8 w-3/5 rounded-t-md mt-2 transition-all duration-1000 overflow-hidden '
          }
        >
          {files?.map((file: any, index: number) => {
            return (
              <Draggable key={file.id} index={index} draggableId={`${file.id}`}>
                {(draggableProvider) => (
                  <div>
                    <FilePreview
                      key={index}
                      type={type}
                      position={index}
                      readOnly={readOnly}
                      file={file}
                      deleteFile={deleteFile}
                      ref={draggableProvider.innerRef}
                      {...draggableProvider.draggableProps}
                      {...draggableProvider.dragHandleProps}
                    />
                  </div>
                )}
              </Draggable>
            );
          })}
          {droppableProvider.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default PreviewList;
