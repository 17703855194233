import React, { useState, useEffect } from 'react';
import GoogleMaps from './Location/GoogleMap';
import Toggle from '../../form/Toggle';
import { useTranslation } from 'react-i18next';

const LocationProperty: React.FC<any> = ({
  editedProperty,
  setEditedProperty,
  setCanClickNext,
}) => {
  const [isToggled, setIsToggled] = useState<boolean>(editedProperty.showExactLocation);
  const { t } = useTranslation();

  useEffect(() => {
    setEditedProperty({ ...editedProperty, showExactLocation: isToggled });
  }, [isToggled]);

  return (
    <div className="flex flex-col w-full relative">
      <div
        className=" xl:h-[58vh]
      lg:h-[56vh] md:h-[56vh]
      sm:h-[48vh] h-[56vh]"
      >
        <GoogleMaps
          editedProperty={editedProperty}
          setEditedProperty={setEditedProperty}
          setCanClickNext={setCanClickNext}
        />
      </div>
      <div className="flex justify-between ">
        <div className="flex-col w-7/8 mt-3 md:mt-5">
          <p className="text-sm">{t('Show exact location')}</p>
          <p className="font-light text-xs">{t('exact_loc_tip')}</p>
        </div>
        <div className="flex justify-center items-center my-auto mx-auto">
          <Toggle setIsToggled={setIsToggled} isToggled={isToggled} />
        </div>
      </div>
    </div>
  );
};

export default LocationProperty;
