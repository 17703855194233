import React from 'react';

interface IItemIconProp {
  Icon: any; // React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  size: number;
  isSelected?: boolean;
}

const ItemIcon = ({ Icon, size, isSelected = true }: IItemIconProp) => {
  return (
    <div
      className={`w-10 h-10 sm:w-${size} sm:h-${size} cursor-pointer opacity-90 group-hover:opacity-100 `}
    >
      <Icon
        fill={`${isSelected ? '#1BB095' : '#1d1c4b'}`}
        stroke={`${isSelected ? '#1BB095' : '#1d1c4b'}`}
        className={`w-10 h-10 sm:w-${size} sm:h-${size} `}
      />
    </div>
  );
};

export default ItemIcon;
