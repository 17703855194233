import http from './httpService';

const apiPromotedCardEndpoint = '/api/promoted';

// Retrieve all promoted card clicks
export const getAllPromotedCardClicks = () => {
  return http.get(`${apiPromotedCardEndpoint}/clicks`);
};

// Track a click on a promoted card
export const trackPromotedCardClick = (promotedCardId: number) => {
  return http.post(`${apiPromotedCardEndpoint}/clicks`, { promotedCardId });
};
