import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/auth';
import { FormProvider, useForm } from 'react-hook-form';
import { MultiSelect } from '../form/MultiSelect';
import Selector, { SelectMenuOption } from '../form/countries/Selector';
import { countries, interestsList, skillsList } from '../../data/data';
import Toggle2 from '../form/Toggle2';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TopMobileNavigation from '../main/navigation/TopMobileNavigation';
import { cleanText } from '../../utils/textValidater';
import PhoneInput from 'react-phone-input-2';
import { CitySelector } from '../main/CityPicker';
// import { yupResolver } from '@hookform/resolvers/yup';
// import { UserFormSchema } from "../../../lib/yup"

// import Input from '../form/Input';
// import Textarea from '../form/Textarea';

import Loader from '../main/Loader';
import { useTranslation } from 'react-i18next';

import { AiOutlineUser, AiFillCamera } from 'react-icons/ai';
import { User } from '../../types/types';
import { ImageProfileUpload } from '../../utils/uploader';

const findUserCountryCode = (country: string) => {
  const code = countries.find((option) => option.title === country) as SelectMenuOption;
  if (!code) return 'AF';
  return code.value;
};

const ProfileForm: React.FC<any> = () => {
  const methods = useForm({
    mode: 'onTouched',
    // resolver: yupResolver(UserFormSchema),
  });
  const { currentUser, updateUser, fetchUserById } = useAuth();
  const [user, setUser] = useState<User>(currentUser!);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const languagesOptionList = [
    { label: 'English' },
    { label: 'Thai' },
    { label: 'Mandarin Chinese' },
    { label: 'Hindi' },
    { label: 'Spanish' },
    { label: 'Russian' },
    { label: 'Japanese' },
    { label: 'Vietnamese' },
    { label: 'German' },
    { label: 'French' },
    { label: 'Italian' },
  ];
  const skillsOptionList = skillsList.map((skill) => {
    return { label: skill.title.toLowerCase() };
  });

  // const [isToggled, setIsToggled] = useState(false);
  // const [isInvestorToggled, setIsInvestorToggled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // const [country, setCountry] = useState<SelectMenuOption['value']>('TH');
  const [country, setCountry] = useState<SelectMenuOption['value']>('TH');
  const [inputValue, setInputValue] = useState('');
  const [selectedCities, setSelectedCities] = useState<string[]>(
    user.preferredAreas ? JSON.parse(user.preferredAreas) : []
  );
  // const [selectedCities, setSelectedCities] = useState<string[]>();

  const [isEditingUsername, setIsEditingUsername] = useState(false);
  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const [isEditingPhoneNumber, setIsEditingPhoneNumber] = useState(false);

  const [isEditingFirstName, setIsEditingFirstName] = useState(false);
  const [isEditingLastName, setIsEditingLastName] = useState(false);
  const [isEditingPersonalizedUrl, setIsEditingPersonalizedUrl] = useState(false);
  const [isEditingCountry, setIsEditingCountry] = useState(false);
  const [isEditingCity, setIsEditingCity] = useState(false);
  const [isEditingLanguages, setIsEditingLanguages] = useState(false);
  const [isEditingSkills, setIsEditingSkills] = useState(false);
  const [isEditingInterests, setIsEditingInterests] = useState(false);
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [isEditingPreferredAreas, setIsEditingPreferredAreas] = useState(false);

  const getCountryName = (v: SelectMenuOption['value']) => {
    return countries.find((option) => option.value === v)?.title;
  };

  // handle change
  const handleUsernameChange = (event: any) => {
    // setUser({ ...user, username: event.target.value });
    setUser((prevUser) => ({ ...prevUser, username: event.target.value }));
  };

  const handleEmailChange = (event: any) => {
    // setUser({ ...user, email: event.target.value });
    setUser((prevUser) => ({ ...prevUser, email: event.target.value }));
  };

  const handlePhoneNumberChange = (value: any) => {
    // setUser({ ...user, phone: event.target.value });
    setUser((prevUser) => ({ ...prevUser, phone: value }));
  };

  const handleFirstNameChange = (event: any) => {
    // setUser({ ...user, firstName: event.target.value });
    setUser((prevUser) => ({ ...prevUser, firstName: event.target.value }));
  };

  const handleLastNameChange = (event: any) => {
    // setUser({ ...user, lastName: event.target.value });
    setUser((prevUser) => ({ ...prevUser, lastName: event.target.value }));
  };

  const handleCityChange = (event: any) => {
    // setUser({ ...user, city: event.target.value });
    setUser((prevUser) => ({ ...prevUser, city: event.target.value }));
  };

  // const handlePreferredAreasChange = (cities: string[]) => {
  //   alert(cities);
  //   setSelectedCities(cities);
  //   const selectedCitiesJson = JSON.stringify(cities);
  //   // setUser({ ...user, description: cleanedDescription });
  //   setUser((prevUser) => ({ ...prevUser, preferresAreas: selectedCitiesJson }));
  // };

  const handleDescriptionChange = (event: any) => {
    const cleanedDescription = cleanText(event.target.value);
    // setUser({ ...user, description: cleanedDescription });
    setUser((prevUser) => ({ ...prevUser, description: cleanedDescription }));
  };

  // handle edit click
  const handleUsernameEditClick = () => {
    if (isEditingUsername) {
      updateUser({ username: user.username });
    }
    setIsEditingUsername((p) => !p);
  };

  const handleEmailEditClick = () => {
    if (user.email !== currentUser?.email) {
      let confirmation = confirm('Are you sure you want to change your email address?');

      if (confirmation) {
        if (isEditingEmail) {
          updateUser({ email: user.email });
        }
      } else {
        // setUser({ ...user, email: currentUser?.email! });
        setUser((prevUser) => ({ ...prevUser, email: currentUser?.email! }));
      }
    }

    setIsEditingEmail((p) => !p);
  };

  const handlePhoneNumberEditClick = () => {
    if (isEditingPhoneNumber) {
      updateUser({ phone: user.phone });
    }
    setIsEditingPhoneNumber((p) => !p);
  };

  const handleFirstNameEditClick = () => {
    if (isEditingFirstName) {
      updateUser({ firstName: user.firstName });
    }
    setIsEditingFirstName((p) => !p);
  };

  const handleLastNameEditClick = () => {
    if (isEditingLastName) {
      updateUser({ lastName: user.lastName });
    }
    setIsEditingLastName((p) => !p);
  };

  const handleCountryEditClick = () => {
    if (isEditingCountry) {
      // updateUser({ country: user.country });
      updateUser({ country: getCountryName(country) });
      // setUser({ ...user, country: getCountryName(country) });
      setUser((prevUser) => ({ ...prevUser, country: getCountryName(country) }));
    }
    setIsEditingCountry((p) => !p);
  };

  const handleCityEditClick = () => {
    if (isEditingCity) {
      updateUser({ city: user.city });
    }
    setIsEditingCity((p) => !p);
  };

  const handleLanguagesEditClick = () => {
    if (isEditingLanguages) {
      updateUser({ languages: user.languages });
    }
    setIsEditingSkills(false);
    setIsEditingInterests(false);
    setIsEditingLanguages((p) => !p);
  };

  const handleSkillsEditClick = () => {
    if (isEditingSkills) {
      updateUser({ skills: user.skills });
    }
    setIsEditingLanguages(false);
    setIsEditingInterests(false);
    setIsEditingSkills((p) => !p);
  };

  const handleInterestsEditClick = () => {
    if (isEditingInterests) {
      updateUser({ interests: user.interests });
    }
    setIsEditingLanguages(false);
    setIsEditingSkills(false);
    setIsEditingInterests((p) => !p);
  };

  const handleDescriptionEditClick = () => {
    if (isEditingDescription) {
      updateUser({ description: user.description });
    }
    setIsEditingDescription((p) => !p);
  };

  const handlePreferredAreasEditClick = () => {
    if (isEditingPreferredAreas) {
      updateUser({ preferredAreas: user.preferredAreas });
    }
    setIsEditingPreferredAreas((p) => !p);
  };

  const onImageChange = (event: any) => {
    const tempImages = event.target.files;

    if (tempImages && tempImages[0]) {
      if (tempImages[0].size > 2097152) {
        toast.error('File too big, the maximum allowed size is 2MB');
        return;
      }

      if (tempImages[0]) {
        setIsLoading(true);
        ImageProfileUpload(tempImages[0], (progress: number) => {
          console.log(progress);
        })
          .then((res) => {
            const { secureUrl } = res;
            updateUser({ image: secureUrl });
            setUser({ ...user, image: secureUrl });
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  };

  const handleLanguageChange = (selectedItems: string[]) => {
    const validValues = selectedItems.filter((value) =>
      languagesOptionList.some((option) => option.label === value)
    );
    if (validValues.length === selectedItems.length) {
      setUser((prevUser) => ({
        ...prevUser,
        languages: JSON.stringify(validValues),
      }));
    }
  };

  const handleSkillsChange = (selectedItems: string[]) => {
    const validValues = selectedItems.filter((value) =>
      skillsOptionList.some((option) => option.label === value)
    );
    if (validValues.length === selectedItems.length) {
      setUser((prevUser) => ({
        ...prevUser,
        skills: JSON.stringify(validValues),
      }));
    }
  };

  const handleInterestsChange = (selectedItems: string[]) => {
    const validValues = selectedItems.filter((value) =>
      interestsList.some((option) => option.title === value)
    );
    if (validValues.length === selectedItems.length) {
      setUser((prevUser) => ({
        ...prevUser,
        interests: JSON.stringify(validValues),
      }));
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.keyCode == 13 && !event.shiftKey) {
      if (isEditingUsername) {
        handleUsernameEditClick();
        return;
      }

      if (isEditingEmail) {
        handleEmailEditClick();
        return;
      }

      if (isEditingPhoneNumber) {
        handlePhoneNumberEditClick();
        return;
      }

      if (isEditingFirstName) {
        handleFirstNameEditClick();
        return;
      }

      if (isEditingLastName) {
        handleLastNameEditClick();
        return;
      }

      if (isEditingCountry) {
        handleCountryEditClick();
        return;
      }

      if (isEditingCity) {
        handleCityEditClick();
        return;
      }

      if (isEditingLanguages) {
        handleLanguagesEditClick();
        return;
      }

      if (isEditingSkills) {
        handleSkillsEditClick();
        return;
      }

      if (isEditingDescription) {
        handleDescriptionEditClick();
        return;
      }

      if (isEditingPreferredAreas) {
        handlePreferredAreasEditClick();
        return;
      }

      if (isEditingInterests) {
        handleInterestsEditClick();
        return;
      }

      setIsEditingPersonalizedUrl(false);
    }
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    // if (event.key === 'Enter') {
    //   //..
    // }
  };

  const handleToggleDedicated = (e: any) => {
    setUser((prevUser) => ({
      ...prevUser,
      isDedicatedProfile: e.target.checked, // Toggle the property value
    }));
    updateUser({ isDedicatedProfile: e.target.checked });
  };

  const handleToggleInvestor = (e: any) => {
    setUser((prevUser) => ({
      ...prevUser,
      isInvestor: e.target.checked, // Toggle the property value
    }));
    updateUser({ isInvestor: e.target.checked });
  };

  const handleToggleSeller = (e: any) => {
    setUser((prevUser) => ({
      ...prevUser,
      isSeller: e.target.checked, // Toggle the property value
    }));
    updateUser({ isSeller: e.target.checked });
  };

  const handleToggleLandlord = (e: any) => {
    setUser((prevUser) => ({
      ...prevUser,
      isLandlord: e.target.checked, // Toggle the property value
    }));
    updateUser({ isLandlord: e.target.checked });
  };

  useEffect(() => {
    fetchUserById(currentUser?.id)
      .then((fetchedUser) => {
        setUser(fetchedUser);
        setSelectedCities(fetchedUser.preferredAreas ? JSON.parse(fetchedUser.preferredAreas) : []);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    setCountry(findUserCountryCode(user.country!));
  }, [user.country]);

  useEffect(() => {
    setUser({ ...user, personalizedUrl: `www.deehiy.com/${user.username}` });
  }, [user.username]);

  useEffect(() => {
    setUser({ ...user, preferredAreas: JSON.stringify(selectedCities) });
  }, [selectedCities]);

  // useEffect(() => {
  //   setSelectedCities(user.preferredAreas ? JSON.parse(user.preferredAreas) : []);
  // }, [user.preferredAreas]);

  return (
    <>
      <TopMobileNavigation />
      <div className="flex flex-col pt-4 pb-20 sm:pt-6 text-sm mx-auto px-6 md:px-20">
        <div className=" font-bold text-3xl sm:text-5xl pb-3">{t('Personal info')}</div>
        <div className="text-sm sm:text-xl">{t('tell the world more about you.')}</div>
        <div className="flex-col md:flex-row flex mt-12">
          <div className="w-full md:w-1/3 items-center flex-col flex space-y-4">
            <div
              title="edit profile image"
              className="border-4  text-deehiy border-deehiy w-36 h-36 rounded-full"
            >
              <label htmlFor="file-input">
                {user.image ? (
                  <img
                    src={user.image}
                    alt="profile"
                    className="rounded-full aspect-square w-full "
                  />
                ) : (
                  <AiOutlineUser className="w-full h-full cursor-pointer hover:scale-105 duration-300 hover:shadow-md rounded-full " />
                )}

                <AiFillCamera className="relative top-[-36px] left-[106px] p-1 w-7 h-7 cursor-pointer bg-white rounded-full " />
              </label>
              <input className="hidden" id="file-input" type="file" onChange={onImageChange} />
            </div>
            <button
              type="button"
              className="font-bold text-deehiy"
              onClick={() => navigate('/users/verifyprofile')}
            >
              {t('Verify profile')}
            </button>
            {isLoading && <Loader />}
          </div>
          <div className=" flex-col w-full">
            <FormProvider {...methods}>
              <form
                className="flex-col space-y-4 "
                onKeyDown={handleKeyDown}
                onSubmit={handleSubmit}
              >
                <div className="w-full md:w-2/3">
                  <div className="hidden pt-2 pb-8">
                    <div className="flex justify-between pb-2">
                      <span>{t('username')}</span>
                      <button
                        type="button"
                        className="text-deehiy font-bold"
                        onClick={handleUsernameEditClick}
                      >
                        {!isEditingUsername ? t('Edit') : t('Save')}
                      </button>
                    </div>
                    <div>
                      {!isEditingUsername ? (
                        <div className="font-bold">{user.username}</div>
                      ) : (
                        // <Input
                        //   label=""
                        //   id="username"
                        //   type="text"
                        //   value={user.username}
                        //   onChange={handleUsernameChange}
                        // />
                        <div className="w-full">
                          <div className="relative mt-1">
                            <input
                              className="text-[12px] sm:text-[14px] md:text-[16px] w-full"
                              value={user.username}
                              onChange={handleUsernameChange}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="pt-2 pb-8">
                    <div className="flex justify-between pb-2">
                      <span>{t('email address')}</span>
                      <button
                        type="button"
                        className="text-deehiy font-bold"
                        onClick={handleEmailEditClick}
                      >
                        {!isEditingEmail ? t('Edit') : t('Save')}
                      </button>
                    </div>

                    <div>
                      {!isEditingEmail ? (
                        <div className="font-bold">{user.email}</div>
                      ) : (
                        // <Input
                        //   label=""
                        //   id="email"
                        //   type="text"
                        //   value={user.email}
                        //   onChange={handleEmailChange}
                        // />
                        <div className="w-full">
                          <div className="relative mt-1">
                            <input
                              className="text-[12px] sm:text-[14px] md:text-[16px] w-full"
                              value={user.email}
                              onChange={handleEmailChange}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="pt-2 pb-8">
                    <div className="flex justify-between pb-2">
                      <span>{t('first name')} </span>
                      <button
                        type="button"
                        className="text-deehiy font-bold"
                        onClick={handleFirstNameEditClick}
                      >
                        {!isEditingFirstName ? t('Edit') : t('Save')}
                      </button>
                    </div>
                    <div>
                      {!isEditingFirstName ? (
                        <div className="font-bold">{user.firstName}</div>
                      ) : (
                        // <Input
                        //   label=""
                        //   id="firstName"
                        //   type="text"
                        //   value={user.firstName}
                        //   onChange={handleFirstNameChange}
                        // />
                        <div className="w-full">
                          <div className="relative mt-1 w-full">
                            <input
                              className="text-[12px] sm:text-[14px] md:text-[16px] w-full"
                              value={user.firstName}
                              onChange={handleFirstNameChange}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="pt-2 pb-8">
                    <div className="flex justify-between pb-2">
                      <span>{t('last name')} </span>
                      <button
                        type="button"
                        className="text-deehiy font-bold"
                        onClick={handleLastNameEditClick}
                      >
                        {!isEditingLastName ? t('Edit') : t('Save')}
                      </button>
                    </div>
                    <div>
                      {!isEditingLastName ? (
                        <div className="font-bold">{user.lastName}</div>
                      ) : (
                        // <Input
                        //   label=""
                        //   id="lastName"
                        //   type="text"
                        //   value={user.lastName}
                        //   onChange={handleLastNameChange}
                        // />
                        <div className="w-full">
                          <div className="relative mt-1 w-full">
                            <input
                              className="text-[12px] sm:text-[14px] md:text-[16px] w-full"
                              value={user.lastName}
                              onChange={handleLastNameChange}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="pt-2">
                    <div className="flex justify-between pb-2">
                      <span>{t('phone number')}</span>
                      <button
                        type="button"
                        className="text-deehiy font-bold"
                        onClick={handlePhoneNumberEditClick}
                      >
                        {!isEditingPhoneNumber ? t('Edit') : t('Save')}
                      </button>
                    </div>
                    <div>
                      {!isEditingPhoneNumber ? (
                        <PhoneInput
                          country={'th'}
                          enableSearch={false}
                          countryCodeEditable={false}
                          value={user.phone}
                          onChange={handlePhoneNumberChange}
                          buttonClass={'hover:!p-0 !border-none'}
                          inputClass={
                            '!w-full !py-0 !border-none !bg-transparent !text-base !font-bold '
                          }
                          searchClass={''}
                          disabled={true}
                        />
                      ) : (
                        // <Input
                        //   label=""
                        //   id="phoneNumber"
                        //   type="tel"
                        //   pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                        //   value={user.phone}
                        //   onChange={handlePhoneNumberChange}
                        // />
                        <div className="w-full">
                          <div className="relative mt-1 w-full">
                            <PhoneInput
                              country={'th'}
                              enableSearch={true}
                              countryCodeEditable={false}
                              placeholder={t('Enter phone number')}
                              value={user.phone}
                              onChange={handlePhoneNumberChange}
                              buttonClass={'hover:!p-0 !border-none'}
                              inputClass={
                                '!w-full !text-lg !py-5 !ring-2 ring-gray-200 !border-none focus:!ring-deehiy '
                              }
                              searchClass={'focus:ring-deehiy '}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="hidden pt-2 pb-8">
                    <div className="flex justify-between pb-2">
                      <span>{t('personalized URL')}</span>
                      {/* <button
                      type='button'
                      className='text-deehiy font-bold'
                      onClick={handlePersonalizedUrlEditClick}>
                      {!isEditingPersonalizedUrl ? "Edit" : "Save"}
                    </button> */}
                      <span className="text-deehiy font-bold">{t('coming soon')}</span>
                    </div>

                    <div>
                      {!isEditingPersonalizedUrl ? (
                        <div className="font-bold">{user.personalizedUrl}</div>
                      ) : (
                        // <Input
                        //   disabled
                        //   label=""
                        //   id="personalizedUrl"
                        //   type="text"
                        //   value={user.personalizedUrl}
                        // />
                        <input
                          className="text-[12px] sm:text-[14px] md:text-[16px] w-full"
                          value={user.personalizedUrl}
                          onChange={handlePhoneNumberChange}
                        />
                      )}
                    </div>
                  </div>
                  <div className="hidden pt-2 pb-8">
                    <div className="flex justify-between pb-2">
                      <span>{t('where do you live?')}</span>
                      <button
                        type="button"
                        className="text-deehiy font-bold"
                        onClick={handleCountryEditClick}
                      >
                        {!isEditingCountry ? t('Edit') : t('Save')}
                      </button>
                    </div>

                    <div>
                      {!isEditingCountry ? (
                        <div className="font-bold">{user.country ? user.country : <br />}</div>
                      ) : (
                        <Selector
                          id={'countries'}
                          open={isOpen}
                          onToggle={() => setIsOpen(!isOpen)}
                          onChange={(val) => setCountry(val)}
                          // We use this type assertion because we are always sure this find will return a value but need to let TS know since it could technically return null
                          selectedValue={
                            countries.find((option) => option.value === country) as SelectMenuOption
                          }
                        />
                      )}
                    </div>
                  </div>
                  <div className="hidden pt-2 pb-8">
                    <div className="flex justify-between pb-2">
                      <span>{t('what city?')}</span>
                      <button
                        type="button"
                        className="text-deehiy font-bold"
                        onClick={handleCityEditClick}
                      >
                        {!isEditingCity ? t('Edit') : t('Save')}
                      </button>
                    </div>

                    <div>
                      {!isEditingCity ? (
                        <div className="font-bold">{user.city}</div>
                      ) : (
                        // <Input
                        //   label=""
                        //   id="city"
                        //   type="text"
                        //   value={user.city}
                        //   onChange={handleCityChange}
                        // />
                        <div className="w-full">
                          <div className="relative mt-1 w-full">
                            <input
                              className="text-[12px] sm:text-[14px] md:text-[16px] w-full"
                              value={user.city}
                              onChange={handleCityChange}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="pt-2 pb-8">
                  <div className="flex justify-between pb-2 w-full md:w-2/3">
                    <span>{t('selected locations of interest')}</span>
                    <button
                      type="button"
                      className="text-deehiy font-bold"
                      onClick={handlePreferredAreasEditClick}
                    >
                      {!isEditingPreferredAreas ? t('Edit') : t('Save')}
                    </button>
                  </div>

                  <div className="flex w-full">
                    {!isEditingPreferredAreas ? (
                      <div className="font-bold">
                        {user.preferredAreas &&
                          JSON.parse(user.preferredAreas).map(
                            (preferredArea: string, index: number) => (
                              <div key={index}>{preferredArea}</div>
                            )
                          )}
                      </div>
                    ) : (
                      <CitySelector
                        inputValue={inputValue}
                        selectedCities={selectedCities}
                        setSelectedCities={setSelectedCities}
                        // setSelectedCities={handlePreferredAreasChange}
                        setInputValue={setInputValue}
                      />
                    )}
                  </div>
                </div>
                <div className="pt-2 pb-8">
                  <div className="flex justify-between pb-2 w-full md:w-2/3">
                    <span>{t('add languages')}</span>
                    <button
                      type="button"
                      className="text-deehiy font-bold"
                      onClick={handleLanguagesEditClick}
                    >
                      {!isEditingLanguages ? t('Edit') : t('Save')}
                    </button>
                  </div>

                  <div>
                    {!isEditingLanguages ? (
                      <div className="font-bold">
                        {user.languages &&
                          JSON.parse(user.languages).map((language: string, index: number) => (
                            <div key={index}>
                              <span>{language}</span>{' '}
                              {index === 0 && <span className="font-normal">(primary)</span>}
                            </div>
                          ))}
                      </div>
                    ) : (
                      <MultiSelect
                        label=""
                        name="languages"
                        size="small"
                        optionList={languagesOptionList}
                        placeholder={t('select languages')}
                        // valueChange={(arr: string[]) =>
                        //   setUser({
                        //     ...user,
                        //     languages: JSON.stringify(arr),
                        //   })
                        // }
                        valueChange={(arr: string[]) => handleLanguageChange(arr)}
                        value={user.languages ? JSON.parse(user.languages) : []}
                      />
                    )}
                  </div>
                </div>
                <div className="pt-2 pb-8">
                  <div className="flex justify-between pb-2 w-full md:w-2/3">
                    <span>{t('add interests')}</span>
                    <button
                      type="button"
                      className="text-deehiy font-bold"
                      onClick={handleInterestsEditClick}
                    >
                      {!isEditingInterests ? t('Edit') : t('Save')}
                    </button>
                  </div>

                  <div>
                    {!isEditingInterests ? (
                      <div className="font-bold">
                        {user.interests &&
                          JSON.parse(user.interests).map((interest: string, index: number) => (
                            <div key={index}>{interest}</div>
                          ))}
                      </div>
                    ) : (
                      <MultiSelect
                        label=""
                        name="interests"
                        size="big"
                        optionList={interestsList.map((i) => {
                          return { label: i.title };
                        })}
                        placeholder="Add interests"
                        valueChange={(arr: string[]) => handleInterestsChange(arr)}
                        value={user.interests && JSON.parse(user.interests)}
                      />
                    )}
                  </div>
                </div>
                <div className="pt-2 pb-8">
                  <div className="flex justify-between pb-2 w-full md:w-2/3">
                    <span>{t('add skills')}</span>
                    <button
                      type="button"
                      className="text-deehiy font-bold"
                      onClick={handleSkillsEditClick}
                    >
                      {!isEditingSkills ? t('Edit') : t('Save')}
                    </button>
                  </div>

                  <div>
                    {!isEditingSkills ? (
                      <div className="font-bold">
                        {user.skills &&
                          JSON.parse(user.skills).map((skills: string, index: number) => (
                            <div key={index}>{skills}</div>
                          ))}
                      </div>
                    ) : (
                      <MultiSelect
                        label=""
                        name="skills"
                        size="big"
                        optionList={skillsOptionList}
                        placeholder={t('Add skills')}
                        // valueChange={(arr: string[]) =>
                        //   setUser({ ...user, skills: JSON.stringify(arr) })
                        // }
                        valueChange={(arr: string[]) => handleSkillsChange(arr)}
                        value={user.skills && JSON.parse(user.skills)}
                      />
                    )}
                  </div>
                </div>
                <div className="pt-2 pb-8">
                  <div className="flex justify-between pb-2 w-full md:w-2/3">
                    <span>{t('tell the world about you')}</span>
                    <button
                      type="button"
                      className="text-deehiy font-bold"
                      onClick={handleDescriptionEditClick}
                    >
                      {!isEditingDescription ? t('Edit') : t('Save')}
                    </button>
                  </div>

                  <div className="flex w-full">
                    {!isEditingDescription ? (
                      <div className="font-bold">{user.description}</div>
                    ) : (
                      // <Textarea
                      //   cols={5}
                      //   maxLength={3000}
                      //   textLength={user.description?.length!}
                      //   rows={7}
                      //   placeholder=""
                      //   label=""
                      //   id="description"
                      //   value={user.description}
                      //   onChange={handleDescriptionChange}
                      // />
                      <div className="w-full">
                        <div className="relative mt-1 w-full">
                          <textarea
                            className="text-[12px] sm:text-[14px] md:text-[16px] w-full"
                            cols={5}
                            maxLength={3000}
                            // textLength={user.description?.length!}
                            rows={7}
                            placeholder=""
                            value={user.description}
                            onChange={handleDescriptionChange}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <div className="pb-3 pt-1 text-base">
                    Dedicate your profile to receive free leads
                  </div>
                  <div className="w-full sm:w-2/3  flex-col pb-7">
                    <div className="font-bold  whitespace-nowrap justify-between flex">
                      <div className="font-bold"> I am a Real Estate Agent</div>
                      <div>
                        <Toggle2
                          isToggled={user.isDedicatedProfile}
                          setIsToggled={handleToggleDedicated}
                        />
                      </div>
                    </div>
                    <div className="text-xs">
                      I represent property owners and help clients buy, sell, or rent homes.
                    </div>
                  </div>
                  <div className=" w-full sm:w-2/3  flex-col pb-7">
                    <div className="font-bold whitespace-nowrap justify-between flex">
                      <span className="font-bold">I am a Landlord</span>
                      <div className="flex justify-end w-full">
                        <Toggle2 isToggled={user.isLandlord} setIsToggled={handleToggleLandlord} />
                      </div>
                    </div>
                    <div className="text-xs">
                      I own rental properties and lease them directly to tenants.
                    </div>
                  </div>
                  <div className=" w-full sm:w-2/3  flex-col pb-7">
                    <div className="font-bold whitespace-nowrap justify-between flex">
                      <span className="font-bold">I am a Seller</span>
                      <div className="flex justify-end w-full">
                        <Toggle2 isToggled={user.isSeller} setIsToggled={handleToggleSeller} />
                      </div>
                    </div>
                    <div className="text-xs">
                      I own property and want to sell it directly to buyers.
                    </div>
                  </div>
                  <div className=" w-full sm:w-2/3  flex-col pb-7">
                    <div className="font-bold whitespace-nowrap justify-between flex">
                      <span className="font-bold">I am an investor</span>
                      <div className="flex justify-end w-full">
                        <Toggle2 isToggled={user.isInvestor} setIsToggled={handleToggleInvestor} />
                      </div>
                    </div>
                    <div className="text-xs">
                      I purchase properties to build wealth through resale or rental income.
                    </div>
                  </div>
                </div>
              </form>
              <ToastContainer transition={Slide} />
            </FormProvider>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileForm;
