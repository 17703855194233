import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { updatePasswordSchema } from '../../lib/yup';
import PasswordInput from '../form/PasswordInput';
import { useTranslation } from 'react-i18next';

import Box from '../animation/Box';
import Loader from '../main/Loader';

const UpdatePassword = () => {
  const { userId, token } = useParams();
  const { resetUpdatePasswordUser } = useAuth();

  let navigate = useNavigate();
  const { t } = useTranslation();

  const [confirmPassword, setConfirmPassword] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);

  const methods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(updatePasswordSchema),
    // defaultValues: stepOne || {},
  });

  const handleSubmit = async () => {
    setIsLoading(true);
    if (token) {
      await new Promise((resolve, reject) => {
        if (password !== confirmPassword) {
          setIsLoading(false);
          reject('Passwords do not match');
          setError(t('Passwords do not match'));
          return;
        }
        resolve(undefined);
      });

      await resetUpdatePasswordUser(Number(userId), token, password).then((res: any) => {
        setIsLoading(false);
        if (res === 'success') {
          setMessage(t('password_updated_successfully'));
          setError('');
          setTimeout(() => {
            navigate('/login');
          }, 3000);
        } else {
          setError(res);
        }
      });
    } else {
      setError('wrong token');
    }
  };

  return (
    <Box>
      <div className="auth-form">
        {isLoading && <Loader />}
        <p className="mx-auto font-light text-2xl">{t('New Password')}</p>
        <hr className="border-deehiy" />
        <div className="p-4 my-2 rounded-2xl border-deehiy border text-sm bg-white">
          {t('password_validation')}
        </div>

        {message && (
          <div className=" flex justify-center text-green-400 text-sm mx-auto">{message}</div>
        )}

        {error && <span className=" flex justify-center text-red-500 mx-auto">{error}</span>}

        <FormProvider {...methods}>
          <form className="flex flex-col space-y-4">
            <PasswordInput
              name="password"
              id="password"
              label={t('new password')}
              value={password}
              placeholder={t('password')}
              onChange={(e) => setPassword(e.target.value)}
            />
            <PasswordInput
              name="confirmPassword"
              id="confirmPassword"
              label={t('confirm new password')}
              value={confirmPassword}
              placeholder={t('confirm password')}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <button
              type="button"
              // disabled={!checked}
              onClick={handleSubmit}
              className="primary-btn"
            >
              {t('Submit')}
            </button>
          </form>
        </FormProvider>
      </div>
    </Box>
  );
};
export default UpdatePassword;
