import React, { useState, useEffect, useRef } from 'react';
import ItemIcon from '../../main/ItemIcon';
import { featuresList } from '../../../data/data';
import { useProperty } from '../../../contexts/property';

const Feature: React.FC<any> = ({ feature, id, handleClick }: any) => {
  return (
    <div className="flex justify-start items-center gap-1 text-deehiy">
      <input
        className="text-deehiy rounded active:scale-90 duration-200 focus:ring-0"
        type="checkbox"
        id={id}
        onChange={() => handleClick(id)}
        checked={feature.selected ? true : false}
      />
      <ItemIcon size={5} Icon={feature.SVG} />
      <span className="text-defaultText">{feature.title}</span>
    </div>
  );
};

const FeaturesSelector: React.FC<any> = ({ features, handleClick }: any) => {
  return (
    <>
      <div className="container p-4 mx-auto h-auto overflow-auto">
        <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
          {features.map((feature: any, i: any) => {
            if (feature.category === 'basic') {
              return <Feature handleClick={handleClick} key={i} id={i} feature={feature} />;
            }
          })}
        </div>

        <div className=" text-sm pt-6 pb-2">standout amenities</div>
        <div className="grid grid-cols-2 sm:grid-cols-4 gap-3">
          {features.map((feature: any, i: any) => {
            if (feature.category === 'standoutAmenities') {
              return <Feature handleClick={handleClick} key={i} id={i} feature={feature} />;
            }
          })}
        </div>
        <div className=" text-sm pt-6 pb-2">view</div>
        <div className="grid grid-cols-2 sm:grid-cols-4 gap-3">
          {features.map((feature: any, i: any) => {
            if (feature.category === 'views') {
              return <Feature handleClick={handleClick} key={i} id={i} feature={feature} />;
            }
          })}
        </div>
        <div className="text-sm pt-6 pb-2">safety items</div>
        <div className="grid grid-cols-2 sm:grid-cols-4 gap-3">
          {features.map((feature: any, i: any) => {
            if (feature.category === 'security') {
              return <Feature handleClick={handleClick} key={i} id={i} feature={feature} />;
            }
          })}
        </div>
      </div>
    </>
  );
};

const FeaturesContent = ({ modifyProperty }: any) => {
  const [features, setFeatures] = useState(featuresList);
  const firstUpdate = useRef(true);
  const { currentProperty, setCurrentProperty } = useProperty();

  const [isEditingFeatures, setIsEditingFeatures] = useState(false);

  const handleFeaturesEditClick = () => {
    setIsEditingFeatures((p) => !p);
  };

  const handleClick = (i: number) => {
    const updatedFeatures = features.map((a: any, index: any) => {
      const updated = [...currentProperty.features];
      if (i === index) {
        a.selected = !a.selected;

        if (a.selected) {
          updated.push(a.title);
        } else {
          const indexToRemove = currentProperty.features.indexOf(a.title);
          updated.splice(indexToRemove, 1);
        }
      }
      return a;
    });

    setFeatures(updatedFeatures);
  };

  useEffect(() => {
    if (currentProperty.features.length) {
      // we extract the featureTitle so that it become a simple array of strings
      const alreadySelected = [...currentProperty.features];
      // we set the boolean for each feature
      const updatedFeatures = features.map((am) => {
        if (alreadySelected.find((s) => s.featureTitle === am.title)) {
          am.selected = true;
        } else {
          am.selected = false;
        }
        return am;
      });
      setFeatures(updatedFeatures);
    }
  }, []);

  useEffect(() => {
    if (!isEditingFeatures) {
      const updatedFeatures: any[] = [];
      features.map((feature) => {
        if (feature.selected) {
          updatedFeatures.push({ featureTitle: feature.title });
        }
      });

      setCurrentProperty({ ...currentProperty, features: updatedFeatures });
    }
  }, [features, isEditingFeatures]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    modifyProperty(currentProperty);
  }, [currentProperty.features]);

  return (
    <div>
      <div className="w-full md:w-full">
        <div className="pt-2 pb-4">
          <div className="flex justify-end pb-2">
            <button
              type="button"
              className="text-deehiy font-bold"
              onClick={handleFeaturesEditClick}
            >
              {!isEditingFeatures ? 'Edit' : 'Save'}
            </button>
          </div>
          <div>
            {!isEditingFeatures ? (
              <div className="">
                <ul>
                  {currentProperty.features.length > 0 &&
                    currentProperty.features.map((p: any) => {
                      return <li key={p.featureTitle}>{p.featureTitle}</li>;
                    })}
                </ul>
              </div>
            ) : (
              <FeaturesSelector features={features} handleClick={handleClick} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturesContent;
