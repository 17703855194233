import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ImageGrid from './dnd/ImageGrid';
// import FloorplanGrid from './dnd/FloorplanGrid';
const ImagesContent = ({ modifyProperty }: any) => {
  return (
    <DndProvider backend={HTML5Backend}>
      <ImageGrid modifyProperty={modifyProperty} />
      {/* <FloorplanGrid /> */}
    </DndProvider>
  );
};

export default ImagesContent;
