import React, { useState } from 'react';
import { adjustImageSize } from '../../utils/adjustImageSize';
import { Avatar } from '../../pages/ListingPage';
import { ROLES } from '../../constants/constant';
import { BiSolidInfoCircle } from 'react-icons/bi';
import { useBusiness } from '../../contexts/business';

const AssignRole = ({ handleChangeRole, user, setShowModal }: any) => {
  const [selectedRoleId, setSelectedRoleId] = useState(user.roleId || 3);
  const { users } = useBusiness();

  const handleDone = () => {
    setShowModal(false);
    handleChangeRole(user.id, selectedRoleId, users);
  };

  return (
    <div className="wull sm:w-[550px] flex flex-col">
      <div className="flex w-full justify-center border-transparent text-base text-center font-bold border pb-4 border-b-deehiy">
        Assign Role
      </div>
      <div className="flex-col space-y-12 px-8 sm:px-12 py-12">
        <div className="flex justify-between">
          <div className="flex flex-col sm:flex-row items-center justify-center space-x-4">
            <Avatar
              isSales={false}
              image={
                user.image
                  ? adjustImageSize(user.image[0].url, 250)
                  : process.env.REACT_APP_DEFAULT_USER_URL
              }
            />
            <div className="flex flex-col">
              <span className="font-bold">{user.firstName + ' ' + user.lastName}</span>
              <span className="">{user.email}</span>
            </div>
          </div>

          <select
            value={selectedRoleId}
            className="border border-gray-300 my-auto rounded focus:border-deehiy focus:ring-0"
            onChange={(e) => setSelectedRoleId(e.target.value)}
          >
            {ROLES.map((role) => {
              if (role.roleId !== 1)
                return (
                  <option key={role.roleId} value={role.roleId}>
                    {role.roleName}
                  </option>
                );
            })}
          </select>
        </div>
        <div className="flex justify-between">
          <div className="rounded shadow-sm bg-deehiy text-white flex  opacity-80 font-bold py-3 px-2 sm:px-6 mr-6  gap-x-2">
            <BiSolidInfoCircle className="text-white w-4 h-4" />
            Users with access can make changes
          </div>

          <div className="flex items-center justify-center ">
            <button onClick={handleDone} className="primary-btn">
              Done
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignRole;
