import { Action } from '../types/types';
import {
  SET_PROPERTIES,
  GET_PROPERTY_BY_ID,
  // SAVE_PROPERTY,
  SET_USER_PROPERTIES,
  SET_CURRENT_PROPERTY,
  SET_COORDINATES,
  SET_FILTER,
  SET_MAX_LISTINGPRICE,
  SET_PAGE_INDEX,
  SET_COUNT,
} from '../actions/actions';

export const propertyReducer = (state: any, action: Action) => {
  switch (action.type) {
    case SET_CURRENT_PROPERTY:
      return { ...state, currentProperty: action.payload };
    case SET_PROPERTIES:
      return { ...state, properties: action.payload };
    case GET_PROPERTY_BY_ID:
      return { ...state, property: action.payload };
    // case SAVE_PROPERTY:
    //   return { ...state, property: action.payload }
    case SET_COORDINATES:
      return { ...state, coordinates: action.payload }; // to check
    case SET_FILTER:
      return { ...state, filter: action.payload };
    case SET_MAX_LISTINGPRICE:
      return { ...state, maxListingPrice: action.payload };
    case SET_PAGE_INDEX:
      return { ...state, pageIndex: action.payload };
    case SET_COUNT:
      return { ...state, count: action.payload };
    case SET_USER_PROPERTIES:
      return { ...state, userProperties: action.payload };
    default:
      return state;
  }
};
