import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Link, useNavigate } from 'react-router-dom';
// import StepperControl from './StepperControl';
// import { IDirection } from './Steps';

// import FormTopBtn from './FormTopBtn';
import HuntTopBtn from '../../components/huntingBoard.tsx/HuntTopBtn';
import Loader from '../../components/main/Loader';
import { useHunt } from '../../contexts/hunt';
import HuntForm from '../../components/huntingBoard.tsx/HuntForm';
import HuntFeatures from '../../components/huntingBoard.tsx/HuntFeatures';
import { useAuth } from '../../contexts/auth';
import { LAND } from '../../constants/constant';
import Lottie from 'lottie-react';
import animationData from '../../assets/loader_white.json';

import { useTranslation } from 'react-i18next';

const huntSteps = [
  {
    title: 'what are you hunting?',
    description: 'the better your bait, the more likely you won’t go hungry.',
    category: 'Hunting',
    Component: HuntForm,
  },
  {
    title: 'must have features',
    description: 'only properties which have these features will be matched.',
    category: 'Features',
    Component: HuntFeatures,
  },
];

const AddHunt = () => {
  const { currentHunt, createHuntContext } = useHunt();
  const { currentUser } = useAuth();
  const [canClickNext, setCanClickNext] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(huntSteps[0]);
  const [loadingScreen, setLoadingScreen] = useState(true); // Start with loading screen visible
  const [showLoader, setShowLoader] = useState(false); // State to control SVG loader visibility

  const navigate = useNavigate();
  const { t } = useTranslation();

  const SubmitHunt = async () => {
    try {
      setIsLoading(true);
      setShowLoader(true); // Show SVG loader when submission starts

      // Start a timer to ensure the loader shows for at least 3 seconds
      const delay = new Promise((resolve) => setTimeout(resolve, 4000));

      // Submit the hunt and wait for both the submission and delay to finish
      const newHunt = await createHuntContext({ ...currentHunt, userId: currentUser?.id });

      console.log('new hunt::', newHunt);

      // Ensure both hunt submission and 3 seconds delay are completed before proceeding
      await Promise.all([delay]);

      navigate(`/users/matches/hunt/${newHunt.hunt.huntId}`);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
      setShowLoader(false); // Hide SVG loader after submission and delay
    }
  };

  const handleClick = () => {
    if (step.title === huntSteps[0].title && currentHunt.propertyType !== LAND) {
      setStep(huntSteps[1]);
    } else {
      SubmitHunt();
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoadingScreen(false); // Hide loading screen after 1s
    }, 2000);

    return () => clearTimeout(timer); // Cleanup timer on unmount
  }, []);

  if (loadingScreen) {
    return ReactDOM.createPortal(
      <div className="fixed inset-0 h-screen w-screen flex items-center justify-center bg-deehiy z-900">
        <img key={Date.now()} src="/images/hunt.svg" className="w-[500px] mx-auto" alt="Loading" />
      </div>,
      document.body // Render the loading screen directly to the body
    );
  }

  if (showLoader) {
    return ReactDOM.createPortal(
      <div className="fixed inset-0 h-screen w-screen flex items-center justify-center bg-deehiy z-900">
        <div className="w-[500px]">
          <Lottie animationData={animationData} loop={true} />
        </div>
      </div>,
      document.body // Render the loading screen directly to the body
    );
  }
  return (
    <div className="flex w-full h-screen">
      <div className="absolute h-0">
        <Link to="/">
          <span className="hidden lg:block font-title relative md:top-8 lg:top-16 md:left-1 lg:left-20 md:text-4xl lg:text-5xl xl:text-6xl text-white">
            deehiy
          </span>
        </Link>
      </div>
      <div className=" hidden font-light md:text-3xl lg:text-5xl 2xl:text-5xl lg:flex w-1/2 antialiased bg-gradient-to-b from-deehiy via-[#1e7a7a]  to-[#1d1c4b]  text-white  justify-center items-center">
        <div className=" flex-col space-y-6 w-5/6 p-[40px]">
          <div>
            <img src="/images/icons/hunt.svg" className="w-36 h-36 mx-auto" />
          </div>
          <p className="text-center">{t(step.title)}</p>
          <p className="text-center md:text-base md:text-md xl:text-md opacity-90">
            {t(step.description)}
          </p>
        </div>
      </div>
      <div className="w-full h-screen lg:w-1/2 max-w-[680px] mx-auto">
        <div className="h-8  sm:h-1/6">
          <HuntTopBtn />
        </div>

        <div className="min-h-[400px] h-4/6 flex mx-auto">
          {step.Component && (
            <step.Component
              // editedProperty={editedProperty}
              // setEditedProperty={setEditedProperty}
              // handleClick={handleClick}
              setCanClickNext={setCanClickNext}
            />
          )}
        </div>
        {/* <StepsleftLayer /> */}
        <div className="h-1/6 flex">
          <div className="flex items-center mx-auto justify-between w-full gap-4 px-10">
            {step.title !== huntSteps[0].title ? (
              <button
                type="button"
                onClick={() => {
                  setStep(huntSteps[0]);
                }}
                className="form-back-btn"
              >
                {t('back')}
              </button>
            ) : (
              <div></div>
            )}

            <div className="space-x-8 flex">
              <button
                type="button"
                disabled={canClickNext || isLoading ? false : true}
                onClick={handleClick}
                className={`
                
                ${canClickNext ? 'cursor-pointer' : 'cursor-not-allowed opacity-50'}
                form-next-btn`}
              >
                {isLoading && <Loader />}
                {step.title === huntSteps[1].title || currentHunt.propertyType === LAND
                  ? t('submit')
                  : t('next')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddHunt;
