import React, { useState, useEffect } from 'react';
import { MarketFilter } from '../../../types/types';
import { LAND } from '../../../constants/constant';
import { useTranslation } from 'react-i18next';

interface RadioBtnProp {
  max: number;
  filter: MarketFilter;
  setFilter: any;
  category: string;
}

const RadioBtn = ({ category, max, filter, setFilter }: RadioBtnProp) => {
  const { t } = useTranslation();
  const [values, setValues] = useState<boolean[]>(Array(max).fill(false));

  const handleClick = (index: number) => {
    setValues(
      values.map((e, i) => {
        if (i === index) {
          setFilter({ ...filter, [category]: i });
          return true;
        } else {
          return false;
        }
      })
    );
  };

  useEffect(() => {
    if (filter?.propertyTypes?.length === 1 && filter?.propertyTypes.includes(LAND)) {
      setFilter({ ...filter, [category]: 0 });
      setValues([true]);
    } else {
      setValues(Array(max).fill(false));
    }
  }, [filter?.propertyTypes]);

  return (
    <div className={'flex text-sm justify-around space-x-1 w-full'}>
      {values.map((el: boolean, i: number) => {
        let label = i === 0 ? t('all') : i;
        return (
          <div
            key={i}
            onClick={() => handleClick(i)}
            className={`cursor-pointer w-8 h-8 justify-center items-center hover:border-gray-300 rounded-lg border flex duration-100 + ${
              filter && i === filter[category as keyof MarketFilter]
                ? 'bg-deehiy text-white shadow-md font-bold '
                : 'bg-white shadow-md'
            } `}
          >
            {label} {i === max - 1 && '+'}
          </div>
        );
      })}
    </div>
  );
};

export default RadioBtn;
