import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { BsCalendar2 } from 'react-icons/bs';
import { format, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';

export const CustomInput = ({ value, onClick, ...props }: any) => (
  <div className="relative flex items-center" onClick={onClick}>
    <input
      {...props}
      value={value}
      className="border rounded-md px-2 py-2 outline-none focus:ring-1 focus:ring-deehiy w-full pr-8"
    />
    <BsCalendar2
      className="absolute top-3 right-2 text-gray-500 focus:text-defaultText " // Set absolute position
    />
  </div>
);

const Availability: React.FC<any> = ({
  editedProperty,
  setEditedProperty,
  setCanClickNext,
}: any) => {
  const { t } = useTranslation();
  const [date, setDate] = useState(
    editedProperty.availability ? parseISO(editedProperty.availability as string) : null
  );

  // Function to handle radio button change
  const handleRadioChange = (e: any) => {
    if (e.target.value === 'now') {
      setEditedProperty({ ...editedProperty, availability: null });
    } else {
      setDate(new Date());
    }
  };

  // const handleDateChange = (e: any) => {
  //   setEditedProperty({ ...editedProperty, availability: e.target.value });
  // };

  useEffect(() => {
    if (!editedProperty.frequency) {
      setCanClickNext(false);
    } else {
      setCanClickNext(true);
    }

    if (editedProperty.frequency === 'yearly') {
      setEditedProperty({
        ...editedProperty,
        hasDailyRental: false,
      });
    }
  }, [editedProperty.frequency]);

  useEffect(() => {
    setEditedProperty({
      ...editedProperty,
      availability: date && format(date, 'yyyy-MM-dd HH:mm:ss'),
    });
  }, [date]);

  return (
    <div className="step-form-wrapper flex-col w-full">
      <div className="text-sm sm:text-sm lg:text-lg font-light">
        {t('Availability')}
        <div className="text-sm sm:text-sm lg:text-sm font-light">
          {t('When is the property available?')}
        </div>
      </div>

      <div className="flex pt-2 text-sm gap-x-4 items-center">
        <input
          type="radio"
          className={`focus:outline-none focus:ring-0 h-4 w-4 border-gray-300 ${
            editedProperty.availability === null ? 'text-deehiy' : 'text-white'
          }`}
          name="dateOption"
          value="now"
          onChange={handleRadioChange}
          checked={editedProperty.availability === null}
        />
        <div className=" font-normal">{t('Now')}</div>
      </div>
      <div className="flex text-sm gap-x-4 items-center">
        <input
          type="radio"
          className={`focus:outline-none focus:ring-0 h-4 w-4 border-gray-300 ${
            editedProperty.availability !== null ? 'text-deehiy' : 'text-white'
          }`}
          name="dateOption"
          value="selectDate"
          onChange={handleRadioChange}
          checked={editedProperty.availability !== null}
        />
        <div className="gap-x-4 flex items-center">
          <div className=" font-normal">{t('From')}</div>
          <DatePicker
            selected={date}
            minDate={new Date()}
            placeholderText={t('Select date')}
            onChange={(d: Date) => setDate(d)}
            disabled={editedProperty.availability === null}
            customInput={<CustomInput value={date && date.toLocaleDateString()} />} // Pass date for display
            shouldCloseOnSelect={false}
            className={` ${
              editedProperty.availability === null
                ? 'opacity-50 bg-gray-400 cursor-not-allowed text-gray-200'
                : 'bg-white cursor-pointer'
            } rounded-xl focus:outline-none  outline-transparent focus:ring-0 focus:border-deehiy border`}
          />
        </div>
      </div>

      <div className="text-sm sm:text-sm lg:text-lg font-light pt-12">
        <p>{t('Contract period')}</p>
      </div>

      <div className="flex space-x-12">
        <div>
          <input
            type="radio"
            id="yearly"
            value="yearly"
            checked={editedProperty.frequency === 'yearly'}
            onChange={() => setEditedProperty({ ...editedProperty, frequency: 'yearly' })}
            className="sr-only"
          />
          <label
            htmlFor="yearly"
            className={`${
              editedProperty.frequency === 'yearly'
                ? 'bg-deehiy-veryLight border-deehiy'
                : 'bg-[#fcfcfc] border-gray-200'
            } w-36 flex p-3 sm:p-4 group cursor-pointer text-center flex-col items-left rounded-2xl border-2 hover:border-deehiy`}
          >
            {t('Yearly')}
          </label>
        </div>
        <div>
          <input
            type="radio"
            id="monthly"
            value="monthly"
            checked={editedProperty.frequency === 'monthly'}
            onChange={() => setEditedProperty({ ...editedProperty, frequency: 'monthly' })}
            className="sr-only"
          />
          <label
            htmlFor="monthly"
            className={`${
              editedProperty.frequency === 'monthly'
                ? 'bg-deehiy-veryLight border-deehiy'
                : 'bg-[#fcfcfc] border-gray-200'
            } w-36 flex p-3 sm:p-4 group cursor-pointer text-center flex-col items-left rounded-2xl  border-2 hover:border-deehiy `}
          >
            {t('Monthly')}
          </label>
        </div>
      </div>
      <div className={` ${editedProperty.frequency === 'yearly' ? 'hidden' : 'flex'} pt-2`}>
        <input
          className="w-5 h-5 text-deehiy cursor-pointer bg-white outline-none  focus:outline-transparent focus:ring-transparent border-gray-300 rounded"
          type="checkbox"
          name="daily"
          checked={editedProperty.hasDailyRental}
          onChange={(e) =>
            setEditedProperty({ ...editedProperty, hasDailyRental: e.target.checked })
          }
        />
        <div className=" text-justify w-full flex flex-wrap gap-x-4 font-normal">
          <span className="whitespace-nowrap">
            &nbsp;&nbsp;{t('Also available for daily rental')}
          </span>{' '}
        </div>
      </div>
    </div>
  );
};

export default Availability;
