import React from 'react';
import { BsFillGrid3X3GapFill } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';

const OpenGalleryBtn = ({ setShowModal }: any) => {
  const { t } = useTranslation();

  return (
    <button
      className=" flex px-6 py-3 justify-center focus:outline-none semibold hover:shadow-lg shadow-sm duration-500 items-center gap-2  text-deehiy bg-white rounded-xl border border-deehiy"
      type="button"
      onClick={() => setShowModal(true)}
    >
      <BsFillGrid3X3GapFill className="w-6 h-6" />
      {t('show all photos')}
    </button>
  );
};

export default OpenGalleryBtn;
