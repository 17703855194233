import React, { useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { locationFormSchema } from '../../../../lib/yup';
import Map from '../../../map/Map';
import LocationSearch from './LocationSearch';
import { countries } from '../../../../data/data';

import Input from '../../../form/Input';
import { useTranslation } from 'react-i18next';

const getCountryByValue = (value: string) => {
  const matchingItem = countries.find((item) => item.value === value);
  return matchingItem ? matchingItem : null;
};

type ManualLocationObject = {
  country: string;
};

const ManualLocation: React.FC<any> = ({
  editedProperty,
  setEditedProperty,
  setCanClickNext,
}: any) => {
  const methods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(locationFormSchema),
  });
  const { t } = useTranslation();

  const { province, district, postalCode, city, streetNameHouseNumber } = editedProperty;
  const [locationString, setLocationString] = useState(city);
  const [locationObject, setLocationObject] = useState<ManualLocationObject | null>();

  const position = {
    lat: editedProperty.latitude,
    lng: editedProperty.longitude,
  };
  // eslint-disable-next-line
  const [{ prov, dist }, setData] = useState({
    prov: province,
    dist: district,
  });

  const handleChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    setEditedProperty({ ...editedProperty, [name]: value });
  };

  useEffect(() => {
    setCanClickNext(!postalCode || !streetNameHouseNumber || !city ? false : true);
  }, []);

  useEffect(() => {
    console.log('location string', locationString.length);
    if (locationString.length === 0) {
      setCanClickNext(false);
    } else {
      setCanClickNext(!postalCode || !streetNameHouseNumber || !city ? false : true);
    }
  }, [locationString, postalCode, streetNameHouseNumber, city]);

  useEffect(() => {
    if (locationObject && locationObject.country.length > 0) {
      const country = getCountryByValue(locationObject.country);

      setEditedProperty({
        ...editedProperty,
        city: locationString,
        country: country?.title,
        isoCodeCurrency: country?.currency,
        isoCodeCountry: country?.value,
      });
    }
  }, [locationObject]);

  return (
    <div className="step-form-wrapper flex-col w-full">
      <div>
        <div className="text-sm sm:text-sm lg:text-lg font-light">{t('Type your address')}</div>
        <div className="text-sm sm:text-sm lg:text-sm font-light">
          {t('This will appear in search results')}
        </div>
      </div>
      <FormProvider {...methods}>
        <form className="flex flex-col space-y-2">
          <div className="flex flex-col space-y-1">
            <label className="flex gap-x-4 text-sm sm:text-md lg:text-md font-normal">
              {t('city')}
            </label>
            <div className="login-input-manual-location py-1 px-2 w-full bg-white">
              <LocationSearch
                label=""
                locationString={locationString}
                setLocationString={setLocationString}
                setLocationObject={setLocationObject}
              />
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-0 sm:gap-8">
            <Input
              label={t('street name, house number')}
              type="text"
              name="streetNameHouseNumber"
              id="streetNameHouseNumber"
              onChange={handleChange}
              value={editedProperty.streetNameHouseNumber}
            />
            <Input
              label={t('postal code')}
              type="text"
              name="postalCode"
              id="postalCode"
              onChange={handleChange}
              value={editedProperty.postalCode}
            />
          </div>
        </form>
      </FormProvider>

      <Map position={position} isToggled={true} zoom={12} />
    </div>
  );
};

export default ManualLocation;
