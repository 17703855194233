import { FunctionComponent } from 'react';
interface IProps {
  pointCount: number;
  onClick?: () => void;
  lat?: number;
  lng?: number;
}

const ClusterMarker: FunctionComponent<IProps> = ({ pointCount, onClick }: IProps) => {
  return (
    <div
      className="z-0 bg-deehiy text-sm font-bold text-white inline-flex justify-center items-center rounded-full aspect-square p-2 min-w-[2.5rem]"
      onClick={onClick}
    >
      <span>{pointCount}</span>
    </div>
  );
};
export default ClusterMarker;
