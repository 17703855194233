import { useState, useEffect, useMemo } from 'react';
import GoogleMapReact from 'google-map-react';
import CircularMarker from './CircularMarker';
import useSupercluster from 'use-supercluster';
import ClusterMarker from './ClusterMarker';
import { Property } from '../../types/types';
import { useProperty } from '../../contexts/property';
import SeeAllListingsBtn from './SeeAllListingsBtn';
// import { useUpdateEffect } from 'react-use';

interface ICoordinates {
  lat: number;
  lng: number;
}

// interface ViewMapProp {
//   properties: Property[];
// }

const ViewMap = () => {
  const { coordinates, properties, fetchAllPropertiesForMap, filter, setPageIndex } = useProperty();
  const [center, setCenter] = useState<ICoordinates>(coordinates);

  const [map, setMap] = useState<any>(null);
  const [maps, setMaps] = useState<any>(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const [zoom, setZoom] = useState<number>(15);
  const [bounds, setBounds] = useState<any>([]);

  // TODO: fix the visual glitch on marker when draging the map
  // https://github.com/google-map-react/google-map-react/issues/1143
  const points = useMemo(
    () =>
      properties.map((property: Property) => ({
        type: 'Feature',
        properties: {
          cluster: false,
          property,
        },
        geometry: {
          type: 'Point',
          coordinates: [property.longitude, property.latitude],
        },
      })),
    [properties]
  );

  const { clusters } = useSupercluster({
    points,
    bounds,
    zoom: zoom,
    options: { radius: 100, maxZoom: 20 },
  });

  // Refer to https://github.com/google-map-react/google-map-react#use-google-maps-api
  // eslint-disable-next-line
  const handleApiLoaded = async (map: any, maps: any) => {
    setMap(map);
    setMaps(maps);
    setIsLoaded(true);
  };

  const onMapChange = (params: any) => {
    const { bounds: b, center: c, zoom: z } = params;

    setZoom(z);
    setCenter(c);
    setBounds([b.nw.lng, b.se.lat, b.se.lng, b.nw.lat]);
  };

  useEffect(() => {
    if (isLoaded && maps && coordinates.lat === 0) {
      // updateMarkers()
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition((position) => {
          const { latitude, longitude } = position.coords;
          map.setCenter({ lat: latitude, lng: longitude });
        });
      }
    }
  }, [map, maps, isLoaded]);

  useEffect(() => {
    setCenter(coordinates);
  }, [coordinates]);

  useEffect(() => {
    fetchAllPropertiesForMap(filter);

    setPageIndex(1); // re-initialization for PropertiesGrid component
  }, [coordinates]);

  useEffect(() => {
    console.log('properties on Map:', properties);
  }, [properties]);

  if (!properties.length)
    return (
      <div className="text-center p-12 text-sm text-gray-500">
        Hmm, looks like no listings here yet. Tap 'Search the Globe' or use filters to explore other
        locations!
        <SeeAllListingsBtn />
      </div>
    );

  return (
    <div className="h-[99vh] mt-1">
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLEMAP_API_KEY }}
        center={center}
        zoom={zoom}
        onChange={onMapChange}
        yesIWantToUseGoogleMapApiInternals
        options={{
          streetViewControl: true,
          gestureHandling: 'greedy',
          styles: [
            {
              featureType: 'poi',
              elementType: 'labels',
              stylers: [{ visibility: 'off' }],
            },
          ],
        }}
        // eslint-disable-next-line
        onGoogleApiLoaded={({ map, maps }: any) => handleApiLoaded(map, maps)}
      >
        {clusters.map((cluster) => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const { cluster: isCluster, point_count: pointCount, property } = cluster.properties;

          if (isCluster) {
            return (
              <ClusterMarker
                key={`cluster-${cluster.id}`}
                lat={latitude}
                lng={longitude}
                pointCount={pointCount}
                onClick={() => {
                  setZoom(zoom + 2);
                  setCenter({ lat: latitude, lng: longitude });
                }}
              />
            );
          }

          return (
            <CircularMarker
              key={property.propertyId}
              lat={latitude}
              lng={longitude}
              id={property.propertyId}
              setCenter={setCenter}
            />
          );
        })}
      </GoogleMapReact>
    </div>
  );
};

export default ViewMap;
