export const HOUSE = 'House / Villa';
export const CONDO = 'Condo / Apartment';
export const TOWNHOUSE = 'Townhouse';
export const LAND = 'Land';
// export const ALL = 'all';

export const INPROGRESS = 'in_progress';
export const PENDING = 'pending_approval';
export const APPROVED = 'approved';
export const DELETED = 'deleted';
export const REJECTED = 'rejected';

export const AGENCY = 'Real Estate Agency';
export const DEVELOPER = 'Real Estate Developer';
export const CONSULTANCY = 'Real Estate Consultancy';

export const FULLY_FURNISHED = 'fully';
export const PARTIALLY_FURNISHED = 'partially';
export const UNFURNISHED = 'unfurnished';

export const MONTHLY_CONTRACT = 'monthly';
export const YEARLY_CONTRACT = 'yearly';

export const POST_TYPES = {
  GENERAL: 'general',
  ARTICLE: 'article',
  DISCUSSION: 'discussion',
};
export const CATEGORY_TYPES = {
  PROPERTY: 'properties',
  ARTICLE: 'article',
  MEMBER: 'member',
  BUSINESS: 'business',
  WL_LISTING: 'wl-properties',
  WL: 'market',
};

// export const ADMIN_ROLE = { roleName: 'admin', roleId: 1 };
// export const MANAGER_ROLE = { roleName: 'manager', roleId: 2 };
// export const MODERATOR_ROLE = { roleName: 'moderator', roleId: 3 };
// export const AGENT_ROLE = { roleName: 'agent', roleId: 4 };

export const ADMIN = 'Admin';
export const MANAGER = 'Manager';
export const MODERATOR = 'Moderator';
export const AGENT = 'Agent';

export const EDIT_LISTING = 'edit listing';
export const ASSIGN_USER = 'assign user';
export const CHANGE_USER_ROLE = 'change user Role';
export const PUBLISH_LISTING = 'publish listing';
export const EDIT_BUSINESS = 'edit business details';
export const INVITE_MEMBER = 'invite member';
export const DELETE_MEMBER = 'delete member';
export const REMOVE_LISTING = 'remove listing';
export const MESSENGER_ACCESS = 'messenger access';

export const ROLES = [
  { roleId: 1, roleName: ADMIN },
  { roleId: 2, roleName: MANAGER },
  // { roleId: 3, roleName: MODERATOR },
  { roleId: 4, roleName: AGENT },
];

export const accessControlConfig = {
  [EDIT_BUSINESS]: {
    1: true, // admin
    2: false, // manager
    3: false, // moderator
    4: false,
  },
  [EDIT_LISTING]: {
    1: true, // admin
    2: true, // manager
    3: false, // moderator
    4: true, // agent :: handle within the parent function "canAccess"
  },
  [REMOVE_LISTING]: {
    1: true, // admin
    2: true, // manager
    3: false, // moderator
    4: false, // agent
  },
  [ASSIGN_USER]: {
    1: true, // admin
    2: true, // manager
    3: false, // moderator
    4: false, // agent
  },
  [CHANGE_USER_ROLE]: {
    1: true, // admin
    2: true, // manager
    3: false, // moderator
    4: false, // agent
  },
  [PUBLISH_LISTING]: {
    1: true, // admin
    2: true, // manager
    3: false, // moderator
    4: true, // agent
  },
  [INVITE_MEMBER]: {
    1: true, // admin
    2: false, // manager
    3: false, // moderator
    4: false, // agent
  },
  [DELETE_MEMBER]: {
    1: true, // admin
    2: false, // manager
    3: false, // moderator
    4: false, // agent
  },
  [MESSENGER_ACCESS]: {
    1: true, // admin
    2: true, // manager
    3: true, // moderator
    4: true, // agent
  },
};

export const COMMUNITY_CATEGORIES = {
  ALL: { label: 'All Deehiy', value: 'all' },
  CONNECTIONS: { label: 'Connections', value: 'connections' },
  // INVITATIONS: { label: 'Invitations', value: 'invitations' },
  BLOCKED: { label: 'Blocked members', value: 'blocked' },
};

export const BUYER_TYPE = 'buyer';
export const RENTER_TYPE = 'renter';
export const SELLER_TYPE = 'seller';
export const AGENT_TYPE = 'agent';
export const LANDLORD_TYPE = 'landlord';
