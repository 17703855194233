import React from 'react';
import LoginAndSecurity from '../components/dashboard/LoginAndSecurity';

const LoginAndSecurityPage = () => {
  return (
    <div>
      <LoginAndSecurity />
    </div>
  );
};

export default LoginAndSecurityPage;
