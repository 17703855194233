import { MarketFilter, Owner, Property } from '../types/types';
import http from './httpService';
// import { resizeFilesToStr } from '../utils/reactResize';
// import { uploadOnCloud } from '../utils/uploader';
// import { resizeToStr } from '../utils/resizeImage';
const apiPropertyEndpoint = '/api/properties';

// Retrieve filtered Properties
export const getAllProperties = (filter: MarketFilter = null, page = 1) => {
  return http.post(`${apiPropertyEndpoint}/search?page=${page}`, { filter });
};

export const getAllPropertiesForMap = (filter: MarketFilter = null) => {
  return http.post(`${apiPropertyEndpoint}/map`, { filter });
};

// Retrieve all published Properties
export const getAllPublishedProperties = () => {
  return http.get(`${apiPropertyEndpoint}/published`);
};

// retrieve 1 single Property by id
export const getPropertyById = (id: number) => {
  return http.get(`${apiPropertyEndpoint}/${id}`);
};

// retrieve 1 single Property by id
export const getPublicPropertyById = (id: number, currency: string) => {
  return http.get(`${apiPropertyEndpoint}/${id}/${currency}`);
};

// Update a Property with id
export const updateProperty = async (property: Property) => {
  return http.put(`${apiPropertyEndpoint}/`, { property });
};

// Add a Property with id
export const addProperty = async (property: Property) => {
  return http.post(apiPropertyEndpoint, { property }); // put
};

// in progress create
export const saveInProgressProperty = async (property: Property) => {
  // const { imagesTempFiles, floorplansTempFiles } = property;
  // if (typeof imagesTempFiles !== 'undefined') {
  //   const filesStr = await resizeToStr(
  //     imagesTempFiles.map((file) => file.preview),
  //     1280,
  //     720,
  //     true
  //   );
  //   // const filesStr = await resizeFilesToStr(imagesTempFiles, 1280, 720);
  //   const imgs = await uploadOnCloud(filesStr as string[]);
  //   if (Array.isArray(imgs)) {
  //     property.cloudinaryImages = [...imgs];
  //   }
  // }
  // if (typeof floorplansTempFiles !== 'undefined') {
  //   const floorPlansFilesStr = await resizeFilesToStr(floorplansTempFiles, 1280, 720);
  //   const fps = await uploadOnCloud(floorPlansFilesStr as string[]);

  //   if (Array.isArray(fps)) {
  //     property.cloudinaryFps = [...fps];
  //   }
  // }

  return http.post(`${apiPropertyEndpoint}/inprogress`, { property });
};

// Delete a Property with id
export const deleteProperty = (propertyId: number, userId?: number) => {
  // will change the status to 'deleted'
  return http.post(`${apiPropertyEndpoint}/delete`, { propertyId, userId });
};

// Delete all Properties
export const deleteAllProperties = () => {
  // return  http.delete(apiPropertyEndpoint)
};

// Search property by keyword
export const searchPropertyByKeyword = (keyword: string) => {
  return http.get(`${apiPropertyEndpoint}/search/${keyword}`);
};

// Search property by user id
export const getPropertiesByUserId = (userId: Number, currency: string) => {
  return http.get(`${apiPropertyEndpoint}/user/${userId}/${currency}`);
};

// get max price
export const getMaxListingPrice = (curr: string) => {
  return http.get(`${apiPropertyEndpoint}/maxprice/${curr}`);
};

// increase property view count
export const increaseViewCount = (id: number) => {
  return http.post(`${apiPropertyEndpoint}/view/${id}`);
};

//toggle save property in favorit
export const toggleSaveProperty = (propertyId: number, userId: number) => {
  return http.post(`${apiPropertyEndpoint}/save/`, { propertyId, userId });
};

//toggle publish/unpublish property
export const togglePropertyPublishStatus = (propertyId: number) => {
  return http.post(`${apiPropertyEndpoint}/publish/`, { propertyId });
};

// check if the current property is already saved
export const isPropertySaved = (propertyId: number, userId: number) => {
  return http.post(`${apiPropertyEndpoint}/issaved/`, { propertyId, userId });
};

//toggle save property in favorit
export const contactAgentProperty = (propertyId: number, userId: number) => {
  return http.post(`${apiPropertyEndpoint}/contact/`, { propertyId, userId });
};

// save owner
export const saveOwner = (propertyId: number, owner: Owner) => {
  return http.post(`${apiPropertyEndpoint}/owner/`, { propertyId, owner });
};

// fetch owner
export const fetchOwner = (propertyId: number) => {
  return http.post(`${apiPropertyEndpoint}/fetchowner/`, { propertyId });
};

export const generateDescription = async (property: Property) => {
  return http.post(`${apiPropertyEndpoint}/generate`, { property });
};
