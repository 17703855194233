import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { attributeUserType } from '../services/auth/userService';
import { useAuth } from '../contexts/auth';
import CityPicker from '../components/main/CityPicker';
import Loader from '../components/main/Loader';
import {
  AGENT_TYPE,
  BUYER_TYPE,
  LANDLORD_TYPE,
  RENTER_TYPE,
  SELLER_TYPE,
} from '../constants/constant';

const UserTypeSelector = ({ setStep, setRole }: any) => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async (selectedRole: string) => {
    // Handle button clfirst  const handleClick = async (role: string) => {
    setIsLoading(true);
    try {
      // Call the external function from UserService
      await attributeUserType(currentUser?.id, selectedRole.toLowerCase());
      setRole(selectedRole.toLowerCase());
      // Redirect after successful API call

      setStep(1);
    } catch (error) {
      console.error('Error handling role selection:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle skip click
  const handleSkip = () => {
    navigate('/');
  };

  return (
    <div className="flex flex-col pt-20 sm:pt-16  min-h-screen">
      {isLoading && <Loader />}
      <h1 className="text-3xl sm:text-5xl font-bold text-center">Welcome to Deehiy!</h1>
      <p className="text-xl sm:text-2xl text-center mt-12">We're thrilled to have you here</p>

      {/* Conditional text based on screen size */}
      <p className="text-xl sm:text-2xl text-center mt-4 hidden sm:block px-6">
        To enhance your experience, please click the button that best matches you.
      </p>
      <p className="text-xl sm:text-2xl text-center mt-4 sm:hidden">Tap what best matches you.</p>

      <div className="mt-10 space-y-6">
        {['Buyer', 'Renter', 'Seller', 'Landlord', 'Agent'].map((r) => (
          <button
            key={r}
            onClick={() => handleClick(r)}
            className="form-next-btn mx-auto text-center justify-center items-center flex-col flex space-y-6 w-48 p-3"
          >
            {r}
          </button>
        ))}
      </div>

      <p
        className="mt-4 underline cursor-pointer text-center justify-center text-deehiy"
        onClick={handleSkip}
      >
        Skip
      </p>
    </div>
  );
};
type Role =
  | typeof BUYER_TYPE
  | typeof RENTER_TYPE
  | typeof SELLER_TYPE
  | typeof LANDLORD_TYPE
  | typeof AGENT_TYPE
  | '';
const PostRegistrationPage: React.FC = () => {
  const [step, setStep] = useState(0);
  const [role, setRole] = useState<Role>('');

  if (step === 0) {
    return <UserTypeSelector setStep={setStep} setRole={setRole} />;
  } else {
    return <CityPicker role={role} />;
  }
};

export default PostRegistrationPage;
