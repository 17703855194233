import React, { useMemo, useRef } from 'react';
import { Grid, _ } from 'gridjs-react';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import 'gridjs/dist/theme/mermaid.css';
import { AiFillEyeInvisible } from 'react-icons/ai';
import { BsTrash } from 'react-icons/bs';
import { BiEdit } from 'react-icons/bi';
import { NumericFormat } from 'react-number-format';

import { useAuth } from '../../contexts/auth';
import { useNavigate } from 'react-router-dom';
import { useInternationalization } from '../../contexts/internationalization';
import { adaptStatus } from '../../utils/adaptStatusStyle';
import { togglePropertyPublishStatus, deleteProperty } from '../../services/propertyService';
import { useTranslation } from 'react-i18next';

const DefaultImgUrl = process.env.REACT_APP_DEFAULT_LISTING_URL;

const PropertiesTable = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const gridRef = useRef<any>(null);
  const { currency } = useInternationalization();
  const handlePublication = async (propertyId: number, rowId: string) => {
    console.log(rowId);
    try {
      const publish = await togglePropertyPublishStatus(propertyId);

      // gridRef.current.forceRender();
      gridRef.current.forceUpdate();

      if (publish.data === t('not approved')) {
        toast.error(t('the listing has not been approved yet'));
        return;
      }
    } catch (err: any) {
      console.log('error when toggle publih status', err);
      throw new Error(err);
    }
  };

  const handleDelete = async (propertyId: number) => {
    if (confirm(t('confirm_delete_Property', { propertyId })) === true) {
      try {
        await deleteProperty(propertyId, currentUser?.id);

        gridRef.current.forceUpdate();
      } catch (err: any) {
        console.log('error when delete property', err);
        throw new Error(err);
      }
    } else {
      return;
    }
  };

  const columns = useMemo(() => {
    const baseColumns = [
      {
        id: '0',
        name: 'd',
        hidden: true,
        width: '0%',
        formatter: (cell: any) => cell,
      },
      {
        id: '1',
        name: _(<div className="flex justify-evenly"></div>),
        width: '9%',
        formatter: (cell: any) =>
          _(
            <div className="aspect-video object-contain px-2">
              <img className={'w-full'} alt="img_property" src={cell} />
            </div>
          ),
      },
      {
        id: '2',
        name: t('Listings'),
        sort: 'true',
        align: 'left',
        width: '15%',
        formatter: (cell: any, row: any) =>
          _(
            <div
              title={t('Edit property')}
              className="px-6 hover:underline hover:cursor-pointer"
              onClick={() => navigate(`/users/editlisting/${row.cells[0].data}`)}
            >
              {cell ? cell : '-'}
            </div>
          ),
      },
      {
        id: '3',
        name: t('Location'),
        width: '13%',
        sort: true,
        formatter: (cell: any) => _(<div className="px-3">{cell}</div>),
      },
      {
        id: '4',
        name: t('Sale Price'),
        width: '8%',
        sort: true,
        formatter: (cell: any) =>
          _(
            <div className="text-center">
              {cell ? (
                <NumericFormat
                  value={cell}
                  thousandSeparator=" "
                  displayType={'text'}
                  prefix={currency + ' '}
                />
              ) : (
                '-'
              )}
            </div>
          ),
      },
      {
        id: '5',
        name: t('Rent Price'),
        width: '8%',
        sort: true,
        formatter: (cell: any) =>
          _(
            <div className="text-center">
              {cell ? (
                <NumericFormat
                  value={cell}
                  thousandSeparator=" "
                  displayType={'text'}
                  prefix={currency + ' '}
                />
              ) : (
                '-'
              )}
            </div>
          ),
      },
      {
        id: '6',
        name: t('Status'),
        width: '6%',
        sort: true,
        formatter: (cell: any) =>
          // adapt adaptStatus crash because of translation context.
          // it works on ListingTable (business, admin)
          _(<div className="mx-auto">{cell ? adaptStatus(cell) : 'unknown'}</div>),
      },
      {
        id: '7',
        name: t('Visibility'),
        width: '7%',
        sort: true,
        formatter: (cell: any) =>
          _(
            <div className="mx-auto">
              <img
                alt=""
                className="w-6 h-6 mx-auto"
                src={`/images/icons/icon-dashboard-landing/${
                  cell ? 'published.svg' : 'unpublished.svg'
                }`}
              />
            </div>
          ),
      },
      {
        id: '8',
        name: t('Views'),
        width: '6%',
        sort: true,
        formatter: (cell: any) => _(<div className="text-center">{cell}</div>),
      },
      {
        id: '9',
        name: t('Action'),
        width: '7%',
        sort: false,

        formatter: (cell: any, row: any) =>
          _(
            <div className="justify-evenly flex">
              <BsTrash
                size={20}
                onClick={() => handleDelete(cell)}
                className="text-gray-600 hover:text-deehiy hover:scale-110 duration-300"
              />
              <AiFillEyeInvisible
                size={20}
                onClick={() => handlePublication(cell, row.id)}
                className="text-gray-600 hover:text-deehiy hover:scale-110 duration-300"
              />
              <BiEdit
                size={20}
                onClick={() => navigate(`/users/editlisting/${cell}`)}
                className="text-gray-600 hover:text-deehiy hover:scale-110 duration-300"
              />
            </div>
          ),
      },
    ];

    // hide some columns on mobile
    const mobileColumns = baseColumns.filter((column) => ['0', '1', '2', '7'].includes(column.id));

    return window.innerWidth <= 768 ? mobileColumns : baseColumns;
  }, [window.innerWidth]);

  return (
    <>
      <Grid
        ref={gridRef}
        className={{
          table: 'text-xs w-full',
          search: 'border-deehiy border rounded-md ',
          // pagination: " text-gray-700  flex justify-between p-2 ",
          // paginationSummary: "text-xs",
          // paginationButton:
          //   "bg-white text-sm py-1 shadow-sm rounded px-2 hover:bg-gray-100 hover:text-gray-800 border border-gray-300",
          // paginationButtonNext: "w-18 ",
          // paginationButtonCurrent: " text-black bg-gray-100 border-gray-400",
          // paginationButtonPrev: "w-18",
        }}
        server={{
          url: `/api/properties/user/${currentUser?.id}/${currency}`,
          then: (data: any) => {
            return data.map((property: any) => [
              property.propertyId,
              property.images.length ? property.images[0].url : DefaultImgUrl,
              property.propertyTitle,
              property.city ? property.city : '-',
              property.listingPrice,
              property.monthlyRentPrice,
              property.status,
              property.published,
              property.viewCount,
              property.propertyId,
            ]);
          },
          handle: (res: any) => {
            // no matching records found
            if (res.status === 404) return { data: [] };
            if (res.ok) return res.json();

            throw Error('oh no :(');
          },
        }}
        columns={columns}
        // pagination={{
        //   limit: 5,
        // }}
        search={true}
        language={{
          search: {
            placeholder: t('Search listings...'),
          },
          // pagination: {
          //   previous: " < Previous",
          //   next: "Next >",
          //   showing: "Showing",
          //   results: () => "properties",
          // },
        }}
      />
      <ToastContainer transition={Slide} />
    </>
  );
};

export default PropertiesTable;
