import React, { useState, useEffect } from 'react';
import SquarePage from './square/SquarePage';
import CommunityPage from './community/CommunityPage';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import MarketPage from './market/MarketPage';
type SearchResultType = 'square' | 'community' | 'market';
import { useGlobal } from '../contexts/global';

const SearchResultPage = () => {
  const { t } = useTranslation();
  const { type } = useParams<{ type: SearchResultType }>(); // Get the URL parameter
  const [searchType, setSearchType] = useState<SearchResultType>(type || 'square'); // Set initial state based on URL param
  const navigate = useNavigate();
  const { keyword } = useGlobal();
  const [content, setContent] = useState<JSX.Element | null>(null);

  useEffect(() => {
    console.log('useffect type', type);
    setSearchType(type || 'square');
  }, [type]);

  // Update the content state whenever searchType or other dependencies change
  useEffect(() => {
    const renderContent = (currentSearchType: string) => {
      switch (currentSearchType) {
        case 'square':
          return <SquarePage />;
        case 'community':
          return <CommunityPage />;
        case 'market':
          return <MarketPage />;
        default:
          return <div className="text-center">something went wrong</div>;
      }
    };

    // Update the state with the new content
    setContent(renderContent(searchType));
  }, [searchType]);

  useEffect(() => {
    if (!keyword) navigate('/');
  }, []);

  return (
    <div>
      <div className="h-12 mx-auto w-full sm:w-[500px] bg-white  mt-12 sm:my-2 p-1 flex items-center border text-gray-800 border-gray-300 rounded-xl justify-around ">
        <button
          className={`h-full w-full px-1 rounded-xl text-center  items-center ${
            searchType === 'market' ? 'text-deehiy bg-deehiy-veryLight font-bold' : 'semibold'
          }`}
          onClick={() => navigate('/results/market')}
          //   onClick={() => setSearchType('market')}
        >
          {t('Market')}
        </button>
        <button
          className={`h-full w-full px-1 rounded-xl text-center  items-center ${
            searchType === 'square' ? 'text-deehiy bg-deehiy-veryLight font-bold' : 'semibold'
          }`}
          onClick={() => navigate('/results/square')}
          //   onClick={() => setSearchType('square')}
        >
          {t('Square')}
        </button>
        <button
          className={`h-full w-full px-1 rounded-xl text-center  items-center ${
            searchType === 'community' ? 'text-deehiy bg-deehiy-veryLight font-bold' : 'semibold'
          }`}
          onClick={() => navigate('/results/community')}
          //   onClick={() => setSearchType('community')}
        >
          {t('Community')}
        </button>
      </div>
      {/* Render the content based on the current search type */}
      {content}{' '}
    </div>
  );
};

export default SearchResultPage;
