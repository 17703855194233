import React from 'react';

const Listing = () => {
  return (
    <div className="container max-w-[850px] mx-auto space-y-6 py-10 px-4">
      <div className="font-light text-2xl text-center">Listing</div>
      <div className="text-sm font-extralight">
        <div className="font-bold text-xl mb-2">Try Listing</div>
        We believe in transparency and promote a free and simple to use end-to-end user platform.
        Listing on deehiy.com is as easy as ordering a taxi on your phone and created to benefit
        Sellers, Buyers, Agents and Agencies alike.
        <br />
        <br />
        <div className="font-bold text-xl mb-2">Zero Commission Explained</div>
        No commission means more profit. At deehiy.com anyone can self-list their property for free
        at <b> Zero Commission </b> and have access to all the tools required to sell with ease.
        Users have the choice to maintain a free user profile or choose any of the plans suitable
        for individual Agents or Agencies, where various tools are unlocked to provide further
        insights.
        <br />
        <br />
        <div className="font-bold text-xl mb-2">Listing Successfully</div>
        “One image is worth a thousand words”, is the ideal upon which we have created deehiy.com.
        The better and more complete a listing, the more likely it will be sold quicker. We
        therefore encourage complete and detailed listings with high quality photos and full
        information including floor plans. Buyers often buy property based on a set of location
        factors and therefore a description with what the neighborhood has to offer is always
        helpful.
        <br />
        <br />
        <div className="font-bold text-xl mb-2">Property Hunters</div>
        No more hours and days spent on finding the right property for you. Our hunting board is
        accessible to anyone who wishes to put up a wanted property search. Within two simple steps
        anyone can post what they are looking for and let artificial intelligence do the rest. Our
        system will match properties globally based on the criteria and listing owners of properties
        with a matching rate above 75% will be notified and given the opportunity to connect with
        the hunter. Once the connection is done, our job is done, and we leave the parties to work
        out the rest. It is really that simple.
        <br />
        <br />
      </div>
    </div>
  );
};

export default Listing;
