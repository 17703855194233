import React from 'react';

const HuntingPreLaunch = () => {
  return (
    <div className="mx-auto flex flex-col justify-center items-center px-6 sm:px-24 container">
      <div className="flex flex-col text-left w-full">
        {/* title */}
        <div className="text-2xl sm:text-5xl font-bold pt-12 sm:pt-8 pb-4 sm:py-6">
          Welcome Hunters! We’re launching soon.
        </div>
        <div className="text-sm sm:text-xl pb-4 sm:pb-16">
          Scouting properties is about to get as simple as never before.
        </div>
      </div>
      <div className="flex flex-col sm:flex-row">
        <div className="flex flex-col w-wull sm:w-3/5 pr-12">
          <div className="text-xs sm:text-sm py-6 ">
            We are finalizing last details and launch the hunting board very soon. Here is a small
            teaser.
          </div>
          <div className="py-3">
            <div className="text-xs sm:text-sm font-bold text-deehiy">
              Benefits for property seekers
            </div>
            <div className="text-xs sm:text-sm">
              The hunting board will allow property seekers to reduce scouting time to just a few
              minutes. How? All we need to know is what you look for and our AI will do the rest and
              bring properties, matching your criteria, directly to you.
            </div>
          </div>
          <div className="py-3">
            <div className="text-xs sm:text-sm font-bold text-deehiy">
              Benefits for property sellers & agents
            </div>
            <div className="text-xs sm:text-sm">
              It was never easier to generate leads for sellers and agents. Hunts entered into the
              hunting board will be matched with all listings on our marketplace. Should a listing
              match the hunt, the listing owner will be notified and is able to submit the property
              listing to the seeker for consideration.
            </div>
          </div>
        </div>
        <div className="flex justify-center w-full sm:w-2/5 p-2 sm:p-6 items-center">
          <img alt="" src={'/images/prelaunch-hunting-board.svg'} className="w-full" />
        </div>
      </div>
    </div>
  );
};
export default HuntingPreLaunch;
