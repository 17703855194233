import React, { useEffect, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import * as Emoji from 'quill-emoji';
import 'quill-emoji/dist/quill-emoji.css';
import 'quill-mention/dist/quill.mention.css';
import 'quill-mention';
import ReactDOMServer from 'react-dom/server';
import { CiFaceSmile } from 'react-icons/ci';
import { PiImageLight } from 'react-icons/pi';
import MagicUrl from 'quill-magic-url';
// import { AiOutlineGif } from 'react-icons/ai';

// Convert the CiFaceSmile component to an SVG string
const emojiIcon = ReactDOMServer.renderToStaticMarkup(
  <CiFaceSmile title="emoji" className="cursor-pointer text-deehiy w-6 h-6 " />
);
const imageIcon = ReactDOMServer.renderToStaticMarkup(
  <PiImageLight className="cursor-pointer flex text-deehiy w-5 h-5" />
);
// Get the current icons
let icons = Quill.import('ui/icons');
// Replace the 'emoji' icon
// icons.emoji = emojiIcon;
icons.image = imageIcon;

//Add https to link if https is not present
const Link = Quill.import('formats/link');
Link.sanitize = function (url: any) {
  // quill by default creates relative links if scheme is missing.
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    return `http://${url}`;
  }
  return url;
};
Quill.register(Link, true);
Quill.register('modules/emoji', Emoji);
Quill.register('modules/magicUrl', MagicUrl);

// Add sizes to whitelist and register them
const Size = Quill.import('formats/size');
Size.whitelist = ['extra-small', 'small', 'medium', 'large'];
Quill.register(Size, true);

// Add fonts to whitelist and register them
const Font = Quill.import('formats/font');
Font.whitelist = ['arial', 'comic-sans', 'courier-new', 'georgia', 'helvetica', 'lucida'];
Quill.register(Font, true);

let atValues: any[] = [];
let hashValues: any[] = [];

const mention = {
  allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
  mentionDenotationChars: ['@', '#'],
  linkTarget: '_blank',
  // linkTarget: '_self',
  source: function (searchTerm: any, renderList: any, mentionChar: any) {
    let values;

    if (mentionChar === '@') {
      values = atValues;
    } else {
      values = hashValues;
    }

    if (searchTerm.length === 0) {
      renderList(values, searchTerm);
    } else {
      const matches = [];
      for (let i = 0; i < values.length; i++)
        if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase()))
          matches.push(values[i]);
      renderList(matches, searchTerm);
    }
  },
  // renderItem: function (item: any) {
  //   if (item.denotationChar === '@') {
  //     return `<span class='mention-item' data-link="/user/${item.id}">${item.value}</span>`;
  //   } else {
  //     return `<span class='mention-item' data-link="/hashtag/${item.id}">${item.value}</span>`;
  //   }
  // },
};

// const formats = [
//   'header',
//   'font',
//   'size',
//   'bold',
//   'italic',
//   'underline',
//   'strike',
//   'blockquote',
//   'list',
//   'bullet',
//   'indent',
//   'link',
//   'mention',
//   'emoji',
// ];

const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'mention',
  'emoji',
];

function Editor({
  changeHandler,
  value,
  values,
  people,
  placeholder,
  onImageUpload,
  tools,
  reset,
}: any) {
  const [editorHtml, setEditorHtml] = useState(value);

  const modules = {
    // toolbar: [
    //   [{ header: [1, 2, 3, 4, 5, 6, false] }],

    //   [{ list: 'ordered' }, { list: 'bullet' }],
    //   ['bold', 'italic', 'underline'],
    //   [{ color: [] }, { background: [] }],
    //   // [{ script: 'sub' }, { script: 'super' }],
    //   [{ align: [] }],
    //   ['link', 'blockquote', 'emoji'],
    //   ['clean'],
    // ],
    toolbar: {
      container: tools || [['image'], ['emoji']],
      handlers: {
        image: onImageUpload,
      },
    },
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
    magicUrl: true,
    mention,
    // 'emoji-toolbar': true,
    'emoji-textarea': false,
    'emoji-toolbar': {
      buttonIcon: emojiIcon,
    },
  };

  const handleChange = (val: any) => {
    if (val) {
      setEditorHtml(val);
    } else {
      setEditorHtml('');
    }

    // mention @
    if (values) {
      let hash = values;
      hash.forEach((v: any) => {
        // v['value'] = v['display'];
        v.value = v.display;
      });
      hashValues = hash;
    }

    // mention #
    if (people) {
      let peoples = people;
      peoples.forEach((v: any) => {
        // v['value'] = v['display'];
        v.value = v.display;
      });
      atValues = peoples;
    }
    // changeHandler(html);
  };

  useEffect(() => {
    changeHandler(editorHtml);
  }, [editorHtml]);

  useEffect(() => {
    if (reset) {
      setEditorHtml('');
    }
  }, [reset]);

  // useEffect(() => {
  //   setEditorHtml(value);
  // }, [value]);

  return (
    <div>
      <ReactQuill
        onChange={handleChange}
        value={editorHtml}
        modules={modules}
        formats={formats}
        // onFocus={() => alert('focus')}
        // onBlur={() => alert('blur')}
        bounds={'.app'}
        // bounds={window}
        placeholder={placeholder}
        className={`${editorHtml.length > 0 ? 'h-40' : 'h-28'} duration-500`}
      />
    </div>
  );
}

export default function EMTextArea({
  placeHolder,
  name,
  value,
  changeHandler,
  hash,
  peopleMention,
  onImageUpload,
  tools,
  reset,
}: any) {
  return (
    <div className="custom-quill">
      <Editor
        placeholder={placeHolder}
        name={name}
        value={value}
        changeHandler={changeHandler}
        values={hash}
        people={peopleMention}
        onImageUpload={onImageUpload}
        tools={tools}
        reset={reset}
      />
    </div>
  );
}
