import React, { useState, useEffect, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Toggle from '../../form/Toggle';
// import { yupResolver } from '@hookform/resolvers/yup';
import { useProperty } from '../../../contexts/property';
import { dateOptions, propertyTypesInitialState } from '../../../data/data';
import Input from '../../form/Input';
import Select from '../../form/Select';
import Textarea from '../../form/Textarea';
import { Status, ParamsPerType } from '../../../types/types';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { cleanText } from '../../../utils/textValidater';
import { useTranslation } from 'react-i18next';

export const getCountryAreaUnit = (countryIsoCode: string | undefined) => {
  if (countryIsoCode === 'LK') {
    return {
      homeUnitTitle: 'square feet',
      homeUnit: 'sq.f',
      homeUnitDescription: 'Home size in square feet',
      landUnitTitle: 'perches',
      landUnit: 'perch',
      landUnitDescription: 'Lot size in perches',
    };
  } else {
    return {
      homeUnitTitle: 'square meter',
      homeUnit: 'sq.m',
      landUnitTitle: 'square meter',
      landUnit: 'sq.m',
      homeUnitDescription: 'Home size in square meter',
      landUnitDescription: 'Lot size in square meter',
    };
  }
};

const ListingBasicContent = ({ modifyProperty }: any) => {
  const methods = useForm({
    mode: 'onTouched',
    // resolver: yupResolver(UserFormSchema),
  });

  const firstUpdate = useRef(true);
  const { currentProperty, setCurrentProperty } = useProperty();
  const { t } = useTranslation();

  const [editProperty, setEditProperty] = useState(currentProperty);
  const [params, setParams] = useState<ParamsPerType | null>(null);

  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [isEditingSpecifics, setIsEditingSpecifics] = useState(false);
  const [isEditingPublished, setIsEditingPublished] = useState(false);
  const areaUnit = getCountryAreaUnit(currentProperty.isoCodeCountry);

  function checkParams(specificsParams: any, obj: any) {
    if (
      (specificsParams.bedrooms && obj.bedrooms !== 0) ||
      (specificsParams.bathrooms && obj.bathrooms !== 0) ||
      (specificsParams.homeSize && obj.homeSize !== 0) ||
      (specificsParams.lotSize && obj.lotSize !== 0)
    ) {
      return false;
    }

    return true;
  }

  const handleTitleEditClick = () => {
    setIsEditingTitle((p) => !p);
  };

  //description

  const handleDescriptionEditClick = () => {
    setIsEditingDescription((p) => !p);
  };

  // specifics

  const handleSpecificsEditClick = () => {
    if (checkParams(params, editProperty)) {
      console.log(checkParams(params, editProperty));
      setIsEditingSpecifics((p) => !p);
    }
  };

  // status
  const handlePublishedEditClick = () => {
    setIsEditingPublished((p) => !p);
  };

  useEffect(() => {
    if (!isEditingSpecifics && JSON.stringify(editProperty) !== JSON.stringify(currentProperty)) {
      setCurrentProperty({
        ...currentProperty,
        bedrooms: editProperty.bedrooms,
        bathrooms: editProperty.bathrooms,
        homeSize: editProperty.homeSize,
        lotSize: editProperty.lotSize,
        builtInDate: editProperty.builtInDate,
        lastRenovationDate: editProperty.lastRenovationDate,
        floorLevel: editProperty.floorLevel,
        ceilingHeight: editProperty.ceilingHeight,
      });
    }
  }, [
    editProperty.bedrooms,
    editProperty.bathrooms,
    editProperty.homeSize,
    editProperty.lotSize,
    editProperty.builtInDate,
    editProperty.lastRenovationDate,
    editProperty.floorLevel,
    editProperty.ceilingHeight,
    ,
    isEditingSpecifics,
  ]);

  const handleKeyDown = (event: any) => {
    // close on press ENTER
    // shift is used to create a line break
    if (event.keyCode == 13 && !event.shiftKey) {
      if (isEditingTitle) {
        handleTitleEditClick();
        return;
      }

      // setIsEditingDescription(false);
      if (isEditingDescription) {
        handleDescriptionEditClick();
        return;
      }

      // setIsEditingSpecifics(false);
      if (isEditingSpecifics) {
        handleSpecificsEditClick();
        return;
      }
      // setIsEditingPublished(false);
      if (isEditingPublished) {
        handlePublishedEditClick();
        return;
      }
    }
  };

  useEffect(() => {
    if (!isEditingTitle && editProperty.propertyTitle !== currentProperty.propertyTitle) {
      setCurrentProperty({ ...currentProperty, propertyTitle: editProperty.propertyTitle });
    }
  }, [editProperty.propertyTitle, isEditingTitle]);

  useEffect(() => {
    if (!isEditingPublished && editProperty.published !== currentProperty.published) {
      if (currentProperty.status !== ('approved' as Status)) {
        toast.warn(t('This listing has not been approved yet'));
        setEditProperty({ ...editProperty, published: false });
        return;
      }
      setCurrentProperty({ ...currentProperty, published: editProperty.published });
    }
  }, [editProperty.published, isEditingPublished]);

  useEffect(() => {
    if (
      !isEditingDescription &&
      editProperty.propertyDescription !== currentProperty.propertyDescription
    ) {
      setCurrentProperty({
        ...currentProperty,
        propertyDescription: editProperty.propertyDescription,
      });
    }
  }, [editProperty.propertyDescription, isEditingDescription]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    modifyProperty(currentProperty).then(() => {
      toast.success('Listing updated!');
    });
  }, [
    currentProperty.propertyTitle,
    currentProperty.propertyDescription,
    currentProperty.bedrooms,
    currentProperty.bedrooms,
    currentProperty.bathrooms,
    currentProperty.homeSize,
    currentProperty.lotSize,
    currentProperty.builtInDate,
    currentProperty.lastRenovationDate,
    currentProperty.floorLevel,
    currentProperty.ceilingHeight,
    currentProperty.published,
    currentProperty.furnishingStatus,
  ]);

  useEffect(() => {
    const selectedType = propertyTypesInitialState.find(
      (el: ParamsPerType) => el.propertyType === currentProperty.propertyType
    );

    if (selectedType) setParams(selectedType);
  }, [currentProperty.propertyType]);

  return (
    <div className="flex-col">
      <FormProvider {...methods}>
        <form onKeyDown={handleKeyDown}>
          <div className="w-full md:w-full">
            <div className="pt-2 pb-4">
              <div className="flex justify-between pb-2">
                <span className="font-light">{t('Listing title')}</span>
                <button
                  type="button"
                  className="text-deehiy font-bold"
                  onClick={handleTitleEditClick}
                >
                  {!isEditingTitle ? t('Edit') : t('Save')}
                </button>
              </div>
              <div>
                {!isEditingTitle ? (
                  <div className="">{currentProperty.propertyTitle}</div>
                ) : (
                  <Input
                    label=""
                    name="propertyTitle"
                    id="propertyTitle"
                    type="text"
                    maxLength={50}
                    value={editProperty.propertyTitle}
                    onChange={(e) =>
                      setEditProperty({
                        ...editProperty,
                        propertyTitle: cleanText(e.target.value),
                      })
                    }
                  />
                )}
              </div>
            </div>
          </div>

          <hr className="my-5" />
          <div className="w-full md:w-full">
            <div className="pt-2 pb-4">
              <div className="flex justify-between pb-2">
                <span className="font-light">{t('Listing description')}</span>
                <button
                  type="button"
                  className="text-deehiy font-bold"
                  onClick={handleDescriptionEditClick}
                >
                  {!isEditingDescription ? t('Edit') : t('Save')}
                </button>
              </div>
              <div>
                {!isEditingDescription ? (
                  <div className="">{currentProperty.propertyDescription}</div>
                ) : (
                  <Textarea
                    cols={5}
                    maxLength={2000}
                    textLength={editProperty.propertyDescription.length}
                    rows={4}
                    placeholder=""
                    label=""
                    id="propertyDescription"
                    // type='text'
                    value={editProperty.propertyDescription}
                    onChange={(e) =>
                      setEditProperty({
                        ...editProperty,
                        propertyDescription: cleanText(e.target.value),
                      })
                    }
                  />
                )}
              </div>
            </div>
          </div>

          <hr className="my-5" />

          <div className="w-full md:w-full">
            <div className="pt-2 pb-4">
              <div className="flex justify-between pb-2">
                <span className="font-light">{t('Property specifics')}</span>

                <button
                  type="button"
                  className="text-deehiy font-bold"
                  onClick={handleSpecificsEditClick}
                >
                  {!isEditingSpecifics ? t('Edit') : t('Save')}
                </button>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2">
                <div className="flex-col ">
                  {/* bedrooms */}
                  {params?.specifics.bedrooms && (
                    <div className="flex w-full space-x-4 items-center">
                      <span className="whitespace-nowrap font-light py-3 w-[110px]">
                        {t('Bedrooms')}:
                      </span>
                      <div className="max-w-[70px]">
                        {!isEditingSpecifics ? (
                          currentProperty.bedrooms
                        ) : (
                          <Input
                            label=""
                            name="bedrooms"
                            id="bedrooms"
                            type="number"
                            placeholder=""
                            value={editProperty.bedrooms ? editProperty.bedrooms : ''}
                            onChange={(e) =>
                              setEditProperty({ ...editProperty, bedrooms: +e.target.value })
                            }
                          />
                        )}
                      </div>
                    </div>
                  )}
                  {/* Baths */}
                  {params?.specifics.bathrooms && (
                    <div className="flex w-full space-x-4 items-center">
                      <span className="whitespace-nowrap font-light py-3 w-[110px]">
                        {t('Baths')}:
                      </span>
                      <div className="max-w-[70px]">
                        {!isEditingSpecifics ? (
                          currentProperty.bathrooms
                        ) : (
                          <Input
                            label=""
                            name="bathrooms"
                            id="bathrooms"
                            type="number"
                            placeholder=""
                            value={editProperty.bathrooms ? editProperty.bathrooms : ''}
                            onChange={(e) =>
                              setEditProperty({ ...editProperty, bathrooms: +e.target.value })
                            }
                          />
                        )}
                      </div>
                    </div>
                  )}
                  {/* home size */}
                  {params?.specifics.homeSize && (
                    <div className="flex w-full space-x-4 items-center">
                      <span className="whitespace-nowrap font-light py-3 w-[110px]">
                        {t('Home size')}:
                      </span>
                      <div className="max-w-[70px]">
                        {!isEditingSpecifics ? (
                          currentProperty.homeSize
                        ) : (
                          <Input
                            label=""
                            name="homeSize"
                            id="homeSize"
                            type="number"
                            placeholder=""
                            value={editProperty.homeSize ? editProperty.homeSize : ''}
                            onChange={(e) =>
                              setEditProperty({ ...editProperty, homeSize: +e.target.value })
                            }
                          />
                        )}
                      </div>
                      &nbsp;{t(areaUnit.homeUnit)}
                    </div>
                  )}
                  {/* lot size */}
                  {params?.specifics.lotSize && (
                    <div className="flex w-full space-x-4 items-center">
                      <span className="whitespace-nowrap font-light py-3 w-[110px]">
                        {' '}
                        {t('Lot size')}:
                      </span>
                      <div className="max-w-[70px]">
                        {!isEditingSpecifics ? (
                          currentProperty.lotSize
                        ) : (
                          <Input
                            label=""
                            name="lotSize"
                            id="lotSize"
                            type="number"
                            placeholder=""
                            value={editProperty.lotSize ? editProperty.lotSize : ''}
                            onChange={(e) =>
                              setEditProperty({ ...editProperty, lotSize: +e.target.value })
                            }
                          />
                        )}
                      </div>
                      &nbsp;{t(areaUnit.landUnit)}
                    </div>
                  )}
                </div>
                <div className="flex-col">
                  {/* built in */}
                  {params?.details.builtIn && (
                    <div className="flex w-full space-x-4 items-center">
                      <span className="whitespace-nowrap font-light py-3 w-[110px]">
                        {t('Built in')}:
                      </span>
                      <div className="max-w-[150px]">
                        {!isEditingSpecifics ? (
                          currentProperty.builtInDate
                        ) : (
                          <Select
                            id="builtInDate"
                            name="builtInDate"
                            label=""
                            placeholder={t('built in')}
                            value={editProperty.builtInDate}
                            onChange={(e) =>
                              setEditProperty({ ...editProperty, builtInDate: e.target.value })
                            }
                          >
                            {dateOptions.map(({ value, text }) => (
                              <option value={value} key={value}>
                                {text}
                              </option>
                            ))}
                          </Select>
                        )}
                      </div>
                    </div>
                  )}
                  {/* last renovation */}
                  {params?.details.lastRenovation && !editProperty.isOffPlan ? (
                    <div className="flex w-full space-x-4 items-center">
                      <span className="whitespace-nowrap font-light py-3 w-[110px]">
                        {t('Last renovation')}:
                      </span>
                      <div className="max-w-[150px]">
                        {!isEditingSpecifics ? (
                          currentProperty.lastRenovationDate
                        ) : (
                          <Select
                            id="lastRenovationDate"
                            name="lastRenovationDate"
                            label=""
                            placeholder={t('last renovation in')}
                            value={editProperty.lastRenovationDate}
                            onChange={(e) =>
                              setEditProperty({
                                ...editProperty,
                                lastRenovationDate: e.target.value,
                              })
                            }
                          >
                            {dateOptions.map(({ value, text }) => (
                              <option value={value} key={value}>
                                {text}
                              </option>
                            ))}
                          </Select>
                        )}
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  {/* floor level */}
                  {params?.details.floorLevel && (
                    <div className="flex w-full space-x-4 items-center">
                      <span className="whitespace-nowrap font-light py-3 w-[110px]">
                        {t('Floor level')}:
                      </span>
                      <div className="max-w-[70px]">
                        {!isEditingSpecifics ? (
                          currentProperty.floorLevel
                        ) : (
                          <Input
                            label=""
                            name="floorLevel"
                            id="floorLevel"
                            type="text"
                            value={editProperty.floorLevel!}
                            onChange={(e) =>
                              setEditProperty({ ...editProperty, floorLevel: +e.target.value })
                            }
                          />
                        )}
                      </div>
                    </div>
                  )}
                  {/* ceiling height */}
                  {params?.details.ceilingHeight && (
                    <div className="flex w-full space-x-4 items-center">
                      <span className="whitespace-nowrap font-light py-3 w-[110px]">
                        {t('Ceiling height')}:
                      </span>
                      <div className="max-w-[70px]">
                        {!isEditingSpecifics ? (
                          currentProperty.ceilingHeight
                        ) : (
                          <Input
                            label=""
                            name="ceilingHeight"
                            id="ceilingHeight"
                            type="number"
                            value={editProperty.ceilingHeight!}
                            onChange={(e) =>
                              setEditProperty({ ...editProperty, ceilingHeight: +e.target.value })
                            }
                          />
                        )}
                      </div>
                      &nbsp;{t('meters')}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="flex w-full space-x-4 items-center">
              <div className="w-[130px]  flex whitespace-nowrap font-light">
                {t('Furnishing status')}:
              </div>
              {!isEditingSpecifics ? (
                <> {currentProperty.furnishingStatus}</>
              ) : (
                <div>
                  <div className="flex pt-2 text-sm gap-x-4 items-center">
                    <input
                      type="radio"
                      className={`focus:outline-none focus:ring-0 h-4 w-4 border-gray-300 ${
                        currentProperty.furnishingStatus === 'fully' ? 'text-deehiy' : 'text-white'
                      }`}
                      name="furnishingStatus"
                      value="fully"
                      onChange={() =>
                        setCurrentProperty({ ...currentProperty, furnishingStatus: 'fully' })
                      }
                      checked={currentProperty.furnishingStatus === 'fully'}
                    />
                    <div className="font-normal">{t('fully furnished')}</div>
                  </div>

                  <div className="flex pt-2 text-sm gap-x-4 items-center">
                    <input
                      type="radio"
                      className={`focus:outline-none focus:ring-0 h-4 w-4 border-gray-300 ${
                        currentProperty.furnishingStatus === 'partially'
                          ? 'text-deehiy'
                          : 'text-white'
                      }`}
                      name="furnishingStatus"
                      value="partially"
                      onChange={() =>
                        setCurrentProperty({ ...currentProperty, furnishingStatus: 'partially' })
                      }
                      checked={currentProperty.furnishingStatus === 'partially'}
                    />
                    <div className="font-normal">{t('partially furnished')}</div>
                  </div>

                  <div className="flex pt-2 text-sm gap-x-4 items-center">
                    <input
                      type="radio"
                      className={`focus:outline-none focus:ring-0 h-4 w-4 border-gray-300 ${
                        currentProperty.furnishingStatus === 'unfurnished'
                          ? 'text-deehiy'
                          : 'text-white'
                      }`}
                      name="furnishingStatus"
                      value="unfurnished"
                      onChange={() =>
                        setCurrentProperty({
                          ...currentProperty,
                          furnishingStatus: 'unfurnished',
                        })
                      }
                      checked={currentProperty.furnishingStatus === 'unfurnished'}
                    />
                    <div className="font-normal">{t('unfurnished')}</div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <hr className="my-5" />

          <div className="w-full md:w-full">
            <div className="pt-2 pb-4">
              <div className="flex justify-between pb-2">
                <span className="font-light">{t('Listing status')}</span>
                <button
                  type="button"
                  className="text-deehiy font-bold"
                  onClick={handlePublishedEditClick}
                >
                  {!isEditingPublished ? t('Edit') : t('Save')}
                </button>
              </div>
              <div>
                {!isEditingPublished ? (
                  <div className="pb-12">
                    {currentProperty.published
                      ? t('listing published')
                      : t('listing not published')}
                  </div>
                ) : (
                  <div className="flex gap-4 pb-12">
                    {t('publish')}:
                    <Toggle
                      isToggled={editProperty.published}
                      setIsToggled={() =>
                        setEditProperty({ ...editProperty, published: !editProperty.published })
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
      <ToastContainer transition={Slide} />
    </div>
  );
};

export default ListingBasicContent;
