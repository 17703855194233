import http from '../httpService';
// import apiUrl from "../../config.json"
import { User, Business } from '../../types/types';
// import { resizeFile } from '../../lib/reactResize';
const apiEndpoint = '/api/users';

export const register = (user: User) => {
  console.log('USER REGISTER', user);

  return http.post(apiEndpoint, {
    // username: user.username,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    password: user.password,
    language: user.language,
  });
};

export const resetPassword = (email: string) => {
  return http.post(`${apiEndpoint}/reset_password/${email}`, {});
};

export function resetUpdatePassword(id: number, token: string, password: string) {
  return http.post(`${apiEndpoint}/receive_new_password/${id}/${token}`, {
    password: password,
  });
}

export function verifyAccount(id: number, token: string) {
  return http.post(`${apiEndpoint}/verify_account/${id}/${token}`);
}

export function sendVerificationEmail(user: User) {
  return http.post(`${apiEndpoint}/send_verification_email`, { email: user.email });
}

// Update a user with id
export const update = async (params: any) => {
  console.log('USER PARAMS', params);
  return http.patch(`${apiEndpoint}/`, { params });
};

// Search user by user id
export const getUserById = (userId: Number) => {
  return http.get(`${apiEndpoint}/${userId}`);
};

// send contact request to admin
export function sendContactRequestToAdmin(userId: number, agentId: number, propertyId: number) {
  return http.post(`${apiEndpoint}/contact_request`, {
    userId,
    agentId,
    propertyId,
  });
}

// send appoint agent request to admin
export function sendAppointAgentToAdmin(userId: number, agentId: number) {
  return http.post(`${apiEndpoint}/appoint_agent_request`, {
    userId,
    agentId,
  });
}

// business
export function sendInvitationEmail(email: string, business: Business, roleId: number) {
  return http.post(`${apiEndpoint}/send_invitation`, { email, business, roleId });
}

export function verifyInvitation(userId: number, token: string) {
  return http.post(`${apiEndpoint}/accept_invitation/${userId}/${token}`);
}

export const registerInvitedUser = (user: User) => {
  return http.post(`${apiEndpoint}/invitation_register`, {
    // username: user.username,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    password: user.password,
  });
};

export const addContact = (id: any) => {
  return http.get(`/api/chat/add-contact?addedTo=${id}`);
};

export const updateLanguage = async (id: number, language: string) => {
  return http.patch(`${apiEndpoint}/${id}/language`, { language });
};

export const attributeUserType = async (id: number, type: string) => {
  return http.post(`${apiEndpoint}/${id}/type`, { type });
};

export const updatePreferredAreas = async (id: number, preferredAreas: string) => {
  return http.post(`${apiEndpoint}/${id}/areas`, { preferredAreas });
};
