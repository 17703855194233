import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FiChevronLeft } from 'react-icons/fi';

const TopMobileNavigation = ({ btn }: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const navStyle = 'fixed sm:hidden bg-red-300 top-0 left-0 right-0 bg-[#f7f7f7] z-50';

  const goBack = () => {
    if (location.key !== 'default') {
      navigate(-1);
    } else {
      navigate('/');
    }
  };

  useEffect(() => {
    console.log(document.referrer.indexOf(window.location.host));
  }, []);

  return (
    <nav className={navStyle}>
      <div className="bg-[#f7f7f7] px-4 py-2 items-center flex justify-between w-full">
        <button onClick={goBack} className="border-none ">
          <FiChevronLeft className="text-deehiy h-9 w-9" />
        </button>
        <div>{btn}</div>
      </div>
    </nav>
  );
};

export default TopMobileNavigation;
