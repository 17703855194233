export const SET_ALL_PROPERTIES = 'SET_ALL_PROPERTIES';
export const SET_PROPERTIES = 'SET_PROPERTIES';
export const GET_PROPERTY_BY_ID = 'GET_PROPERTY_BY_ID';
export const SET_CURRENT_PROPERTY = 'SET_CURRENT_PROPERTY';
export const SET_COORDINATES = 'SET_COORDINATES';
export const SET_FILTER = 'SET_FILTER';
export const SET_MAX_LISTINGPRICE = 'SET_MAX_LISTINGPRICE';
export const SET_PAGE_INDEX = 'SET_PAGE_INDEX';
export const SET_COUNT = 'SET_COUNT';
export const SET_USER_PROPERTIES = 'SET_USER_PROPERTIES';

export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const LOGOUT = 'LOGOUT';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const GET_ERRORS = 'GET_ERRORS';

export const SET_HUNTS = 'SET_HUNTS';
export const SET_CURRENT_HUNT = 'SET_CURRENT_HUNT';

export const SET_BUSINESS = 'SET_BUSINESS';
export const SET_CURRENT_BUSINESS = 'SET_CURRENT_BUSINESS';
export const SET_BUSINESS_USERS = 'SET_BUSINESS_USERS';
export const SET_BUSINESS_PROPERTIES = 'SET_BUSINESS_PROPERTIES';
