import axios from 'axios';

// TODO: check why token is string or boolean
const setAuthToken = (token: string | boolean) => {
  if (token) {
    // axios.defaults.headers.common["Authorization"] = token
    axios.defaults.headers.common['x-auth-token'] = token;
  } else {
    // Delete auth header
    // delete axios.defaults.headers.common["Authorization"]
    delete axios.defaults.headers.common['x-auth-token'];
  }
};

export default setAuthToken;
