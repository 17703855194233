import React, { createContext, useContext, ReactNode } from 'react';
import { addContact, getUserById } from '../services/auth/userService';
import { toast } from 'react-toastify';

interface ChatContextProps {
  addToContact: (userId: number) => Promise<void>;
  chatHandler: (id: number, username: string) => Promise<void>;
}

const ChatContext = createContext<ChatContextProps | undefined>(undefined);

interface ChatProviderProps {
  children: ReactNode;
}

export const ChatProvider: React.FC<ChatProviderProps> = ({ children }) => {
  const addToContact = async (userId: number) => {
    {
      try {
        const res = await addContact(userId);
        const data = res.data;
        if (res.status === 200) {
          toast.success(data.message);
        }
      } catch (err) {
        {
          const axiosError = err as import('axios').AxiosError;
          if (axiosError.response?.data) {
            console.error('Failed to add contact:', axiosError.response.data);
            toast.info('User already exist in contacts');
          }
        }
      }
    }
  };

  const chatHandler = async (id: number, username: string) => {
    const appToken = localStorage.getItem('@App:token');
    try {
      const res = await getUserById(id);
      const groupId = res.data.groupId;
      window.open(
        `${
          process.env.REACT_APP_MESSENGER_URL
        }?token=${appToken}&id=${id}&username=${username}&groupId=${groupId}&userSpecificChat=${true}`,
        '_blank'
      );
    } catch (err) {
      throw new Error('Failed to redirect to messenger');
    }
  };

  return (
    <ChatContext.Provider value={{ addToContact, chatHandler }}>{children}</ChatContext.Provider>
  );
};

export const useChat = (): ChatContextProps => {
  const context = useContext(ChatContext);

  if (!context) {
    throw new Error('useChat must be used within a ChatProvider');
  }

  return context;
};
