import React, { useState } from 'react';
import translateParagraph from '../../utils/translate';
import { Dropdown } from 'flowbite-react';

const TranslateTextDropdown = ({ text, setTranslatedText }: any) => {
  const [selectedLanguage, setSelectedLanguage] = useState('en');

  const handleLanguageChange = async (newLanguage: string) => {
    setSelectedLanguage(newLanguage);
    const translatedText = await translateParagraph(text, newLanguage);
    setTranslatedText(translatedText);
  };

  return (
    <div className="w-full justify-end flex">
      <Dropdown
        size="sm"
        className=" border-transparent outline-none ring-color-0 focus:outline-0 border-0 focus:outline-none focus:ring-0 ring-0"
        placement="top-end"
        label={
          <div className=" text-white primary-btn ring-color-0 text-xs gap-x-2 items-center justify-center border-transparent outline-none outline-0 border-0 focus:outline-none ring-0 focus:ring-0 flex">
            <img
              src={`/images/icons/flags/${selectedLanguage}.svg`}
              className="w-4 h-4"
              alt={selectedLanguage}
            />
            {selectedLanguage.toUpperCase()}
          </div>
        }
        arrowIcon={false}
        // placement="auto"
      >
        <Dropdown.Item
          onClick={() => handleLanguageChange('en')}
          className="items-center flex gap-x-2"
        >
          <img src="/images/icons/flags/en.svg" className="w-4 h-4" alt="English" />
          English
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => handleLanguageChange('th')}
          className="items-center flex gap-x-2"
        >
          <img src="/images/icons/flags/th.svg" className="w-4 h-4" alt="Thai" />
          ไทย
        </Dropdown.Item>
        {/* <Dropdown.Item
          onClick={() => handleLanguageChange('de')}
          className="items-center flex gap-x-2"
        >
          <img src="/images/icons/flags/de.svg" className="w-4 h-4" alt="German" />
          Deutsch
        </Dropdown.Item> */}

        <Dropdown.Item
          onClick={() => handleLanguageChange('ja')}
          className="items-center flex gap-x-2"
        >
          <img src="/images/icons/flags/ja.svg" className="w-4 h-4" alt="Japanese" />
          日本語
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => handleLanguageChange('ru')}
          className="items-center flex gap-x-2"
        >
          <img src="/images/icons/flags/ru.svg" className="w-4 h-4" alt="Russian" />
          Русский
        </Dropdown.Item>
        {/* <Dropdown.Item
          onClick={() => handleLanguageChange('fr')}
          className="items-center flex gap-x-2"
        >
          <img src="/images/icons/flags/fr.svg" className="w-4 h-4" alt="French" />
          Français
        </Dropdown.Item> */}
        {/* <Dropdown.Item
          onClick={() => handleLanguageChange('es')}
          className="items-center flex gap-x-2"
        >
          <img src="/images/icons/flags/es.svg" className="w-4 h-4" alt="Spanish" />
          Español
        </Dropdown.Item> */}
      </Dropdown>
    </div>
  );
};

export default TranslateTextDropdown;
