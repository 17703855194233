// import { Post } from '../types/types';
import http from './httpService';

const apiPostEndpoint = '/api/posts';

export const getPostsService = (params: any) => {
  return http.post(`${apiPostEndpoint}/search`, { ...params });
};

export const createPostService = (post: any) => {
  console.log(post);
  return http.post(`${apiPostEndpoint}/`, { post });
};
export const createAdsPostService = (post: any) => {
  console.log(post);
  return http.post(`${apiPostEndpoint}/ads`, { post });
};

export const updatePostService = (postId: number, post: any) => {
  console.log('update post...', post);
  return http.put(`${apiPostEndpoint}/${postId}`, { post });
};

export const toggleLikePostService = (postId: any) => {
  return http.post(`${apiPostEndpoint}/${postId}/like`);
};

export const deletePostService = (postId: any) => {
  return http.delete(`${apiPostEndpoint}/${postId}`);
};

export const getPostByIdService = (postId: any) => {
  return http.get(`${apiPostEndpoint}/${postId}`);
};

export const getPostByUserIdService = (userId: any) => {
  return http.get(`${apiPostEndpoint}/author/${userId}`);
};
