import React from 'react';
import { FaUserAstronaut } from 'react-icons/fa';
import TopMobileNavigation from '../../components/main/navigation/TopMobileNavigation';
import TakeMeBack from '../../components/main/navigation/TakeMeBack';

const ComingSoonPage = () => {
  return (
    <>
      <div className="pt-6 w-full justify-start">
        <TakeMeBack />
      </div>
      <TopMobileNavigation />
      <div className="flex px-4 py-8 sm:py-0 sm:p-20 container justify-center items-center mx-auto">
        <div className="text-3xl sm:text-5xl font-light text-gray-500">
          This feature is coming soon
        </div>
        <FaUserAstronaut className="w-36 h-36 text-deehiy" />
      </div>
    </>
  );
};

export default ComingSoonPage;
