import React from 'react';
import ImageUploading, { ImageListType } from 'react-images-uploading';

import { FaRegImages } from 'react-icons/fa';
import { RiDeleteBin6Line } from 'react-icons/ri';

export function ImageUploader({ images, setImages }: any) {
  const maxNumber = 1;

  const onChange = (imageList: ImageListType, addUpdateIndex: number[] | undefined) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setImages(imageList as never[]);
  };

  return (
    <ImageUploading multiple value={images} onChange={onChange} maxNumber={maxNumber}>
      {({ imageList, onImageUpload, onImageUpdate, onImageRemove, isDragging, dragProps }) => (
        // write your building UI
        <div>
          {images.length === 0 && (
            <button
              className={` ${
                isDragging ? 'border-deehiy bg-white' : 'bg-gray-100 border-gray-300'
              } px-6 py-6 w-full rounded-2xl border  text-gray-500 text-sm`}
              onClick={onImageUpload}
              {...dragProps}
            >
              drag & drop your files or <b>browse</b>
            </button>
          )}
          &nbsp;
          {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
          {imageList.map((image, index) => (
            <div key={index} className=" justify-center flex relative pb-3">
              <img src={image.dataURL} alt="" className="shadow-md max-h-16" />
              <div className="absolute flex justify-end top-1 w-full">
                <button
                  title="change image"
                  className=" bg-white flex rounded shadow-md text-gray-500 hover:text-deehiy p-1 mx-1"
                  onClick={() => onImageUpdate(index)}
                >
                  <FaRegImages className="w-5 h-5" />
                </button>
                <button
                  title="delete image"
                  className=" bg-white rounded flex shadow-md text-gray-500 hover:text-deehiy-red p-1 mx-1"
                  onClick={() => onImageRemove(index)}
                >
                  <RiDeleteBin6Line className="w-5 h-5" />
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </ImageUploading>
  );
}
