import { useState, useEffect, useRef } from 'react';
import { Card } from './Card';
import { FaPlus } from 'react-icons/fa';
import Loader from '../../../main/Loader';
import { useProperty } from '../../../../contexts/property';
import uuid from 'react-uuid';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { FileStateObject } from '../../../form/DropzoneInput';
import { ImagePropertyUpload } from '../../../../utils/uploader';
import { PENDING } from '../../../../constants/constant';
import { useTranslation } from 'react-i18next';

export type FileStateImage = {
  id: string;
  file?: File;
  preview: string;
  uploaded: boolean;
  progress: number;
  publicId?: string;
  url?: string;
};

const ConfirmMessage = ({ question, onConfirm, onCancel, toastId }: any) => {
  const { t } = useTranslation();

  const handleConfirm = () => {
    onConfirm();
    toast.dismiss(toastId);
  };

  const handleCancel = () => {
    onCancel();
    toast.dismiss(toastId);
  };

  return (
    <div className="flex my-8  flex-col ">
      <div>{question}</div>
      <div className="flex w-full justify-between pt-20">
        <button className=" text-white font-bold bg-deehiy p-3 rounded" onClick={handleConfirm}>
          {t('I want to add more photos')}
        </button>
        <button className="border border-gray-400 font-bold p-3 rounded" onClick={handleCancel}>
          {t('I dont want to add more photos')}
        </button>
      </div>
    </div>
  );
};

const ImageGrid = ({ modifyProperty }: any) => {
  const firstUpdate = useRef(true);
  const { t } = useTranslation();
  const { currentProperty, setCurrentProperty } = useProperty();

  const [isLoading, setIsLoading] = useState(false);

  const [cards, setCards] = useState<FileStateImage[]>([]);

  const handleMove = (id: number, index: number) => {
    if (cards) {
      setCards((pvCards: any) => {
        const cloned = pvCards && [...pvCards];
        if (cloned) {
          const oldIndex = pvCards && pvCards.findIndex((item: any) => item.id === id);
          const item = cloned.splice(oldIndex, 1)[0];
          cloned.splice(index, 0, item);

          return cloned;
        }
      });
    }
  };

  const handleDelete = (id: number) => {
    if (cards.length <= 5) {
      toast.error(t('A listing can not have less than 5 files.'), { autoClose: 1000 });
      return;
    }

    if (cards) {
      setCards((pvCards: any) => {
        const cloned = pvCards && [...pvCards];
        if (cloned) {
          const index = cloned.findIndex((item: any) => item.id === id);
          if (index !== -1) {
            cloned.splice(index, 1);
          }
          return cloned;
        }
      });
    }
  };

  const uploadImages = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (cards && files) {
      if (files.length + cards.length > 20) {
        toast.error(t('You can only upload a maximum of 20 files.'));
        return;
      }

      const newImages = Array.from(files);
      const acceptedFilesPreview = newImages.map((file: File) => ({
        file,
        id: uuid(),
        preview: URL.createObjectURL(file),
        uploaded: false,
        progress: 0,
      }));

      setCards(cards ? [...cards, ...acceptedFilesPreview] : acceptedFilesPreview);
      // const uploadedArr: FileStateImage[] = [];
      if (acceptedFilesPreview) {
        const uploadPromises = acceptedFilesPreview.map(async (file) => {
          const uploadedFile = await ImagePropertyUpload(file.file, (progress: number) => {
            setCards((prevFiles: FileStateImage[]) =>
              prevFiles.map((prevFile) => {
                if (prevFile.id === file.id) {
                  return { ...prevFile, progress };
                }
                return prevFile;
              })
            );
          });

          if (!uploadedFile) {
            return;
          }

          if (uploadedFile) {
            // uploadedArr.push({
            //   ...file,
            //   id: file.id,
            //   uploaded: true,
            //   progress: 100,
            //   publicId: uploadedFile.publicId,
            //   url: uploadedFile.secureUrl,
            // });

            setCards((prevFiles: FileStateImage[]) =>
              prevFiles.map((prevFile) => {
                if (prevFile.id === file.id) {
                  return {
                    ...prevFile,
                    preview: uploadedFile.secureUrl,
                    uploaded: true,
                    progress: 100,
                    publicId: uploadedFile.publicId,
                    url: uploadedFile.secureUrl,
                  };
                }
                return prevFile;
              })
            );
          }
        });

        try {
          await Promise.all(uploadPromises);
          // toast.success('Upload completed successfully!');
        } catch (error) {
          console.error(error);
          toast.error('Error uploading files');
        }
      }
    }
  };

  const addImages = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (currentProperty.published) {
      toast.info(
        <ConfirmMessage
          toastId={new Date()}
          question={t('unpublish_listing_message')}
          onConfirm={() => {
            setIsLoading(true);
            setCurrentProperty({
              ...currentProperty,
              status: PENDING,
              published: false,
            });
            uploadImages(event).then(() => {
              // ! property is updated in the useeffect cards
              setIsLoading(false);
            });
          }}
          onCancel={() => {
            return;
          }}
        />,
        {
          position: 'top-center',
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        }
      );
    } else {
      setIsLoading(true);
      uploadImages(event).then(() => {
        setIsLoading(false);
      });
    }
  };

  useEffect(() => {
    // current images array => string
    const currentImageUrlStr = JSON.stringify(currentProperty.images);
    // cards array => string
    const cardsUrlStr = JSON.stringify(
      cards?.map((img: any) => {
        return { url: img.preview };
      })
    );

    // compare if both strings are the same
    if (currentImageUrlStr !== cardsUrlStr) {
      const uploadedImages = cards.filter((item) => item.uploaded).map((item) => item.url);

      setCurrentProperty({
        ...currentProperty,
        images: uploadedImages,
      });
    }
  }, [cards]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    modifyProperty(currentProperty);
  }, [currentProperty.images, currentProperty.status]);

  useEffect(() => {
    // init
    const images = currentProperty?.images?.length ? currentProperty.images : null;
    if (images) {
      const initialArray = images.map((item) => {
        // TODO: uploadedImages are string, but images coming from API are object
        const url = item.url ? item.url : item;
        return {
          id: uuid(),
          // preview: item.url,
          preview: url,
          uploaded: true,
          progress: 100,
          // url: item.url,
          url: url,
        };
      });

      if (initialArray) {
        setCards(initialArray as FileStateImage[]);
      }
    }
  }, []);

  return (
    <div>
      <div className="w-full flex justify-between">
        <div className="flex flex-col pt-3 pb-10">
          <span className="font-normal">{t('Photos')}</span>
          <span className="hidden sm:flex text-sm font-light">
            {t('Arrange, add or delete photos as you deem fit. Simply drag them around.')}
          </span>
        </div>
        <div className="items-center flex">
          <label
            htmlFor="file-input"
            className="primary-btn cursor-pointer whitespace-nowrap flex w-auto gap-x-1"
          >
            <FaPlus className="w-4 h-4" /> {t('add photos')}
            <input id="file-input" type="file" multiple onChange={addImages} className="hidden" />
          </label>
        </div>
      </div>
      {isLoading && <Loader />}
      <div className="gap-6 grid grid-cols-2 sm:grid-cols-5 w-full ">
        {cards &&
          cards.map((card: any, index: number) => (
            <Card
              {...card}
              key={card.id}
              index={index}
              onMove={handleMove}
              onDelete={handleDelete}
            />
          ))}
      </div>
      <ToastContainer className="flex flex-col bg-transparent p-8 min-w-[700px]" />
    </div>
  );
};

export default ImageGrid;
