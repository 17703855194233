import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';

import { useInView } from 'react-intersection-observer';

interface IProp {
  children: React.ReactNode;
}

const boxVariant = {
  visible: { opacity: 1, scale: 1, y: 0, transition: { duration: 1 } },
  hidden: { opacity: 0, scale: 1, y: 200 },
};

const Box = ({ children }: IProp) => {
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start('visible');
    } else {
      control.start('hidden');
    }
  }, [control, inView]);

  return (
    <div>
      <motion.div ref={ref} variants={boxVariant} initial="hidden" animate={control}>
        {children}
      </motion.div>
    </div>
  );
};

export default Box;
