import React, { useState, useEffect, createContext, useContext, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const initialGlobalContext = {
  keyword: '',
  setKeyword: () => '',
  modalType: '',
  setModalType: () => '',
  mainColor: '',
  setMainColor: () => '',
  showModal: false,
  setShowModal: () => false,
  modalRootRef: null,
  isWL: false,
  setIsWL: () => false,
};

type GlobalContextType = {
  keyword: string;
  setKeyword: React.Dispatch<React.SetStateAction<string>>;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  modalType: string;
  setModalType: React.Dispatch<React.SetStateAction<string>>;
  mainColor: string | null;
  setMainColor: React.Dispatch<React.SetStateAction<string | null>>;
  modalRootRef: any;
  isWL: boolean;
  setIsWL: React.Dispatch<React.SetStateAction<boolean>>;
};

export const GlobalContext = createContext<GlobalContextType>(initialGlobalContext);

export const GlobalProvider = ({ children }: any) => {
  const [keyword, setKeyword] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [isWL, setIsWL] = useState(false);
  const [mainColor, setMainColor] = useState<string | null>(null);
  const [modalType, setModalType] = useState<any>('general');
  const modalRootRef = useRef<HTMLDivElement | null>(null);
  const location = useLocation();

  // const resultPages = [
  //   '/all',
  //   '/results/market',
  //   '/results/square',
  //   '/results/community',
  //   '/search',
  //   '/properties',
  // ];

  // const resultPages = ['/', '/square', '/community'];

  const value = {
    keyword,
    setKeyword,
    modalType,
    setModalType,
    showModal,
    setShowModal,
    modalRootRef,
    isWL,
    setIsWL,
    mainColor,
    setMainColor,
  };

  useEffect(() => {
    const pathname = location.pathname;

    // Check if the current path is a result page and reset the search keyword
    // if (!resultPages.includes(location.pathname)) {
    if (pathname === '/' || pathname === '/square' || pathname === '/community') {
      console.log('!= loation.pathname, set keyword to empty string');
      setKeyword('');
    }
  }, [location, setKeyword]);

  return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>;
};

export function useGlobal() {
  const context = useContext(GlobalContext);

  return context;
}
