import React from 'react';
import GoogleMapReact from 'google-map-react';

// eslint-disable-next-line
const Marker = ({ lat, lng }: any) => {
  return (
    <img
      alt="pin"
      src="/images/icons/map-pin.svg"
      className="w-16 h-16 translate-y-[-100%] translate-x-[-50%] translate-z-0"
    />
  );
};

export default function Map({ position, isToggled }: any) {
  const defaultProps = {
    center: {
      lat: 13.7563,
      lng: 100.5018,
    },
    zoom: 14,
  };

  return (
    // Important! Always set the container height explicitly
    <div
      className="rounded-full"
      style={{
        height: '30vh',
        width: '100%',
        borderRadius: '24px',
        overflow: 'hidden',
      }}
    >
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLEMAP_API_KEY }}
        defaultCenter={{ lat: 0, lng: 0 }}
        center={{ lat: position.lat, lng: position.lng }}
        defaultZoom={defaultProps.zoom}
      >
        <Marker lat={position.lat} lng={position.lng} isToggled={isToggled} />
      </GoogleMapReact>
    </div>
  );
}
