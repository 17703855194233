import React from 'react';
import {
  // FacebookShareButton,
  // FacebookIcon,
  // EmailShareButton,
  LineShareButton,
  // LinkedinShareButton,
  // TwitterShareButton,
  WhatsappShareButton,
  // EmailIcon,
  LineIcon,
  // LinkedinIcon,
  // TwitterIcon,
  WhatsappIcon,
} from 'react-share';
import { BiCopy } from 'react-icons/bi';
import { CATEGORY_TYPES } from '../../constants/constant';
// import { ToastContainer, toast } from 'react-toastify';

export type CategoryType =
  | typeof CATEGORY_TYPES.PROPERTY
  | typeof CATEGORY_TYPES.MEMBER
  | typeof CATEGORY_TYPES.BUSINESS
  | typeof CATEGORY_TYPES.ARTICLE
  | typeof CATEGORY_TYPES.WL_LISTING
  | typeof CATEGORY_TYPES.WL;

interface ShareProps {
  id: number;
  type: CategoryType;
}

const Share = ({ id, type }: ShareProps) => {
  let baseUrl = `${process.env.REACT_APP_BASE_URL_PROD}/api/dl/${type}/${id}`;
  const copyCurrentUrl = () => {
    navigator.clipboard.writeText(baseUrl);
    // toast.success('Link copied to clipboard!', {
    //   position: 'top-right',
    //   autoClose: 1000,
    //   hideProgressBar: true,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    // });
  };

  const CopyLinkButton = () => {
    return (
      <div onClick={copyCurrentUrl}>
        {/* <ToastContainer /> */}
        <BiCopy className="w-8 h-8 text-gray-400 hover:text-gray-500 duration-200 cursor-pointer" />
      </div>
    );
  };

  return (
    <div className="flex rounded-full space-x-4">
      {/* <FacebookShareButton url={baseUrl} quote={'deehiy'} hashtag="#deehiy">
        <FacebookIcon size={32} round />
      </FacebookShareButton> */}
      <LineShareButton url={baseUrl}>
        <LineIcon size={32} round />
      </LineShareButton>
      {/* <LinkedinShareButton url={baseUrl}>
        <LinkedinIcon size={32} round />
      </LinkedinShareButton> */}
      <WhatsappShareButton url={baseUrl}>
        <WhatsappIcon size={32} round />
      </WhatsappShareButton>
      {/* <TwitterShareButton url={baseUrl}>
        <TwitterIcon size={32} round />
      </TwitterShareButton> */}
      {/* <EmailShareButton url={`${process.env.REACT_APP_BASE_URL_PROD}/${type}/${id}`}>
        <EmailIcon size={32} round />
      </EmailShareButton> */}
      <CopyLinkButton />
    </div>
  );
};

export default Share;
