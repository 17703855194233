import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/auth';
import Toggle from '../form/Toggle';
import TakeMeBack from '../main/navigation/TakeMeBack';

const Notifications = () => {
  const { currentUser } = useAuth();

  const [insightsAndTips, setInsightsAndTips] = useState(true);
  const [newsAndUpdates, setNewsAndUpdates] = useState(true);
  const [lawRegulations, setLawRegulations] = useState(true);
  const [listingsInArea, setListingsInArea] = useState(true);

  useEffect(() => {}, [currentUser]);

  return (
    <div className="container mx-auto px-6 sm:px-20">
      <div className="pt-6 w-full justify-start">
        <TakeMeBack />
      </div>
      <div className=" font-bold text-2xl sm:text-5xl pt-6 pb-3 ">Notifications</div>
      <div className="text-sm sm:text-xl">Choose what notifications you wish to receive.</div>

      <div className="flex flex-col sm:flex-row  w-full justify-evenly text-sm pt-24">
        <div className="flex flex-col space-y-5 w-full sm:w-2/4">
          <div className="flex justify-between">
            <span>Insights & tips</span>
            <Toggle isToggle={insightsAndTips} setIsToggle={setInsightsAndTips} />
          </div>
          <div className="flex justify-between">
            <span>News & updates</span>
            <Toggle isToggle={newsAndUpdates} setIsToggle={setNewsAndUpdates} />
          </div>
          <div className="flex justify-between">
            <span>Local laws & regulations</span>
            <Toggle isToggle={lawRegulations} setIsToggle={setLawRegulations} />
          </div>
          <div className="flex justify-between">
            <span>New listings in my area</span>
            <Toggle isToggle={listingsInArea} setIsToggle={setListingsInArea} />
          </div>
        </div>

        <div className="flex sm:w-1/4 ">
          {/* <div className="flex mx-auto justify-start shadow-md flex-col border border-deehiy rounded-2xl bg-white p-8">
            <span className="pb-8">Verify your phone number to never miss any opportunities.</span>
            <label htmlFor="tel">Enter phone number</label>
            <input type="tel" id="tel" className="border border-black py-2 px-6 rounded-2xl" />
            <button className="mt-8 mx-auto bg-deehiy rounded-2xl py-2 px-6 font-bold text-white hover:opacity-90">
              verify now
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Notifications;
