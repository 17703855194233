import React from 'react';
import { FaRegMap } from 'react-icons/fa';
import { BsGrid3X2Gap } from 'react-icons/bs';
import { useScroll } from '../../../contexts/scroll';
import { useTranslation } from 'react-i18next';

import { useProperty } from '../../../contexts/property';

const OpenMapButton = ({ showMap, setShowMap }: any) => {
  const { setProperties } = useProperty();
  const { visible } = useScroll();
  const { t } = useTranslation();

  const handleClick = () => {
    setProperties([]); // reset

    setShowMap(!showMap);
  };

  return (
    <div
      className={`fixed z-0  inset-x-0 ${
        visible ? 'bottom-[105px]' : 'bottom-[80px]'
      } sm:bottom-[120px] place-content-center w-full flex h-0`}
    >
      <button
        // onClick={() => setShowMap(!showMap)}
        onClick={handleClick}
        className=" flex font-bold items-center text-sm py-4 sm:py-6 px-4 sm:px-8 shadow-xl opacity-60 hover:opacity-100 bg-deehiy duration-200 rounded-full text-white"
      >
        {showMap ? (
          <span className="flex gap-x-2">
            {t('Open list')} <BsGrid3X2Gap className="w-5 sm:w-6 h-5 sm:h-6" />
          </span>
        ) : (
          <span className="flex gap-x-2">
            {t('Map')} <FaRegMap className="w-5 sm:w-6 h-5 sm:h-6" />
          </span>
        )}
      </button>
    </div>
  );
};

export default OpenMapButton;
