import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { HiEye, HiEyeOff } from 'react-icons/hi';
import clsx from 'clsx';
import { InputProps } from './Input';

export default function PasswordInput({
  label,
  placeholder,
  helperText,
  id,
  name = '',
  readOnly = false,
  validation,
  value,
  // isEyeIconVisible = true,
  ...rest
}: InputProps) {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const [showPassword, setShowPassword] = useState(false);
  const togglePassword = () => setShowPassword((prev) => !prev);
  return (
    <div>
      {/* <label htmlFor={id} className="block text-sm sm:text-md lg:text-md font-normal">
        {label}
      </label> */}
      <div className="relative mt-1">
        <input
          {...register(name, validation)}
          {...rest}
          type={showPassword ? 'text' : 'password'}
          name={name}
          id={id}
          readOnly={readOnly}
          className={clsx(
            'block w-full shadow-sm',
            readOnly
              ? ' login-input-readOnly'
              : ' login-input text-[12px] sm:text-[14px] md:text-[16px]',
            {
              'login-input-error': errors[name], // Apply focus ring only if there are errors
              'border-b-red-500': errors[name], // Apply red bottom border only if there are errors
            }
          )}
          placeholder={placeholder}
          aria-describedby={name}
        />

        {/* {!value && showSubPlaceHolder && (
          <div
            className="
            absolute top-[14px] left-[74px] sm:top-[11px] sm:left-[85px] md:top-3 md:bottom-3 md:left-24 bg-transparent text-[8px] sm:text-[11px] xl:text-[12px] inline-block
            "
            onClick={() => inputRef.current?.focus()}
          >
            {subPlaceHolderText}
          </div>
        )} */}

        <button
          onClick={(e) => {
            e.preventDefault();
            togglePassword();
          }}
          type="button"
          className="absolute inset-y-0 right-0 flex items-center p-1 mr-3 rounded-lg focus:outline-none focus:ring focus:ring-deehiy"
        >
          {showPassword ? (
            <HiEyeOff className="text-xl text-gray-500 cursor-pointer hover:text-gray-600" />
          ) : (
            <HiEye className="text-xl text-gray-500 cursor-pointer hover:text-gray-600" />
          )}
        </button>
      </div>
      <div className="mt-1">
        {helperText && <p className="text-xs text-gray-500">{helperText}</p>}
        {errors[name] && (
          <span className="text-[12px] sm:text-sm text-red-500">
            {/* {errors[name].message} */}
            {errors[name]?.message?.toString()}
          </span>
        )}
      </div>
    </div>
  );
}
