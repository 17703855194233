export function getBrowserLanguage() {
  console.log(navigator);
  const language = localStorage.getItem('language');

  // Set the language in localStorage if not already set
  if (!language) {
    console.log('navigator.language', navigator.language);

    if (navigator.language !== 'th') {
      return 'en';
    }
    return 'th';
  }
  return language;
}
