import React, { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/auth';
import { useBusiness } from '../contexts/business';

import Loader from '../components/main/Loader';

interface IProps {
  children: ReactNode;
}

const BusinessProtected: React.FC<IProps> = ({ children }) => {
  const { currentUser, isLoading } = useAuth();
  const { currentBusiness } = useBusiness();
  const location = useLocation();

  if (isLoading) return <Loader />;

  if (!currentUser || !currentBusiness) {
    return <Navigate to="/login" replace state={{ redirectTo: location }} />;
  }

  return <>{children}</>;
};
export default BusinessProtected;
