import http from './httpService';

const apiCommunityEndpoint = '/api/community';

export const blockUserService = (memberId: any) => {
  return http.post(`${apiCommunityEndpoint}/block-user`, { blockTo: memberId });
};
export const reportUserService = (params: any) => {
  return http.post(`${apiCommunityEndpoint}/report-user`, params);
};

// export const addContactService = (params: any) => {
//   return http.post(`${apiCommunityEndpoint}/add-contact`, params);
// };

export const getContactListService = (params: any, page: number) => {
  return http.get(`${apiCommunityEndpoint}/get-contact-list`, { ...params, page });
};

export const filterAllService = (filter: any, page: number, currentUserId?: number) => {
  console.log('filter all community');
  return http.post(`${apiCommunityEndpoint}/search-all`, { filter, page, currentUserId });
};

export const filterContactListService = (filter: any, page: number) => {
  return http.post(`${apiCommunityEndpoint}/search-contact`, { filter, page });
};

export const filterBlockedUsersService = (filter: any, page: number) => {
  return http.post(`${apiCommunityEndpoint}/search-blocked`, { filter, page });
};
