import React from 'react';
import FilterDropdown from './FilterDropdown';
// import FilterList from './FilterList';
import FilterCheckbox from './FilterCheckbox';
import { skillsList, interestsList } from '../../../data/data';
// import { BusinessCategory } from '../../../types/types';
// import { AGENCY, CONSULTANCY, DEVELOPER } from '../../../constants/constant';
import FilterRadio from './FilterRadio';
// import { debounce } from '../../../utils/debounceThrottle';
import FilterContainer from './FilterContainer';
// const INVESTOR = 'investor';
// const DEDICATED_AGENT = 'DEDICATED_AGENT';
// type AgentCategory = typeof INVESTOR | typeof DEDICATED_AGENT;
import FilterSearchBtn from './FilterSearchBtn';
import InputLoc from './InputLoc';
import { useTranslation } from 'react-i18next';

const CommunityFilter = React.memo(
  ({
    communityCategory,
    setCommunityCategory,
    tempFilter,
    setTempFilter,
    filterState,
    setIsFilterOpen,
    handleSearch,
    resetFilter,
    toggleDropdown,
    count,
  }: any) => {
    const { t } = useTranslation();

    //searchParams
    // const [selectedBusinessTypes, setSelectedBusinessTypes] = useState<BusinessCategory[]>([]);

    // const [showWantToSee, setShowWantToSee] = useState(false);
    // const [showLocation, setShowLocation] = useState(false);
    // const [showSkills, setShowSkills] = useState(false);
    // const [showInterests, setShowInterests] = useState(false);

    // const handleTypeCheckboxChange = (type: BusinessCategory) => {
    //   if (selectedBusinessTypes.includes(type)) {
    //     setSelectedBusinessTypes(
    //       selectedBusinessTypes.filter((selectedTypes) => selectedTypes !== type)
    //     );
    //   } else {
    //     setSelectedBusinessTypes([...selectedBusinessTypes, type]);
    //   }
    // };

    const handleSkillCheckboxChange = (skill: string) => {
      const lowerCaseSkill = skill.toLowerCase();
      let selectedSkills = tempFilter.skills;

      if (selectedSkills.includes(lowerCaseSkill)) {
        selectedSkills = selectedSkills.filter((skills: any) => skills !== lowerCaseSkill);
      } else {
        selectedSkills = [...selectedSkills, lowerCaseSkill];
      }

      setTempFilter({ ...tempFilter, skills: selectedSkills });
    };

    const handleInterestsCheckboxChange = (interest: string) => {
      let selectedInterests = tempFilter.interests;

      if (selectedInterests.includes(interest)) {
        selectedInterests = selectedInterests.filter(
          (selectedInterest: any) => selectedInterest !== interest
        );
      } else {
        selectedInterests = [...selectedInterests, interest];
      }

      setTempFilter({ ...tempFilter, interests: selectedInterests });
    };

    return (
      <FilterContainer setIsFilterOpen={setIsFilterOpen} title="Post filters">
        {/* <FilterCheckbox label="From your network" isChecked={fromNetwork} check={setFromNetwork} /> */}
        <div className="sm:relative h-[calc(100vh-7rem)] ">
          <div className="scroller ">
            <InputLoc
              loc={tempFilter.location}
              onChange={(e: any) => {
                setTempFilter({ ...tempFilter, location: e.target.value });
              }}
            />

            <br />
            <FilterRadio
              communityCategory={communityCategory}
              setCommunityCategory={setCommunityCategory}
            />

            <FilterDropdown
              label={t('Select who you want to see')}
              isOpen={filterState.isWantToSeeOpen}
              sectionHeight={80}
              setIsOpen={() => toggleDropdown('isWantToSeeOpen')}
            >
              <>
                {/* <div className="text-gray-400">Agents</div> */}
                <FilterCheckbox
                  title={t('Agents with dedicated agent profiles')}
                  label={t('Individual agents')}
                  isChecked={tempFilter.isDedicatedProfile}
                  check={() =>
                    setTempFilter((prevState: any) => ({
                      ...prevState,
                      isDedicatedProfile: !prevState.isDedicatedProfile,
                    }))
                  }
                />
                <FilterCheckbox
                  title={t('individual profiles dedicated as investors')}
                  label={t('Investors')}
                  isChecked={tempFilter.isInvestor}
                  check={() =>
                    setTempFilter((prevState: any) => ({
                      ...prevState,
                      isInvestor: !prevState.isInvestor,
                    }))
                  }
                />
                {/* <div className="text-gray-400">Businesses</div>
            <FilterCheckbox
              title="real estate agency"
              label="Agencies"
              isChecked={selectedBusinessTypes.includes(AGENCY)}
              check={() => handleTypeCheckboxChange(AGENCY)}
            />
            <FilterCheckbox
              title="real estate developer"
              label="Developers"
              isChecked={selectedBusinessTypes.includes(DEVELOPER)}
              check={() => handleTypeCheckboxChange(DEVELOPER)}
            />
            <FilterCheckbox
              title="real estate consultant"
              label="Consultants"
              isChecked={selectedBusinessTypes.includes(CONSULTANCY)}
              check={() => handleTypeCheckboxChange(CONSULTANCY)}
            /> */}
              </>
            </FilterDropdown>

            <FilterDropdown
              sectionHeight={250}
              label={t('Skills')}
              isOpen={filterState.isSkillsOpen}
              setIsOpen={() => toggleDropdown('isSkillsOpen')}
            >
              {skillsList.map((skill) => (
                <FilterCheckbox
                  key={skill.title}
                  title={t(skill.title)}
                  label={t(skill.title)}
                  Icon={skill.SVG}
                  isChecked={tempFilter.skills.includes(skill.title.toLowerCase())}
                  check={() => handleSkillCheckboxChange(skill.title)}
                />
              ))}
              {/* <FilterList list={interestsList} setSearchParams={setSearchParams} /> */}
            </FilterDropdown>
            <FilterDropdown
              sectionHeight={250}
              label={t('Interests')}
              isOpen={filterState.isInterestsOpen}
              setIsOpen={() => toggleDropdown('isInterestsOpen')}
            >
              {interestsList.map((int) => (
                <FilterCheckbox
                  key={int.title}
                  title={t(int.title)}
                  label={t(int.title)}
                  Icon={int.SVG}
                  isChecked={tempFilter.interests.includes(int.title)}
                  check={() => handleInterestsCheckboxChange(int.title)}
                />
              ))}
              {/* <FilterList list={interestsList} setSearchParams={setSearchParams} /> */}
            </FilterDropdown>
          </div>
          <div className=" w-full absolute bottom-0 sm:bottom-8 flex flex-grow border  bg-white border-transparent border-t-gray-100 pt-2 pb-8">
            <FilterSearchBtn
              resetFilter={resetFilter}
              handleSearch={handleSearch}
              count={count}
              setIsFilterOpen={setIsFilterOpen}
            />
          </div>
        </div>
      </FilterContainer>
    );
  }
);

export default CommunityFilter;
