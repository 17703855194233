import { useState, cloneElement, useEffect } from 'react';
import { AiOutlineClose } from 'react-icons/ai';

const Modal = ({ children, Btn, absoluteCloseBtn }: any) => {
  const [showModal, setShowModal] = useState(false);

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (!Btn) {
      setShowModal(true); // Automatically open the modal when there is no Btn component
    }
  }, [Btn]);

  useEffect(() => {
    const handleEscapeKey = (event: any) => {
      if (event.key === 'Escape') {
        closeModal();
      }
    };

    window.addEventListener('keydown', handleEscapeKey);

    return () => {
      window.removeEventListener('keydown', handleEscapeKey);
    };
  }, []);

  return (
    <>
      {Btn && <Btn setShowModal={setShowModal} showModal={showModal}></Btn>}
      {showModal ? (
        <>
          <div className="fixed inset-0 z-50 overflow-y-auto">
            <div
              className="fixed inset-0 w-full h-full bg-gray-900 opacity-60"
              onClick={() => setShowModal(false)}
            ></div>
            <div className="flex items-center min-h-screen container max-w-[1400px] mx-auto">
              <div className={'relative mx-auto bg-white  pb-3 rounded-2xl shadow-lg'}>
                <div
                  className={`flex justify-start pt-6 rounded-full ${
                    absoluteCloseBtn && 'absolute'
                  }`}
                >
                  <button
                    className="text-deehiy duration-200 pl-6 text-xl"
                    onClick={() => setShowModal(false)}
                  >
                    <AiOutlineClose />
                  </button>
                </div>

                {/* {children} */}
                <div className={`max-h-[90vh] overflow-auto ${absoluteCloseBtn ? 'px-0' : 'px-2'}`}>
                  {cloneElement(children, { onClose: closeModal })}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default Modal;
