import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BsChevronLeft } from 'react-icons/bs';

import 'react-toastify/dist/ReactToastify.css';

const HuntTopBtn = () => {
  const navigate = useNavigate();

  const handleExit = async () => {
    navigate(-1);
  };

  return (
    <div className="flex pt-4 sm:pt-10 justify-end text-sm sm:text-sm md:text-base lg:text-md px-6">
      <div className="flex justify-between  w-full sm:w-5/6 ">
        <button
          onClick={handleExit}
          className="order-1 sm:order-2 items-center underline underline-offset-4 sm:no-underline whitespace-nowrap flex hover:text-deehiy duration-200 text-deehiy sm:text-[#1d1c4b]"
        >
          <BsChevronLeft className="flex sm:hidden text-deehiy w-5 h-5" />
          exit
        </button>
        <div className="order-2 sm:order-1">
          <button className="hidden primary-btn">
            <Link to="/appointsalesrep">Appoint agent</Link>
          </button>
        </div>
      </div>
    </div>
  );
};

export default HuntTopBtn;
