import React from 'react';
import { Navigation } from '../learningCenter/Navigation';
import { Outlet } from 'react-router-dom';
// import BackToTopButton from '../main/BackToTopButton';
import Footer from './Footer';

const LearningCenterLayout = ({ children }: any) => {
  return (
    <div className=" dark:bg-slate-900 flex flex-col min-h-screen ">
      <header>
        <Navigation />
      </header>

      <main className="mx-auto pt-20 h-full w-full">
        <Outlet />
        {children}
      </main>

      {/* <BackToTopButton /> */}
      <Footer />
    </div>
  );
};

export default LearningCenterLayout;
