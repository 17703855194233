import React, { useState } from 'react';
import { useAuth } from '../../contexts/auth';

import { FormProvider, useForm } from 'react-hook-form';
// import PasswordInput from '../form/PasswordInput';
import Loader from '../main/Loader';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { updatePasswordSchema } from '../../lib/yup';
import PasswordInput from '../form/PasswordInput';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TakeMeBack from '../main/navigation/TakeMeBack';
import { useTranslation } from 'react-i18next';

const LoginAndSecurity: React.FC<any> = () => {
  const methods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(updatePasswordSchema),
    // defaultValues: stepOne || {},
  });

  const token = localStorage.getItem('@App:token');

  const { currentUser, resetUpdatePasswordUser } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isEditingPassword, setIsEditingPassword] = useState(false);
  // const [isEditingGoogle, setIsEditingGoogle] = useState(false);
  // const [isEditingLinkedIn, setIsEditingLinkedIn] = useState(false);
  // const [facebook, setFacebook] = useState('');
  // const [google, setGoogle] = useState('');
  // const [linkedIn, setLinkedIn] = useState('');

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
    }
  };
  // const handleSubmit = (event: any) => {
  //   event.preventDefault();
  //   if (event.key === 'Enter') {
  //     console.log('Submit');
  //   }
  // };

  const handleSubmit = async () => {
    setIsLoading(true);
    if (token) {
      await new Promise((resolve, reject) => {
        if (password !== confirmPassword) {
          setIsLoading(false);
          reject('Passwords do not match');
          toast.error('Passwords do not match');
          return;
        }
        resolve(undefined);
      });

      await resetUpdatePasswordUser(Number(currentUser?.id), token, password).then((res: any) => {
        setIsLoading(false);
        if (res === 'success') {
          toast.success('Password updated successfully.');
        } else {
          toast.error(res);
        }
      });
    } else {
      toast.error('wrong token');
    }
  };

  const handlePasswordEditClick = () => {
    setIsEditingPassword((p) => !p);
  };

  return (
    <div className="mx-auto px-4 sm:px-20">
      <div className="pt-6 w-full justify-start">
        <TakeMeBack />
      </div>
      <div className="font-bold text-3xl sm:text-5xl pt-4 sm:pt-6 pb-3 ">
        {t('Login & Security')}
      </div>
      <div className="text-sm sm:text-xl">
        {t('We are committed to making your account as secure as possible.')}
      </div>

      {isLoading && <Loader />}
      <div className=" flex-col w-full">
        <FormProvider {...methods}>
          <form className="flex-col space-y-4 " onKeyDown={handleKeyDown} onSubmit={handleSubmit}>
            <div className="w-full md:w-2/5 pt-6 sm:pt-12">
              {/* login */}
              <div className="text-md sm:text-2xl font-bold py-8">{t('Login')}</div>
              <div className=" pb-2">
                <div className="flex text-xs sm:text-sm justify-between pb-2">
                  <span className="font-semibold ">{t('password')}</span>
                  <button
                    type="button"
                    className="text-deehiy font-bold"
                    onClick={handlePasswordEditClick}
                  >
                    {!isEditingPassword ? t('Update') : t('Close')}
                  </button>
                </div>
                <div className="w-[500px]">
                  {!isEditingPassword ? (
                    <div className="font-bold">********</div>
                  ) : (
                    <>
                      <div className="p-4 my-2 rounded-2xl border-deehiy border text-sm bg-white">
                        {t('password_validation')}
                      </div>
                      <div className="w-[300px]">
                        {isLoading && <Loader />}
                        <FormProvider {...methods}>
                          <form className="flex flex-col space-y-4">
                            <PasswordInput
                              name="password"
                              id="password"
                              label=""
                              value={password}
                              placeholder={t('new password')}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                            <PasswordInput
                              name="confirmPassword"
                              id="confirmPassword"
                              label=""
                              value={confirmPassword}
                              placeholder={t('confirm password')}
                              onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                            <button
                              type="button"
                              // disabled={!checked}
                              onClick={handleSubmit}
                              className="primary-btn"
                            >
                              {t('Save new password')}
                            </button>
                          </form>
                        </FormProvider>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <hr className="border border-deehiy mt-8" />
              {/* <div className="text-xs sm:text-sm font-bold py-8">Social accounts</div> */}
              {/* <div className="py-4">
                <div className="flex text-xs sm:text-sm justify-between pb-2">
                  <span className="font-semibold">Facebook</span>
                  <button
                    type="button"
                    className="text-deehiy font-bold"
                    onClick={handleFacebookEditClick}
                  >
                    {!isEditingFacebook ? 'connect' : 'Save'}
                  </button>
                </div>
                <div className="w-[300px]">
                  {!isEditingFacebook ? (
                    <div className="font-bold text-xs sm:text-sm">{facebook}</div>
                  ) : (
                    <>
                      <Input
                        label=""
                        id="facebook"
                        type="text"
                        value={facebook}
                        onChange={(e) => setFacebook(e.target.value)}
                      />
                    </>
                  )}
                </div>
              </div> */}

              {/* google */}
              {/* <div className="py-4">
                <div className="flex text-xs sm:text-sm justify-between pb-2">
                  <span className="font-semibold">Google</span>
                  <button
                    type="button"
                    className="text-deehiy font-bold"
                    onClick={handleGoogleEditClick}
                  >
                    {!isEditingGoogle ? 'connect' : 'Save'}
                  </button>
                </div>
                <div className="w-[300px]">
                  {!isEditingGoogle ? (
                    <div className="font-bold text-xs sm:text-sm">{google}</div>
                  ) : (
                    <>
                      <Input
                        label=""
                        id="google"
                        type="text"
                        value={google}
                        onChange={(e) => setGoogle(e.target.value)}
                      />
                    </>
                  )}
                </div>
              </div> */}
              {/* linkedin */}
              {/* <div className="py-4">
                <div className="flex text-xs sm:text-sm justify-between pb-2">
                  <span className="font-semibold">LinkedIn</span>
                  <button
                    type="button"
                    className="text-deehiy font-bold"
                    onClick={handleLinkedInEditClick}
                  >
                    {!isEditingLinkedIn ? 'connect' : 'Save'}
                  </button>
                </div>
                <div className="w-[300px]">
                  {!isEditingLinkedIn ? (
                    <div className="font-bold text-xs sm:text-sm">{linkedIn}</div>
                  ) : (
                    <>
                      <Input
                        label=""
                        id="linkedIn"
                        type="text"
                        value={linkedIn}
                        onChange={(e) => setLinkedIn(e.target.value)}
                      />
                    </>
                  )}
                </div>
              </div> */}

              <hr className="border border-deehiy" />
              <div className="text-md sm:text-2xl font-bold py-4 sm:py-8">{t('My account')}</div>
              <div className=" pb-2">
                <div className="flex text-xs sm:text-sm justify-between pb-2">
                  <div className="flex flex-col">
                    <span className="font-semibold ">{t('delete my account')}</span>
                    <span className="">{t('This will permanently delete your account.')}</span>
                  </div>
                  <button
                    type="button"
                    className="text-deehiy font-bold"
                    onClick={() => navigate('/users/deleteaccount')}
                  >
                    {t('delete')}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
      <ToastContainer transition={Slide} />
    </div>
  );
};

export default LoginAndSecurity;
