import React, { useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { ArticlesCards } from '../../pages/info/HelpCenterHomePage';

const HelpCenterSearch = ({ articlesCards, setFilteredArticlesCards }: any) => {
  const [searched, setSearched] = useState('');

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    //
  };

  const handleChange = (e: any) => {
    setSearched(e.target.value);

    const filteredData = articlesCards.filter((art: ArticlesCards) => {
      return art.keywords.includes(e.target.value);
    });

    setFilteredArticlesCards(filteredData);
  };

  return (
    <form onSubmit={handleSubmit} className="m-0">
      <div className="flex p-3 m-0 border border-deehiy font-bold rounded-full bg-white shadow-lg my-8">
        <input
          className={
            'outline-0 placeholder-deehiy text-sm ring-0 border-none rounded-l-full focus:ring-0 py-1 sm:py-3 px-5 w-full'
          }
          type="text"
          value={searched}
          placeholder="What can we help with"
          onChange={handleChange}
        />
        <button
          type="submit"
          className={
            'bg-deehiy text-white font-bold  duration-300 hover:shadow-lg  py-3 sm:py-2 rounded-full px-3 '
          }
        >
          <FaSearch className="w-5 h-5 text-white" />
        </button>
      </div>
    </form>
  );
};

export default HelpCenterSearch;
