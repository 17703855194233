// src/utils/translate.js
import axios from 'axios';

const apiKey = process.env.REACT_APP_GOOGLE_TRANSLATE_API_KEY;

export const translateText = async (text: string, targetLanguage: string) => {
  const instance = axios.create();
  delete instance.defaults.headers.common['x-auth-token']; // to remove cors policy
  const url = `https://translation.googleapis.com/language/translate/v2`;

  try {
    const response = await instance.post(
      url,
      {},
      {
        // Send empty object instead of null
        params: {
          q: text,
          target: targetLanguage,
          key: apiKey,
        },
      }
    );
    return response.data.data.translations[0].translatedText;
  } catch (error) {
    console.error('Error translating text:', error);
    return text; // Return the original text in case of an error
  }
};

const translateParagraph = async (paragraph: string, targetLanguage: string) => {
  // Split the paragraph by line breaks to handle each line separately
  const lines = paragraph.split('\n').filter((line) => line.trim() !== ''); // Remove empty lines

  // Translate each line individually
  const translatedLines = await Promise.all(
    lines.map(async (line) => {
      const translatedLine = await translateText(line, targetLanguage);
      return translatedLine;
    })
  );

  // Reassemble the translated lines into a paragraph
  return translatedLines.join('\n\n');
};

export default translateParagraph;
