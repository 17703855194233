import { useState, cloneElement, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useGlobal } from '../../contexts/global';
import { FiChevronLeft } from 'react-icons/fi';

const GalleryPortal = ({ children, Btn, setShowModal, showModal }: any) => {
  const [localShowModal, setLocalShowModal] = useState(false);
  const { modalRootRef } = useGlobal();

  // Use the prop setShowModal if provided, otherwise use the local setShowModal
  const handleSetShowModal = (value: boolean) => {
    if (setShowModal) {
      setShowModal(value);
    } else {
      setLocalShowModal(value);
    }
  };

  const closeModal = () => {
    setLocalShowModal(false);
    setShowModal(false);
  };

  // useEffect(() => {
  //   if (!Btn) {
  //     setShowModal(true); // Automatically open the modal when there is no Btn component
  //   }
  // }, [Btn]);

  useEffect(() => {
    const handleEscapeKey = (event: any) => {
      if (event.key === 'Escape') {
        closeModal();
      }
    };

    window.addEventListener('keydown', handleEscapeKey);

    return () => {
      window.removeEventListener('keydown', handleEscapeKey);
    };
  }, []);

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [showModal]);

  return (
    <>
      {Btn && <Btn setShowModal={handleSetShowModal} showModal={showModal}></Btn>}
      {showModal || (localShowModal && modalRootRef.current)
        ? ReactDOM.createPortal(
            <>
              <div className="fixed inset-0 z-50 overflow-y-auto">
                <div className="fixed inset-0 w-full h-full bg-white" onClick={closeModal}></div>
                <div className="flex relative items-center justify-center h-screen w-screen">
                  <button
                    className=" fixed z-50 text-gray-600 duration-200 left-6 top-6 text-2xl p-3 rounded-full hover:bg-gray-100 bg-white"
                    onClick={closeModal}
                  >
                    <FiChevronLeft />
                  </button>
                  <div className="w-screen h-screen px-8">
                    {cloneElement(children, { onClose: closeModal })}
                  </div>
                </div>
              </div>
            </>,
            modalRootRef.current
          )
        : null}
    </>
  );
};

export default GalleryPortal;
