import React, { useState, useEffect } from 'react';
import OverlaySidebarLayout from '../../components/square/OverlaySidebarLayout';
import Market from '../../components/main/market/Market';
import MarketFilter3 from '../../components/main/filter/MarketFilter3';
import { useProperty } from '../../contexts/property';
import { initialFilter } from '../../initialStates/initialStates';
// import FeaturesCarousel from '../../components/main/carousel/FeaturesCarousel';
import SEO from '../../components/SEO/SEO';
import { MarketFilter } from '../../types/types';
import { useLocation } from 'react-router-dom';

// import NewLook from '../../components/intro/video/NewLook';
// import Modal from '../../components/listing/Modal';

const MarketPage = () => {
  const { setFilter, tempFilter, setTempFilter } = useProperty();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const location = useLocation();
  // const introViewed = localStorage.getItem('introViewed');
  // const [isFilterOpen, setIsFilterOpen] = useState(window.innerWidth >= 768);

  useEffect(() => {
    // reset filter when unmount
    return () => {
      const newPath = window.location.pathname;

      // Decide whether to reset filter or not based on the new location
      if (!newPath.includes('/properties')) {
        const emptyFilterWithKeyword: MarketFilter = {
          ...initialFilter,
          listingPrice: ['', ''],
          // keyword: keyword,
          hasSellerFinance: false,
          businessId: tempFilter?.businessId, // do not reset businessId in case it is a WL
        };
        // resetFilter();
        setFilter(emptyFilterWithKeyword);
        setTempFilter(emptyFilterWithKeyword);
      }
    };
  }, [location]);

  return (
    <>
      <SEO
        title="Buy & Sell With Ease - Deehiy"
        description="Buy and sell property with ease globally and take control of your real estate business"
        name="Deehiy"
        type="home page"
        image={`${process.env.REACT_APP_BASE_URL_PROD}/images/meta-image.jpeg`}
      />
      {/* intro modal */}
      {/* {introViewed !== process.env.REACT_APP_VIDEO_INTRO_ID && (
        <Modal absoluteCloseBtn={true}>
          <NewLook />
        </Modal>
      )} */}

      {/* <AdaptiveSidebarLayout Filter={MarketFilter2}>
        <Market />
      </AdaptiveSidebarLayout> */}
      <OverlaySidebarLayout
        isFilterOpen={isFilterOpen}
        setIsFilterOpen={setIsFilterOpen}
        Filter={MarketFilter3}
        isFullWidth={true}
        isMarket={true}
      >
        <Market />
      </OverlaySidebarLayout>
    </>
  );
};

export default MarketPage;
