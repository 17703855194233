import React from 'react';
import { useBusiness } from '../../contexts/business';
import { useNavigate } from 'react-router-dom';
import {
  DashboardCard,
  MobileProfileItem,
  DashboardMobileListItem,
  Divider,
} from '../../pages/DashboardHomePage';
import { useAuth } from '../../contexts/auth';
import { useTranslation } from 'react-i18next';
import { adjustImageSize } from '../../utils/adjustImageSize';
import TranslationDropdown from '../main/TranslationDropdown';

const BusinessDashboard = () => {
  const { currentBusiness } = useBusiness();
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  // const appToken = localStorage.getItem('@App:token');

  return (
    <div className="container mx-auto pb-20  px-4 sm:px-0">
      <div className="py-4 sm:py-12 font-bold justify-center sm:justify-between px-0 sm:px-20 flex flex-col sm:flex-row">
        <div className="flex gap-x-3 sm:gap-x-12 items-center pb-12 sm:pb-0 w-full">
          <img
            onClick={() => window.open(`/business/${currentBusiness.businessId}`, '_blank')}
            title="go to business profile page"
            src={adjustImageSize(currentBusiness.image)}
            className="rounded-full shadow-md w-12 h-12 sm:w-[192px] sm:h-[192px]"
            alt="logo"
          />
          <div className="w-full">
            <div className="text-2xl sm:text-5xl ">{currentBusiness?.businessName}</div>

            <div className=" hidden sm:flex text-xs sm:text-xl pt-2 sm:pt-4">
              {t('Your business on Deehiy')}
            </div>
          </div>
          {/* desktop */}
          <div className="hidden sm:flex my-2 sm:mt-auto  items-end justify-end w-full">
            <button
              onClick={() => navigate('/users/dashboard')}
              className="primary-btn space-x-2 flex whitespace-nowrap mr-0"
            >
              <img
                src="/images/icons/business/profile-white.svg"
                className="w-4 h-4 sm:w-5 sm:h-5"
              />
              <span className="text-sm sm:text-base">{t('Manage personal profile')}</span>
            </button>
          </div>
        </div>
        {/* Mobile */}
        <div className="flex flex-col sm:hidden my-2 sm:mt-auto">
          <MobileProfileItem
            title={currentUser?.firstName}
            subtitle={t('See profile')}
            url="/users/dashboard"
            imageUrl={
              currentUser?.image || '/images/icons/icon-dashboard-landing/mobile/profile-info.svg'
            }
          />
          <Divider />
        </div>
      </div>
      <div className="hidden sm:grid grid-cols-2 sm:grid-cols-3 gap-4 sm:gap-6 px-4 mx-auto">
        {currentUser?.roleId === 1 && (
          <DashboardCard
            title={'Business info'}
            subTitle={'Tell the world more about your business'}
            icon={'/images/icons/icon-dashboard-landing/personal-info.svg'}
            url={'/business/profile'}
            // canClick={currentUser?.roleId === 1 ? true : false}
            isComingSoon={false}
          />
        )}
        <DashboardCard
          title={'Property listings'}
          subTitle={'Manage, edit and update your listings'}
          icon={'/images/icons/icon-dashboard-landing/listings.svg'}
          url={'/business/managelistings'}
          isComingSoon={false}
        />
        {/* <DashboardCard
          title={'Community'}
          subTitle={'Explore a world of opportunities'}
          icon={'/images/icons/icon-dashboard-landing/network.svg'}
          url={'/network'}
          isComingSoon={true}
        /> */}
        {/* <DashboardCard
          title={'Messaging'}
          subTitle={'Chat, call, share'}
          icon={'/images/icons/icon-dashboard-landing/messaging.svg'}
          url={`${process.env.REACT_APP_MESSENGER_URL}?token=${appToken}`}
          isComingSoon={false}
        /> */}
        {/* <DashboardCard
          title={'Hunting Board'}
          subTitle={'Manage hunts and leads'}
          icon={'/images/icons/icon-dashboard-landing/hunting-board.svg'}
          url={'/huntingboard'}
          isComingSoon={true}
        /> */}
        {/* <DashboardCard
          title={'Forms'}
          subTitle={'Create contracts with ease online'}
          icon={'/images/icons/icon-dashboard-landing/forms.svg'}
          url={'/forms'}
          isComingSoon={true}
        /> */}
        {/* <DashboardCard
          title={'Analytics'}
          subTitle={'Learn how you are doing online'}
          icon={'/images/icons/icon-dashboard-landing/analytics.svg'}
          url={'/comingsoon'}
          isComingSoon={true}
        /> */}
        {/* <DashboardCard
          title={'Wishlist'}
          subTitle={'A list of your favorite properties'}
          icon={'/images/icons/icon-dashboard-landing/wishlist.svg'}
          url={'/comingsoon'}
          isComingSoon={true}
        /> */}
        <DashboardCard
          title={'Account settings'}
          subTitle={'Set your personal preferences'}
          icon={'/images/icons/icon-dashboard-landing/settings.svg'}
          url={'/business/settings'}
          isComingSoon={false}
        />
      </div>
      <div className="flex flex-col sm:hidden">
        {currentUser?.roleId === 1 && (
          <DashboardMobileListItem
            url={'/business/profile'}
            imageUrl={'/images/icons/icon-dashboard-landing/mobile/personal-profile.svg'}
            title="Business info"
          />
        )}
        <DashboardMobileListItem
          url={'/business/managelistings'}
          imageUrl={'/images/icons/icon-dashboard-landing/mobile/manage-listing.svg'}
          title="Property listings"
        />
        {/* <DashboardMobileListItem
          url={'/business/huntingboard'}
          imageUrl={'/images/icons/icon-dashboard-landing/mobile/hunting-board.svg'}
          title="Hunting board"
        /> */}
        <DashboardMobileListItem
          url={'/business/settings'}
          imageUrl={'/images/icons/icon-dashboard-landing/mobile/settings.svg'}
          title="Settings"
        />
        <Divider />
      </div>
      <div className="justify-between items-center flex sm:hidden w-full px-12 h-24">
        <TranslationDropdown />
        <div></div>
      </div>
    </div>
  );
};

export default BusinessDashboard;
