import React from 'react';

const TermsOfService = () => {
  return (
    <div className="container max-w-[850px] mx-auto space-y-6 py-10 px-4">
      <div className="font-light text-2xl text-center">Terms of Service</div>
      <div className="text-sm font-extralight">
        <div className="font-light text-xl mb-2">Terms</div>
        By accessing this Website, accessible from www.deehiy.com, you are agreeing to be bound by
        these Website Terms and Conditions of Use and agree that you are responsible for the
        agreement with any applicable local laws. If you disagree with any of these terms, you are
        prohibited from accessing this site. The materials contained in this Website are protected
        by copyright and trade mark law.
      </div>
      <div className="text-sm font-extralight">
        <div className="font-light text-xl mb-2">Use License</div>
        Permission is granted to temporarily download one copy of the materials on C&C Cavin Co.
        Ltd.’s Website for personal, non-commercial transitory viewing only. This is the grant of a
        license, not a transfer of title, and under this license you may not:
        <br />
        <br />
        <ul className="text-sm space-y-2 list-disc font-extralight">
          <li>modify or copy the materials;</li>
          <li>use the materials for any commercial purpose or for any public display;</li>
          <li>
            attempt to reverse engineer any software contained on C&C Cavin Co. Ltd.’s Website;
          </li>
          <li>remove any copyright or other proprietary notations from the materials; or</li>
          <li>
            transferring the materials to another person or “mirror” the materials on any other
            server.
          </li>
        </ul>
        <br />
        This will let C&C Cavin Co. Ltd. to terminate upon violations of any of these restrictions.
        Upon termination, your viewing right will also be terminated and you should destroy any
        downloaded materials in your possession whether it is printed or electronic format. These
        Terms of Service has been created with the help of the Terms Of Service Generator.
      </div>

      <div className="text-sm font-extralight">
        <div className="font-light text-xl mb-2">Disclaimer</div>
        All the materials on C&C Cavin Co. Ltd.’s Website are provided “as is”. C&C Cavin Co. Ltd.
        makes no warranties, may it be expressed or implied, therefore negates all other warranties.
        Furthermore, C&C Cavin Co. Ltd. does not make any representations concerning the accuracy or
        reliability of the use of the materials on its Website or otherwise relating to such
        materials or any sites linked to this Website.
      </div>
      <div className="text-sm font-extralight">
        <div className="font-light text-xl mb-2">Terms of Service for Advertising Ads</div>
        We are the only intermediary of every communication between users of our platform and our
        service as we do not use any third party to handle our support.
        <br />
        <br />
        You can always contact us on contact@deehiy.com, support@deehiy.com, directly on our contact
        us page, or use other means of contact displayed on the contact us page.
        <br />
        <br />
        Advertising your listing does not guarantee the sale of your listing and we take no
        responsibility to broker any transactions. . By using the advertising tools provided by our
        platform you will achieve greater visibility of your boosted listing and receive insights on
        the performance of your listing. Our system will display your boosted listing to assure
        highest visibility and deduct the allocated campaign funds without guarantee of a Sales
        transaction.
        <br />
        <br />
        When advertising a new boost, we provide an estimation of the reach you can hope for during
        the selected period. This has to do with factors like the quality of the real estate, the
        traffic on the platform and your budget. Your actual reach may be higher or lower than this
        estimate.
      </div>
      <div className="text-sm font-extralight">
        <div className="font-light text-xl mb-2">Prohibited content and behavior</div>
        It is forbidden to publish and/or promote content that you are not entirely sure if you have
        the approval of the real estate owner attached to the listing. Make sure you are the
        rightful intermediary between users contacting you and the listing you provide for visits
        and/or sales. Users of our platform are able to report any suspicious behavior and we keep
        the right to take immediate actions against misuses of the tools we provide. Non exhaustive
        list of forbidden behavior and actions : Harassment, publishing sexual or gore material,
        publishing material that infringe the privacy of other, writing hurtful/mean comments or
        descriptions, lying about the real estate you are trying to sell.
      </div>
      <div className="text-sm font-extralight">
        <div className="font-light text-xl mb-2">Limitations</div>
        C&C Cavin Co. Ltd. or its suppliers will not be hold accountable for any damages that will
        arise with the use or inability to use the materials on C&C Cavin Co. Ltd.’s Website, even
        if C&C Cavin Co. Ltd. or an authorize representative of this Website has been notified,
        orally or written, of the possibility of such damage. Some jurisdiction does not allow
        limitations on implied warranties or limitations of liability for incidental damages, these
        limitations may not apply to you.
      </div>
      <div className="text-sm font-extralight">
        <div className="font-light text-xl mb-2">Revisions and Errata</div>
        The materials appearing on C&C Cavin Co. Ltd.’s Website may include technical,
        typographical, or photographic errors. C&C Cavin Co. Ltd. will not promise that any of the
        materials in this Website are accurate, complete, or current. C&C Cavin Co. Ltd. may change
        the materials contained on its Website at any time without notice. C&C Cavin Co. Ltd. does
        not make any commitment to update the materials.
      </div>
      <div className="text-sm font-extralight">
        <div className="font-light text-xl mb-2">Links</div>
        C&C Cavin Co. Ltd. has not reviewed all of the sites linked to its Website and is not
        responsible for the contents of any such linked site. The presence of any link does not
        imply endorsement by C&C Cavin Co. Ltd. of the site. The use of any linked website is at the
        user’s own risk.
      </div>
      <div className="text-sm font-extralight">
        <div className="font-light text-xl mb-2">Site Terms of Use Modifications</div>
        C&C Cavin Co. Ltd. may revise these Terms of Use for its Website at any time without prior
        notice. By using this Website, you are agreeing to be bound by the current version of these
        Terms and Conditions of Use.
      </div>
      <div className="text-sm font-extralight">
        <div className="font-light text-xl mb-2">Your Privacy</div>
        Please read our Privacy Policy.
      </div>
      <div className="text-sm font-extralight">
        <div className="font-light text-xl mb-2">Subscription & cancellation</div>
        Please read our Subscription and cancellation terms.
      </div>
      <div className="text-sm font-extralight">
        <div className="font-light text-xl mb-2">Governing Law</div>
        Any claim related to C&C Cavin Co. Ltd.’s Website shall be governed by the laws of the
        kingdom of Thailand without regards to its conflict of law provisions.
      </div>
    </div>
  );
};

export default TermsOfService;
