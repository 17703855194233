import React, { useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { businessStep3FormSchema } from '../../../lib/yup';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Input from '../../form/Input';
import Tooltip from '../../form/Tooltip';
import { BiSolidInfoCircle } from 'react-icons/bi';
import { useBusiness } from '../../../contexts/business';
import Loader from '../../main/Loader';
import { useAuth } from '../../../contexts/auth';
import PopoverPicker from '../../main/color-picker/PopoverPicker';
import { useTranslation } from 'react-i18next';

const Step3 = ({ setIndex }: any) => {
  const methods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(businessStep3FormSchema),
  });
  const { business, setBusinessContext, createBusinessContext, fetchBusinessByUserIdContext } =
    useBusiness();
  const { currentUser, setCurrentUserAsBusinessAdmin } = useAuth();
  const { t } = useTranslation();

  const [phoneNumber, setPhoneNumber] = useState<any>(business.phone || null);
  const [noWebsite, setNoWebsite] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [canClickNext, setCanClickNext] = useState(false);

  const handleChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;

    setBusinessContext({ ...business, [name]: value });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const res = await createBusinessContext(business);
      console.log(res);
      await fetchBusinessByUserIdContext(currentUser?.id);
      setCurrentUserAsBusinessAdmin(currentUser?.id);
      setIndex((prev: number) => prev + 1);
    } catch (err: any) {
      alert(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const checkMandatoryFields = (checkedBusiness: any) => {
    const { email, websiteUrl, haveWebsite, phone } = checkedBusiness;

    if (haveWebsite && websiteUrl === '') {
      return false;
    }

    if (email === '' || (phone && phone.length < 8)) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    setBusinessContext({ ...business, haveWebsite: !noWebsite, phone: phoneNumber });
  }, [noWebsite, phoneNumber]);

  useEffect(() => {
    setCanClickNext(checkMandatoryFields(business));
  }, [phoneNumber, business.email, business.websiteUrl, business.haveWebsite]);

  return (
    <div className="h-full">
      <div className="space-y-6 pt-3 pb-3">
        <div className="w-full flex justify-center text-2xl sm:text-3xl md:text-4xl xl:text-5xl font-bold ">
          {t('Add your info details')}
        </div>
        <div className="w-full flex text-center justify-center text-sm sm:text-md font-bold">
          {t('more_details')}
          <br />
          <br />
        </div>
      </div>
      <div className="flex h-[62vh] pt-3">
        <div className=" hidden sm:flex w-1/2 justify-end">
          <img alt="add business" className=" mr-12" src="/images/addbusiness3.svg" />
        </div>
        <div className="sm:w-1/2 w-full ">
          <div className="flex-col w-full h-full relative sm:w-3/5 mx-2 sm:mx-12 ">
            <FormProvider {...methods}>
              <form className="flex flex-col space-y-3">
                {/* phone */}
                <div className="">
                  <div className="text-sm pb-1">{t('phone')}</div>
                  <PhoneInput
                    country={'th'}
                    enableSearch={true}
                    countryCodeEditable={false}
                    placeholder={t('Enter phone number')}
                    value={phoneNumber}
                    onChange={setPhoneNumber}
                    buttonClass={'hover:!p-0 !border-none'}
                    inputClass={
                      '!w-full !text-lg !py-5 !ring-2 ring-gray-200 !border-none focus:!ring-deehiy '
                    }
                    searchClass={'focus:ring-deehiy '}
                  />
                </div>
                {/* street name  */}
                <Input
                  label={t('business email')}
                  type="email"
                  id="email"
                  name="email"
                  onChange={handleChange}
                  value={business.email}
                  placeholder={''}
                />
                {/* website url  */}
                <div>
                  <Input
                    readOnly={noWebsite}
                    label={t('your website URL')}
                    Tooltip={() => (
                      <Tooltip
                        width={400}
                        text="Assure to use an active and regularly monitored
                      email account as this will be used to send notifications to,
                      such as leads, requests and messages."
                        Component={() => <BiSolidInfoCircle className="text-deehiy w-5 h-5" />}
                      />
                    )}
                    type="text"
                    id="websiteUrl"
                    name="websiteUrl"
                    onChange={handleChange}
                    value={business.websiteUrl}
                    placeholder={''}
                  />

                  <div className="flex pt-2">
                    <input
                      className="w-5 h-5 text-deehiy cursor-pointer bg-white outline-none  focus:outline-transparent focus:ring-transparent border-gray-300 rounded  dark:focus:ring-deehiy dark:bg-gray-700 dark:border-gray-600"
                      type="checkBox"
                      onClick={() => setNoWebsite((prev) => !prev)}
                    />
                    <div className="text-sm font-light text-justify w-full flex flex-wrap">
                      <span className="whitespace-nowrap">&nbsp;{t('I don’t have a website')}</span>{' '}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="flex">
                    <input
                      className="w-5 h-5 text-deehiy cursor-pointer bg-white outline-none  focus:outline-transparent focus:ring-transparent border-gray-300 rounded  dark:focus:ring-deehiy dark:bg-gray-700 dark:border-gray-600"
                      type="checkBox"
                      checked={business.isWhiteLabel}
                      onClick={() =>
                        setBusinessContext({ ...business, isWhiteLabel: !business.isWhiteLabel })
                      }
                    />
                    <div className="text-sm text-justify w-full flex flex-wrap">
                      <span className="whitespace-nowrap">
                        &nbsp;{t('Enable your White Label market')}
                      </span>{' '}
                    </div>
                  </div>
                </div>
                <div className="text-sm flex gap-x-2 items-center">
                  <span className="whitespace-nowrap">{t('Choose primary brand color')}</span>
                  <PopoverPicker
                    color={business.color}
                    onChange={(col) => setBusinessContext({ ...business, color: col })}
                  />
                  <span className="w-20 italic text-gray-400">{business.color}</span>
                </div>
              </form>
            </FormProvider>
            <div className="w-full flex justify-between absolute bottom-2">
              <button
                className="form-back-btn"
                onClick={() => setIndex((prev: number) => prev - 1)}
              >
                {t('back')}
              </button>
              <button
                type="button"
                onClick={handleSubmit}
                disabled={!canClickNext || isLoading ? true : false}
                className={`form-next-btn ${
                  !canClickNext || isLoading ? 'opacity-50 cursor-not-allowed' : ''
                }`}
              >
                {canClickNext}
                {isLoading && <Loader />}
                {t('Submit')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step3;
