import React from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

const AdminLabel = () => {
  const navigate = useNavigate();

  return (
    <div className="w-full flex items-center pb-3 bg-deehiy space-x-3 rounded-b-2xl font-bold text-white text-sm px-4 py-1">
      <button
        onClick={() => navigate(-1)}
        className="flex space-x-3 items-center hover:text-gray-100 hover:cursor-pointer"
      >
        <FiChevronLeft className="h-6 w-6 " /> back
      </button>
      {/* <span>admin dashboard</span> */}
    </div>
  );
};

export default AdminLabel;
