import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { RxCross2 } from 'react-icons/rx';
import { HiCheck } from 'react-icons/hi';
import { adjustImageSize } from '../../../../utils/adjustImageSize';

const ITEM_TYPE = 'CARD';

// ${(props) =>
//   props.dragging &&
//   css`
//     opacity: 0;
//   }

export const Card = ({ id, index, onMove, onDelete, preview, uploaded, progress }: any) => {
  const wrapperRef = useRef(null);

  const [collected, connectDrag] = useDrag(
    () => ({
      item: { id },
      type: ITEM_TYPE,
      collect: (monitor: any) => ({
        dragging: monitor.isDragging(),
      }),
    }),
    [id]
  );

  const [, connectDrop] = useDrop(
    () => ({
      accept: ITEM_TYPE,
      hover: (item: any) => {
        if (item.id === id) return;
        // move item with this id to this index
        onMove(item.id, index);
      },
    }),
    [id, index, onMove]
  );

  connectDrop(connectDrag(wrapperRef));

  return (
    <div
      className={`${
        collected.dragging ? ' opacity-5' : 'opacity-100'
      } relative flex duration-500 w-[150px] shadow-md hover:cursor-grab hover:shadow-lg active:shadow-2xl active:cursor-grabbing rounded-md`}
      ref={wrapperRef}
      {...collected}
    >
      <img
        title="drag and drop me"
        className="object-cover aspect-video rounded-md hover:opacity-80 duration-300"
        src={adjustImageSize(preview)}
        alt=""
      />
      {!uploaded ? (
        <div
          className={` absolute center ${
            progress < 100 ? 'cursor-progress inset-0' : ' inset-6'
          }  flex items-center justify-center`}
        >
          <div className="relative w-8 h-8">
            <div className="absolute inset-0">
              <div className="w-full h-full rounded-full bg-white opacity-50 border-gray-300 border-4" />
              <div
                className="absolute top-0 left-0 w-full h-full rounded-full"
                style={{
                  background: `conic-gradient(#1BB095 ${(progress / 100) * 360}deg, transparent 0 ${
                    (progress / 100) * 360
                  }deg)`,
                }}
              />
            </div>
            <span className="absolute text-lg inset-0 flex items-center justify-center text-white font-semibold">
              {progress < 100 ? `${progress}%` : <HiCheck className="w-6 h-6" />}
            </span>
          </div>
        </div>
      ) : (
        <div className={`  absolute center inset-6 flex items-center justify-center`}>
          <div className="relative w-8 h-8">
            <div className="absolute inset-0">
              <div className="absolute bg-deehiy top-0 left-0 w-full h-full rounded-full" />
            </div>
            <span className="absolute text-lg inset-0 flex items-center justify-center text-white font-semibold">
              <HiCheck className="w-6 h-6" />
            </span>
          </div>
        </div>
      )}
      <button title="delete" onClick={() => onDelete(id)}>
        <RxCross2 className="absolute top-2 h-6 w-6 p-1 right-3 bg-gray-200 bg-opacity-30 rounded-full hover:bg-opacity-100 duration-200 text-black" />
      </button>
    </div>
  );
};
