import React from 'react';
import { adjustImageSize } from '../../utils/adjustImageSize';
import { Avatar } from '../../pages/ListingPage';
import { useBusiness } from '../../contexts/business';

const ConfirmRemoveUser = ({ handleDelete, user, setShowConfirmModal }: any) => {
  const { currentBusiness } = useBusiness();

  const handleDone = () => {
    setShowConfirmModal(false);
    handleDelete(user.id, currentBusiness.businessId);
  };

  return (
    <div className="w-full sm:w-[550px] flex flex-col">
      <div className="flex w-full justify-center border-transparent text-base text-center font-bold border pb-4 border-b-deehiy">
        People and access
      </div>
      <div className="flex flex-col items-center justify-center space-y-10 px-12 py-10">
        <div className="text-gray-500 text-base">Are you sure you want to remove access for :</div>
        <div className="flex items-center justify-center space-x-4">
          <Avatar
            isSales={false}
            image={
              user.image
                ? adjustImageSize(user.image[0].url, 250)
                : process.env.REACT_APP_DEFAULT_USER_URL
            }
          />
          <div className="flex flex-col">
            <span className="font-bold">{user.firstName + ' ' + user.lastName}</span>
            <span className="">{user.email}</span>
          </div>
        </div>
        <div className="flex items-center justify-center">
          <button onClick={handleDone} className="primary-btn bg-deehiy-red">
            Remove
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmRemoveUser;
