import React from 'react';
import TakeMeBack from '../../components/main/navigation/TakeMeBack';

const NetworkPreLaunch = () => {
  return (
    <div className="mx-auto flex flex-col justify-center items-center px-6 sm:px-24 container">
      <div className="pt-6 w-full justify-start">
        <TakeMeBack />
      </div>
      <div className="flex flex-col text-left w-full">
        {/* title */}
        <div className="text-2xl sm:text-5xl font-bold pt-12 sm:pt-8 pb-4 sm:py-6">
          Build your own powerhouse network
        </div>
        <div className="text-sm sm:text-xl pb-4 sm:pb-16">
          Discover a new wold of opportunities.
        </div>
      </div>
      <div className="flex flex-col sm:flex-row">
        <div className="flex flex-col w-wull sm:w-3/5 pr-12">
          <div className="text-xs sm:text-sm py-6 ">
            We are finalizing last details to start networking on Deehiy, but here is a small teaser
            of what’s to come.
          </div>
          <div className="py-3">
            <div className="text-xs sm:text-sm font-bold text-deehiy">Instant Messaging</div>
            <div className="text-xs sm:text-sm">
              Forget about the many chat apps to manage your daily business. With the new chat app
              of Deehiy you can communicate directly with clients, agents or your network
              connections and track all your efforts on a single platform.
            </div>
          </div>
          <div className="py-3">
            <div className="text-xs sm:text-sm font-bold text-deehiy">Expand your network</div>
            <div className="text-xs sm:text-sm">
              Reach beyond boarders and expand your horizon while connecting with other real estate
              stakeholders and communicate with ease about deals, leads, opportunities and much
              more.
            </div>
          </div>
        </div>
        <div className="flex justify-center w-full sm:w-2/5 p-2 sm:p-6 items-center">
          <img alt="" src={'/images/network-teaser-image.png'} className="w-full" />
        </div>
      </div>
    </div>
  );
};
export default NetworkPreLaunch;
