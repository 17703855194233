import React from 'react';
// import { useAuth } from '../../contexts/auth';
import { useNavigate } from 'react-router-dom';

const HuntModalContent = ({ setShowModal }: any) => {
  const navigate = useNavigate();
  // const { currentUser } = useAuth();

  const handleClick = (type: 'buy' | 'rent') => {
    setShowModal(false);

    navigate(`/users/addhunt/${type}`);
  };

  return (
    <div className="max-w-[500px] text-center p-0 sm:p-14">
      <h1 className="font-bold text-2xl sm:text-3xl">Tired of searching?</h1>
      <div className="pt-8 text-lg sm:text-xl">
        Tell us what matters most and let our community do the rest.
      </div>
      <div className="pt-12 text-lg sm:text-xl">Are you Buying or Renting?</div>
      <div className=" pt-10 flex px-4 justify-between">
        <button
          onClick={() => handleClick('buy')}
          className="form-next-btn mx-auto text-center justify-center items-center flex  w-32 p-3"
        >
          Buying
        </button>
        <button
          onClick={() => handleClick('rent')}
          className="form-next-btn mx-auto text-center justify-center items-center flex  w-32 p-3"
        >
          Renting
        </button>
      </div>
    </div>
  );
};

export default HuntModalContent;
