import { Action } from '../types/types';
import {
  SET_BUSINESS,
  SET_CURRENT_BUSINESS,
  SET_BUSINESS_PROPERTIES,
  SET_BUSINESS_USERS,
} from '../actions/actions';

export const businessReducer = (state: any, action: Action) => {
  switch (action.type) {
    case SET_BUSINESS:
      return { ...state, business: action.payload };
    case SET_CURRENT_BUSINESS:
      return { ...state, currentBusiness: action.payload };
    case SET_BUSINESS_PROPERTIES:
      return { ...state, properties: action.payload };
    case SET_BUSINESS_USERS:
      return { ...state, users: action.payload };
    default:
      return state;
  }
};
