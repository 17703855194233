import React, { useState, createContext, useContext, useEffect } from 'react';
import { useScrollDirection } from 'react-use-scroll-direction';

const initialScrollContext = {
  visible: true,
  setVisible: () => [],
  isActive: true,
  setIsActive: () => [],
  isAtTop: true,
  setIsAtTop: () => [],
};

type ScrollContextType = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isActive: boolean;
  setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
  isAtTop: boolean;
  setIsAtTop: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ScrollContext = createContext<ScrollContextType>(initialScrollContext);

export const ScrollProvider = ({ children }: any) => {
  const { isScrollingUp, isScrollingDown } = useScrollDirection();

  const [visible, setVisible] = useState(true);
  const [isActive, setIsActive] = useState(true);
  const [isAtTop, setIsAtTop] = useState(true);

  const value = { visible, setVisible, isActive, setIsActive, isAtTop, setIsAtTop };

  useEffect(() => {
    const handleScroll = () => {
      setIsAtTop(window.scrollY === 0);
    };

    if (isActive) {
      if (isScrollingUp) {
        setVisible(true);
      }
      if (isScrollingDown) {
        setVisible(false);
      }
    }

    // Check if the page is at the top on mount and on scroll
    handleScroll();
    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isScrollingUp, isScrollingDown]);

  return <ScrollContext.Provider value={value}>{children}</ScrollContext.Provider>;
};

export function useScroll() {
  const context = useContext(ScrollContext);

  return context;
}
