import React, { useState, useEffect, useMemo } from 'react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  getPaginationRowModel,
  // getFilteredRowModel,
} from '@tanstack/react-table';
// import { rankItem } from '@tanstack/match-sorter-utils';
import type { ColumnDef, SortingState } from '@tanstack/react-table';
// import type { FilterFn } from '@tanstack/react-table';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { BsChevronExpand } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { useBusiness } from '../../contexts/business';
import { useInternationalization } from '../../contexts/internationalization';
import { Property } from '../../types/types';
import { NumericFormat } from 'react-number-format';
import DebouncedInput from '../dashboard/adminDashboard/DebouncedInput';
import { adaptStatus } from '../../utils/adaptStatusStyle';
import { adjustImageSize } from '../../utils/adjustImageSize';
import { assignUserService } from '../../services/businessService';
import { togglePropertyPublishStatus, deleteProperty } from '../../services/propertyService';

import { CustomDropwdown, DropdownItem } from '../main/navigation/Navigation';
import { DropdownDotsBtn } from '../main/community/Community';

import Modal2 from '../listing/Modal2';
import Loader from '../main/Loader';
import AssignListing from './AssignListing';

import { canAccess } from '../../utils/businessAccess';
import { useAuth } from '../../contexts/auth';
import {
  ASSIGN_USER,
  PUBLISH_LISTING,
  EDIT_LISTING,
  REMOVE_LISTING,
} from '../../constants/constant';
import { RiAdminLine } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';

// const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
//   // Rank the item
//   const itemRank = rankItem(row.getValue(columnId), value);

//   // Store the itemRank info
//   addMeta({
//     itemRank,
//   });

//   // Return if the item should be filtered in/out
//   return itemRank.passed;
// };

const columnHelper = createColumnHelper<Property>();
// const headerStyle = 'px-4 w-full flex';

export const AccessModal = ({ text, setShowAccessModal }: any) => {
  const { t } = useTranslation();
  return (
    <div className=" p-12 text-base flex flex-col ">
      <div className="flex justify-center items-center">
        <RiAdminLine className="text-deehiy w-10 h-10" />
        <div className="px-6 text-sm">{text}</div>
      </div>
      <div className="justify-center flex pt-8">
        <button className="secondary-btn" onClick={() => setShowAccessModal(false)}>
          {t('Close')}
        </button>
      </div>
    </div>
  );
};

const EditDropdown = ({
  handleDelete,
  handleAssignment,
  handlePublication,
  users,
  property,
}: {
  handleDelete: any;
  handleAssignment: any;
  handlePublication: any;
  users: any;
  property: any;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { currentUser } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [showAccessModal, setShowAccessModal] = useState(false);
  const [text, setText] = useState('');

  const openAssignModal = () => {
    if (!canAccess(currentUser, ASSIGN_USER, property)) {
      setText(t('assign_access_denied_message'));
      setShowAccessModal(true);
      return;
    }

    setShowModal(true);
  };

  const publishListing = (id: number) => {
    if (!canAccess(currentUser, PUBLISH_LISTING, property)) {
      setText(t('publish_access_denied_message'));
      setShowAccessModal(true);
      return;
    }

    handlePublication(id);
  };

  const removeListing = (id: number) => {
    if (!canAccess(currentUser, REMOVE_LISTING, property)) {
      setText(t('remove_access_denied_message'));
      setShowAccessModal(true);
      return;
    }

    handleDelete(id);
  };

  const editListing = (id: number) => {
    if (!canAccess(currentUser, EDIT_LISTING, property)) {
      setText(t('edit_access_denied_message'));
      setShowAccessModal(true);
      return;
    }

    navigate(`/users/editlisting/${id}`);
  };

  return (
    <>
      <CustomDropwdown Btn={<DropdownDotsBtn />}>
        {property.published ? (
          <DropdownItem
            onClick={() => publishListing(property.propertyId)}
            imageUrl="/images/icons/business/unpublish-listing.svg"
            itemLabel={t('Unpublish')}
          />
        ) : (
          <DropdownItem
            onClick={() => publishListing(property.propertyId)}
            imageUrl={'/images/icons/business/publish-listing.svg'}
            itemLabel={t('Publish')}
          />
        )}

        <DropdownItem
          onClick={() => editListing(property.propertyId)}
          imageUrl={'/images/icons/business/edit-listing.svg'}
          itemLabel={t('Edit')}
        />

        <DropdownItem
          onClick={openAssignModal}
          imageUrl={`/images/icons/business/assign-listing.svg`}
          itemLabel={t('Assign listing')}
        />
        <DropdownItem
          onClick={() => removeListing(property.propertyId)}
          imageUrl={`/images/icons/business/delete.svg`}
          itemLabel={t('Remove')}
        />
      </CustomDropwdown>
      {showModal && (
        <div className="absolute top-0 left-0 w-full h-full">
          <Modal2 showModal={showModal} setShowModal={setShowModal}>
            <AssignListing
              handleAssignment={handleAssignment}
              users={users}
              property={property}
              setShowModal={setShowModal}
            />
          </Modal2>
        </div>
      )}
      {showAccessModal && (
        <div className="absolute top-0 left-0 w-full h-full">
          <Modal2 showModal={showAccessModal} setShowModal={setShowAccessModal}>
            <AccessModal text={text} setShowAccessModal={setShowAccessModal} />
          </Modal2>
        </div>
      )}
    </>
  );
};

const ListingsTable = () => {
  // const rerender = useReducer(() => ({}), {})[1];

  const { currentBusiness, properties, fetchPropertiesByBusinessIdContext, users, setProperties } =
    useBusiness();
  const { currency } = useInternationalization();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [sorting, setSorting] = useState<SortingState>([]);
  // const [globalFilter, setGlobalFilter] = useState('');
  const [data, setData] = useState(() => properties || []);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pageIndex, setPageIndex] = useState(1);
  const [search, setSearch] = useState('');

  const handlePublication = async (propertyId: number) => {
    try {
      setIsLoading(true);
      const publish = await togglePropertyPublishStatus(propertyId);

      if (publish.data === 'not approved') {
        alert(t('listing_not_approved_message'));
        return;
      }
      const updatedProperties = properties.map((property) => {
        if (property.propertyId === propertyId) {
          return { ...property, published: publish.data };
        }
        return property;
      });

      setProperties(updatedProperties);
    } catch (err: any) {
      alert(err.response.data);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (propertyId: number) => {
    if (confirm(`Are you sure you want to delete the property: ${propertyId}?`) == true) {
      try {
        await deleteProperty(propertyId);

        const updatedProperties = properties.filter(
          (property) => property.propertyId !== propertyId
        );

        setProperties(updatedProperties);
      } catch (err: any) {
        alert(err.response.data);
      }
    } else {
      return;
    }
  };

  const handleAssignment = (propertyId: number, userId: number) => {
    setIsLoading(true);

    assignUserService(propertyId, currentBusiness.businessId!, userId)
      .then(() => {
        const updatedProperties = properties.map((property) => {
          if (property.propertyId === propertyId) {
            const matchingUser = users.find((user) => user.id === userId);

            if (matchingUser) {
              const { id, firstName, lastName, email } = matchingUser;

              return { ...property, assignment: { id, firstName, lastName, email } };
            }
          }
          return property;
        });
        setProperties(updatedProperties);
      })
      .catch((err) => alert(err.response.data))
      .finally(() => setIsLoading(false));
  };

  const columns = useMemo<ColumnDef<Property, any>[]>(() => {
    const baseColumns = [
      columnHelper.accessor('images', {
        id: 'propertyId',
        cell: (info) =>
          info.renderValue() && info.renderValue()![0] ? (
            <img
              loading="lazy"
              src={adjustImageSize(info.renderValue()![0].url, 300)}
              alt=""
              className="my-2 sm:my-3 max-w-[50px] sm:max-w-[120px] aspect-video pr-1"
            />
          ) : (
            <>img</>
          ),

        header: () => <span className="pl-4 text-xs sm:text-md">{t('Listings')}</span>,

        // footer: (info) => info.column.id,
      }),
      columnHelper.accessor((row) => row.propertyTitle, {
        id: 'propertyTitle',
        cell: (info) => (
          <div
            onClick={() =>
              window.open(`/properties/preview/${info.row.original.propertyId}`, '_blank')
            }
            title={`${info.getValue()}`}
            className="line-clamp-2 cursor-pointer hover:underline max-w-[300px] pr-1"
          >
            {info.getValue()}
          </div>
        ),
        enableSorting: false,
        header: () => '',
        // footer: (info) => info.column.id,
      }),
      columnHelper.accessor((row) => row.city, {
        id: 'city',
        header: () => <span className="pl-4 text-xs sm:text-md">{t('Location')}</span>,
        cell: (info) => <div className="line-clamp-1">{info.renderValue()}</div>,
      }),
      columnHelper.accessor('listingPrice', {
        id: 'listingPrice',
        header: () => (
          <span className="pl-4 text-xs sm:text-md whitespace-nowrap">{t('Sale Price')}</span>
        ),
        cell: (info) => (
          <span className="whitespace-nowrap">
            {info.row.original.isForSale || info.row.original.isOffPlan ? (
              <NumericFormat
                value={info.renderValue()}
                thousandSeparator=" "
                displayType={'text'}
                prefix={info.row.original.isoCodeCurrency + ' '}
              />
            ) : (
              ''
            )}
          </span>
        ),
      }),
      columnHelper.accessor('monthlyRentPrice', {
        id: 'monthlyRentPrice',
        header: () => (
          <span className="pl-4 text-xs sm:text-md whitespace-nowrap">{t('Rent Price / m')}</span>
        ),
        cell: (info) => (
          <span className="whitespace-nowrap">
            {info.row.original.isForRent && (
              <NumericFormat
                value={Number(info.renderValue())}
                thousandSeparator=" "
                displayType={'text'}
                prefix={info.row.original.isoCodeCurrency + ' '}
              />
            )}
          </span>
        ),
      }),
      columnHelper.accessor('status', {
        id: 'status',
        header: () => <span className="pl-4 text-xs sm:text-md">{t('Status')}</span>,
        cell: (info) => (
          <span className="whitespace-nowrap text-xs sm:text-md">
            {info.renderValue() ? adaptStatus(info.getValue()) : 'unknown'}
          </span>
        ),
      }),
      columnHelper.accessor((row) => row.published, {
        id: 'published',
        header: () => <span className="pl-4 text-xs sm:text-md">{t('Visibility')}</span>,
        cell: (info) => (
          <div className="mx-auto">
            <img
              alt=""
              className="w-6 h-6 mx-auto"
              src={`/images/icons/icon-dashboard-landing/${
                info.getValue() ? 'published.svg' : 'unpublished.svg'
              }`}
            />
          </div>
        ),
      }),
      // columnHelper.accessor('userId', {
      //   header: () => <span className="pl-4 text-xs sm:text-md">Listed by</span>,
      //   id: 'listedBy',
      //   cell: (info) => <>{info.renderValue()}</>,
      // }),
      columnHelper.accessor((row) => row.assignment, {
        header: () => (
          <span className="pl-4 text-xs sm:text-md whitespace-nowrap">{t('Assigned to')}</span>
        ),
        id: 'userId',
        cell: (info: any) => {
          if (!info.row.original.assignment) {
            return info.row.original.user.firstName + ' ' + info.row.original.user.lastName;
          } else {
            return (
              info.row.original.assignment.firstName + ' ' + info.row.original.assignment.lastName
            );
          }
        },
      }),
      columnHelper.accessor('propertyId', {
        id: 'edit',
        header: t('Action'),
        cell: (info) => (
          <EditDropdown
            handleDelete={handleDelete}
            property={info.row.original}
            users={users}
            handleAssignment={handleAssignment}
            handlePublication={handlePublication}
          />
        ),
        enableSorting: false,
      }),
    ];
    const mobileColumns = baseColumns.filter(
      (col: any) =>
        col.id === 'id' || col.id === 'propertyTitle' || col.id === 'userId' || col.id === 'edit'
    ) as ColumnDef<Property, any>[];

    return window.innerWidth <= 768 ? mobileColumns : baseColumns;
  }, [users, properties]); // ******************** //

  const table = useReactTable({
    data,
    columns,
    // filterFns: {
    //   fuzzy: fuzzyFilter,
    // },
    // state: {
    //   sorting,
    //   globalFilter,
    // },
    // onGlobalFilterChange: setGlobalFilter,
    // globalFilterFn: fuzzyFilter,
    // getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
    pageCount: Math.max(1, Math.floor(count / limit)),
  });

  useEffect(() => {
    if (currentBusiness.businessId) {
      setIsLoading(true);
      const colName = sorting[0]?.id ? sorting[0].id : 'updatedAt';
      const dir = sorting[0]?.desc ? 'desc' : 'asc';
      const params = {
        colName,
        dir,
        page: pageIndex,
        limit: limit,
      };

      fetchPropertiesByBusinessIdContext(currentBusiness.businessId, currency, search, params)
        .then((c) => {
          setCount(c);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [currentBusiness.businessId, pageIndex, currency, limit]);

  useEffect(() => {
    if (currentBusiness.businessId && (search || sorting)) {
      setIsLoading(true);
      const colName = sorting[0]?.id ? sorting[0].id : 'updatedAt';
      const dir = sorting[0]?.desc ? 'desc' : 'asc';
      const params = {
        colName,
        dir,
        page: 1,
        limit: limit,
      };
      setPageIndex(1);

      fetchPropertiesByBusinessIdContext(currentBusiness.businessId, currency, search, params)
        .then((c) => {
          setCount(c);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [sorting, search]);

  useEffect(() => {
    if (properties && properties.length > 0) {
      setData(() => [...properties]);
    }
  }, [properties]);

  if (properties && properties.length === 0 && search.length === 0) {
    return (
      <div className="justify-center h-[500px] items-center flex-col gap-10 flex w-full px-4">
        {t('no_listing_text')}
        <button
          onClick={() => navigate('/addproperty/1')}
          className="primary-btn whitespace-nowrap"
        >
          + {t('create a listing')}
        </button>
      </div>
    );
  } else {
    return (
      <div className="p-0 sm:p-2">
        <div className="flex flex-col justify-between">
          <div className="font-bold text-2xl  sm:text-5xl pt-4 px-4 sm:px-0 sm:pt-6">
            {t('Property listings')}
          </div>

          <div className="items-center grid grid-cols-3 py-4 px-4 sm:px-0">
            {!!properties.length && (
              <div className="flex">
                <div className="font-bold text-sm sm:text-xl">{t('listingsCount', { count })}</div>
                <div className="mx-auto">{!properties || isLoading ? <Loader /> : ''}</div>
              </div>
            )}
            <button
              onClick={() => navigate('/addproperty/1')}
              className="primary-btn whitespace-nowrap "
            >
              {t('create listing')}
            </button>
          </div>
        </div>
        <div className="pb-2">
          <DebouncedInput
            debounce={500}
            value={search ?? ''}
            onChange={(value) => setSearch(String(value))}
            className="p-2 text-xs rounded-md  border outline-deehiy border-deehiy focus:border-deehiy"
            placeholder={t('Search listings...')}
          />
        </div>
        <table className="w-full ">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer select-none flex items-center'
                            : 'hidden',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: <BiChevronUp className="w-4 h-4" />,
                          desc: <BiChevronDown className="w-4 h-4" />,
                        }[header.column.getIsSorted() as string] ?? (
                          <BsChevronExpand className="ml-1 w-4 h-4 text-gray-400" />
                        )}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {properties && properties.length === 0 ? (
            <tbody className="h-44 flex w-full mx-auto text-center items-center justify-center">
              {' '}
              no result for "{search}"
            </tbody>
          ) : (
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id} className="hover:bg-white">
                  {row.getVisibleCells().map((cell) => (
                    <td className="border border-transparent text-xs " key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          )}

          <tfoot>
            {table.getFooterGroups().map((footerGroup) => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.footer, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </tfoot>
        </table>

        {/* pagination */}
        <div className="h-2" />
        <div className="flex items-center gap-2">
          {/* <button
            className="border rounded p-1"
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            {'<<'}
          </button> */}
          <button
            className=""
            onClick={() => setPageIndex((prev) => prev - 1)}
            disabled={pageIndex === 1}
          >
            <img alt="prev" src="/images/icons/previous.svg" className="w-8 h-8" />
          </button>
          <button
            className=""
            onClick={() => setPageIndex((prev) => prev + 1)}
            disabled={pageIndex === table.getPageCount()}
          >
            <img alt="prev" src="/images/icons/next.svg" className="w-8 h-8" />
          </button>
          {/* <button
            className="border rounded p-1"
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            {'>>'}
          </button> */}
          <span className="flex items-center gap-1 text-gray-600">
            <div>{t('Page')}</div>
            <strong>
              {pageIndex} of {table.getPageCount()}
            </strong>
          </span>
          {/* <span className="flex items-center gap-1">
            | Go to page:
            <input
              type="number"
              defaultValue={table.getState().pagination.pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                table.setPageIndex(page);
              }}
              className="border border-gray-400 p-1 rounded w-12 focus:ring-deehiy focus:border-none"
            />
          </span> */}
          <select
            className="border border-deehiy py-1 px-2 text-sm rounded text-center focus:ring-deehiy focus:border-deehiy"
            value={limit}
            onChange={(e) => {
              setLimit(Number(e.target.value));
            }}
          >
            {[5, 10, 20].map((pageSize) => (
              <option key={pageSize} value={pageSize} className="border border-deehiy">
                {t('Show')} {pageSize}
              </option>
            ))}
          </select>
        </div>
        {/* <div>{table.getPrePaginationRowModel().rows.length} Rows</div> */}
      </div>
    );
  }
};

export default ListingsTable;
