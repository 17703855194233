export const resizeToBlob = async (
  file: File,
  maxWidth: number,
  maxHeight: number,
  quality: number,
  watermark: boolean
): Promise<File> => {
  console.log('in resize to blob');

  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      const image = new Image() as HTMLImageElement;
      image.src = this.result as string;
      image.onerror = function () {
        URL.revokeObjectURL(this.src);
      };
      image.onload = function () {
        // @ts-ignore
        URL.revokeObjectURL(this.src);

        // const aspectRatio = maxHeight === maxWidth ? 1 : maxHeight / maxWidth;
        const aspectRatio = maxHeight === maxWidth ? 1 : 16 / 9;

        const imageAspectRatio = image.width / image.height;
        let cropWidth: number, cropHeight: number;
        if (imageAspectRatio >= aspectRatio) {
          cropWidth = image.height * aspectRatio;
          cropHeight = image.height;
        } else {
          cropWidth = image.width;
          cropHeight = image.width / aspectRatio;
        }

        // Determine the dimensions of the resized image, maintaining aspect ratio
        let newWidth = maxWidth;
        let newHeight = maxHeight;
        // if (cropWidth > maxWidth) {
        //   newWidth = maxWidth;
        //   newHeight = (cropHeight / cropWidth) * maxWidth;
        // }
        // if (newHeight > maxHeight) {
        //   newWidth = (newWidth / newHeight) * maxHeight;
        //   newHeight = maxHeight;
        // }

        // Calculate the position to crop the image from the center, based on the crop dimensions
        const x = (image.width - cropWidth) / 2;
        const y = (image.height - cropHeight) / 2;

        const font = new FontFace('utm-avo', 'url(/images/icons/utm-avo.ttf)');
        font.load().then(() => {
          document.fonts.add(font);

          // Draw the image on a new canvas, using the calculated crop and resize dimensions
          const canvas = document.createElement('canvas');
          canvas.width = newWidth;
          canvas.height = newHeight;
          const ctx = canvas.getContext('2d');

          ctx?.drawImage(image, x, y, cropWidth, cropHeight, 0, 0, newWidth, newHeight);
          if (ctx && watermark) {
            // Add the watermark text
            ctx.font = '50px utm-avo';

            ctx.fillStyle = 'rgba(255, 255, 255, 0.4)';
            ctx?.fillText('deehiy', 1100, 60);
            ctx?.fillText('deehiy', 10, 680);
            ctx?.fillText('deehiy', 590, 340);
          }

          canvas.toBlob(
            (blob) => {
              const compressedFile = new File([blob as Blob], file.name, {
                type: file.type,
                lastModified: Date.now(),
              });
              resolve(compressedFile);
            },
            file.type,
            quality
          );
        });
      };
    };
  });
};
