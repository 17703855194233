import ItemIcon from '../ItemIcon';

const FilterCheckbox = ({ label, isChecked, check, title, Icon }: any) => {
  return (
    <div className="w-full flex justify-between my-3 px-4" title={title}>
      <span className="mr-2 flex gap-x-2 items-center">
        {Icon && <ItemIcon Icon={Icon} size={6} isSelected={false} />}
        {label}
      </span>
      <input
        type="checkbox"
        checked={isChecked}
        onChange={(e) => check(e.target.checked)}
        className={`focus:outline-none focus:ring-0 rounded border-gray-300 ${
          isChecked && 'text-deehiy'
        }`}
      />
    </div>
  );
};

export default FilterCheckbox;
