import React, { useState, useEffect } from 'react';
import ItemIcon from '../../main/ItemIcon';
import { featuresList } from '../../../data/data';
import { useTranslation } from 'react-i18next';

export const Feature: React.FC<any> = ({ feature, id, handleClick }: any) => {
  const { t } = useTranslation();

  return (
    <button
      onClick={() => {
        handleClick(id);
      }}
      className={`${
        feature.selected ? 'bg-deehiy-veryLight border-deehiy' : 'bg-[#fcfcfc] border-gray-200'
      } flex p-3 sm:p-4 text-deehiy group cursor-pointer duration-200 flex-col items-left rounded-2xl w-full border-2  hover:border-deehiy active:scale-90`}
    >
      <ItemIcon size={8} Icon={feature.SVG} />
      <p
        className={`${
          feature.selected ? 'text-deehiy' : 'text-gray-600'
        } flex justify-left pt-1 text-xs font-bold`}
      >
        {t(feature.title)}
      </p>
    </button>
  );
};

const Features: React.FC<any> = ({ editedProperty, setEditedProperty, setCanClickNext }: any) => {
  const [features, setFeatures] = useState(featuresList);
  const { t } = useTranslation();

  const handleClick = (i: number) => {
    const updatedFeatures = features.map((a: any, index: any) => {
      const updated = [...editedProperty.features];
      if (i === index) {
        a.selected = !a.selected;

        if (a.selected) {
          updated.push(a.title);

          setEditedProperty({
            ...editedProperty,
            features: updated,
          });
        } else {
          const indexToRemove = editedProperty.features.indexOf(a.title);
          updated.splice(indexToRemove, 1);

          setEditedProperty({
            ...editedProperty,
            features: updated,
          });
        }
      }
      return a;
    });

    setFeatures(updatedFeatures);
  };

  // useEffect(() => {}, [editedProperty.features])

  useEffect(() => {
    if (editedProperty.features) {
      // we extract the featureTitle so that it become a simple array of strings
      const alreadySelected = [...editedProperty.features];
      // we set the boolean for each feature
      const updatedFeatures = features.map((am) => {
        if (alreadySelected.includes(am.title)) {
          am.selected = true;
        } else {
          am.selected = false;
        }
        return am;
      });
      setFeatures(updatedFeatures);
    }

    setCanClickNext(true);
  }, []);

  return (
    <>
      <div className="container p-4 mx-auto h-auto overflow-y-scroll custom-scrollbar px-4">
        <div className="text-sm sm:text-md lg:text-lg font-light pb-4">
          {t('Do you have any standout features?')}
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-3 gap-2 sm:gap-3">
          {features.map((feature, i: any) => {
            if (feature.category === 'basic') {
              return <Feature handleClick={handleClick} key={i} id={i} feature={feature} />;
            }
          })}
        </div>

        <div className="font-bold text-sm pt-8 pb-4">
          {t('Do you have any standout amenities')}?
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-3 gap-2 sm:gap-3">
          {features.map((feature, i: any) => {
            if (feature.category === 'standoutAmenities') {
              return <Feature handleClick={handleClick} key={i} id={i} feature={feature} />;
            }
          })}
        </div>
        <div className="font-bold text-sm pt-8 pb-4">{t('how is the view?')}</div>
        <div className="grid grid-cols-2 sm:grid-cols-3 gap-2 sm:gap-3">
          {features.map((feature, i: any) => {
            if (feature.category === 'views') {
              return <Feature handleClick={handleClick} key={i} id={i} feature={feature} />;
            }
          })}
        </div>
        <div className="font-bold text-sm pt-8 pb-4">
          {t('Do you have any of these safety items?')}
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-3 gap-2 sm:gap-3">
          {features.map((feature, i: any) => {
            if (feature.category === 'security') {
              return <Feature handleClick={handleClick} key={i} id={i} feature={feature} />;
            }
          })}
        </div>
      </div>
    </>
  );
};

export default Features;
