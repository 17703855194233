import React, { useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { huntFormSchema } from '../../lib/yup';
import Input from '../form/Input';
import Select from '../form/Select';
import Loader from '../main/Loader';
import { useHunt } from '../../contexts/hunt';
import { CONDO, HOUSE, LAND, TOWNHOUSE } from '../../constants/constant';
// import { useHunt } from '../../contexts/hunt';
import { countries } from '../../data/data';
import { SelectMenuOption } from '../form/countries/Selector';
import LocationSearch from '../steps/propertySteps/Location/LocationSearch';
import { Hunt } from '../../types/types';
import { NumericFormat } from 'react-number-format';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const requiredFieldsByType = [
  {
    propertyType: HOUSE,
    areaRadius: true,
    price: true,
    bedrooms: true,
    homeSize: false,
    lotSize: false,
    location: true,
    postalCode: false,
  },
  {
    propertyType: TOWNHOUSE,
    areaRadius: true,
    price: true,
    bedrooms: true,
    homeSize: false,
    lotSize: false,
    location: true,
    postalCode: false,
  },
  {
    propertyType: CONDO,
    areaRadius: true,
    price: true,
    bedrooms: true,
    homeSize: false,
    lotSize: false,
    location: true,
    postalCode: false,
  },
  {
    propertyType: LAND,
    areaRadius: true,
    price: true,
    bedrooms: false,
    homeSize: false,
    lotSize: true,
    location: true,
    postalCode: false,
  },
];

const HuntForm = ({ setCanClickNext }: any) => {
  const { setCurrentHuntContext, currentHunt } = useHunt();
  const { t } = useTranslation();
  const { type } = useParams();

  const [locationString, setLocationString] = useState('');
  const [locationObject, setLocationObject] = useState({
    city: '',
    country: '',
    latitude: 0,
    longitude: 0,
    postalCode: '',
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(huntFormSchema),
  });

  const findIsoCodeCurrency = (selectedCountry: string) => {
    const code = countries.find(
      (option: any) => option.value === selectedCountry
    ) as SelectMenuOption;
    if (!code) return 'THB';

    return code.currency;
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoading(false);
  };

  const handleChange = (e: any) => {
    const value = e.target.value;
    const name = e.target.name;
    setCurrentHuntContext({ ...currentHunt, [name]: value });
  };

  const handlePriceChange = (e: any) => {
    let value = e.target.value; // string
    const formatted = Number(value.replace(/ /g, ''));
    setCurrentHuntContext({ ...currentHunt, price: formatted });
  };

  const checkRequiredFields = (hunt: Hunt) => {
    const requiredFields: any = requiredFieldsByType.find(
      (fields) => fields.propertyType === hunt.propertyType
    );
    // console.log('current hunt:', currentHunt);
    if (requiredFields) {
      const requiredProperties = Object.keys(requiredFields).filter(
        (key) => key !== 'propertyType' && requiredFields[key]
      );

      for (const property of requiredProperties) {
        // console.log(property, hunt[property]);
        if (hunt[property] === '' || hunt[property] === null) {
          return false;
        }
      }
      if (hunt.price === 0) return false;

      return true;
    }

    return false;
  };

  const handleRadioChange = (event: any) => {
    const { value } = event.target;
    if (value === 'buy') {
      setCurrentHuntContext({ ...currentHunt, isForSale: true, isForRent: false });
    } else if (value === 'rent') {
      setCurrentHuntContext({ ...currentHunt, isForRent: true, isForSale: false });
    }
  };

  useEffect(() => {
    console.log('current hunt', currentHunt);

    if (checkRequiredFields(currentHunt)) {
      setCanClickNext(true);
    } else {
      setCanClickNext(false);
    }
  }, [currentHunt]);

  useEffect(() => {
    if (currentHunt.propertyType === LAND) {
      setCurrentHuntContext({
        ...currentHunt,
        bedrooms: 0,
        homeSize: 0,
        lotSize: 0,
      });
    }
    if (currentHunt.propertyType === CONDO) {
      setCurrentHuntContext({ ...currentHunt, lotSize: 0 });
    }
  }, [currentHunt.propertyType]);

  useEffect(() => {
    console.log('location string', locationString);
    console.log('location object', locationObject);

    setCurrentHuntContext({
      ...currentHunt,
      location: locationString,
      postalCode: locationObject.postalCode ? locationObject.postalCode : '',
      city: locationObject.city,
      isoCodeCurrency: findIsoCodeCurrency(locationObject.country),
      isoCodeCountry: locationObject.country,
      latitude: locationObject.latitude,
      longitude: locationObject.longitude,
    });
  }, [locationObject]);

  useEffect(() => {
    if (type && type === 'buy') {
      setCurrentHuntContext({ ...currentHunt, isForSale: true, isForRent: false });
    } else if (type && type === 'rent') {
      setCurrentHuntContext({ ...currentHunt, isForSale: false, isForRent: true });
    }
  }, [type]);

  return (
    <div className="mx-auto w-full px-10">
      <span className="font-bold flex sm:hidden py-6">{t('what are you hunting?')}</span>
      {isLoading && <Loader />}
      <FormProvider {...methods}>
        <form
          className="flex flex-col text-gray-600 space-y-2 sm:space-y-6"
          onSubmit={(e) => handleSubmit(e)}
        >
          <div className="flex w-full py-2 gap-x-12">
            <div className="flex items-center">
              <input
                type="radio"
                id="buy"
                name="propertyOption"
                value="buy"
                checked={currentHunt.isForSale}
                onChange={handleRadioChange}
                className="mr-2 text-deehiy h-6 w-6 focus:none focus:outline-none focus:ring-0"
              />
              <label htmlFor="buy" className="text-gray-700 text-sm">
                {t('Buy')}
              </label>
            </div>
            <div className="flex items-center">
              <input
                type="radio"
                id="rent"
                name="propertyOption"
                value="rent"
                checked={currentHunt.isForRent}
                onChange={handleRadioChange}
                className="mr-2 text-deehiy h-6 w-6 focus:none focus:outline-none focus:ring-0"
              />
              <label htmlFor="rent" className="text-gray-700 text-sm">
                {t('Rent')}
              </label>
            </div>
          </div>

          <div>
            <label className="flex mb-1 gap-x-4 text-base sm:text-sm font-normal">
              {t('location')}
            </label>
            <div className="bg-white pl-2 flex items-center border border-gray-300 focus:border-deehiy rounded-xl ">
              <LocationSearch
                locationString={locationString}
                setLocationString={setLocationString}
                setLocationObject={setLocationObject}
                label=""
              />
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-y-2 sm:gap-y-6 gap-x-6 sm:gap-x-8">
            {/* property type */}
            <div>
              <Select
                value={currentHunt.propertyType ? currentHunt.propertyType : ''}
                onChange={handleChange}
                id="propertyType"
                label={t('property type')}
              >
                <option value="" disabled>
                  {t('Select property type')}
                </option>
                <option value={HOUSE}>{t(HOUSE)}</option>
                <option value={CONDO}>{t(CONDO)}</option>
                <option value={TOWNHOUSE}>{t(TOWNHOUSE)}</option>
                <option value={LAND}>{t(LAND)}</option>
              </Select>
            </div>
            {/* areaRadius */}
            <div className="w-full">
              <Select
                value={currentHunt.areaRadius ? currentHunt.areaRadius : ''}
                onChange={handleChange}
                name="areaRadius"
                id="areaRadius"
                label={t('area radius')}
              >
                <option value="" disabled>
                  {t('Define radius')}
                </option>
                <option value="0.5">0.5 km</option>
                <option value="1">1 km</option>
                <option value="1.5">1.5 km</option>
                <option value="2">2 km</option>
                <option value="3">3 km</option>
                <option value="5">5 km</option>
                <option value="10">10 km</option>
                <option value="20">20 km</option>
              </Select>
            </div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-y-2 sm:gap-y-6 gap-x-6 sm:gap-x-8">
            {/* minHomeSize */}
            {/* {currentHunt.propertyType !== LAND && (
              <Input
                name="homeSize"
                id="homeSize"
                type="number"
                label={`${t('home size')} (${t('in sq.m')})`}
                value={currentHunt.homeSize ? currentHunt.homeSize : ''}
                placeholder=""
                onChange={handleChange}
              />
            )} */}
            {/* minLotSize */}
            {currentHunt.propertyType === LAND && (
              <Input
                name="lotSize"
                id="lotSize"
                type="number"
                label={`${t('lot size')} (${t('in sq.m')})`}
                value={currentHunt.lotSize ? currentHunt.lotSize : ''}
                placeholder=""
                onChange={handleChange}
              />
            )}
            {/* minBedrooms */}
            {currentHunt.propertyType !== LAND && (
              // <Input
              //   name="bedrooms"
              //   id="bedrooms"
              //   type="number"
              //   label={t('number of bedrooms')}
              //   value={currentHunt.bedrooms ? currentHunt.bedrooms : ''}
              //   placeholder=""
              //   onChange={handleChange}
              // />
              <div>
                <Select
                  value={currentHunt.bedrooms ? currentHunt.bedrooms : ''}
                  onChange={handleChange}
                  type="number"
                  name="bedrooms"
                  id="bedrooms"
                  label={t('bedrooms')}
                >
                  <option value="" disabled>
                    {t('Select number of rooms')}
                  </option>
                  <option value="0">Studio</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9+</option>
                </Select>
              </div>
            )}
            {/* average Price / budget */}
            {/* <Input
              name="price"
              id="price"
              type="number"
              label={`budget
                (in ${currentHunt.isoCodeCurrency})`}
              value={currentHunt.price}
              placeholder=""
              onChange={handleChange}
            /> */}
            <div className="flex flex-col">
              <label className="text-gray-700 text-base sm:text-sm mb-1">
                {/* budget{currentHunt.isForRent && ' / month'} (in {currentHunt.isoCodeCurrency}) */}
                {t('budget_label', {
                  isForRent: currentHunt.isForRent ? t('per_month') : '',
                  currency: currentHunt.isoCodeCurrency,
                })}
              </label>
              <NumericFormat
                id="price"
                name="price"
                className="w-full focus:ring-deehiy rounded border-gray-300 focus:border-gray-300"
                onChange={handlePriceChange}
                value={currentHunt.price}
                thousandSeparator=" "
                placeholder="0"
                // suffix={currentHunt.isoCodeCurrency || ''}
              />
            </div>
          </div>
        </form>
      </FormProvider>
      {/* <pre>{JSON.stringify(currentHunt, null, 2)}</pre>{' '} */}
    </div>
  );
};

export default HuntForm;
