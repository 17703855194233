import React, { useState, useEffect, useRef } from 'react';
import { useProperty } from '../../../contexts/property';
import { CustomInput } from '../../steps/propertySteps/Availability';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format, parseISO } from 'date-fns';

const AvailabilityContent = ({ modifyProperty }: any) => {
  const { currentProperty, setCurrentProperty } = useProperty();

  const firstUpdate = useRef(true);

  const [date, setDate] = useState(
    currentProperty.availability ? parseISO(currentProperty.availability as string) : new Date()
  );

  // Function to handle radio button change
  const handleRadioChange = (e: any) => {
    if (e.target.value === 'now') {
      setCurrentProperty({ ...currentProperty, availability: null });
    } else {
      setCurrentProperty({
        ...currentProperty,
        availability: date && format(date, 'yyyy-MM-dd HH:mm:ss'),
      });
    }
  };

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    modifyProperty(currentProperty);
  }, [currentProperty.availability, currentProperty.frequency]);

  useEffect(() => {
    setCurrentProperty({
      ...currentProperty,
      availability: date && format(date, 'yyyy-MM-dd HH:mm:ss'),
    });
  }, [date]);

  return (
    <div className="flex-col w-full">
      <div className="flex pt-2 text-sm gap-x-4 items-center">
        <input
          type="radio"
          className={`focus:outline-none focus:ring-0 h-4 w-4 border-gray-300 ${
            currentProperty.availability === null ? 'text-deehiy' : 'text-white'
          }`}
          name="dateOption"
          value="now"
          onChange={handleRadioChange}
          checked={currentProperty.availability === null}
        />
        <div className=" font-normal">Now</div>
      </div>
      <div className="flex text-sm gap-x-4 items-center">
        <input
          type="radio"
          className={`focus:outline-none focus:ring-0 h-4 w-4 border-gray-300 ${
            currentProperty.availability !== null ? 'text-deehiy' : 'text-white'
          }`}
          name="dateOption"
          value="selectDate"
          onChange={handleRadioChange}
          checked={currentProperty.availability !== null}
        />
        <div className="gap-x-4 flex items-center">
          <div className=" font-normal">From</div>
          <DatePicker
            selected={date}
            minDate={new Date()}
            placeholderText={'Select date'}
            onChange={(d: Date) => setDate(d)}
            disabled={currentProperty.availability === null}
            customInput={<CustomInput value={date.toLocaleDateString()} />} // Pass date for display
            shouldCloseOnSelect={false}
            className={` ${
              currentProperty.availability === null
                ? 'opacity-50 bg-gray-400 cursor-not-allowed text-gray-200'
                : 'bg-white cursor-pointer'
            } rounded-xl focus:outline-none  outline-transparent focus:ring-0 focus:border-deehiy border`}
          />
        </div>
      </div>

      <div className="text-sm sm:text-sm font-light pt-12 pb-3">
        <p>Contract period</p>
      </div>

      <div className="flex space-x-12">
        <div>
          <input
            type="radio"
            id="yearly"
            value="yearly"
            checked={currentProperty.frequency === 'yearly'}
            onChange={() =>
              setCurrentProperty({ ...currentProperty, frequency: 'yearly', hasDailyRental: false })
            }
            className="sr-only"
          />
          <label
            htmlFor="yearly"
            className={`${
              currentProperty.frequency === 'yearly'
                ? 'bg-deehiy-veryLight border-deehiy'
                : 'bg-[#fcfcfc] border-gray-200'
            } w-36 flex p-3 sm:p-4 group cursor-pointer text-center flex-col items-left rounded-2xl border-2 hover:border-deehiy`}
          >
            Yearly
          </label>
        </div>
        <div>
          <input
            type="radio"
            id="monthly"
            value="monthly"
            checked={currentProperty.frequency === 'monthly'}
            onChange={() => setCurrentProperty({ ...currentProperty, frequency: 'monthly' })}
            className="sr-only"
          />
          <label
            htmlFor="monthly"
            className={`${
              currentProperty.frequency === 'monthly'
                ? 'bg-deehiy-veryLight border-deehiy'
                : 'bg-[#fcfcfc] border-gray-200'
            } w-36 flex p-3 sm:p-4 group cursor-pointer text-center flex-col items-left rounded-2xl  border-2 hover:border-deehiy `}
          >
            Monthly
          </label>
        </div>
      </div>
    </div>
  );
};

export default AvailabilityContent;
