import React from 'react';
import ProfileForm from './ProfileForm';
import BusinessAccessControl from './BusinessAccessControl';
import { EDIT_BUSINESS } from '../../constants/constant';
import TakeMeBack from '../main/navigation/TakeMeBack';

const BusinessProfilePage = () => {
  return (
    <div>
      <div className="px-20 pt-6">
        <TakeMeBack />
      </div>
      <BusinessAccessControl action={EDIT_BUSINESS} />
      <ProfileForm />
    </div>
  );
};

export default BusinessProfilePage;
