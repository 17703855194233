import http from '../httpService';
// import apiUrl  from "../../config.json"

// const apiEndpoint = apiUrl + "/auth"
const apiEndpoint = '/api/auth';

export const login = (email: string, password: string) => {
  return http.post(apiEndpoint, {
    email,
    password,
  });
};
