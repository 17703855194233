import React from 'react';
import { FaUserAstronaut } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const Notifications = () => {
  const { t } = useTranslation();

  return (
    <div className="flex px-4 py-8 sm:py-0 sm:p-20 container justify-center items-center mx-auto">
      <div className="text-3xl sm:text-5xl font-light text-gray-500">
        {t('This feature is coming soon')}
      </div>
      <FaUserAstronaut className="w-36 h-36 text-deehiy" />
    </div>
  );
};

export default Notifications;
