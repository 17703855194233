import React from 'react';
import FilterTop from './FilterTop';

const FilterContainer = ({ children, setIsFilterOpen, title }: any) => {
  return (
    <div className="no-scrollbar h-full overflow-y-auto flex flex-col text-sm w-full ">
      <FilterTop setIsFilterOpen={setIsFilterOpen} title={title} />
      {children}
    </div>
  );
};

export default FilterContainer;
