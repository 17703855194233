import React, { useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link } from 'react-router-dom';
import { uploadPropertyImagesFormSchema } from '../../../lib/yup';
import DropzoneInput, { FileStateObject } from '../../form/DropzoneInput';
import { useTranslation } from 'react-i18next';

const UploadPropertyImages: React.FC<any> = ({
  editedProperty,
  setEditedProperty,
  // handleClick,
  setCanClickNext,
}) => {
  const methods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(uploadPropertyImagesFormSchema),
  });
  const { t } = useTranslation();

  // const [isAccepted, setIsAccepted] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<FileStateObject[]>(
    editedProperty.imagesTempFiles
  );

  const updateTempsFiles = async (type = 'images') => {
    if (typeof uploadedFiles !== 'undefined') {
      if (type !== 'images') {
        setEditedProperty({ ...editedProperty, floorplansTempFiles: uploadedFiles });
        // setEditedProperty({ ...editedProperty, cloudinaryFps: uploadedFiles });
      } else {
        setEditedProperty({ ...editedProperty, imagesTempFiles: uploadedFiles });
        // setEditedProperty({ ...editedProperty, cloudinaryImages: uploadedFiles });
      }
    }
  };

  useEffect(() => {
    setCanClickNext(editedProperty.imagesTempFiles.length >= 5);
  }, [editedProperty.imagesTempFiles]);

  useEffect(() => {
    updateTempsFiles();
  }, [uploadedFiles]);

  const handleSubmitFiles = (e: any) => {
    e.preventDefault();
  };

  return (
    <div className="step-form-wrapper flex-col  w-full px-10">
      <FormProvider {...methods}>
        <form onSubmit={handleSubmitFiles} className=" mt-4 space-y-4">
          <DropzoneInput
            label=""
            id="property_images"
            type="property_images"
            accept="image/png, image/jpg, image/jpeg"
            // helperText=''
            maxFiles={20}
            minFiles={5}
            setUploadedFiles={setUploadedFiles}
            uploadedFiles={editedProperty.imagesTempFiles}
          />

          {/* <button
            onClick={() => handleClick('next')}
            className={
              ' whitespace-nowrap cursor-pointer font-semibold text-base text-deehiy 2xl:text-xl py-1 px-2 underline'
            }
          >
            Add Plans
          </button> */}
          <div className="flex">
            {/* <input
              className='w-4 h-4 text-deehiy bg-gray-100 border-gray-300 rounded focus:ring-deehiy dark:focus:ring-deehiy dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"'
              type="checkBox"
              onClick={() => setIsAccepted((prev) => !prev)}
            /> */}

            <div className="text-sm font-light text-justify w-full flex flex-wrap">
              <span className="whitespace-nowrap">&nbsp;{t('by_submitting')}</span>{' '}
              <Link
                className="text-deehiy flex whitespace-nowrap"
                to="/contentpolicy"
                target="_blank"
              >
                &nbsp;{t('Content Policy')}
              </Link>
              <span className="whitespace-nowrap">&nbsp;{t('and agree with the')}</span>
              <Link
                className="text-deehiy whitespace-nowrap flex"
                to="/termsofservice"
                target="_blank"
              >
                &nbsp;{t('Terms of Service')}
              </Link>
            </div>
          </div>

          {/* {editedProperty.imagesTempFiles.length < 5 && isAccepted ? (
            <div className="text-red-400 text-xs text-center font-bold p-1 mb-3 ">
              {' '}
              {t('You need to upload at least 5 files before you can submit')}
            </div>
          ) : (
            ''
          )} */}
        </form>
      </FormProvider>
    </div>
  );
};

export default UploadPropertyImages;
