import { Action } from '../types/types';
import { SET_HUNTS, SET_CURRENT_HUNT } from '../actions/actions';

export const huntReducer = (state: any, action: Action) => {
  switch (action.type) {
    case SET_HUNTS:
      return { ...state, hunts: action.payload };
    case SET_CURRENT_HUNT:
      return { ...state, currentHunt: action.payload };
    default:
      return state;
  }
};
