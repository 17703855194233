import React from 'react';
import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';
import { HiExclamationCircle } from 'react-icons/hi';

export type TextareaProps = {
  label: string;
  id: string;
  placeholder?: string;
  helperText?: string;
  readOnly?: boolean;
  validation?: any;
  rows: number;
  cols: number;
  maxLength: number;
  textLength: number;
} & React.ComponentPropsWithoutRef<'textarea'>;

export default function Textarea({
  label,
  placeholder = '',
  helperText,
  id,
  readOnly = false,
  validation,
  rows = 3,
  cols = 3,
  maxLength = 32,
  textLength = 0,
  ...rest
}: TextareaProps) {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  // const handleChange = (e: any) => {
  //   // if (textLength >= 32) return
  //   setTextLength(e.target.value.length)
  // }

  return (
    <div className="w-full">
      <label htmlFor={id} className="block text-sm sm:text-md lg:text-md font-normal text-gray-600">
        {label}
      </label>
      <div className="relative mt-1">
        <textarea
          {...register(id, validation)}
          {...rest}
          name={id}
          id={id}
          readOnly={readOnly}
          placeholder={placeholder}
          aria-describedby={id}
          rows={rows}
          cols={cols}
          maxLength={maxLength}
          className={clsx(
            readOnly
              ? 'form-field=readOnly'
              : errors[id]
              ? 'focus:ring-red-500 border-red-500 focus:border-red-500'
              : 'form-field',
            'block w-full rounded-md shadow-sm'
          )}
          // onChange={handleChange}
        />
        <span className="text-sm text-gray-400 flex justify-end pt-1">
          limit {textLength}/ {maxLength}
        </span>

        {errors[id] && (
          <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
            <HiExclamationCircle className="text-xl text-red-500" />
          </div>
        )}
      </div>
      <div className="mt-1">
        {helperText && <p className="text-xs text-gray-500">{helperText}</p>}
        {errors[id] && <span className="text-sm text-red-500">{/* {errors[id].message} */}</span>}
      </div>
    </div>
  );
}
