import React from 'react';
import { Slide, Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

import { MdOutlineArrowForwardIos, MdOutlineArrowBackIos } from 'react-icons/md';
import { adjustImageSize } from '../../utils/adjustImageSize';

const buttonStyle =
  'hidden md:flex w-18 h-18 p-4 text-2xl bg-gray-600 text-white rounded-full opacity-50 hover:opacity-90 hover:bg-white hover:text-gray-300 duration-300';

const properties = {
  prevArrow: (
    <button className={`${buttonStyle}`}>
      <MdOutlineArrowBackIos />
    </button>
  ),
  nextArrow: (
    <button className={`${buttonStyle}`}>
      <MdOutlineArrowForwardIos />
    </button>
  ),
};

const Slideshow = ({ list, defaultIndex }: any) => {
  const isMobile = window.innerWidth <= 768;

  if (list.length === 1) {
    return (
      <div className="flex min-h-full w-full">
        <img
          src={adjustImageSize(list[0].url)}
          alt="prop"
          className="w-screen h-screen object-contain"
        />
      </div>
    );
  }

  if (isMobile) {
    return (
      <section className="py-0 h-full mx-auto px-0">
        <Slide
          {...properties}
          slidesToScroll={1}
          slidesToShow={1}
          defaultIndex={defaultIndex}
          transitionDuration={300}
          indicators={false}
          arrows={true}
          canSwipe={true}
          autoplay={false}
        >
          {list.map((el: any, i: number) => (
            <div className="flex min-h-full w-full" key={i}>
              <img
                src={adjustImageSize(el.url)}
                alt="prop"
                className="w-screen h-screen object-contain"
              />
            </div>
          ))}
        </Slide>
      </section>
    );
  }

  return (
    <section className="py-0 h-full mx-auto px-0">
      <Fade
        {...properties}
        // slidesToScroll={1}
        // slidesToShow={1}
        defaultIndex={defaultIndex}
        transitionDuration={300}
        indicators={false}
        arrows={true}
        canSwipe={true}
        autoplay={false}
        // responsive={[
        //   {
        //     breakpoint: 800,
        //     settings: {
        //       slidesToShow: 1,
        //       slidesToScroll: 1,
        //     },
        //   },
        //   {
        //     breakpoint: 500,
        //     settings: {
        //       slidesToShow: 1,
        //       slidesToScroll: 1,
        //     },
        //   },
        //   {
        //     breakpoint: -300,
        //     settings: {
        //       slidesToShow: 1,
        //       slidesToScroll: 1,
        //     },
        //   },
        // ]}
      >
        {list.map((el: any, i: number) => (
          <div className="flex min-h-full w-full" key={i}>
            <img
              src={adjustImageSize(el.url)}
              alt="prop"
              className="w-screen h-screen object-contain"
            />
          </div>
        ))}
      </Fade>
    </section>
  );
};

export default Slideshow;
