import React from 'react';
import { useNavigate } from 'react-router-dom';
import AdminLabel from './AdminLabel';

const DashboardCard = ({ title, subTitle, icon, url, isComingSoon }: any) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate(url)}
      className="text-sm relative shadow-xl cursor-pointer p-8 rounded-lg flex-col hover:shadow-md duration-500"
    >
      {isComingSoon && (
        <div className="absolute w-full sm:h-10 md:h-14 top-[-0.8em]">
          <div className="flex w-full justify-evenly">
            <span></span>
            <img
              src={'/images/icons/icon-dashboard-landing/coming-soon.svg'}
              alt="ico"
              className="sm:h-10 md:h-14"
            />
          </div>
        </div>
      )}
      <img src={icon} alt="ico" className=" w-14 h-14" />
      <div className="font-bold">{title}</div>
      <div className="font-light">{subTitle}</div>
    </div>
  );
};

const AdminDashboardHomePage = () => {
  return (
    <div className="container">
      <AdminLabel />
      <div className="py-12 font-bold px-20">
        <div className=" text-5xl">Dashboard</div>
      </div>

      <div className="grid sm:grid-cols-1 md:grid-cols-3 gap-6 px-20 mx-auto">
        <DashboardCard
          title={'Listings info'}
          subTitle={'verify the listings'}
          icon={'/images/icons/icon-dashboard-landing/listings.svg'}
          url={'/admin/listings'}
          isComingSoon={false}
        />
        <DashboardCard
          title={'Users'}
          subTitle={'See all the users'}
          icon={'/images/icons/icon-dashboard-landing/users.svg'}
          url={'/admin/users'}
          isComingSoon={false}
        />
        <DashboardCard
          title={'Hunting-board'}
          subTitle={'Hunts & Matches'}
          icon={'/images/icons/icon-dashboard-landing/hunting-board.svg'}
          url={'/admin/huntingboard'}
          isComingSoon={false}
        />
        <DashboardCard
          title={'Businesses'}
          subTitle={'Manage businesses'}
          icon={'/images/icons/icon-dashboard-landing/business-profile-green.svg'}
          url={'/admin/businesses'}
          isComingSoon={false}
        />
        <DashboardCard
          title={'Manage Square'}
          subTitle={'Manage Square'}
          icon={'/images/icons/square-round.svg'}
          url={'/admin/square'}
          isComingSoon={false}
        />
        <DashboardCard
          title={'Insights'}
          subTitle={'Promoted Cards'}
          icon={'/images/icons/icon-dashboard-landing/insights.svg'}
          url={'/admin/promoted-cards'}
          isComingSoon={false}
        />
      </div>
    </div>
  );
};

export default AdminDashboardHomePage;
