import React, { useEffect } from 'react';
import { canAccess } from '../../utils/businessAccess';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import { Property } from '../../types/types';

const BusinessAccessControl = ({ property, action }: { property?: Property; action: string }) => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!canAccess(currentUser, action, property)) {
      alert('Sorry, your role does not allow you to access this page');
      navigate('/business/dashboard');
    }
  }, []);
  return <></>;
};

export default BusinessAccessControl;
