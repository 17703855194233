import React, { useState, useEffect } from 'react';
import Square from '../../components/square/Square';
// import InterestCarousel from '../../components/main/carousel/InterestsCarousel';
import OverlaySidebarLayout from '../../components/square/OverlaySidebarLayout';
import SquareFilter from '../../components/main/filter/SquareFilter';
import { useGlobal } from '../../contexts/global';
import { getPostsService } from '../../services/postService';
import { POST_TYPES } from '../../constants/constant';

export type SquareFilterType = {
  selectedTypes: string[];
  interests: string[];
  keyword: string;
  fromNetwork: boolean;
};

export const initialSquareFilter = {
  selectedTypes: [POST_TYPES.GENERAL, POST_TYPES.DISCUSSION, POST_TYPES.ARTICLE],
  interests: [],
  keyword: '',
  fromNetwork: false,
};

const SquarePage = () => {
  const { keyword } = useGlobal();
  const [dataList, setDataList] = useState<any[]>([]);
  const [filter, setFilter] = useState<SquareFilterType>(initialSquareFilter);
  const [tempFilter, setTempFilter] = useState(initialSquareFilter);

  const [filterState, setFilterState] = useState({ isInterestsOpen: false });
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  // const [isFilterOpen, setIsFilterOpen] = useState(window.innerWidth >= 768);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [count, setCount] = useState(0);

  // debounce
  const handleSearch = async (updatedFilter: SquareFilterType, currentPage: number) => {
    try {
      const res = await getPostsService({
        ...updatedFilter,
        page: currentPage,
      });

      const hasMorePosts = res.data.hasMore;
      if (currentPage === 1) {
        setDataList([...res.data.posts]);
      } else {
        setDataList((post) => [...post, ...res.data.posts]);
      }

      if (hasMorePosts) {
        setPage(page + 1);
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error('Error while fetching posts:', error);
    } finally {
    }
  };

  const handleShowResults = () => {
    handleSearch(tempFilter, 1);
    setFilter({ ...tempFilter }); // the temporary filter become the filter for scrolling
  };

  const fetchCount = async (updatedFilter: SquareFilterType) => {
    try {
      const res = await getPostsService(updatedFilter);
      setCount(res.data.totalCount);
    } catch (error) {
      console.error('Error while fetching posts:', error);
    } finally {
    }
  };

  const resetFilter = () => {
    setTempFilter({ ...initialSquareFilter, keyword });
  };

  // initial search
  useEffect(() => {
    return () => resetFilter();
  }, []);

  useEffect(() => {
    // Use refs to capture the latest state values
    const currentKeyword = keyword;
    const currentTempFilter = { ...tempFilter, keyword: currentKeyword };

    const delayDebounceFn = setTimeout(() => {
      // Use the latest filter object here
      setFilter((prev) => ({ ...prev, keyword: currentKeyword }));
      setTempFilter(currentTempFilter);
      handleSearch(currentTempFilter, 1); // Use the updated filter object
    }, 300); // 300ms delay, adjust as necessary

    return () => clearTimeout(delayDebounceFn); // Cleanup the timeout if the keyword changes again
  }, [keyword]);

  useEffect(() => {
    fetchCount(tempFilter);
  }, [tempFilter]);

  return (
    <div>
      <OverlaySidebarLayout
        isFilterOpen={isFilterOpen}
        setIsFilterOpen={setIsFilterOpen}
        // setIsFilterOpen={setIsFilterOpen}
        tempFilter={tempFilter}
        setTempFilter={setTempFilter}
        filterState={filterState}
        setFilterState={setFilterState}
        resetFilter={resetFilter}
        handleSearch={handleShowResults}
        count={count}
        Filter={SquareFilter}
        isFullWidth={false}
      >
        <Square
          posts={dataList}
          hasMore={hasMore}
          handleSearch={() => handleSearch(filter, page)}
        />
      </OverlaySidebarLayout>
    </div>
  );
};

export default SquarePage;
