import React from 'react';
import PropertiesGrid from './PropertiesGrid';
import OpenMapButton from './OpenMapButton';
// import Box from '../../../components/animation/Box';
import { useProperty } from '../../../contexts/property';
import ViewMap from '../../../components/listingMap/ViewMap';
import SortBy from '../filter/SortBy';
// import FilterSideButton from '../filter/FilterSideButton';

const Market = () => {
  const { showMap, setShowMap } = useProperty();
  return (
    <div className="mx-auto w-full flex flex-col">
      {showMap ? (
        <>
          <ViewMap />
        </>
      ) : (
        <>
          <SortBy />
          <PropertiesGrid />
        </>
      )}
      <OpenMapButton showMap={showMap} setShowMap={setShowMap} />
    </div>
  );
};

export default Market;
