import React, { useState, useEffect } from 'react';
// import CategoryIcon from '../main/FeatureIcon';
import { featuresList } from '../../data/data';
import { useHunt } from '../../contexts/hunt';
import { Feature } from '../steps/propertySteps/Features';
import { useTranslation } from 'react-i18next';

const HuntFeatures = () => {
  const [features, setFeatures] = useState(featuresList);
  const { currentHunt, setCurrentHuntContext } = useHunt();
  const { t } = useTranslation();

  const handleClick = (i: number) => {
    const currentFeatures = [...currentHunt?.features!];

    const updatedFeatures = features.map((a: any, index: any) => {
      if (i === index) {
        if (a.selected) {
          a.selected = !a.selected;
          // we remove the feature
          currentFeatures.splice(currentFeatures.indexOf(a.title), 1);
        } else {
          //we add the feature
          currentFeatures.push(a.title);
        }
      }
      setCurrentHuntContext({ ...currentHunt, features: currentFeatures as string[] });
      return a;
    });

    setFeatures(updatedFeatures);
  };

  useEffect(() => {
    if (currentHunt?.features) {
      const alreadySelected = [...currentHunt?.features];

      const updatedFeatures = features.map((f) => {
        if (alreadySelected.includes(f.title)) {
          f.selected = true;
        } else {
          f.selected = false;
        }
        return f;
      });
      setFeatures(updatedFeatures);
    }
  }, [currentHunt?.features]);

  useEffect(() => {
    if (currentHunt?.features?.length) {
      const alreadySelected = [...currentHunt.features];

      const updatedFeatures = features.map((f) => {
        if (alreadySelected.includes(f.title)) {
          f.selected = true;
        } else {
          f.selected = false;
        }
        return f;
      });
      setFeatures(updatedFeatures);
    }
  }, []);

  return (
    <div className="container p-4 mx-auto  rounded-2xl   ">
      <span className="font-bold flex sm:hidden py-6">{t('must have features')}</span>
      <div className="container p-4 mx-auto rounded-2xl border border-gray-300 h-[52vh] sm:h-[50vh] overflow-y-scroll custom-scrollbar ">
        <div className="text-xs sm:text-sm lg:text-lg font-light pb-4">
          {t('standout features')}
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
          {features.map((feature, i: any) => {
            if (feature.category === 'basic') {
              return <Feature handleClick={handleClick} key={i} id={i} feature={feature} />;
            }
          })}
        </div>

        <div className="font-light text-xs sm:text-sm pt-8 pb-4">{t('standout amenities')}</div>
        <div className="grid grid-cols-2 sm:grid-cols-3 gap-3">
          {features.map((feature, i: any) => {
            if (feature.category === 'standoutAmenities') {
              return <Feature handleClick={handleClick} key={i} id={i} feature={feature} />;
            }
          })}
        </div>
        <div className="font-light text-xs sm:text-sm pt-8 pb-4">{t('view')}</div>
        <div className="grid grid-cols-2 sm:grid-cols-3 gap-3">
          {features.map((feature, i: any) => {
            if (feature.category === 'views') {
              return <Feature handleClick={handleClick} key={i} id={i} feature={feature} />;
            }
          })}
        </div>
        <div className="font-light text-xs sm:text-sm pt-8 pb-4">{t('safety items')}</div>
        <div className="grid grid-cols-2 sm:grid-cols-3 gap-3">
          {features.map((feature, i: any) => {
            if (feature.category === 'security') {
              return <Feature handleClick={handleClick} key={i} id={i} feature={feature} />;
            }
          })}
        </div>
      </div>

      <div className="flex justify-end">
        <button
          type="button"
          className="p-2 px-3 text-xs shadow-md hover:opacity-90 hover:shadow-sm m-2 rounded-full bg-gray-200"
          onClick={() => setCurrentHuntContext({ ...currentHunt, features: [] })}
        >
          {t('clear all')}
        </button>
      </div>
    </div>
  );
};

export default HuntFeatures;
