import React, { useState } from 'react';
import Step1 from '../../components/business/createBusinessSteps/Step1';
import Step2 from '../../components/business/createBusinessSteps/Step2';
import Step3 from '../../components/business/createBusinessSteps/Step3';
import Step4 from '../../components/business/createBusinessSteps/Step4';

const steps = [Step1, Step2, Step3, Step4];

const AddBusiness = () => {
  const [index, setIndex] = useState(0);
  const [images, setImages] = useState<any>([]);
  const CurrentStep = ({ Step }: any) => (
    <Step setIndex={setIndex} images={images} setImages={setImages} />
  );

  return (
    <div className="px-6 h-full">
      <CurrentStep Step={steps[index]} />
    </div>
  );
};

export default AddBusiness;
