import React, { useState, useEffect } from 'react';
import { FiChevronUp } from 'react-icons/fi';

const FilterDropdown = ({ label, isOpen, setIsOpen, children, sectionHeight = 100 }: any) => {
  const [height, setHeight] = useState(isOpen ? `${sectionHeight}px` : '0');
  const isMobile = window.innerWidth <= 768;

  const handleClick = () => {
    setIsOpen();
    setHeight(isOpen ? '0' : `${sectionHeight}px`);
  };

  useEffect(() => {
    setHeight(isOpen ? `${sectionHeight}px` : '0');
  }, [isOpen]);

  if (isMobile)
    return (
      <div className="py-6 ">
        <div className="px-4 ">
          <span className="font-bold text-base">{label}</span>

          <div className="my-2">{children}</div>
        </div>
      </div>
    );

  return (
    <div className={` relative flex flex-col border border-transparent `}>
      <button
        onClick={handleClick}
        className={`${
          isOpen ? 'bg-gray-100' : 'bg-white'
        }  h-12 hover:bg-gray-100 font-bold px-4 group w-full text-defaultText flex items-center justify-between cursor-pointer`}
      >
        <span>{label}</span>
        <FiChevronUp
          className={`${
            isOpen ? 'text-gray-400 rotate-0' : 'text-gray-300 rotate-180'
          }  group-hover:text-gray-500 duration-500`}
        />
      </button>
      <div className="px-4 scroller duration-500 transition-height" style={{ height }}>
        <div className="my-2">{children}</div>
      </div>
    </div>
  );
};

export default FilterDropdown;
