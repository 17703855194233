import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface RegisterRedirectionProps {
  to: string;
}

const RegisterRedirection = ({ to }: RegisterRedirectionProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleRedirectionType = (newType: string) => {
    if (newType === 'login') {
      navigate(`/login`);
    } else {
      navigate('/register');
    }
  };

  return (
    <div className="flex justify-center mt-6">
      <p
        className="text-deehiy hover:text-gray-500 duration-200 text-[12px] md:text-[18px] cursor-pointer"
        onClick={() => handleRedirectionType(to)} // Update state based on 'to' prop
      >
        {to === 'login' ? t('already_have_account') : t('do_not_have_account')}
      </p>
    </div>
  );
};

export default RegisterRedirection;
