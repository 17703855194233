import React, { useState } from 'react';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TakeMeBack from '../main/navigation/TakeMeBack';
import { useAuth } from '../../contexts/auth';
import { useNavigate } from 'react-router-dom';
import { sendAppointAgentToAdmin } from '../../services/auth/userService';
import Loader from '../main/Loader';

const DeleteAccount = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const notify = async () => {
    if (!currentUser?.id) {
      navigate('/');
      return;
    }
    const confirmation = window.confirm('Are you sure you want to delete your account?');
    if (!confirmation) {
      // If the user clicks Cancel, exit the function
      return;
    }

    setIsLoading(true);

    const userId = currentUser?.id;
    try {
      if (userId) await sendAppointAgentToAdmin(userId, 1);
      toast.success('Good bye! Your account will be deleted in 30 days');
      navigate('/users/dashboard');
    } catch (err: any) {
      toast.error('oops, something went wrong');
      throw new Error(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col font-bold justify-center h-[70vh] items-center px-4 pt-12">
      <ToastContainer transition={Slide} />
      <TakeMeBack />
      <div className="container flex flex-col mx-auto justify-center items-center px-0 sm:px-44">
        {/* title */}
        <div className="text-2xl sm:text-5xl font-bold mx-auto py-6 text-center">
          We are sad to see you leave!
        </div>
        <div className="text-sm sm:text-xl mx-auto pb-6 sm:pb-24 text-center flex">
          Before you delete your account please consider that all your listings, connection and data
          will be removed permanently in 30 days and cannot be backed-up.
        </div>

        <div className="text-sm sm:text-2xl font-bold my-4 sm:my-8">Why are you leaving us?</div>
        <select className="text-center text-xs flex px-2 mx-4 sm:mx-auto sm:px-24 ring-0 focus:ring-0 outline-none ring-deehiy space-y-3 bg-white border-2 border-gray-800 rounded-2xl py-3">
          <option>I have a duplicate account</option>
          <option>I am leaving real estate for good</option>
          <option>I am not satisfied with the experience on Deehiy</option>
          <option>I am not happy about how Deehiy treats my private data</option>
          <option>Other</option>
        </select>
        {isLoading ? (
          <div className="flex w-full justify-center items-center">
            <Loader />
          </div>
        ) : (
          <button
            onClick={notify}
            className="bg-deehiy text-xs sm:text-sm shadow-md hover:opacity-90 rounded-full my-16 py-3 px-5 font-bold text-white "
          >
            good bye
          </button>
        )}
      </div>
    </div>
  );
};

export default DeleteAccount;
