import React from 'react';

const ContentPolicy = () => {
  return (
    <div className="container max-w-[850px] mx-auto space-y-6 py-10 px-4">
      <div className="font-light text-2xl text-center">Content Policy</div>
      <div className="text-sm font-light leading-loose ">
        At Deehiy we believe in quality and transparency and built a policy to achieve these two
        major goals. By posting content on Deehiy, you agree to abide by this policy. Content
        includes any written, photographic, audio, video, or other content, including:
        <br />
        <br />
        <b>Writing:</b> Listing titles and descriptions, profile pages, public and private reviews,
        feedback, Community Center posts, and messages to Deehiy, or Deehiy users.
        <br />
        <br />
        <b>Imagery:</b> Photos and videos, as well as imagery depicted within photos and videos
        (such as posters or art hanging on a wall)
        <br />
        <br />
        We reserve the right to remove any content, in whole or part, that violates this policy, our
        <a href="https://deehiy.com/termsofserviceTerms"> Terms of Service</a>, our{' '}
        <a href="##">Reviews Policy</a>, or for any other reason at our sole discretion. In the
        event of repeated or severe violations, we may also restrict, suspend, or remove the
        associated Deehiy account.
        <br />
        <br />
        <br />
        <div className="font-light text-xl mb-2">
          The following content is not allowed on Deehiy:
        </div>
        <ul className="text-sm space-y-2 list-disc font-extralight">
          <li>
            Content created for the purpose of advertising or other commercial content, including
            company logos, links, or company names
          </li>
          <li>
            Spam, unwanted contact, or content that is shared repeatedly in a disruptive manner
          </li>
          <li>
            Content that endorses or promotes illegal or harmful activity, or that is sexually
            explicit, violent, graphic, threatening, or harassing
          </li>
          <li>
            Content that is discriminatory (review our Nondiscrimination Policy for more
            information)
          </li>
          <li>
            Content that attempts to impersonate another person, account, or entity, including a
            representative of Deehiy
          </li>
          <li>
            Content that is illegal or that violates another person’s or entity’s rights, including
            intellectual property rights and privacy rights
          </li>
          <li>
            Content that includes another person’s private or confidential information, including
            content that is sufficient to identify an individual
          </li>
          <li>
            Content that may violate the privacy of third parties including vehicle number plates,
            phone numbers, or property identifications
          </li>
        </ul>
        <br />
        <br />
        <br />
        <div className="font-light text-xl mb-2">
          Additional policy violations for specific types of content:
        </div>
        <b>Listing titles</b>
        <ul className="text-sm space-y-2 list-disc font-extralight">
          <li>
            Listing titles that include information irrelevant to the listing type, style, or
            location
          </li>
          <li>Listing titles that include symbols or emojis</li>
        </ul>
        <br />
        <b>Listing or profile pages</b>
        <ul className="text-sm space-y-2 list-disc font-extralight">
          <li>
            Listings and profiles that provide fraudulent, false, misleading, or deceptive
            information
          </li>
        </ul>
        <br />
        <b>Community Center</b>
        <ul className="text-sm space-y-2 list-disc font-extralight">
          <li>
            Content that is off-topic, doesn’t ask a question, or doesn’t offer knowledge in
            response to a question as part of a larger discussion
          </li>
          <li>Harassing, trolling, or repeatedly targeting community members</li>
        </ul>
        <br />
        <b>Reviews</b>
        <ul className="text-sm space-y-2 list-disc font-extralight">
          <li>
            Reviews that violate our <a href="##">Reviews Policy</a>
          </li>
          <li>Harassing, trolling, or repeatedly targeting community members</li>
        </ul>
        <br />
        <b>Custom URLs</b>
        <ul className="text-sm space-y-2 list-disc font-extralight">
          <li>
            Personalized web addresses that don’t follow our <a href="##">Custom URL Policy</a>
          </li>
        </ul>
        <br />
        <br />
        <div className="font-light text-xl mb-2">
          How to report content that violates our policy
        </div>
        If you believe content violates this policy, you can report the content by{' '}
        <a href="mailto:contact@deehiy.com">contacting us</a>.
      </div>
    </div>
  );
};

export default ContentPolicy;
