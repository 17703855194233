import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/auth';

interface IProps {
  children: ReactNode;
}

const AdminProtected: React.FC<IProps> = ({ children }) => {
  const { currentUser } = useAuth();

  if (currentUser?.roles !== 'admin') {
    return <Navigate to="/" replace />;
  }
  return <>{children}</>;
};
export default AdminProtected;
